<div id="kt_header_mobile" class="kt-header-mobile  kt-header-mobile--fixed ">
	<div class="kt-header-mobile__logo">
		<a>
			<img alt="Logo" src="assets/imgs/minilogo.png" width="30">
		</a>
	</div>
	<div class="kt-header-mobile__toolbar">
		<button class="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left" id="kt_aside_mobile_toggler"><span></span></button>
	</div>
</div>
<div class="kt-grid kt-grid--hor kt-grid--root" style="position: fixed;z-index:100">
	<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
		<div class="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">

			<div class="kt-aside__brand kt-grid__item" style="background: #e7e7e7;" id="kt_aside_brand">
				<div class="kt-aside__brand-logo kt-padding-t-10">
					<a>
						<img alt="Logo" src="assets/imgs/minilogo.png" width="80" />
					</a>
				</div>
			</div>
			<div class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
				<button class="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn"><i class="la la-close"></i></button>
				<div id="kt_aside_menu" class="kt-aside-menu  kt-aside-menu--dropdown " data-ktmenu-vertical="1" data-ktmenu-dropdown="1" data-ktmenu-scroll="0">
					<ul class="kt-menu__nav">
						<li class="kt-menu__item" id="id_menu_Home">
							<a class="kt-menu__link" routerLink="/Home" routerLinkActive="active" (click)="GetUrl()">
								<i class="kt-menu__link-icon flaticon-home">
								</i>
								<span class="kt-menu__link-text">Home</span>
							</a>
						</li>
						<li *ngIf="idPerfil != '3' && idPerfil != '4'" class="kt-menu__item" id="id_menu_Cliente">
							<a class="kt-menu__link" routerLink="/Cliente" routerLinkActive="active" (click)="GetUrl()">
								<i class="kt-menu__link-icon fa fa-clinic-medical">
								</i>
								<span class="kt-menu__link-text">Clientes</span>
							</a>
						</li>
						<li *ngIf="idPerfil != '3'" class="kt-menu__item" id="id_menu_Paciente">
							<a class="kt-menu__link" routerLink="/Paciente" routerLinkActive="active" (click)="GetUrl()">
								<i class="kt-menu__link-icon flaticon-users">
								</i>
								<span class="kt-menu__link-text">Pacientes</span>
							</a>
						</li>
						<li *ngIf="idPerfil != '3' && idPerfil != '4'" class="kt-menu__item" id="id_menu_Reembolso">
							<a class="kt-menu__link" routerLink="/Reembolso" routerLinkActive="active" (click)="GetUrl()">
								<i class="kt-menu__link-icon flaticon2-medical-records-1">
								</i>
								<span class="kt-menu__link-text">Reembolso</span>
							</a>
						</li>
						<li *ngIf="idPerfil == '1'" class="kt-menu__item" id="id_menu_Usuario">
							<a class="kt-menu__link" routerLink="/Usuario" routerLinkActive="active" (click)="GetUrl()">
								<i class="kt-menu__link-icon flaticon-user-add">
								</i>
								<span class="kt-menu__link-text">Usuários</span>
							</a>
						</li>
						<li *ngIf="idPerfil == '1'" class="kt-menu__item" id="id_menu_Gerenciamento">
							<a class="kt-menu__link" routerLink="/Gerenciamento" routerLinkActive="active" (click)="GetUrl()">
								<i class="kt-menu__link-icon flaticon2-protected"></i>
								<span class="kt-menu__link-text">Gerenciamento</span>
							</a>
						</li>
						<li *ngIf="idPerfil != '3'  && idPerfil != '4'" class="kt-menu__item" id="id_menu_Notificacao">
							<a class="kt-menu__link" routerLink="/Notificacao" routerLinkActive="active" (click)="GetUrl()">
								<i class="kt-menu__link-icon flaticon2-mail"></i>
								<span class="kt-menu__link-text">Notificação</span>
							</a>
						</li>
						<li *ngIf="idPerfil == '4'" class="kt-menu__item" id="id_menu_Agenda">
							<a class="kt-menu__link" routerLink="/Agenda" routerLinkActive="active" (click)="GetUrl()">
								<i class="kt-menu__link-icon flaticon-calendar-with-a-clock-time-tools"></i>
								<span class="kt-menu__link-text">Agendamento</span>
							</a>
						</li>
						<li *ngIf="VisualizacaoCelular" class="kt-menu__item" id="id_menu_Home" (click)="Sair()">
							<a class="kt-menu__link" (click)="Sair()">
								<i class="kt-menu__link-icon flaticon2-delete">
								</i>
								<span class="kt-menu__link-text">Sair</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
			<div id="kt_header" class="kt-header kt-grid__item  kt-header--fixed " style="background: #5f7e7e;">
				<button class="kt-header-menu-wrapper-close" id="kt_header_menu_mobile_close_btn"><i class="la la-close"></i></button>
				<div class="kt-header__topbar menu">

					<div class="kt-header__topbar-item kt-header__topbar-item--user">
						<div class="kt-header__topbar-wrapper">
							<div class="kt-header__topbar-user kt-padding-t-15">
								<span class="kt-hidden kt-header__topbar-username kt-hidden-mobile" style="display: flex !important;">Bem-Vindo,</span>
								<span class="kt-hidden kt-header__topbar-username kt-hidden-mobile" style="display: flex !important;">{{nome}}</span>
								<span class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bolder" (click)="AbrirGerenciamento()"><i class="flaticon2-calendar-3 kt-font-success" style="font-size: 20px;"></i></span>
							</div>
						</div>
						<div class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl" style="{{mostrarGerenciamento ? 'position: absolute; transform: translate3d(-125px, 5px, 0px); top: 0px; left: 0px; will-change: transform; display: block;' : ''}}">
							<div class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x" style="background-image: url(/assets/bg/bg-1.jpg)">
								<i class="la la-close kt-font-success kt-pointer" style="position: fixed;top: 10px;right: 10px;font-size: 20px;" (click)="mostrarGerenciamento = false"></i>
								<div class="kt-user-card__avatar">
									<img class="kt-hidden" alt="Pic">
									<span class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">E</span>
								</div>
								<div class="kt-user-card__name">
									Olá
									<div class="kt-user-card__name kt-padding-l-0">
										{{nome}}
									</div>
								</div>
							</div>
							<div class="kt-notification">
								<a class="kt-notification__item  kt-pointer">
									<div class="kt-notification__item-icon">
										<i class="flaticon2-calendar-3 kt-font-success"></i>
									</div>
									<div class="kt-notification__item-details" (click)="AbrirSenha()">
										<div class="kt-notification__item-title kt-font-bold">
											Alterar Minha Senha
										</div>
										<div class="kt-notification__item-time">
											Clique para alterar sua senha!
										</div>
									</div>
									<i class="{{mostrarSenha ? 'flaticon2-up' : 'flaticon2-down'}} kt-font-success kt-pointer" (click)="AbrirSenha()"></i>
								</a>
								<a *ngIf="mostrarSenha">
									<div class="kt-notification__item-details kt-margin-10">
										<div class="kt-notification__item-title kt-font-bold">
											<label>Nova Senha:</label>
	                    <div class="kt-input-icon kt-input-icon--right">
												<input class="form-control" type="password" placeholder="Nova Senha" #TrocaSenha ngModel name="nova_senha" id="nova_senha" autocomplete="off">
												<div class="errorLabel kt-padding-0" *ngIf="TrocaSenha.value.length < 6 && TrocaSenha.value != ''">Senhas deve ter, no mínimo, 6 caracteres</div>
												</div>
										</div>
										<div class="kt-notification__item-title kt-font-bold">
											<label>Repita a Nova Senha:</label>
	                    <div class="kt-input-icon kt-input-icon--right">
												<input class="form-control" type="password" placeholder="Repita Nova Senha" #TrocaSenhaRepetida ngModel name="repita_senha" id="repita_senha" autocomplete="off">
			                  <div class="errorLabel kt-padding-0" *ngIf="TrocaSenha.value != TrocaSenhaRepetida.value && TrocaSenhaRepetida.value != ''">Senhas não conferem, digite as senhas iguais</div>
	                    </div>
										</div>
									</div>
									<div class="kt-notification__custom kt-space-between" style="float: right;">
										<button href="custom/user/login-v2.html" target="_blank" class="btn btn-label btn-sm btn-bold" (click)="AbrirSenha()">Cancelar</button>
										<button href="custom/user/login-v2.html" target="_blank" class="btn btn-label btn-label-brand btn-sm btn-bold" [disabled]="TrocaSenha.value != TrocaSenhaRepetida.value || TrocaSenha.value == ''" (click)="AlterarSenha(TrocaSenha.value)">Alterar</button>
									</div>
								</a>
								<div class="kt-notification__custom kt-space-between" *ngIf="!mostrarSenha" (click)="Sair()">
									<a class="btn btn-label btn-label-danger btn-sm btn-bold" (click)="Sair()" >Sair da Conta</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
