<div class="kt-portlet kt-padding-10" id="kt_portlet">
	<div class="kt-portlet__head">
		<div class="kt-portlet__head-label">
			<span class="kt-portlet__head-icon">
				<i class="flaticon-map-location"></i>
			</span>
			<h3 class="kt-portlet__head-title">
				Agenda de Consultas
			</h3>
		</div>
		<div class="kt-portlet__head-toolbar">
			<input type="text" class="form-control" id="id_agendamento" style="width: 1px;display:none" readonly="" placeholder="Selecione Fim da Agenda">

			<button type="reset" class="btn btn-primary alinhamento-botao" (click)="CarregarTelaCadastro()" *ngIf="MostrarLista"> <i class="la la-plus"></i>Nova Sessão</button>
		</div>
	</div>
	<div class="kt-portlet__body" *ngIf="MostrarLista">
		<div id="kt_calendar"></div>
	</div>
	<div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid kt-margin-t-10" *ngIf="!MostrarLista">
		<div class="row">
			<div class="col-lg-12">
				<div class="kt-portlet">
					<form [formGroup]="formCadastroAgenda">
						<div class="kt-portlet__body kt-margin-10">
							<div class="form-group row">
								<div class="col-lg-3">
									<label>Paciente:</label>
									<div class="kt-input-icon kt-input-icon--right">
										<ng-select [readonly]="bloqueioGeral" placeholder="SELECIONE" formControlName="no_titulo" name="no_titulo" notFoundText="Nenhum item encontrado"
										class="form-control ng-select" style="padding-right: 0;" (change)="GetInformacaoPaciente()">
											<ng-option *ngFor="let opt of ListaPaciente" [value]="opt.id_paciente">
												<font style="vertical-align: inherit;">{{opt.no_paciente}}</font>
											</ng-option>
										</ng-select>
									</div>
								</div>
								<div class="col-lg-3">
									<label>Inicio:</label>
									<div class="kt-input-icon kt-input-icon--right">
										<input [disabled]="bloqueioGeral" type="text" class="form-control" id="kt_datetimepicker_2" value="{{dt_inicio}}" name="dt_inicio" readonly="" placeholder="Selecione Inicio da Agenda">
									</div>
								</div>
								<div class="col-lg-3">
									<label>Fim:</label>
									<div class="kt-input-icon kt-input-icon--right">
										<input [disabled]="bloqueioGeral" type="text" class="form-control" id="kt_datetimepicker_3" readonly="" value="{{dt_fim}}" placeholder="Selecione Fim da Agenda">
									</div>
								</div>
								<div class="col-lg-3">
									<div class="row" [hidden]="formCadastroAgenda.value.id_agendamento != null">
										<div class="col-lg-6">
											<span class="kt-switch kt-switch--sm kt-margin-t-30">
												<label>
													<input type="checkbox" (click)="AbrirRecorencia()" [disabled]="formCadastroAgenda.value.id_agendamento != null" name="">Recorrência
													<span></span>
												</label>
											</span>
										</div>
									</div>
									<div class="row" [hidden]="formCadastroAgenda.value.id_agendamento == null">
										<div class="col-lg-12">
											<label>Atendimento:</label>
											<div class="kt-input-icon kt-input-icon--right">
												<ng-select [readonly]="bloqueio" placeholder="SELECIONE" formControlName="fg_consulta" name="fg_consulta" notFoundText="Nenhum item encontrado" class="form-control ng-select" style="padding-right: 0;">
													<ng-option *ngFor="let opt of ListaStatus" [value]="opt.id">
														<font style="vertical-align: inherit;">{{opt.descricao}}</font>
													</ng-option>
												</ng-select>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group row">
								<div class="col-lg-6">
									<label>Descrição:</label>
									<div class="kt-input-icon kt-input-icon--right">
										<input [readonly]="bloqueio" type="text" name="no_descricao" formControlName="no_descricao" class="form-control" placeholder="Digite a Descrição do Agendamento">
										<span [hidden]="!f.no_descricao.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_descricao.errors }"></i></span></span>
									</div>
								</div>
								<div class="col-lg-3">
									<label>Tipo de Pagamento:</label>
									<div class="kt-input-icon kt-input-icon--right">
										<ng-select [readonly]="bloqueio" placeholder="SELECIONE" formControlName="id_tipo_pagamento" name="id_tipo_pagamento" notFoundText="Nenhum item encontrado" class="form-control ng-select">
											<ng-option *ngFor="let opt of ListaTipoPagamento" [value]="opt.id_tipo_pagamento">
												<font style="vertical-align: inherit;">{{opt.ds_pagamento}}</font>
											</ng-option>
										</ng-select>
									</div>
								</div>
								<div class="col-lg-3">
									<label>Valor da Consulta:</label>
									<div class="kt-input-icon kt-input-icon--right">
										<input [readonly]="bloqueio || usoCreditoAgendamento || bloqueioBotaoExcluir" class="form-control" currencyMask formControlName="vl_consulta" placeholder="Digite o Valor Consulta" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',',align:'left' }" />
										<span [hidden]="!f.vl_consulta.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.vl_consulta.errors }"></i></span></span>
									</div>
								</div>
							</div>
							<div class="alert alert-warning fade show" role="alert" *ngIf="usoCreditoAgendamento" style="font-weight: 500;">
					      <div class="alert-icon"><i class="flaticon-warning"></i></div>
					      <div class="alert-text">Crédito utilizado para pagamento ( {{dt_credito | date : 'dd/MM/yyyy'}} )</div>
					    </div>
							<div *ngIf="MostrarRecorencia">
								<div class="kt-subheader  kt-grid__item" id="kt_subheader">
									<div class="kt-container  kt-container--fluid kt-padding-l-10">
										<div class="kt-subheader__main">
											<h4 class="kt-subheader__title">Cadastro da Recorrência</h4>
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-lg-1">
										<div class="kt-input-icon kt-input-icon--right">
											<input type="text" name="qtd" formControlName="qtd" class="form-control" placeholder="" style="display: unset;padding-right: 15px;width: 50px;margin-left: 10px;margin-right: 10px;">
										</div>
									</div>
									<div class="col-lg-2">
										<div class="kt-input-icon kt-input-icon--right">
											<div class="kt-input-icon kt-input-icon--right">
												<ng-select placeholder="SELECIONE" formControlName="tempo" name="tempo" notFoundText="Nenhum item encontrado" class="form-control ng-select" style="padding-right: 0;">
													<ng-option *ngFor="let opt of ListaTempo" [value]="opt.id">
														<font style="vertical-align: inherit;">{{opt.descricao}}</font>
													</ng-option>
												</ng-select>
											</div>
										</div>
									</div>
									<div class="col-lg-12 kt-margin-l-10" >
										<div class="kt-checkbox-inline">
											<label class="kt-checkbox">
												<input type="checkbox" formControlName="segunda" name="segunda"> Segunda-Feira
												<span></span>
											</label>
											<label class="kt-checkbox">
												<input type="checkbox" formControlName="terca" name="terca"> Terça-Feira
												<span></span>
											</label>
											<label class="kt-checkbox">
												<input type="checkbox" formControlName="quarta" name="quarta"> Quarta-Feira
												<span></span>
											</label>
											<label class="kt-checkbox">
												<input type="checkbox" formControlName="quinta" name="quinta"> Quinta-Feira
												<span></span>
											</label>
											<label class="kt-checkbox">
												<input type="checkbox" formControlName="sexta" name="sexta"> Sexta-Feira
												<span></span>
											</label>
											<label class="kt-checkbox">
												<input type="checkbox" formControlName="sabado" name="sabado"> Sábado
												<span></span>
											</label>
											<label class="kt-checkbox">
												<input type="checkbox" formControlName="domingo" name="domingo"> Domingo
												<span></span>
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
					<div class="kt-portlet__foot">
						<div class="kt-form__actions alinhamento-div-botao">
							<button type="reset" [hidden]="bloqueio || bloqueioBotaoExcluir" class="btn btn-danger alinhamento-botao" *ngIf="formCadastroAgenda.value.id_agendamento != null" (click)="CancelarEvento()">Excluir</button>
							<button type="reset" [hidden]="bloqueio" class="btn btn-primary alinhamento-botao" [disabled]="formCadastroAgenda.invalid" (click)="AdicionarEvento()">{{formCadastroAgenda.value.id_agendamento != null ? 'Atualizar' : 'Cadastrar'}}</button>
							<button type="reset" class="btn btn-secondary" (click)="Voltar()">Voltar</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!--end::Portlet-->
