<div class="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
  <div class="kt-subheader  kt-grid__item" id="kt_subheader">
    <div class="kt-container  kt-container--fluid ">
      <div class="kt-subheader__main">
        <h3 class="kt-subheader__title">Cadastro de Reembolso</h3>
        <span class="kt-subheader__separator kt-subheader__separator--v"></span>
        <span class="kt-subheader__desc"><small>Reembolso >> Cadastro de Reembolso</small></span>
      </div>
      <button type="reset" class="btn btn-secondary kt-margin-t-10" (click)="Voltar()" [routerLink]="'/Home'" *ngIf="idRedirecionamento != null">Voltar</button>
    </div>
  </div>
  <div class="row">
    <div class="card-body" [hidden]="!MostrarLista">
      <div class="accordion accordion-light accordion-toggle-arrow" id="accordionExample2">
        <div class="card">
          <div class="card-header" id="headingOne2">
            <div class="card-title collapsed" data-toggle="collapse" data-target="#collapseOne2" aria-expanded="false">Filtros de Pesquisa</div>
          </div>
          <div id="collapseOne2" class="collapse" data-parent="#accordionExample2" style="">
            <div class="card-body">
              <div class="row col-md-12">
                <div class="col-md-3">
                  <div class="kt-subheader__main">
                    <div class="kt-subheader__group" id="kt_subheader_search">
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-0">
                              <input type="search" class="form-control" placeholder="Pesquisar Cliente" name="generalSearch" [(ngModel)]="SearchProcedimento.no_fantasia" (keypress)="p = 1">
                              <span class="kt-input-icon__icon kt-input-icon__icon--right">
                                <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                      <rect x="0" y="0" width="24" height="24"></rect>
                                      <path
                                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                        fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                      <path
                                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                        fill="#000000" fill-rule="nonzero"></path>
                                    </g>
                                  </svg>
                                </span>
                              </span>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="kt-subheader__main">
                    <div class="kt-subheader__group" id="kt_subheader_search">
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-0">
                              <input type="search" class="form-control" placeholder="Pesquisar Paciente" name="generalSearch" [(ngModel)]="SearchProcedimento.no_paciente" (keypress)="p = 1">
                              <span class="kt-input-icon__icon kt-input-icon__icon--right">
                                <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                      <rect x="0" y="0" width="24" height="24"></rect>
                                      <path
                                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                        fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                      <path
                                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                        fill="#000000" fill-rule="nonzero"></path>
                                    </g>
                                  </svg>
                                </span>
                              </span>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="kt-subheader__main">
                    <div class="kt-subheader__group" id="kt_subheader_search">
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-0">
                              <!-- <input type="search" class="form-control" placeholder="Pesquisar Tipo de Processos" name="generalSearch"  > -->
                              <ng-select placeholder="Pesquisar Tipo de Processos" [(ngModel)]="SearchProcedimento.no_processo" (change)="p = 1" name="id_processo" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                                <ng-option *ngFor="let opt of ListaProcessoFiltro" [value]="opt.no_sigla">
                                  <font style="vertical-align: inherit;">{{opt.no_sigla}}</font>
                                </ng-option>
                              </ng-select>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="kt-subheader__main">
                    <div class="kt-subheader__group" id="kt_subheader_search">
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-0">
                              <!-- <input type="search" class="form-control" placeholder="Pesquisar Status" name="generalSearch" [(ngModel)]="SearchProcedimento.no_status" (keypress)="p = 1"> -->
                              <ng-select placeholder="Pesquisar Status" (change)="p = 1" [(ngModel)]="SearchProcedimento.no_status" name="pesquisar_status" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                                <ng-option *ngFor="let opt of ListaStatus" [value]="opt.no_nome">
                                  <font style="vertical-align: inherit;">{{opt.no_nome}}</font>
                                </ng-option>
                              </ng-select>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="kt-subheader__main">
                    <div class="kt-subheader__group" id="kt_subheader_search">
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 kt-margin-t-10">
                            <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-0">
                              <input type="search" class="form-control" placeholder="Pesquisar Tuss" name="generalSearch" [(ngModel)]="SearchProcedimento.nu_tuss" (keypress)="p = 1">
                              <span class="kt-input-icon__icon kt-input-icon__icon--right">
                                <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                      <rect x="0" y="0" width="24" height="24"></rect>
                                      <path
                                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                        fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                      <path
                                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                        fill="#000000" fill-rule="nonzero"></path>
                                    </g>
                                  </svg>
                                </span>
                              </span>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="kt-subheader__main">
                    <div class="kt-subheader__group" id="kt_subheader_search">
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 kt-margin-t-10">
                            <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-0">
                              <input type="search" class="form-control" placeholder="Pesquisar Carteira" name="generalSearch" [(ngModel)]="SearchProcedimento.no_carteira" (keypress)="p = 1">
                              <span class="kt-input-icon__icon kt-input-icon__icon--right">
                                <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                      <rect x="0" y="0" width="24" height="24"></rect>
                                      <path
                                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                        fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                      <path
                                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                        fill="#000000" fill-rule="nonzero"></path>
                                    </g>
                                  </svg>
                                </span>
                              </span>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="kt-subheader__main">
                    <div class="kt-subheader__group" id="kt_subheader_search">
                      <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 kt-margin-t-10">
                            <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-0">
                              <input type="search" class="form-control" placeholder="Pesquisar Operadora" name="generalSearch" [(ngModel)]="SearchProcedimento.no_plano" (keypress)="p = 1">
                              <span class="kt-input-icon__icon kt-input-icon__icon--right">
                                <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                      <rect x="0" y="0" width="24" height="24"></rect>
                                      <path
                                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                        fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                      <path
                                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                        fill="#000000" fill-rule="nonzero"></path>
                                    </g>
                                  </svg>
                                </span>
                              </span>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="kt-subheader   kt-grid__item" id="kt_subheader" [hidden]="!MostrarLista">
        <div class="row col-md-12">

          <div class="col-md-4">
            <div class="form-group has-feedback" style="float:left">
              <b class="totalRegistro"> Mostrar </b>
              <button type="button" class="btn btn-default dropdown-toggle countPage  " data-toggle="dropdown" aria-expanded="false">
                {{itemsPage}} <span class="caret"></span>
              </button>
              <ul class="dropdown-menu dropdown-menu-right dropCount kt-pointer" role="menu">
                <li>
                  <a (click)="itemsPage = 10">10</a>
                </li>
                <li>
                  <a (click)="itemsPage = 50">50</a>
                </li>
                <li>
                  <a (click)="itemsPage = 100">100</a>
                </li>
                <li>
                  <a (click)="itemsPage = 500">500</a>
                </li>
              </ul>
              <b class="totalRegistro"> Registros</b>
            </div>
          </div>
          <div class="col-md-8">
            <div class="kt-header__topbar" style="float:right">
              <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle">
                <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Carregar os Reembolsos" (click)="GetListaProcedimento()">
                  <span class="kt-header__topbar-icon"><i class="flaticon-refresh"></i></span>
                </div>
              </div>
              <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle">
                <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Adicionar Novo Reembolso" (click)="CarregarTelaCadastro()">
                  <span class="kt-header__topbar-icon"><i class="flaticon-plus"></i></span>
                </div>
              </div>
              <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" [hidden]="VisualizacaoCelular" *ngIf="idPerfil == '3'">
                <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Exportar Dados dos Reembolsos">
                  <span class="kt-header__topbar-icon">
                    <i class="flaticon-download">
                    </i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid" *ngIf="MostrarLista">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body kt-portlet__body--fit">
          <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded" id="kt_apps_user_list_datatable">
            <table class="table table-striped- table-hover table-checkable dataTable no-footer dtr-inline" id="kt_table_1">
              <thead>
                <tr role="row">
                  <th class="sorting">Cliente</th>
                  <th class="sorting">Paciente</th>
                  <th class="sorting" [hidden]="VisualizacaoCelular">Carteira</th>
                  <th class="sorting" [hidden]="VisualizacaoCelular">Operadora</th>
                  <th class="sorting" [hidden]="VisualizacaoCelular">Cadastro</th>
                  <th class="sorting">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr role="row" class="odd"
                  *ngFor="let detalhe of ListaProcedimento |
                    filtroPagination: [{ no_fantasia: SearchProcedimento.no_fantasia, no_paciente: SearchProcedimento.no_paciente, no_status: SearchProcedimento.no_status, no_processo: SearchProcedimento.no_processo, nu_tuss: SearchProcedimento.nu_tuss, no_carteira: SearchProcedimento.no_carteira, no_plano: SearchProcedimento.no_plano }] | paginate: { itemsPerPage: itemsPage, currentPage: p }">
                  <td>{{detalhe.no_fantasia}}</td>
                  <td>{{detalhe.no_paciente}}</td>
                  <td [hidden]="VisualizacaoCelular">{{detalhe.no_carteira}}</td>
                  <td [hidden]="VisualizacaoCelular">{{detalhe.no_plano}}</td>
                  <td [hidden]="VisualizacaoCelular">{{detalhe.dt_cadastro | date : 'dd/MM/yyyy'}}</td>
                  <td> <span class="dropdown">
                      <a class="btn btn-sm btn-clean btn-icon btn-icon-md" data-toggle="dropdown" aria-expanded="true">
                        <i class="la la-ellipsis-h"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right kt-pointer">
                        <a class="dropdown-item" (click)="GetProcedimento(detalhe.id_procedimento)">
                          <i class="la la-edit"></i>
                          Editar Cadastro</a>
                        <!-- <a class="dropdown-item" (click)="GetMostrarProtocolo(detalhe.id_procedimento)">
                          <i class="la la-edit"></i>
                          Cadastrar Observações</a> -->
                      </div>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="kt-datatable__pager kt-datatable--paging-loaded kt-padding-0">
              <div class="kt-datatable__pager-nav">
                <pagination-controls (pageChange)="p = $event" [maxSize]="VisualizacaoCelular ? 4 : 7" previousLabel="<" nextLabel=">"></pagination-controls>
              </div>
              <div class="kt-datatable__pager-info">
                <!-- <span class="kt-datatable__pager-detail qt-pagination">Mostrando {{ (ListaProcedimento | filter: Pesquisa.value)?.length }} de {{ListaProcedimento.length}}</span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid" *ngIf="MostrarCadastro">
      <div class="alert alert-warning fade show" role="alert" *ngIf="formCadastroProcedimento.value.dt_envio != null">
        <div class="alert-icon"><i class="flaticon-warning"></i></div>
        <div class="alert-text">A Prevía do Reembolso foi enviada em {{formCadastroProcedimento.value.dt_envio | date : 'dd/MM/yyyy'}}</div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="kt-portlet">
            <form [formGroup]="formCadastroProcedimento">
              <div class="kt-portlet__body">
                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>Nome do Paciente:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select placeholder="SELECIONE" formControlName="id_paciente" name="id_paciente" notFoundText="Nenhum item encontrado" class="form-control ng-select" (change)="GetClienteProcedimento()">
                        <ng-option *ngFor="let opt of ListaPaciente" [value]="opt.id_paciente">
                          <font style="vertical-align: inherit;">{{opt.no_paciente}}</font>
                        </ng-option>
                      </ng-select>
                      <span [hidden]="!f.id_paciente.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.id_paciente.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Nome do Cliente:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select placeholder="SELECIONE" formControlName="id_cliente" name="id_cliente" notFoundText="Nenhum item encontrado" class="form-control ng-select" (change)="GetProcessoProcedimento()">
                        <ng-option *ngFor="let opt of ListaCliente" [value]="opt.id_cliente">
                          <font style="vertical-align: inherit;">{{opt.no_fantasia}}</font>
                        </ng-option>
                      </ng-select>
                      <span [hidden]="!f.id_cliente.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.id_cliente.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Nome do Plano:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select placeholder="SELECIONE" formControlName="id_carteira" name="id_carteira" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                        <ng-option *ngFor="let opt of ListaCarteira" [value]="opt.id_carteira">
                          <font style="vertical-align: inherit;">{{opt.no_operadora}}</font>
                        </ng-option>
                      </ng-select>
                      <span [hidden]="!f.id_carteira.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.id_carteira.errors }"></i></span></span>
                      <span [hidden]="formCadastroProcedimento.value.id_carteira == null" class="kt-input-icon__icon kt-input-icon__icon--right">
                        <span>
                          <button type="button" style="border: none;" data-toggle="kt-popover" data-trigger="focus" title="" data-html="true" (click)="GetDetalhePlano()" data-content="<span id='idDetalhePlano'></span>"
                            data-original-title="Informação do Plano"><i class="fa fa-plus-circle"></i>
                          </button>
                        </span></span>
                    </div>
                  </div>
                </div>
                <div class="form-group row" *ngIf="ListaDadosPaciente.length > 0">
                  <div class="col-lg-4">
                    <label>CPF:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input class="form-control" placeholder="{{ListaDadosPaciente[0]?.nu_cpf}}" disabled="true" />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Data Nascimento:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input class="form-control" placeholder="{{ListaDadosPaciente[0]?.dt_nascimento | date : 'dd/MM/yyyy'}}" disabled="true" />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>E-Mail:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input class="form-control" placeholder="{{ListaDadosPaciente[0]?.no_email}}" disabled="true" />
                    </div>
                  </div>
                </div>
                <div class="form-group row" *ngIf="ListaDadosPaciente.length > 0">
                  <div class="col-lg-4">
                    <label>Telefone Fixo:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input class="form-control" placeholder="{{ListaDadosPaciente[0]?.no_telefone}}" disabled="true" />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Celular 1:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input class="form-control" placeholder="{{ListaDadosPaciente[0]?.no_celular}}" disabled="true" />
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Celular 2:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input class="form-control" placeholder="{{ListaDadosPaciente[0]?.no_celular_2}}" disabled="true" />
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>Tipo de Processo:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select placeholder="SELECIONE" formControlName="id_processo" name="id_processo" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                        <ng-option *ngFor="let opt of ListaProcesso" [value]="opt.id_processo">
                          <font style="vertical-align: inherit;">{{opt.no_nome}}</font>
                        </ng-option>
                      </ng-select>
                      <span [hidden]="!f.id_processo.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.id_processo.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Tuss:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <div class="kt-input-icon kt-input-icon--left">
                        <ng-autocomplete class="form-control" [data]="ListaTuss" searchKeyword="no_tuss" [initialValue]="initialValue" (selected)='AutoComplete_SelectedItem($event)' (inputChanged)='AutoComplete_Search($event)'
                          [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" [isLoading]='isLoading' placeholder="Digite a Operadora">
                        </ng-autocomplete>
                        <ng-template #itemTemplate let-item>
                          <a [innerHTML]="item.no_tuss"></a>
                        </ng-template>
                        <ng-template #notFoundTemplate let-notFound>
                          <div>Nenhum registro encontrado!</div>
                        </ng-template>
                        <span [hidden]="!f.nu_tuss.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.nu_tuss.errors }"></i></span></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Data do Primeiro Contato (PCP):</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" class="form-control" id="kt_datepicker_1" readonly="" placeholder="Selecione Data" (focusout)="GetData()">
                      <span [hidden]="!f.dt_contato.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.dt_contato.errors }"></i></span></span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-4" style="{{VisualizacaoCelular || formCadastroProcedimento.value.id_procedimento == '' ? '' : 'border-left: 3px solid #ec876b;
                  border-bottom: 3px solid #ec876b;
                  border-top: 3px solid #ec876b;
                  border-radius: 10px 0px 0px 10px;
                  padding-bottom: 10px;
                  padding-top: 10px;'}}">
                    <label>Status:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select placeholder="SELECIONE" formControlName="id_status" name="id_status" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                        <ng-option *ngFor="let opt of ListaStatus" [value]="opt.id_status">
                          <font style="vertical-align: inherit;">{{opt.no_nome}}</font>
                        </ng-option>
                      </ng-select>
                      <span [hidden]="!f.id_status.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.id_status.errors }"></i></span></span>
                      <span [hidden]="formCadastroProcedimento.value.id_procedimento == ''" (click)="VisualizarHistorico = !VisualizarHistorico" class="kt-input-icon__icon kt-input-icon__icon--right kt-pointer"><span><i
                            class="fa fa-plus-circle"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4" [hidden]="formCadastroProcedimento.value.id_status != 13" style="{{(VisualizacaoCelular || formCadastroProcedimento.value.id_procedimento == '' ? '' : '
                  border-bottom: 3px solid #ec876b;
                  border-top: 3px solid #ec876b;
                  padding-bottom: 10px;
                  padding-top: 10px;')}}">
                    <label>Data do Status</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" class="form-control" id="kt_datepicker_5" readonly="" placeholder="Selecione Data" (focusout)="GetDataStatus()">
                      <span [hidden]="!f.dt_status.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.dt_status.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4" style="{{VisualizacaoCelular || formCadastroProcedimento.value.id_procedimento == '' ? '' : 'border-right: 3px solid #ec876b;
                  border-bottom: 3px solid #ec876b;
                  border-top: 3px solid #ec876b;
                  border-radius: 0px 10px 10px 0px;
                  padding-bottom: 10px;
                  padding-top: 10px;'}}">
                    <label>Data de Atendimento no Cliente:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" class="form-control" id="kt_datepicker_2" readonly="" placeholder="Selecione Data" (focusout)="GetDataAtendimento()">
                      <span [hidden]="!f.dt_atendimento.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.dt_atendimento.errors }"></i></span></span>
                    </div>
                  </div>
                </div>
                <div class="tab-content" [hidden]="!VisualizarHistorico">
                  <div class="kt-subheader  kt-grid__item" id="kt_subheader">
                    <div class="kt-container  kt-container--fluid kt-padding-l-10">
                      <div class="kt-subheader__main">
                        <h4 class="kt-subheader__title">Historico do Status</h4>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane active kt-paddind-10" id="kt_widget2_tab1_content">
                    <div class="kt-timeline-v3 separador-borda">
                      <div class="kt-timeline-v3__items" *ngFor="let detalhes of ListaHistoricoStatus">
                        <div class="kt-timeline-v3__item {{detalhes.no_color}}">
                          <span class="kt-timeline-v3__item-time">{{detalhes.dt_cadastro | date : 'dd/MM/yyyy'}}</span>
                          <div class="kt-timeline-v3__item-desc">
                            <span class="kt-timeline-v3__item-text">
                              {{detalhes.no_sigla}} - {{detalhes.no_nome}}
                            </span><br>
                            <span class="kt-timeline-v3__item-user-name">
                              <a class="kt-link kt-link--dark kt-timeline-v3__item-link">
                                {{detalhes.no_usuario}}
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-6">
                    <label>Observação:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <textarea type="text" style="height: 135px;" name="no_observacao" formControlName="no_observacao" class="form-control" placeholder="Digite a Observação"></textarea>
                      <span [hidden]="!f.no_observacao.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_observacao.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <form>
                      <label>Notas:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <ng-select placeholder="SELECIONE" [(ngModel)]="no_nota" name="no_nota" notFoundText="Nenhum item encontrado" class="form-control ng-select" (change)="addData()">
                          <ng-option *ngFor="let opt of ListaNota" [value]="opt.id_nota">
                            <font style="vertical-align: inherit;">{{opt.no_nota}}</font>
                          </ng-option>
                        </ng-select>
                      </div>
                      <div class="kt-padding-t-10">
                        <span *ngFor="let tag of tagData;let rowindex = index;" class="tag-input colortag" (click)="selectedTag(rowindex)">{{tag.no_nota}}
                          <span style="padding-left:10px;" (click)="removeTag(rowindex)">X</span></span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </form>
            <div class="kt-subheader  kt-grid__item" id="kt_subheader">
              <div class="kt-container  kt-container--fluid kt-padding-l-10">
                <div class="kt-subheader__main">
                  <h4 class="kt-subheader__title">Registro de Protocolos</h4>
                </div>
              </div>
            </div>
            <form [formGroup]="formCadastroProtocolo">
              <div class="kt-portlet__body separador-borda">
                <div class="form-group row">
                  <div class="col-lg-3">
                    <label>Data:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" class="form-control" id="kt_datepicker_3" readonly="" placeholder="Selecione Data" (focusout)="GetDataProtocolo()">
                      <span [hidden]="!h.dt_cadastro.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': h.dt_cadastro.errors }"></i></span></span>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <label>Número do Protocolo:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input class="form-control" formControlName="nu_protocolo" placeholder="Digite o Número do Protocolo" />
                      <span [hidden]="!h.nu_protocolo.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': h.nu_protocolo.errors }"></i></span></span>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <label>Descrição do Atendimento:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input class="form-control" formControlName="no_protocolo" placeholder="Digite a Descrição do Atendimento" />
                      <span [hidden]="!h.no_protocolo.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': h.no_protocolo.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <button type="reset" class="btn btn-primary alinhamento-botao botaoadicionar" (click)="AdicionarProtocolo_2()" [disabled]="formCadastroProtocolo.invalid">Adicionar</button>
                  </div>
                </div>
                <div class="form-group row kt-margin-b-10" *ngIf="ListaProtocolos.length > 0" [hidden]="VisualizacaoCelular">
                  <div class="col-lg-2">
                    <label><b>Data</b></label>
                  </div>
                  <div class="col-lg-2">
                    <label><b>Número do Protocolo</b></label>
                  </div>
                  <div class="col-lg-4">
                    <label><b>Descrição do Atendimento</b></label>
                  </div>
                  <div class="col-lg-2">
                    <label><b>Usuário</b></label>
                  </div>
                  <div class="col-lg-2">
                    <label><b>Excluir</b></label>
                  </div>
                </div>
                <div class="form-group row kt-margin-b-10" *ngFor="let detalhe of ListaProtocolos; let i = index">
                  <div class="col-lg-2 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                    <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                      <label><b><small>Data</small></b></label>
                    </div>
                    <label>{{detalhe.dt_cadastro | date : 'dd/MM/yyyy'}}</label>
                  </div>
                  <div class="col-lg-2 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                    <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                      <label><b><small>Número do Protocolo</small></b></label>
                    </div>
                    <label>{{detalhe.nu_protocolo}}</label>
                  </div>
                  <div class="col-lg-4 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                    <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                      <label><b><small>Descrição do Atendimento</small></b></label>
                    </div>
                    <label>{{detalhe.no_protocolo}}</label>
                  </div>
                  <div class="col-lg-2 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                    <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                      <label><b><small>Usuário</small></b></label>
                    </div>
                    <label>{{detalhe.no_usuario}}</label>
                  </div>
                  <div class="col-lg-2">
                    <button type="button" class="btn btn-outline-danger btn-icon botaomax" (click)="ExcluirProtocolo_2(i)"><i class="flaticon2-cancel"></i></button>
                  </div>
                </div>
              </div>
            </form>
            <div class="kt-subheader  kt-grid__item" id="kt_subheader">
              <div class="kt-container  kt-container--fluid kt-padding-l-10">
                <div class="kt-subheader__main">
                  <h4 class="kt-subheader__title">Cadastro de Prévia</h4>
                </div>
              </div>
            </div>
            <form [formGroup]="formCadastroPagamento">
              <div class="kt-portlet__body separador-borda">
                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>Descrição do Pagamento:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select placeholder="SELECIONE" formControlName="id_pagamento" name="id_pagamento" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                        <ng-option *ngFor="let opt of ListaPagamento" [value]="opt.id_pagamento">
                          <font style="vertical-align: inherit;">{{opt.no_pagamento}}</font>
                        </ng-option>
                      </ng-select>
                      <span [hidden]="!g.id_pagamento.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': g.id_pagamento.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Valor:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input class="form-control" currencyMask formControlName="vl_pagamento" placeholder="Digite o Valor" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',',align:'left' }" />
                      <span [hidden]="!g.vl_pagamento.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': g.vl_pagamento.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <button type="reset" class="btn btn-primary alinhamento-botao botaoadicionar" (click)="AdicionarPagamento()" [disabled]="formCadastroPagamento.invalid">Adicionar</button>
                  </div>
                </div>
                <div class="form-group row kt-margin-b-10" *ngIf="ListaPagamentoAdicionado.length > 0" [hidden]="VisualizacaoCelular">
                  <div class="col-lg-4">
                    <label><b>Descrição do Pagamento</b></label>
                  </div>
                  <div class="col-lg-4">
                    <label><b>Valor do Pagamento</b></label>
                  </div>
                  <div class="col-lg-1">
                    <label><b>Excluir</b></label>
                  </div>
                </div>
                <div class="form-group row kt-margin-b-10" *ngFor="let detalhe of ListaPagamentoAdicionado; let i = index">
                  <div class="col-lg-4 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                    <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                      <label><b><small>Descrição do Pagamento</small></b></label>
                    </div>
                    <label>{{detalhe.no_pagamento}}</label>
                  </div>
                  <div class="col-lg-4 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                    <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                      <label><b><small>Valor do Pagamento</small></b></label>
                    </div>
                    <label>R$ {{detalhe.vl_pagamento.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2  })}}</label>
                  </div>
                  <div class="col-lg-2">
                    <button type="button" class="btn btn-outline-danger btn-icon botaomax" (click)="ExcluirPagamento(i)"><i class="flaticon2-cancel"></i></button>
                  </div>
                </div>
              </div>
            </form>
            <div class="kt-portlet__foot">
              <div class="kt-form__actions alinhamento-div-botao">
                <button type="reset" class="btn btn-primary alinhamento-botao" (click)="CrudProcedimento()">{{formCadastroProcedimento.value.id_procedimento == '' ? 'Cadastrar' : 'Atualizar'}}</button>
                <button type="reset" class="btn btn-secondary" (click)="CancelarCadastro()">Cancelar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid" *ngIf="MostrarProtocolos">
      <div class="row">
        <div class="col-md-4">
          <div class="row">
            <div class="col-lg-12">
              <div class="kt-portlet">
                <div class="kt-portlet__body">
                  <div class="form-group row">
                    <div class="col-lg-12">
                      <label>Observação:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <textarea type="text" name="no_observacao" [(ngModel)]="no_observacao" #Observacao ngModel class="form-control" placeholder="Digite a Observação"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="kt-portlet__foot">
                <div class="kt-form__actions alinhamento-div-botao">
                  <button type="reset" class="btn btn-primary alinhamento-botao" (click)="AdicionarProtocolo(Observacao.value)">{{id_protocolo != 0 ? 'Atualizar' : 'Cadastrar'}}</button>
                  <button type="reset" class="btn btn-secondary" (click)="CancelarProtocolo()">Cancelar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body kt-portlet__body--fit">
              <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded" id="kt_apps_user_list_datatable">
                <table class="table table-striped- table-hover table-checkable dataTable no-footer dtr-inline" id="kt_table_1">
                  <thead>
                    <tr role="row">
                      <th class="sorting">Nome</th>
                      <th class="sorting">Protocolo</th>
                      <th class="sorting">Data</th>
                      <th class="sorting">Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr role="row" class="odd" *ngFor="let detalhe of ListaProtocolos | paginate: { itemsPerPage: 8, currentPage: p }">
                      <td>{{detalhe.no_usuario}}</td>
                      <td>{{detalhe.no_protocolo}}</td>
                      <td>{{detalhe.dt_cadastro | date : 'dd/MM/yyyy'}}</td>
                      <td> <span class="dropdown">
                          <a class="btn btn-sm btn-clean btn-icon btn-icon-md" data-toggle="dropdown" aria-expanded="true">
                            <i class="la la-ellipsis-h"></i>
                          </a>
                          <div class="dropdown-menu dropdown-menu-right kt-pointer">
                            <a class="dropdown-item" (click)="GetProtocoloDetalhes(detalhe.id_protocolo)">
                              <i class="la la-edit"></i>
                              Editar Cadastro</a>
                          </div>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="kt-datatable__pager kt-datatable--paging-loaded kt-padding-0">
                  <div class="kt-datatable__pager-nav">
                    <pagination-controls (pageChange)="p = $event" previousLabel="<" nextLabel=">"></pagination-controls>
                  </div>
                  <div class="kt-datatable__pager-info">
                    <span class="kt-datatable__pager-detail">Mostrando {{ListaProtocolos.length}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
