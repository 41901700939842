import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { formatDate } from '@angular/common';

import { GlobalConstants } from '../../common/global-constants';
import { AutenticacaoToken } from './autenticacao.token';
declare function CarregarScript(): any;

@Injectable()
export class AutenticacaoGuard implements CanActivate {
  readonly minutesToken = GlobalConstants.minutesToken;
  constructor(
  private router: Router,
  private autenticacaoToken: AutenticacaoToken) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      setTimeout(() => {
        CarregarScript();
      }, 2000);

    if (this.autenticacaoToken.Token()) {
      return true;
    } else {
      if (this.router.url == '/') {
                this.router.navigate(['/login']);
            }
			else if (this.router.url != '/login'){
				this.router.navigate(['/expires']);
			}
      return false;
    }
  }
}
