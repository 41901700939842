import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
declare var echarts: any;
import { HeaderComponent } from '../commom/header/header.component';
declare var $: any;
import { HomeService } from '../../service/home/home.service';
import { ExcelService } from '../../service/commom/excel.service';
import { SweetAlertService } from '../../service/commom/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-notificacao',
  templateUrl: './notificacao.component.html',
  styleUrls: ['./notificacao.component.css'],
  host: { 'class': 'col' }
})
export class NotificacaoComponent implements OnInit {
  MostrarLista: boolean = true;
  VisualizacaoCelular: boolean = false;
  formData: FormData;
  p: number = 1;
  id_cliente: any = '';
  ListaCliente: any = [];
  ListaNotificacao: any = [];
  ListaPaciente: any = [];
  ListaBMS: any = [];
  formCadastroNotificacao: FormGroup;
  ListaTipoNotificacao: any = [];
  ListaArquivos: File[] = [];
  ListaPeriodo: any = [];
  LiberarEnvio: boolean = true;
  idPerfil = localStorage.getItem('idPerfil');
  search: any = [];
  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private homeService: HomeService,
    private sweetAlertService: SweetAlertService,
    private excelService: ExcelService
  ) {
    const actualWidth = window.innerWidth;
    if (window.innerWidth < 1024) {
      this.VisualizacaoCelular = true;
    }
  }
  @HostListener('window:resize', [''])
  onResize() {
    this.VisualizacaoCelular = window.innerWidth < 1024 ? true : false;
  }
  ngOnInit(): void {
    this.id_cliente = null;
    this.GetNotificacao();
    this.CarregarFormBuilder();
    this.GetCliente();

    if (this.idPerfil == '1') {
      this.ListaTipoNotificacao.push( {tp_notificacao: 'Prévia', no_notificacao: 'Prévia' })
      this.ListaTipoNotificacao.push( {tp_notificacao: 'BMS', no_notificacao: 'BMS' })
    }
    if (this.idPerfil == '2') {
      this.ListaTipoNotificacao.push( {tp_notificacao: 'Prévia', no_notificacao: 'Prévia' })
    }

    for (let index = -1; index < 2; index++) {
      var dt = new Date();
      if (index < 0) {
        dt.setMonth(dt.getMonth() - 1);
      }
      else if (index == 0){
      }
      else{
        dt.setMonth(dt.getMonth() + index);
      }
      var mes = dt.getMonth();
      var ano = dt.getFullYear();
      mes = mes + 1;
      var mesConvertido = mes.toString().length == 1 ? '0' + mes.toString() : mes.toString();

      this.ListaPeriodo.push({
        dt_periodo: mesConvertido.toString() + '/' + ano.toString(),
        descricao: mesConvertido.toString() + '/' + ano.toString()
      });
    }
  }
  CarregarTelaCadastro() {
    this.novaLista_2 = [];
    this.ListaArquivos = [];
    this.LiberarEnvio = true;
    this.MostrarLista = !this.MostrarLista;
    setTimeout(() => {
      this.CarregarFormBuilder();
    }, 500);
  }
  CarregarFormBuilder() {
    this.formCadastroNotificacao = this.formBuilder.group({
      id_cliente: [null, [Validators.required]],
      id_paciente: [null, []],
      tp_notificacao: [null, [Validators.required]],
      dt_periodo: [null, [Validators.required]],
    });
  }
  get f() { return this.formCadastroNotificacao.controls; }

  GetNotificacao() {
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_cliente', this.id_cliente == null ? '' : this.id_cliente);
    this.homeService.GetNotificacao(this.formData).subscribe((data: any) => {
      this.ListaNotificacao = data.retorno;
      if (this.idPerfil == '2') {
        this.ListaNotificacao = this.ListaNotificacao.filter(a => a.tp_notificacao === 'Prévia');
      }
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar as Notificações');
      });
  }
  GetCliente() {
    this.formData = new FormData();
    this.homeService.GetCliente(this.formData).subscribe((data: any) => {
      this.ListaCliente = data.retorno;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Cliente');
      });
  }

  vl_pagamento = 0;
  vl_bms = 0;
  qtd_processos = 0;

  infoBMS: any = [];
  detalhesBMS: any = [];

  novaLista_2: any = [];
  GetBMS() {
    this.novaLista_2 = [];
    this.detalhesBMS = [];
    this.infoBMS = [];
    this.vl_pagamento = 0;
    this.vl_bms = 0;
    this.qtd_processos = 0;

    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_cliente', this.formCadastroNotificacao.value.id_cliente);
    this.formData.append('dt_periodo', this.formCadastroNotificacao.value.dt_periodo);
    this.homeService.GetBMS(this.formData).subscribe((data: any) => {
      if (data.retorno.length == 0) {
        this.spinner.hide();
        this.sweetAlertService.SweetAlert('error', 'Cliente não possui BMS a ser enviado');
        return;
      }
      this.qtd_processos = data.retorno_2.length;
      let novaLista: any = [];
      let no_paciente = '';
      let no_tuss = '';

      for (let index = 0; index < data.retorno.length; index++) {

        this.vl_pagamento = this.vl_pagamento + data.retorno[index].vl_pagamento;
        this.vl_bms = this.vl_bms + data.retorno[index].vl_bms;
      }

      for (let p = 0; p < data.retorno_2.length; p++) {
        no_paciente = '';
        no_tuss = '';
        novaLista = [];
        for (let index = 0; index < data.retorno.length; index++) {
          if (data.retorno[index].id_procedimento == data.retorno_2[p]) {
            no_paciente = data.retorno[index].no_paciente;
            no_tuss = data.retorno[index].no_tuss;
            novaLista.push({
              vl_pagamento: data.retorno[index].vl_pagamento,
              vl_bms: data.retorno[index].vl_bms,
              no_pagamento: data.retorno[index].no_pagamento,
            });

            this.detalhesBMS.push({
              id_procedimento: data.retorno[index].id_procedimento,
              no_paciente: no_paciente,
              vl_pagamento: data.retorno[index].vl_pagamento,
              vl_bms: data.retorno[index].vl_bms,
              no_pagamento: data.retorno[index].no_pagamento,
              no_tuss: no_tuss
            });

          }
        }
        this.novaLista_2.push({
          no_paciente: no_paciente,
          no_tuss: no_tuss,
          pagamento: novaLista
        });


      }

      this.infoBMS.push({
        no_cliente: this.ListaCliente.filter(a => a.id_cliente === this.formCadastroNotificacao.value.id_cliente)[0].no_fantasia,
        qtd_processos: this.qtd_processos,
        vl_pagamento: this.vl_pagamento.toFixed(2),
        vl_bms: this.vl_bms.toFixed(2)
      });
      this.spinner.hide();
      this.LiberarEnvio = false;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar o BMS');
      });
  }

  uploadDocumento(fileInput: any, tpArquivo) {
    this.ListaArquivos.push(fileInput.target.files[0]);
    this.LiberarEnvio = false;
  }

  EnviarNotificacao() {
    this.spinner.show();
    if (this.novaLista_2.length > 0) {
      this.EnviarBMS();
    }
    else {
      this.EnviarPrevia();
    }
  }

  EnviarBMS() {
    this.formData = new FormData();
    this.formData.append('GetInfoBMS', JSON.stringify(this.infoBMS));
    this.formData.append('GetDetalhesBMS', JSON.stringify(this.detalhesBMS));
    this.formData.append('id_cliente', this.formCadastroNotificacao.value.id_cliente);
    this.formData.append('tp_notificacao', this.formCadastroNotificacao.value.tp_notificacao);
    this.formData.append('dt_periodo', this.formCadastroNotificacao.value.dt_periodo);
    this.homeService.EnvioBMS(this.formData).subscribe((data: any) => {
      if (data.retorno == 'NOK') {
        this.spinner.hide();
        this.sweetAlertService.SweetAlert('error', 'Cliente não possui e-mail de BMS cadastrado');
        return;
      }
      this.sweetAlertService.SweetAlert('ok', 'BMS enviado com sucesso');
      this.CarregarTelaCadastro();
      this.GetNotificacao();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao enviar o BMS');
        this.spinner.hide();
      });
  }

  EnviarPrevia() {
    this.formData = new FormData();
    this.formData.append('id_cliente', this.formCadastroNotificacao.value.id_cliente);
    this.formData.append('tp_notificacao', this.formCadastroNotificacao.value.tp_notificacao);
    this.formData.append('dt_periodo', this.formCadastroNotificacao.value.dt_periodo);
    this.formData.append('id_paciente', this.formCadastroNotificacao.value.id_paciente);
    this.formData.append('no_paciente', this.ListaPaciente.filter(a => a.id_paciente === this.formCadastroNotificacao.value.id_paciente)[0].no_paciente);
    this.formData.append('arquivo', this.ListaArquivos[0]);

    this.homeService.EnvioPrevia(this.formData).subscribe((data: any) => {
      this.sweetAlertService.SweetAlert('ok', 'Prévia enviada com sucesso');
      this.CarregarTelaCadastro();
      this.GetNotificacao();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao enviar a Prévia');
        this.spinner.hide();
      });
  }

  GetPaciente() {
    this.formData = new FormData();
    this.formData.append('id_cliente', this.formCadastroNotificacao.value.id_cliente.toString());
    this.homeService.ListaPaciente(this.formData).subscribe((data: any) => {
      this.ListaPaciente = data.retorno;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Pacientes');
      });
  }
}
