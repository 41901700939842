<div class="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
  <div class="kt-subheader  kt-grid__item" id="kt_subheader">
    <div class="kt-container  kt-container--fluid ">
      <div class="kt-subheader__main">
        <h3 class="kt-subheader__title">Cadastro de Usuário</h3>
        <span class="kt-subheader__separator kt-subheader__separator--v"></span>
        <span class="kt-subheader__desc"><small>Usuário >> Cadastro de Usuário</small></span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="kt-subheader   kt-grid__item" id="kt_subheader" [hidden]="!MostrarLista">
        <div class="kt-container  kt-container--fluid ">
          <div class="kt-subheader__main">
            <div class="kt-subheader__group" id="kt_subheader_search">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 kt-padding-0">
                  <form id="kt_subheader_search_form">
                    <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-0">
                      <input type="search" class="form-control" placeholder="Pesquisar" name="generalSearch" #Pesquisa ngModel>
                      <span class="kt-input-icon__icon kt-input-icon__icon--right">
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <path
                                d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                              <path
                                d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                fill="#000000" fill-rule="nonzero"></path>
                            </g>
                          </svg>
                        </span>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="kt-header__topbar">
            <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" (click)="GetUsuarioLista()">
              <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Carregar os Usuários">
                <span class="kt-header__topbar-icon"><i class="flaticon-refresh"></i></span>
              </div>
            </div>
            <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" (click)="CarregarTelaCadastro()">
              <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Adicionar Novo Usuário">
                <span class="kt-header__topbar-icon"><i class="flaticon-plus"></i></span>
              </div>
            </div>
            <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" (click)="Exportar()" [hidden]="VisualizacaoCelular" *ngIf="idPerfil == '3'">
              <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Exportar Dados dos Usuários">
                <span class="kt-header__topbar-icon">
                  <i class="flaticon-download">
                  </i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid" *ngIf="MostrarLista">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body kt-portlet__body--fit">
          <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded" id="kt_apps_user_list_datatable">
            <table class="table table-striped- table-hover table-checkable dataTable no-footer dtr-inline" id="kt_table_1">
              <thead>
                <tr role="row">
                  <th class="sorting">Nome</th>
                  <th class="sorting" [hidden]="VisualizacaoCelular">E-Mail</th>
                  <th class="sorting" [hidden]="VisualizacaoCelular">Perfil</th>
                  <th class="sorting" [hidden]="VisualizacaoCelular">Cadastro</th>
                  <th class="sorting">Status</th>
                  <th class="sorting">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr role="row" class="odd" *ngFor="let detalhe of ListaUsuario | filter : Pesquisa.value | paginate: { itemsPerPage: 8, currentPage: p }">
                  <td>{{detalhe.no_usuario}}</td>
                  <td [hidden]="VisualizacaoCelular">{{detalhe.no_email}}</td>
                  <td [hidden]="VisualizacaoCelular">{{detalhe.no_perfil}}</td>
                  <td [hidden]="VisualizacaoCelular">{{detalhe.dt_cadastro | date : 'dd/MM/yyyy'}}</td>
                  <td>
                    <span class="kt-font-bold {{detalhe.fg_aprovado == 1 ? 'kt-font-primary' : 'kt-font-danger'}}">{{detalhe.fg_aprovado == 1 ? 'Ativo' : 'Inativo'}}</span>
                  </td>
                  <td> <span class="dropdown">
                      <a class="btn btn-sm btn-clean btn-icon btn-icon-md" data-toggle="dropdown" aria-expanded="true">
                        <i class="la la-ellipsis-h"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right kt-pointer" (click)="GetUsuarioDetalhes(detalhe.id_usuario)">
                        <a class="dropdown-item">
                          <i class="la la-edit"></i>
                          Editar Cadastro</a>
                      </div>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="kt-datatable__pager kt-datatable--paging-loaded kt-padding-0">
              <div class="kt-datatable__pager-nav">
                <pagination-controls (pageChange)="p = $event" [maxSize]="VisualizacaoCelular ? 4 : 7" previousLabel="<" nextLabel=">"></pagination-controls>
              </div>
              <div class="kt-datatable__pager-info">
                <span class="kt-datatable__pager-detail qt-pagination">Mostrando {{ (ListaUsuario | filter: Pesquisa.value)?.length }} de {{ListaUsuario.length}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid" *ngIf="!MostrarLista">
      <div class="row">
        <div class="col-lg-12">
          <div class="kt-portlet">
            <form [formGroup]="formCadastroUsuario">
              <div class="kt-portlet__body">
                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>Nome Usuario:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" name="no_usuario" formControlName="no_usuario" class="form-control" placeholder="Digite o Nome do Usuário">
                      <span [hidden]="!f.no_usuario.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_usuario.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>E-Mail:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" formControlName="no_email" class="form-control" placeholder="Digite o E-Mail">
                      <span [hidden]="!f.no_email.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_email.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Perfil:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select placeholder="SELECIONE" formControlName="id_perfil" name="id_perfil" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                        <ng-option *ngFor="let opt of ListaPerfil" [value]="opt.id_perfil">
                          <font style="vertical-align: inherit;">{{opt.no_perfil}}</font>
                        </ng-option>
                      </ng-select>
                      <span [hidden]="!f.id_perfil.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.id_perfil.errors }"></i></span></span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>Cliente:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select placeholder="SELECIONE" formControlName="id_cliente" name="id_cliente" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                        <ng-option *ngFor="let opt of ListaCliente" [value]="opt.id_cliente" [disabled]="formCadastroUsuario.value.id_perfil != 3 && formCadastroUsuario.value.id_perfil != 4">
                          <font style="vertical-align: inherit;">{{opt.no_fantasia}}</font>
                        </ng-option>
                      </ng-select>
                      <span [hidden]="!f.id_cliente.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.id_cliente.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Status:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select placeholder="SELECIONE" formControlName="fg_aprovado" name="fg_aprovado" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                        <ng-option *ngFor="let opt of ListaStatus" [value]="opt.fg_aprovado">
                          <font style="vertical-align: inherit;">{{opt.dsstatus}}</font>
                        </ng-option>
                      </ng-select>
                      <span [hidden]="!f.fg_aprovado.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.fg_aprovado.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Senha:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" name="nu_senha" formControlName="nu_senha" class="form-control" placeholder="Digite a Senha">
                      <div class="errorLabel kt-padding-0" *ngIf="formCadastroUsuario.value.nu_senha?.length < 6 && formCadastroUsuario.value.nu_senha != ''">Senhas deve ter, no mínimo, 6 caracteres</div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="kt-portlet__foot">
              <div class="kt-form__actions alinhamento-div-botao">
                <button type="reset" class="btn btn-primary alinhamento-botao" [disabled]="formCadastroUsuario.invalid" (click)="CrudUsuario()" >{{formCadastroUsuario.value.id_usuario == '' ? 'Cadastrar' : 'Atualizar'}}</button>
                <button type="reset" class="btn btn-secondary" (click)="MostrarLista = !MostrarLista">Cancelar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
