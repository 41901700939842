import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
declare var echarts: any;
import { HeaderComponent } from '../commom/header/header.component';
declare var $: any;
import { HomeService } from '../../service/home/home.service';
import { ExcelService } from '../../service/commom/excel.service';
import { SweetAlertService } from '../../service/commom/sweet-alert/sweet-alert.service';
declare function CarregarScriptData(): any;
declare var Swal: any;
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  host: { 'class': 'col' }
})
export class HomeComponent implements OnInit {

  @ViewChild(HeaderComponent) headerComponent;
  formData: FormData;
  VisualizacaoCelular: boolean = false;
  myChart_1;
  myChart_2;
  myChart_3;
  myChart_4;
  myChart_5;
  Visualizacao = 0;
  ListaPrevia: any = [];
  ListaPreviaCompleta: any = [];
  ListaReembolso: any = [];
  ListaReembolsoCompleta: any = [];
  ListaStatus: any = [];
  ListaDetalheGraficoBMS: any = [];
  p: number = 1;
  DataInicio;
  DataFim;
  id_cliente: any = '';
  ListaCliente: any = [];
  ListaClienteAgendamento: any = [];
  ListaPeriodoAgendamento: any = [];
  idPerfil = localStorage.getItem('idPerfil');
  idFuncionalidade: any = '0';
  anoMes = '';
  anoTexto = '';
  ano: any = '';
  periodo: any = '';
  ListaPeriodo: any = [];
  ListaAno: any = [];
  ListaCor: any = [
    { id: '#ff00004d', cor: 'Vermelho (Prazo Decursado)' },
    { id: '#ffff0052', cor: 'Amarelo (Prazo no Limite)' },
    { id: '#0080004a', cor: 'Verde (Prazo Próximo)' },
    { id: '#ffa50069', cor: 'Laranja (RbL - Reembolso Liberado)' },
    { id: '#fff', cor: 'Branco ( Prazo em Curso, RbR e RbN)' }];

  ListaCorPrevia: any = [
    { id: '#ff00004d', cor: 'Vermelho (Prazo Decursado)' },
    { id: '#ffff0052', cor: 'Amarelo (Prazo no Limite)' },
    { id: '#0080004a', cor: 'Verde (Prazo Próximo)' },
    { id: '#ffa50069', cor: 'Laranja (PvL - Data. Atend. Decursado)' },
    { id: '#bbbbbb4d', cor: 'Cinza (PvN - Prévia Negada)' },
    { id: '#fff', cor: 'Branco (Prazo em Curso e PvL)' }];

  flg_concluido = 0;
  flg_cor = '';
  itemsPageP: number = 10;
  itemsPageR: number = 10;
  itemsPageB: number = 10;
  SearchAgendamento: any = [];
  constructor(
    private spinner: NgxSpinnerService,
    private homeService: HomeService,
    private sweetAlertService: SweetAlertService,
    private excelService: ExcelService,
    private formBuilder: FormBuilder,
  ) {
    const actualWidth = window.innerWidth;
    if (window.innerWidth < 1024) {
      this.VisualizacaoCelular = true;
    }
  }
  @HostListener('window:resize', [''])
  onResize() {
    this.VisualizacaoCelular = window.innerWidth < 1024 ? true : false;
  }
  ngOnInit(): void {
    this.GetCliente();
    this.GetFiltroData();
    this.id_cliente = null;


    var dt = new Date();
    var mes = dt.getMonth();
    mes = mes + 1;
    var mesConvertido = mes.toString().length == 1 ? '0' + mes.toString() : mes.toString();
    this.anoMes = this.MesConvertido(mesConvertido) + '/' + dt.getFullYear().toString();
    this.anoTexto = dt.getFullYear().toString();
    this.periodo = mesConvertido + '/' + dt.getFullYear().toString();
    this.ano = dt.getFullYear().toString();

    let retorno = localStorage.getItem('RedirecionamentoHome');
    if (retorno != null && localStorage.getItem('RedirecionamentoVoltar') != null) {
      if (retorno == '2') {
        this.CarregarVisualizacao_2();
        this.idFuncionalidade = 1;
      }
      else if (retorno == '3') {
        this.CarregarVisualizacao_3();
        this.idFuncionalidade = 1;
      }
      else if (retorno == '6') {
        this.CarregarVisualizacao_6();
        this.idFuncionalidade = 2;
      }
      localStorage.removeItem('RedirecionamentoHome');
      localStorage.removeItem('RedirecionamentoVoltar');
      $('#id_menu_Reembolso').removeClass('kt-menu__item--active').addClass('kt-menu__item--submenu');
      $('#id_menu_Paciente').removeClass('kt-menu__item--active').addClass('kt-menu__item--submenu');
      $('#id_menu_Home').removeClass('kt-menu__item--submenu').addClass('kt-menu__item--active');
      return;
    }

    if (this.idPerfil == '4') {//TERAPIA
      this.idFuncionalidade = 2;
      this.CarregarVisualizacao_5();
    }
    else if (this.idPerfil == '3') { //CLIENTE
      this.idFuncionalidade = 1;
      this.CarregarVisualizacao_1();
    }
    else {
      this.CarregarVisualizacao_1(); //ADMINISTRADOR
      this.spinner.hide();
    }

    localStorage.removeItem('RedirecionamentoHome');
    localStorage.removeItem('RedirecionamentoVoltar');
  }
  CarregarTipoVisualizacao(tp) {
    if (tp == 1) {
      if (this.idPerfil == '1') {
        this.CarregarVisualizacao_1();
        this.idFuncionalidade = 1;
      }
      if (this.idPerfil == '2') {
        this.CarregarVisualizacao_2();
        this.idFuncionalidade = 1;
      }
    }
    else if (tp == 2) {
      this.CarregarVisualizacao_5(); //Todos os Perfil
      this.idFuncionalidade = 2;
    }
  }
  mesAnterior(date, diff) {
    const d = new Date(date);
    d.setMonth(d.getMonth() + diff);
    return d;
  }

  ConvertDataMesAno(data){
      let dt_convertido = ''
      if (data.length == 7) {
        return data.substring(3, 8) + data.substring(0, 2);
      }
      let  date = new Date(data),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      return mnth + '/' + date.getFullYear();
    }

ConvertDataAno(data){
let dt_convertido = ''
if (data.length == 7) {
  return data.substring(3, 8) + data.substring(0, 2);
}
let  date = new Date(data),
mnth = ("0" + (date.getMonth() + 1)).slice(-2),
day = ("0" + date.getDate()).slice(-2);
return date.getFullYear();
}

  GetFiltroData() {
    let dataConvertida;
    this.ListaPeriodo.push({ periodo: this.ConvertDataMesAno(new Date()) });
    let anoConvertido = this.ConvertDataAno(new Date())
    //this.ListaAno.push({ periodo: anoConvertido });
    let flgano = 0;
    for (let i = 1; i < 12; i++) {
        dataConvertida = this.mesAnterior(new Date(), i * -1);
        this.ListaPeriodo.push({ periodo: this.ConvertDataMesAno(dataConvertida) });

        // if (anoConvertido != this.ConvertDataMesAno(dataConvertida) ) {
        //   if (flgano == 0) {
        //     this.ListaAno.push({ periodo: this.ConvertDataAno(dataConvertida) });
        //     flgano = 1;
        //   }
        // }
      }
      //correção provisorio

      let anoTotal = anoConvertido - 2020
      for (let index = 0; index < anoTotal; index++) {
        let novoano = anoConvertido - index
        this.ListaAno.push({ periodo: novoano});
      }
    // this.formData = new FormData();
    // this.homeService.GetFiltroData(this.formData).subscribe((data: any) => {
    //   this.ListaPeriodo = data.retorno;
    //   this.ListaAno = data.retorno_2;
    // },
    //   (err: HttpErrorResponse) => {
    //     if (err.status === 401) {
    //       return;
    //     }
    //   });
  }
  MesConvertido(mes) {
    let mes_comvertido = '';
    switch (mes) {
      case '01':
        mes_comvertido = 'Janeiro';
        break;
      case '02':
        mes_comvertido = 'Fevereiro';
        break;
      case '03':
        mes_comvertido = 'Março';
        break;
      case '04':
        mes_comvertido = 'Abril';
        break;
      case '05':
        mes_comvertido = 'Maio';
        break;
      case '06':
        mes_comvertido = 'Junho';
        break;
      case '07':
        mes_comvertido = 'Julho';
        break;
      case '08':
        mes_comvertido = 'Agosto';
        break;
      case '09':
        mes_comvertido = 'Setembro';
        break;
      case '10':
        mes_comvertido = 'Outubro';
        break;
      case '11':
        mes_comvertido = 'Novembro';
        break;
      case '12':
        mes_comvertido = 'Dezembro';
        break;
    }
    return mes_comvertido;
  }
  CarregarVisualizacao_1() {
    this.spinner.show();
    this.Visualizacao = 1;
    setTimeout(() => {
      this.myChart_1 = echarts.init(document.getElementById('grafico-atendimento-mes'));
      this.myChart_2 = echarts.init(document.getElementById('grafico-atendimento-acumulado'));
      this.myChart_3 = echarts.init(document.getElementById('grafico-atendimento-mes_geral'));
      this.myChart_4 = echarts.init(document.getElementById('grafico-atendimento-acumulado_geral'));

      this.CarregarGraficoAtendimentoMes();
      this.GetGraficoOperadora();
      this.GetGraficoOperadoraTotal();
      this.CarregarGraficoAtendimentoDia();
      CarregarScriptData();
      this.id_cliente = null;
    }, 500);
  }
  RecarregarGraficos() {
    this.spinner.show();
    this.CarregarGraficoAtendimentoMes();
    this.GetGraficoOperadora();
    this.GetGraficoOperadoraTotal();
    this.CarregarGraficoAtendimentoDia();
  }
  vl_total_dia = 0;
  total_dia_reembolso = 0;
  total_dia_aberto = 0;
  vl_total_mensal = 0;
  total_mensal_reembolso = 0;
  total_mensal_aberto = 0;

  RecarregarDia() {
    this.anoMes = this.MesConvertido(this.periodo.substring(0, 2)) + '/' + this.periodo.substring(3, 7);
    this.anoTexto = this.periodo.substring(3, 7);
    this.CarregarGraficoAtendimentoDia();
    this.GetGraficoOperadora();
  }

  RecarregarMes() {
    this.CarregarGraficoAtendimentoMes();
    this.GetGraficoOperadoraTotal();
  }

  CarregarGraficoAtendimentoMes() {
    this.spinner.show();
    this.vl_total_mensal = 0;
    this.total_mensal_reembolso = 0;
    this.total_mensal_aberto = 0;
    this.formData = new FormData();
    this.formData.append('id_status', '');
    this.formData.append('id_cliente', this.id_cliente == null ? '' : this.id_cliente);
    this.formData.append('ano', this.ano == null ? '' : this.ano);
    this.homeService.GetGraficoMes(this.formData).subscribe((data: any) => {
      this.total_mensal_aberto = data.retorno_2.length > 0 ? data.retorno_2[0].vlr_aberto : 0;
      this.vl_total_mensal = data.retorno_3.length > 0 ? data.retorno_3[0].vlr_aberto : 0;

      for (let index = 0; index < data.retorno.length; index++) {
        // this.vl_total_mensal = this.vl_total_mensal + data.retorno[index].vl_pagamento
        this.total_mensal_reembolso = this.total_mensal_reembolso + data.retorno[index].qtde
      }

      setTimeout(() => {
        let option = {
          xAxis: {
            type: 'category',
            data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
          },
          yAxis: {
            type: 'value'
          },
          series: [{
            data: [
              data.retorno.filter(a => a.dt_label === '01').length > 0 ? data.retorno.filter(a => a.dt_label === '01')[0].qtde : 0,
              data.retorno.filter(a => a.dt_label === '02').length > 0 ? data.retorno.filter(a => a.dt_label === '02')[0].qtde : 0,
              data.retorno.filter(a => a.dt_label === '03').length > 0 ? data.retorno.filter(a => a.dt_label === '03')[0].qtde : 0,
              data.retorno.filter(a => a.dt_label === '04').length > 0 ? data.retorno.filter(a => a.dt_label === '04')[0].qtde : 0,
              data.retorno.filter(a => a.dt_label === '05').length > 0 ? data.retorno.filter(a => a.dt_label === '05')[0].qtde : 0,
              data.retorno.filter(a => a.dt_label === '06').length > 0 ? data.retorno.filter(a => a.dt_label === '06')[0].qtde : 0,
              data.retorno.filter(a => a.dt_label === '07').length > 0 ? data.retorno.filter(a => a.dt_label === '07')[0].qtde : 0,
              data.retorno.filter(a => a.dt_label === '08').length > 0 ? data.retorno.filter(a => a.dt_label === '08')[0].qtde : 0,
              data.retorno.filter(a => a.dt_label === '09').length > 0 ? data.retorno.filter(a => a.dt_label === '09')[0].qtde : 0,
              data.retorno.filter(a => a.dt_label === '10').length > 0 ? data.retorno.filter(a => a.dt_label === '10')[0].qtde : 0,
              data.retorno.filter(a => a.dt_label === '11').length > 0 ? data.retorno.filter(a => a.dt_label === '11')[0].qtde : 0,
              data.retorno.filter(a => a.dt_label === '12').length > 0 ? data.retorno.filter(a => a.dt_label === '12')[0].qtde : 0],
            type: 'bar',
            itemStyle: { normal: { color: '#61a0a7' } },
          }],
          label: {
            show: true,
            precision: 1,
            position: 'top',
          }
        };

        this.spinner.hide();
        this.myChart_3.setOption(option);
        this.myChart_3.resize();
      }, 1000);
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
      });
  }
  CarregarGraficoAtendimentoDia() {
    this.spinner.show();
    this.vl_total_dia = 0;
    this.total_dia_reembolso = 0;
    this.total_dia_aberto = 0;
    this.formData = new FormData();
    this.formData.append('id_status', '');
    this.formData.append('id_cliente', this.id_cliente == null ? '' : this.id_cliente);
    this.formData.append('periodo', this.periodo == null ? '' : this.periodo);
    this.homeService.GetGraficoDia(this.formData).subscribe((data: any) => {
      this.total_dia_aberto = data.retorno_2.length > 0 ? data.retorno_2[0].vlr_aberto : 0;

      for (let index = 0; index < data.retorno.length; index++) {
        this.vl_total_dia = this.vl_total_dia + data.retorno[index].vl_pagamento
        this.total_dia_reembolso = this.total_dia_reembolso + data.retorno[index].qtde
      }

      setTimeout(() => {
        let option = {
          xAxis: {
            type: 'category',
            data: ['Dia 1-5', 'Dia 6-10', 'Dia 11-15', 'Dia 16-20', 'Dia 21-25', 'Dia 25-31'],
            axisLabel: {
              interval: this.VisualizacaoCelular ? 1 : 0
            },
          },
          yAxis: {
            type: 'value'
          },
          series: [{
            data: [
              data.retorno.filter(a => parseInt(a.dia) === 1).length > 0 ? data.retorno.filter(a => parseInt(a.dia) === 1)[0].qtde : 0,
              data.retorno.filter(a => parseInt(a.dia) === 2).length > 0 ? data.retorno.filter(a => parseInt(a.dia) === 2)[0].qtde : 0,
              data.retorno.filter(a => parseInt(a.dia) === 3).length > 0 ? data.retorno.filter(a => parseInt(a.dia) === 3)[0].qtde : 0,
              data.retorno.filter(a => parseInt(a.dia) === 4).length > 0 ? data.retorno.filter(a => parseInt(a.dia) === 4)[0].qtde : 0,
              data.retorno.filter(a => parseInt(a.dia) === 5).length > 0 ? data.retorno.filter(a => parseInt(a.dia) === 5)[0].qtde : 0,
              data.retorno.filter(a => parseInt(a.dia) === 6).length > 0 ? data.retorno.filter(a => parseInt(a.dia) === 6)[0].qtde : 0],
            type: 'bar',
            itemStyle: { normal: { color: '#61a0a7' } },
          }],
          label: {
            show: true,
            position: 'top',
          }
        };

        this.spinner.hide();
        this.myChart_1.setOption(option);
        this.myChart_1.resize();
        this.spinner.hide();
      }, 1000);


    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
      });
  }

  GetGraficoOperadora() {
    let Grafico = [];

    this.formData = new FormData();
    this.formData.append('id_status', '');
    this.formData.append('id_cliente', this.id_cliente == null ? '' : this.id_cliente);
    this.formData.append('periodo', this.periodo == null ? '' : this.periodo);

    this.homeService.GetGraficoOperadora(this.formData).subscribe((data: any) => {
      Grafico = data.retorno;
      setTimeout(() => {
        let option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '5%',
            left: 'center',
            type: 'scroll',
            orient: 'horizontal'
          },
          series: [
            {
              name: data.retorno.length == 0 ? 'Nenhuma informação para o Mês Atual' : 'Atendimento Mês Atual',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '16',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: Grafico,
              // '{c}' quantidade
              label: {
                formatter: '{d}%',
                show: true,
                position: 'inside'
              },
            }
          ]
        };
        this.spinner.hide();
        this.myChart_2.setOption(option);
        this.myChart_2.resize();
      }, 1000);
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
      });
  }
  GetGraficoOperadoraTotal() {
    let Grafico = [];

    this.formData = new FormData();
    this.formData.append('id_status', '');
    this.formData.append('id_cliente', this.id_cliente == null ? '' : this.id_cliente);
    this.formData.append('ano', this.ano == null ? '' : this.ano);

    this.homeService.GetGraficoOperadoraTotal(this.formData).subscribe((data: any) => {
      Grafico = data.retorno;
      setTimeout(() => {
        let option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '5%',
            left: 'center',
            type: 'scroll',
            orient: 'horizontal'
          },
          series: [
            {
              name: data.retorno.length == 0 ? 'Nenhuma informação para o Periodo' : 'Atendimento Acumulado',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '16',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: Grafico,
              // '{c}' quantidade
              label: {
                formatter: '{d}%',
                show: true,
                position: 'inside'
              },
            }
          ]
        };
        this.spinner.hide();
        this.myChart_4.setOption(option);
        this.myChart_4.resize();
      }, 1000);
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
      });
  }


  CarregarVisualizacao_2() {
    this.Visualizacao = 2;
    this.CarregarReembolso();
  }
  CarregarVisualizacao_3() {
    this.Visualizacao = 3;
    this.CarregarPrevia();
  }
  CarregarReembolso() {
    this.spinner.show();
    this.homeService.GetHomeReembolso(this.formData).subscribe((data: any) => {
      function FilterStatus(status) {
        return [6, 7, 8, 9, 10, 11].filter(a => a === status.id_status).length == 1;
      }
      this.ListaReembolso = data.retorno.filter(FilterStatus);
      this.ListaReembolsoCompleta = data.retorno;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
      });
  }

  GetConcluidoReembolso() {
    this.p = 1
    function FilterStatus(status) {
      return [6, 7, 8, 9, 10, 11].filter(a => a === status.id_status).length == 1;
    }
    if (this.flg_concluido == 0) {
      this.ListaReembolso = this.ListaReembolsoCompleta;
      this.flg_concluido = 1;
    }
    else {
      this.ListaReembolso = this.ListaReembolsoCompleta.filter(FilterStatus);
      this.flg_concluido = 0;
    }

    if (this.flg_cor != '') {
      this.FiltroCoresReembolso(this.flg_cor);
    }
  }
  FiltroCoresReembolso(e) {
    this.p = 1
    function FilterStatus(status) {
      return [6, 7, 8, 9, 10, 11].filter(a => a === status.id_status).length == 1;
    }

    if (e == undefined) {
      this.ListaReembolso = this.ListaReembolsoCompleta;
    }
    else {
      this.ListaReembolso = this.ListaReembolsoCompleta.filter(a => a.background === e)
    }

    if (this.flg_concluido == 0) {
      this.ListaReembolso = this.ListaReembolso.filter(FilterStatus);
    }

    this.flg_cor = e;
  }

  Redirect(url, tp) {
    $('#id_menu_Home').removeClass('kt-menu__item--active').addClass('kt-menu__item--submenu');
    $('#id_menu_' + url).removeClass('kt-menu__item--submenu').addClass('kt-menu__item--active');
    this.OpenDetalhes(tp);
  }
  GetStatus() {
    this.formData = new FormData();
    this.homeService.GetStatus(this.formData).subscribe((data: any) => {
      this.ListaStatus = data.retorno;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Status');
      });
  }
  GetDataInicio() {
    let data = $("#kt_datepicker_1").datepicker('getDate');
    if (data != null) {
      this.DataInicio = data
      console.log(this.DataInicio);
    }
  }
  GetDataFim() {
    let data = $("#kt_datepicker_2").datepicker('getDate');
    if (data != null) {
      this.DataFim = data
      console.log(this.DataFim);
    }
  }
  GetCliente() {
    this.formData = new FormData();
    this.homeService.GetCliente(this.formData).subscribe((data: any) => {
      this.ListaCliente = data.retorno;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Cliente');
      });
  }
  ExportarReembolso() {
    const lista = this.ListaReembolso.map(item => ({
      Cliente: item.no_cliente,
      Paciente: item.no_paciente,
      Status: item.no_status,
      DataCadastro: item.dt_registro,
      Plano: item.no_plano
    }));
    this.excelService.exportAsExcelFile(lista, 'ListaReembolso');
  }
  FiltroCoresPrevia(e) {
    this.p = 1
    if (e == undefined) {
      this.ListaPrevia = this.ListaPreviaCompleta;
    }
    else {
      this.ListaPrevia = this.ListaPreviaCompleta.filter(a => a.background === e)
    }
  }

  CarregarPrevia() {
    this.spinner.show();
    this.homeService.GetHomePrevia(this.formData).subscribe((data: any) => {
      this.ListaPrevia = data.retorno;
      this.ListaPreviaCompleta = data.retorno;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
      });
  }
  ExportarPrevia() {
    const lista = this.ListaPrevia.map(item => ({
      Cliente: item.no_cliente,
      Paciente: item.no_paciente,
      Status: item.no_status,
      DataCadastro: item.dt_registro,
      Plano: item.no_plano
    }));
    this.excelService.exportAsExcelFile(lista, 'ListaPrevia');
  }
  CarregarVisualizacao_4() {
    this.GetDetalhesGraficoBMS();
    this.Visualizacao = 4;
    setTimeout(() => {
      this.myChart_5 = echarts.init(document.getElementById('grafico-bms'));

      this.CarregarGraficoBMS();
      CarregarScriptData();
      this.id_cliente = null;
    }, 500);
  }
  RecarregarVisualizacao_4() {
    this.ListaDetalheGraficoBMS = [];
    this.GetDetalhesGraficoBMS();
    this.Visualizacao = 4;
    setTimeout(() => {
      this.myChart_5 = echarts.init(document.getElementById('grafico-bms'));

      this.CarregarGraficoBMS();
      CarregarScriptData();
    }, 500);
  }
  GetDetalhesGraficoBMS() {
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_cliente', this.id_cliente == null ? '' : this.id_cliente);
    this.formData.append('ano', this.ano == null ? '' : this.ano);
    this.homeService.GetDetalhesGraficoBMS(this.formData).subscribe((data: any) => {
      this.ListaDetalheGraficoBMS = data.retorno;
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        if (err.status === 401) {
          return;
        }
      });
  }

  indexAnterior = 0;
  ListaSubDetalheGraficoBMS: any = [];

  SubDetalheBMS(i, id_procedimento) {

    if (this.ListaDetalheGraficoBMS.filter(a => a.id_procedimento === id_procedimento)[0].expand) {
      this.ListaDetalheGraficoBMS.filter(a => a.id_procedimento === id_procedimento)[0].expand = false;
      return;
    }
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_procedimento', id_procedimento);
    this.homeService.GetSubDetalhesGraficoBMS(this.formData).subscribe((data: any) => {
      this.ListaSubDetalheGraficoBMS = data.retorno;

      this.indexAnterior != 0 ? this.ListaDetalheGraficoBMS.filter(a => a.id_procedimento === this.indexAnterior)[0].expand = false : '';

      this.ListaDetalheGraficoBMS.filter(a => a.id_procedimento === id_procedimento)[0].expand = true;
      this.indexAnterior = id_procedimento;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        if (err.status === 401) {
          return;
        }
      });



  }
  CarregarGraficoBMS() {

    function format(data) {
      data = parseFloat(data);
      return data.toLocaleString('pt-BR', { style: 'currency', currency: 'TRY' });
    }

    this.formData = new FormData();
    this.formData.append('id_status', '');
    this.formData.append('id_cliente', this.id_cliente == null ? '' : this.id_cliente);
    this.formData.append('ano', this.ano == null ? '' : this.ano);

    this.homeService.GetGraficoBMS(this.formData).subscribe((data: any) => {

      setTimeout(() => {
        let option = {
          tooltip: {
            trigger: 'item',
            formatter: function(params) {
              return `${params.seriesName}: R$ ${parseFloat(params.data).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
            }
          },
          legend: {
            data: ['FATURAMENTO', 'BMS']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            data: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ']

          },
          yAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          series: [
            {
              name: 'FATURAMENTO',
              type: 'bar',
              data: [
                data.retorno.filter(a => a.mes === '01').length > 0 ? data.retorno.filter(a => a.mes === '01')[0].valor.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '02').length > 0 ? data.retorno.filter(a => a.mes === '02')[0].valor.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '03').length > 0 ? data.retorno.filter(a => a.mes === '03')[0].valor.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '04').length > 0 ? data.retorno.filter(a => a.mes === '04')[0].valor.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '05').length > 0 ? data.retorno.filter(a => a.mes === '05')[0].valor.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '06').length > 0 ? data.retorno.filter(a => a.mes === '06')[0].valor.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '07').length > 0 ? data.retorno.filter(a => a.mes === '07')[0].valor.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '08').length > 0 ? data.retorno.filter(a => a.mes === '08')[0].valor.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '09').length > 0 ? data.retorno.filter(a => a.mes === '09')[0].valor.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '10').length > 0 ? data.retorno.filter(a => a.mes === '10')[0].valor.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '11').length > 0 ? data.retorno.filter(a => a.mes === '11')[0].valor.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '12').length > 0 ? data.retorno.filter(a => a.mes === '12')[0].valor.toFixed(2) : 0]
            },
            {
              name: 'BMS',
              type: 'bar',
              data: [
                data.retorno.filter(a => a.mes === '01').length > 0 ? data.retorno.filter(a => a.mes === '01')[0].bms.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '02').length > 0 ? data.retorno.filter(a => a.mes === '02')[0].bms.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '03').length > 0 ? data.retorno.filter(a => a.mes === '03')[0].bms.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '04').length > 0 ? data.retorno.filter(a => a.mes === '04')[0].bms.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '05').length > 0 ? data.retorno.filter(a => a.mes === '05')[0].bms.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '06').length > 0 ? data.retorno.filter(a => a.mes === '06')[0].bms.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '07').length > 0 ? data.retorno.filter(a => a.mes === '07')[0].bms.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '08').length > 0 ? data.retorno.filter(a => a.mes === '08')[0].bms.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '09').length > 0 ? data.retorno.filter(a => a.mes === '09')[0].bms.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '10').length > 0 ? data.retorno.filter(a => a.mes === '10')[0].bms.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '11').length > 0 ? data.retorno.filter(a => a.mes === '11')[0].bms.toFixed(2) : 0,
                data.retorno.filter(a => a.mes === '12').length > 0 ? data.retorno.filter(a => a.mes === '12')[0].bms.toFixed(2) : 0]
            },

          ]
        };


        this.spinner.hide();
        this.myChart_5.setOption(option);
        this.myChart_5.resize();
        this.spinner.hide();
      }, 1000);
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        if (err.status === 401) {
          return;
        }
      });
  }
  OpenDetalhes(tp) {
    localStorage.setItem('RedirecionamentoHome', tp);
  }

  ListaPacienteAgendamento: any = [];
  id_paciente: any = '';
  ListaAnoAgendamento: any = [];
  GetPacienteAgendamento() {
    this.formData = new FormData();
    this.homeService.GetPacienteAgendamento(this.formData).subscribe((data: any) => {
      this.ListaPacienteAgendamento = data.retorno;
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        if (err.status === 401) {
          return;
        }
      });
  }
  GetAnoDisponivel() {
    this.formData = new FormData();
    this.homeService.GetAnoDisponivel(this.formData).subscribe((data: any) => {
      this.ListaAnoAgendamento = data.retorno;
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        if (err.status === 401) {
          return;
        }
      });
  }
  RecarregarGraficosAgenda() {

    this.CarregarGraficoFaturamento();
  }
  CarregarVisualizacao_5() {
    this.id_paciente = null;
    this.id_cliente = null;
    this.GetAnoDisponivel();
    this.GetClienteAgendamento();
    this.GetPacienteAgendamento();
    this.Visualizacao = 5;
    setTimeout(() => {
      this.myChart_5 = echarts.init(document.getElementById('grafico-faturamento'));
      this.CarregarGraficoFaturamento();

    }, 500);
  }

  GetClienteAgendamento(){
    this.homeService.GetClienteAgendamento(this.formData).subscribe((data: any) => {
      this.ListaClienteAgendamento = data.retorno;
      this.ListaPeriodoAgendamento = data.retorno_2;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
      });
  }

  total_agendamento = 0;
  vl_total_agendamento = 0;
  total_agendamento_previsto = 0;
  vl_total_agendamento_previsto = 0;
  ListaDetalheAgendamento: any[];
  ListaPendenteTipoPagamento: any[];
  indexAnteriorPendente = 0;
  ListaTipoPagamento: any = [{ id_tipo_pagamento: 1, ds_pagamento: 'NOTA FISCAL' }, { id_tipo_pagamento: 2, ds_pagamento: 'RECIBO' }, { id_tipo_pagamento: 3, ds_pagamento: 'OUTROS' }];

  ListaArquivos: File[] = [];
  formCadastroPendentePagamento: FormGroup;
  qtAlerta = 0;


  qt_paciente_anual = 0;
  qt_agendamento_anual = 0;
  vl_nota_anual = 0;
  vl_recibo_anual = 0;
  vl_outros_anual = 0;
  vl_faturamento_previsto_anual = 0;

  qt_paciente_mensal = 0;
  qt_agendamento_mensal = 0;
  vl_mensal = 0;
  vl_faturamento = 0;

  CarregarFormBuilder() {
    this.formCadastroPendentePagamento = this.formBuilder.group({
      id_tipo_pagamento: [null],
      id_paciente: [null]
    });
  }

  get f() { return this.formCadastroPendentePagamento.controls; }

  GetInformacaoFaturamentoAnual() {
    this.qt_paciente_anual = 0;
    this.qt_agendamento_anual = 0;
    this.vl_nota_anual = 0;
    this.vl_recibo_anual = 0;
    this.vl_outros_anual = 0;
    this.vl_faturamento_previsto_anual = 0;
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('ano', this.ano == null ? '' : this.ano);
    this.formData.append('id_paciente', this.id_paciente == null ? 'null' : this.id_paciente);
    this.formData.append('id_cliente', this.id_cliente == null ? 'null' : this.id_cliente);
    this.homeService.GetInformacaoFaturamentoAnual(this.formData).subscribe((data: any) => {
      this.qt_paciente_anual = data.retorno_1[0].qt_paciente;

      for (let i = 0; i < data.retorno_2.length; i++) {
        if (data.retorno_2[i].sit_lkrepay_conclusao == true) {
          if (data.retorno_2[i].ds_tipo_pagamento == 'NOTA FISCAL') {
            this.vl_nota_anual = this.vl_nota_anual + data.retorno_2[i].vl_consulta
          }
          if (data.retorno_2[i].ds_tipo_pagamento == 'RECIBO') {
            this.vl_recibo_anual = this.vl_recibo_anual + data.retorno_2[i].vl_consulta
          }
          if (data.retorno_2[i].ds_tipo_pagamento == 'OUTROS') {
            this.vl_outros_anual = this.vl_outros_anual + data.retorno_2[i].vl_consulta
          }
        }

         if (data.retorno_2[i].sit_conclusao == true) {
          this.qt_agendamento_anual = this.qt_agendamento_anual + data.retorno_2[i].qt_agendamento;
         }
         else {
           this.vl_faturamento_previsto_anual = this.vl_faturamento_previsto_anual + data.retorno_2[i].vl_consulta;
          }
      }
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
      });
  }
  GetInformacaoFaturamentoMes(){
    this.anoMes = this.MesConvertido(this.periodo.substring(0, 2)) + '/' + this.periodo.substring(3, 7);
    this.anoTexto = this.periodo.substring(3, 7);

    this.qt_agendamento_mensal = 0;
    this.vl_mensal = 0;
    this.vl_faturamento = 0;
    this.qt_paciente_mensal = 0;
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('ano', this.periodo == null ? '' : this.periodo);
    this.formData.append('id_paciente', this.id_paciente == null ? 'null' : this.id_paciente);
    this.formData.append('id_cliente', this.id_cliente == null ? 'null' : this.id_cliente);
    this.homeService.GetInformacaoFaturamentoMes(this.formData).subscribe((data: any) => {
      this.qt_paciente_mensal = data.retorno_1[0].qt_paciente;

      for (let i = 0; i < data.retorno_2.length; i++) {
        if (data.retorno_2[i].sit_conclusao == true) {
          this.qt_agendamento_mensal = this.qt_agendamento_mensal + data.retorno_2[i].qt_agendamento;
          this.vl_mensal = this.vl_mensal + data.retorno_2[i].vl_consulta;
        }

        if(data.retorno_2[i].sit_lkrepay_conclusao == true) {
          this.vl_faturamento = this.vl_faturamento + data.retorno_2[i].vl_consulta;
        }

      }
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
      });
}

  CarregarGraficoFaturamento() {
    this.GetInformacaoFaturamentoAnual();
    this.GetInformacaoFaturamentoMes();
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('ano', this.ano == null ? '' : this.ano);
    this.formData.append('id_paciente', this.id_paciente == null ? 'null' : this.id_paciente);
    this.formData.append('id_cliente', this.id_cliente == null ? 'null' : this.id_cliente);
    this.formData.append('periodo', this.periodo == null ? '' : this.periodo);
    this.homeService.GetGraficoFaturamento(this.formData).subscribe((data: any) => {
      this.ListaDetalheAgendamento = data.retorno_3;
      this.qtAlerta = data.retorno_4;
      this.spinner.hide();

      setTimeout(() => {
        let option = {
          tooltip: {
            trigger: 'item',
            formatter: function(params) {
              return `${params.seriesName}: R$ ${parseFloat(params.data).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
            }
          },
          xAxis: {
            type: 'category',
            data: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
          },
          yAxis: {
            type: 'value'
          },
          legend: {
            data: ['NOTA FISCAL', 'RECIBO', 'OUTROS']
          },
          series: [
            {
              name: 'NOTA FISCAL',
              type: 'bar',
              data: [
                data.retorno.filter(a => a.dt_periodo === '01' && a.ds_tipo_pagamento === 'NOTA FISCAL').length > 0 ? data.retorno.filter(a => a.dt_periodo === '01' && a.ds_tipo_pagamento === 'NOTA FISCAL')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '02' && a.ds_tipo_pagamento === 'NOTA FISCAL').length > 0 ? data.retorno.filter(a => a.dt_periodo === '02' && a.ds_tipo_pagamento === 'NOTA FISCAL')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '03' && a.ds_tipo_pagamento === 'NOTA FISCAL').length > 0 ? data.retorno.filter(a => a.dt_periodo === '03' && a.ds_tipo_pagamento === 'NOTA FISCAL')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '04' && a.ds_tipo_pagamento === 'NOTA FISCAL').length > 0 ? data.retorno.filter(a => a.dt_periodo === '04' && a.ds_tipo_pagamento === 'NOTA FISCAL')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '05' && a.ds_tipo_pagamento === 'NOTA FISCAL').length > 0 ? data.retorno.filter(a => a.dt_periodo === '05' && a.ds_tipo_pagamento === 'NOTA FISCAL')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '06' && a.ds_tipo_pagamento === 'NOTA FISCAL').length > 0 ? data.retorno.filter(a => a.dt_periodo === '06' && a.ds_tipo_pagamento === 'NOTA FISCAL')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '07' && a.ds_tipo_pagamento === 'NOTA FISCAL').length > 0 ? data.retorno.filter(a => a.dt_periodo === '07' && a.ds_tipo_pagamento === 'NOTA FISCAL')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '08' && a.ds_tipo_pagamento === 'NOTA FISCAL').length > 0 ? data.retorno.filter(a => a.dt_periodo === '08' && a.ds_tipo_pagamento === 'NOTA FISCAL')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '09' && a.ds_tipo_pagamento === 'NOTA FISCAL').length > 0 ? data.retorno.filter(a => a.dt_periodo === '09' && a.ds_tipo_pagamento === 'NOTA FISCAL')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '10' && a.ds_tipo_pagamento === 'NOTA FISCAL').length > 0 ? data.retorno.filter(a => a.dt_periodo === '10' && a.ds_tipo_pagamento === 'NOTA FISCAL')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '11' && a.ds_tipo_pagamento === 'NOTA FISCAL').length > 0 ? data.retorno.filter(a => a.dt_periodo === '11' && a.ds_tipo_pagamento === 'NOTA FISCAL')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '12' && a.ds_tipo_pagamento === 'NOTA FISCAL').length > 0 ? data.retorno.filter(a => a.dt_periodo === '12' && a.ds_tipo_pagamento === 'NOTA FISCAL')[0].vl : 0]
            },
            {
              name: 'RECIBO',
              type: 'bar',
              data: [
                data.retorno.filter(a => a.dt_periodo === '01' && a.ds_tipo_pagamento === 'RECIBO').length > 0 ? data.retorno.filter(a => a.dt_periodo === '01' && a.ds_tipo_pagamento === 'RECIBO')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '02' && a.ds_tipo_pagamento === 'RECIBO').length > 0 ? data.retorno.filter(a => a.dt_periodo === '02' && a.ds_tipo_pagamento === 'RECIBO')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '03' && a.ds_tipo_pagamento === 'RECIBO').length > 0 ? data.retorno.filter(a => a.dt_periodo === '03' && a.ds_tipo_pagamento === 'RECIBO')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '04' && a.ds_tipo_pagamento === 'RECIBO').length > 0 ? data.retorno.filter(a => a.dt_periodo === '04' && a.ds_tipo_pagamento === 'RECIBO')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '05' && a.ds_tipo_pagamento === 'RECIBO').length > 0 ? data.retorno.filter(a => a.dt_periodo === '05' && a.ds_tipo_pagamento === 'RECIBO')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '06' && a.ds_tipo_pagamento === 'RECIBO').length > 0 ? data.retorno.filter(a => a.dt_periodo === '06' && a.ds_tipo_pagamento === 'RECIBO')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '07' && a.ds_tipo_pagamento === 'RECIBO').length > 0 ? data.retorno.filter(a => a.dt_periodo === '07' && a.ds_tipo_pagamento === 'RECIBO')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '08' && a.ds_tipo_pagamento === 'RECIBO').length > 0 ? data.retorno.filter(a => a.dt_periodo === '08' && a.ds_tipo_pagamento === 'RECIBO')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '09' && a.ds_tipo_pagamento === 'RECIBO').length > 0 ? data.retorno.filter(a => a.dt_periodo === '09' && a.ds_tipo_pagamento === 'RECIBO')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '10' && a.ds_tipo_pagamento === 'RECIBO').length > 0 ? data.retorno.filter(a => a.dt_periodo === '10' && a.ds_tipo_pagamento === 'RECIBO')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '11' && a.ds_tipo_pagamento === 'RECIBO').length > 0 ? data.retorno.filter(a => a.dt_periodo === '11' && a.ds_tipo_pagamento === 'RECIBO')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '12' && a.ds_tipo_pagamento === 'RECIBO').length > 0 ? data.retorno.filter(a => a.dt_periodo === '12' && a.ds_tipo_pagamento === 'RECIBO')[0].vl : 0]
            },
            {
              name: 'OUTROS',
              type: 'bar',
              data: [
                data.retorno.filter(a => a.dt_periodo === '01' && a.ds_tipo_pagamento === 'OUTROS').length > 0 ? data.retorno.filter(a => a.dt_periodo === '01' && a.ds_tipo_pagamento === 'OUTROS')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '02' && a.ds_tipo_pagamento === 'OUTROS').length > 0 ? data.retorno.filter(a => a.dt_periodo === '02' && a.ds_tipo_pagamento === 'OUTROS')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '03' && a.ds_tipo_pagamento === 'OUTROS').length > 0 ? data.retorno.filter(a => a.dt_periodo === '03' && a.ds_tipo_pagamento === 'OUTROS')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '04' && a.ds_tipo_pagamento === 'OUTROS').length > 0 ? data.retorno.filter(a => a.dt_periodo === '04' && a.ds_tipo_pagamento === 'OUTROS')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '05' && a.ds_tipo_pagamento === 'OUTROS').length > 0 ? data.retorno.filter(a => a.dt_periodo === '05' && a.ds_tipo_pagamento === 'OUTROS')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '06' && a.ds_tipo_pagamento === 'OUTROS').length > 0 ? data.retorno.filter(a => a.dt_periodo === '06' && a.ds_tipo_pagamento === 'OUTROS')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '07' && a.ds_tipo_pagamento === 'OUTROS').length > 0 ? data.retorno.filter(a => a.dt_periodo === '07' && a.ds_tipo_pagamento === 'OUTROS')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '08' && a.ds_tipo_pagamento === 'OUTROS').length > 0 ? data.retorno.filter(a => a.dt_periodo === '08' && a.ds_tipo_pagamento === 'OUTROS')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '09' && a.ds_tipo_pagamento === 'OUTROS').length > 0 ? data.retorno.filter(a => a.dt_periodo === '09' && a.ds_tipo_pagamento === 'OUTROS')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '10' && a.ds_tipo_pagamento === 'OUTROS').length > 0 ? data.retorno.filter(a => a.dt_periodo === '10' && a.ds_tipo_pagamento === 'OUTROS')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '11' && a.ds_tipo_pagamento === 'OUTROS').length > 0 ? data.retorno.filter(a => a.dt_periodo === '11' && a.ds_tipo_pagamento === 'OUTROS')[0].vl : 0,
                data.retorno.filter(a => a.dt_periodo === '12' && a.ds_tipo_pagamento === 'OUTROS').length > 0 ? data.retorno.filter(a => a.dt_periodo === '12' && a.ds_tipo_pagamento === 'OUTROS')[0].vl : 0]
            }

          ]
        };
        this.spinner.hide();
        this.myChart_5.setOption(option);
        this.myChart_5.resize();
      }, 1000);
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
      });
    }
  CarregarVisualizacao_6() {
    this.CarregarFormBuilder();
    setTimeout(() => {
      this.Visualizacao = 6;
      this.CarregarPendenteTipoPagamento();
    }, 500);
  }
  CarregarPendenteTipoPagamento() {
    this.spinner.show();
    this.homeService.GetPendenteTipoPagamento(this.formData).subscribe((data: any) => {
      this.ListaPendenteTipoPagamento = data.retorno;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
      });
  }
  SubDetalhePendente(i, id_paciente) {
    this.formCadastroPendentePagamento.patchValue({
      id_paciente: null,
      id_tipo_pagamento: null
    });

    if (this.ListaPendenteTipoPagamento[i].expand) {
      this.ListaPendenteTipoPagamento[i].expand = false;
      return;
    }
    this.indexAnteriorPendente = i;
    this.ListaPendenteTipoPagamento[i].expand = true;

    this.formCadastroPendentePagamento.patchValue({
      id_paciente: id_paciente,
    });
  }
  uploadDocumento(fileInput: any, tpArquivo) {
    this.ListaArquivos.push(fileInput.target.files[0]);
  }
  SalvarProcedimento_bkp() {
    this.formData = new FormData();
    this.formData.append('id_tipo_pagamento', this.formCadastroPendentePagamento.value.id_tipo_pagamento);
    this.formData.append('id_paciente', this.formCadastroPendentePagamento.value.id_paciente);
    this.formData.append('arquivo', this.ListaArquivos[0]);

    this.homeService.AtualizarPagamentoPaciente(this.formData).subscribe((data: any) => {
      this.sweetAlertService.SweetAlert('ok', 'Cadastro Atualizado com Sucesso!');
      this.SubDetalhePendente(this.indexAnteriorPendente, null);
      setTimeout(() => {
        this.CarregarVisualizacao_6();
      }, 500);
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao cadastrar');
        this.spinner.hide();
      });
  }
  SalvarProcedimento(id_paciente) {
    Swal.fire({
      title: 'Confirma a conclusão do Primeiro Contato com o Paciente? ',
      text: "Você não será capaz de reverter isso!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ConclusaoPCP(id_paciente);
      }
    });
  }
  ConclusaoPCP(id_paciente){
  this.formData = new FormData();
  this.formData.append('id_paciente', id_paciente);
  this.homeService.ConclusaoPCP(this.formData).subscribe((data: any) => {
    this.sweetAlertService.SweetAlert('ok', 'PCP cadastrado com Sucesso!');
    setTimeout(() => {
      this.CarregarVisualizacao_6();
    }, 500);
  },
    (err: HttpErrorResponse) => {
      if (err.status === 401) {
        return;
      }
      this.sweetAlertService.SweetAlert('error', 'Erro ao cadastrar');
      this.spinner.hide();
    });
}
  SalvarOKProcedimento(id_agendamento) {
    Swal.fire({
      title: 'Deseja Enviar para Cobrança ?',
      text: "Você não será capaz de reverter isso!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ConfirmarOKProcedimento(id_agendamento);
      }
    });
  }
  ConfirmarOKProcedimento(id_agendamento) {
    this.formData = new FormData();
    this.formData.append('id_agendamento', id_agendamento);

    this.homeService.ConclusaoAgendamento(this.formData).subscribe((data: any) => {
      this.sweetAlertService.SweetAlert('time', 'Sessão Enviada com Sucesso!');
      setTimeout(() => {
        this.CarregarGraficoFaturamento();
      }, 500);
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao cadastrar');
        this.spinner.hide();
      });
  }

  ListaGestaoCliente: any = [];
  ListaGestaoPaciente: any = [];
  ListaGestaoPacienteSub: any = [];
  ListaGestaoPaciente_FiltroPeriodo: any = [];
  SearchGestaoPaciente: any = [];
  sit = '0';
  qtCancelado = 0;
  checkCancelado: boolean = false;
  CarregarVisualizacao_7() {
    this.CarregarFormBuilder();
    setTimeout(() => {
      this.Visualizacao = 7;
      this.GetGestaoCliente();
    }, 500);
  }
  GetGestaoCliente() {
    this.formData = new FormData();
    this.spinner.show();
    this.formData.append('ano', this.ano == null ? '' : this.ano);
    this.homeService.GetGestaoCliente(this.formData).subscribe((data: any) => {
      // this.ListaGestaoCliente = data.retorno;
      var novaLista: any = [];
      for (let index = 0; index < data.retorno_2.length; index++) {
              novaLista.push({
                no_fantasia: data.retorno_2[index],
                dt_01: data.retorno.filter(a => a.dt_periodo === '01' && a.no_fantasia == data.retorno_2[index]).length > 0 ? data.retorno.filter(a => a.dt_periodo === '01' && a.no_fantasia == data.retorno_2[index])[0].ds_color : '#fff',
                dt_02: data.retorno.filter(a => a.dt_periodo === '02' && a.no_fantasia == data.retorno_2[index]).length > 0 ? data.retorno.filter(a => a.dt_periodo === '02' && a.no_fantasia == data.retorno_2[index])[0].ds_color : '#fff',
                dt_03: data.retorno.filter(a => a.dt_periodo === '03' && a.no_fantasia == data.retorno_2[index]).length > 0 ? data.retorno.filter(a => a.dt_periodo === '03' && a.no_fantasia == data.retorno_2[index])[0].ds_color : '#fff',
                dt_04: data.retorno.filter(a => a.dt_periodo === '04' && a.no_fantasia == data.retorno_2[index]).length > 0 ? data.retorno.filter(a => a.dt_periodo === '04' && a.no_fantasia == data.retorno_2[index])[0].ds_color : '#fff',
                dt_05: data.retorno.filter(a => a.dt_periodo === '05' && a.no_fantasia == data.retorno_2[index]).length > 0 ? data.retorno.filter(a => a.dt_periodo === '05' && a.no_fantasia == data.retorno_2[index])[0].ds_color : '#fff',
                dt_06: data.retorno.filter(a => a.dt_periodo === '06' && a.no_fantasia == data.retorno_2[index]).length > 0 ? data.retorno.filter(a => a.dt_periodo === '06' && a.no_fantasia == data.retorno_2[index])[0].ds_color : '#fff',
                dt_07: data.retorno.filter(a => a.dt_periodo === '07' && a.no_fantasia == data.retorno_2[index]).length > 0 ? data.retorno.filter(a => a.dt_periodo === '07' && a.no_fantasia == data.retorno_2[index])[0].ds_color : '#fff',
                dt_08: data.retorno.filter(a => a.dt_periodo === '08' && a.no_fantasia == data.retorno_2[index]).length > 0 ? data.retorno.filter(a => a.dt_periodo === '08' && a.no_fantasia == data.retorno_2[index])[0].ds_color : '#fff',
                dt_09: data.retorno.filter(a => a.dt_periodo === '09' && a.no_fantasia == data.retorno_2[index]).length > 0 ? data.retorno.filter(a => a.dt_periodo === '09' && a.no_fantasia == data.retorno_2[index])[0].ds_color : '#fff',
                dt_10: data.retorno.filter(a => a.dt_periodo === '10' && a.no_fantasia == data.retorno_2[index]).length > 0 ? data.retorno.filter(a => a.dt_periodo === '10' && a.no_fantasia == data.retorno_2[index])[0].ds_color : '#fff',
                dt_11: data.retorno.filter(a => a.dt_periodo === '11' && a.no_fantasia == data.retorno_2[index]).length > 0 ? data.retorno.filter(a => a.dt_periodo === '11' && a.no_fantasia == data.retorno_2[index])[0].ds_color : '#fff',
                dt_12: data.retorno.filter(a => a.dt_periodo === '12' && a.no_fantasia == data.retorno_2[index]).length > 0 ? data.retorno.filter(a => a.dt_periodo === '12' && a.no_fantasia == data.retorno_2[index])[0].ds_color : '#fff',
              })
      }

      this.ListaGestaoCliente = novaLista;

      console.log(this.ListaGestaoCliente);
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
      });
  }

  CarregarVisualizacao_8() {
    this.CarregarFormBuilder();
    setTimeout(() => {
      this.sit = '0';
      this.Visualizacao = 8;
      this.GetGestaoPaciente();
    }, 500);
  }

  GetGestaoPacienteConcluido(){
    if (this.sit == '0') {
      this.sit = '1'
    }
    else(
      this.sit = '0'
    )

    this.GetGestaoPaciente();
  }
  GetGestaoPaciente() {
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_cliente', this.SearchGestaoPaciente.id_cliente);
    this.formData.append('ano', this.SearchGestaoPaciente.ano);
    this.formData.append('mes', this.SearchGestaoPaciente.mes);
    this.formData.append('sit', this.sit);

    this.homeService.GetGestaoPaciente(this.formData).subscribe((data: any) => {
      this.ListaGestaoPaciente = data.retorno;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        if (err.status === 401) {
          return;
        }
      });
  }

  GetGestaoPacienteSub(index, id_cliente, dt_periodo, id_paciente, ds_tipo_pagamento, id_agendamento){
    index = this.p != 1 ? ((this.p - 1) * 10) + index : index;
    this.qtCancelado = 0;
    if (this.ListaGestaoPaciente[index].expand) {
      this.ListaGestaoPaciente[index].expand = false;
      return;
    }

    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_cliente', id_cliente);
    this.formData.append('dt_periodo', dt_periodo);
    this.formData.append('id_paciente', id_paciente);
    this.formData.append('ds_tipo_pagamento', ds_tipo_pagamento);
    this.formData.append('id_agendamento', id_agendamento);

    this.homeService.GetGestaoPacienteSub(this.formData).subscribe((data: any) => {
      this.ListaGestaoPacienteSub = data.retorno;
      this.qtCancelado = data.retorno_2.qt;

      this.indexAnterior != index ? this.ListaGestaoPaciente[this.indexAnterior].expand = false : '';

      this.ListaGestaoPaciente[index].expand = true;
      this.indexAnterior = index;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        if (err.status === 401) {
          return;
        }
      });
  }

  GetGestaoPacienteConclusaoAlerta(index, id_cliente, dt_periodo, id_paciente,ds_tipo_pagamento, id_agendamento){
    Swal.fire({
      title: 'Confirma a conclusão do Pagamento do Paciente?',
      text: "Você não será capaz de reverter isso!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.GetGestaoPacienteConclusao(index, id_cliente, dt_periodo, id_paciente,ds_tipo_pagamento, id_agendamento);
      }
    });
  }
  GetGestaoPacienteConclusao(index, id_cliente, dt_periodo, id_paciente,ds_tipo_pagamento, id_agendamento){
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_cliente', id_cliente);
    this.formData.append('dt_periodo', dt_periodo);
    this.formData.append('id_paciente', id_paciente);
    this.formData.append('ds_tipo_pagamento', ds_tipo_pagamento);
    this.formData.append('id_agendamento', id_agendamento);
    this.formData.append('checkCancelado', this.checkCancelado ? '1' : '0');

    this.homeService.GetGestaoPacienteConclusao(this.formData).subscribe((data: any) => {
      this.sweetAlertService.SweetAlert('ok', 'Sessão concluida com sucesso');
      this.indexAnterior = 0;
      this.GetGestaoPaciente();
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        if (err.status === 401) {
          return;
        }
      });
  }

  ExportarGestaoPaciente(detalhe) {
      this.spinner.show();
      var doc = new jsPDF();
      doc.setFontSize(10);
      doc.setFont("normal")
      doc.text("Cliente: " + detalhe.no_fantasia, 20, 20);
      doc.text("Paciente: " + detalhe.no_paciente + " - Celular: " + detalhe.no_celular, 20, 25);
      doc.text("Valor Total da Consulta: R$" + detalhe.vl_consulta + "- Tipo de Pagamento: " + detalhe.ds_tipo_pagamento, 20, 30);
      doc.setFont("bold")
      doc.text("Detalhes da Consulta", 20, 40);

      let tm = 40;
      this.formData = new FormData();
      this.formData.append('id_cliente', detalhe.id_cliente);
      this.formData.append('dt_periodo', detalhe.dt_periodo);
      this.formData.append('id_paciente', detalhe.id_paciente);
      this.formData.append('ds_tipo_pagamento', detalhe.ds_tipo_pagamento);

      this.homeService.GetGestaoPacienteSub(this.formData).subscribe((data: any) => {
        this.ListaGestaoPacienteSub = data.retorno;
        for (let index = 0; index < data.retorno.length; index++) {
          tm = tm + 5;
          doc.setFontSize(8);
          doc.setFont("normal")
          doc.text("Sessão: " + data.retorno[index].dt_sessao + " - Valor: R$" + data.retorno[index].vl_consulta.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2  }), 20, tm);
        }

        setTimeout(() => {
          doc.addPage("a4", "l");
          doc.save('FichaPaciente - ' + "GestaoPaciente_" + detalhe.no_paciente + '.pdf');
          this.spinner.hide();
        }, 2000);
      },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          if (err.status === 401) {
            return;
          }
        });



  }
  GestaoPacienteCancelado(id_cliente){
    console.log(this.checkCancelado);
    console.log(id_cliente)
  }

  GestaoPacienteCheck(detalhe, tp) {
      this.spinner.show();

      this.formData = new FormData();
      this.formData.append('id_cliente', detalhe.id_cliente);
      this.formData.append('dt_periodo', detalhe.dt_periodo);
      this.formData.append('id_paciente', detalhe.id_paciente);
      this.formData.append('ds_tipo_pagamento', detalhe.ds_tipo_pagamento);
      this.formData.append('tp', tp);
      this.formData.append('id_agendamento', detalhe.id_agendamento);

      this.homeService.GestaoPacienteCheck(this.formData).subscribe((data: any) => {
this.spinner.hide();
      },
        (err: HttpErrorResponse) => {
          this.spinner.hide();
          if (err.status === 401) {
            return;
          }
        });
  }

  valorConsulta;
  tipoPagamento;
  atendimento;

  ListaStatusAgendamento: any = [{id:1, descricao:'ATENDIDO'},{id:2, descricao:'CANCELADA COM COBRANÇA'}, {id:3, descricao:'CANCELADA SEM COBRANÇA'}];

  EditarAgendamento(index, id_agendamento){
    index = this.p != 1 ? ((this.p - 1) * 10) + index : index;

    if (this.ListaDetalheAgendamento.filter(a => a.id_agendamento === id_agendamento)[0].expand) {
      this.ListaDetalheAgendamento.filter(a => a.id_agendamento === id_agendamento)[0].expand = false;
      return;
    }

    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_agendamento', id_agendamento);

    this.homeService.GetAgendamentoDetalhes(this.formData).subscribe((data: any) => {

      this.valorConsulta = data.retorno[0].vl_consulta;
      this.tipoPagamento = data.retorno[0].id_tipo_pagamento;
      this.atendimento = data.retorno[0].fg_consulta;

      this.indexAnterior != 0 ? this.ListaDetalheAgendamento.filter(a => a.id_agendamento === id_agendamento)[0].expand = false : '';
      this.indexAnterior = id_agendamento;
      this.ListaDetalheAgendamento.filter(a => a.id_agendamento === id_agendamento)[0].expand = true;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        if (err.status === 401) {
          return;
        }
      });


  }
  SalvarAgendamento(id_agendamento){
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_agendamento', id_agendamento);
    this.formData.append('id_tipo_pagamento', this.tipoPagamento);
    this.formData.append('vl_consulta', this.valorConsulta);
    this.formData.append('fg_consulta', this.atendimento);

    this.homeService.SalvarAgendamento(this.formData).subscribe((data: any) => {
    this.ListaDetalheAgendamento.filter(a => a.id_agendamento === this.indexAnterior)[0].expand = true;
    this.sweetAlertService.SweetAlert('ok', 'Agenda atualizado com sucesso');
      this.CarregarGraficoFaturamento();
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        if (err.status === 401) {
          return;
        }
      });

  }
  CancelarAgendamento(){
    this.ListaDetalheAgendamento.filter(a => a.id_agendamento === this.indexAnterior)[0].expand = false
  }
}
