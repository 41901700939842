<body class="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed kt-page--loading">
  <div class="kt-grid kt-grid--ver kt-grid--root kt-page">
    <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v5 kt-login--signin" id="kt_login">
      <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile" style="background-image: url(/assets /bg/bg-3.jpg);">
        <div class="kt-login__left">
          <div class="kt-login__wrapper">
            <div class="kt-login__content">
              <a class="kt-login__logo">
                <img src="assets/imgs/LK - logo 02.png">
              </a>
              <h3 class="kt-login__title">DESCOMPLIQUE, RESSIGNIFIQUE, REEMBOLSE !</h3>
              <span class="kt-login__desc">
                Um novo jeito de conquistar, um novo jeito de fidelizar !
              </span>
            </div>
          </div>
        </div>
        <div class="kt-login__divider">
          <div></div>
        </div>
        <div class="kt-login__right">
          <div class="kt-login__wrapper">
            <div class="kt-login__signin" *ngIf="mostrarLogin">
              <div class="alert alert-danger" role="alert" *ngIf="mostrarUsuarioInvalido">
                <div class="alert-icon"><i class="flaticon-circle"></i></div>
                <div class="alert-text">Usuário e/ou senha inválidos</div>
              </div>
              <div class="kt-login__head">
                <h3 class="kt-login__title">Faça login para prosseguir</h3>
              </div>
              <div class="kt-login__form">
                <form [formGroup]="registerForm">
                  <div class="form-group">
                    <input type="text" name="email" placeholder="E-Mail" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" [disabled]="mostrarAutoComplete" />
                    <div class="errorLabel kt-padding-0" *ngIf="registerForm.value.email.length > 2 && f.email.errors?.email">Precisa ser um endereço de email válido</div>
                  </div>
                  <div class="form-group">
                    <input type="password" formControlName="password" placeholder="Senha" class="form-control form-control-last" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback kt-padding-0">
                      <div *ngIf="f.password.errors.minlength">Senha dever ter no mínimo 6 caracteres</div>
                    </div>
                  </div>
                  <div class="row kt-login__extra">
                    <div class="col kt-align-right">
                      <a id="kt_login_forgot" class="kt-link" (click)="EsqueciMinhaSenha()">Esqueceu sua Senha ?</a>
                    </div>
                  </div>
                  <div class="kt-login__actions">
                    <button id="kt_login_signin_submit" [disabled]="registerForm.invalid" class="btn btn-outline-primary" (click)="AutenticarUsuario()" style="width:100%">Entrar</button>
                  </div>
                </form>
              </div>
            </div>
            <div class="kt-login__forgot" *ngIf="mostrarTrocaSenha">
              <div class="kt-login__head">
                <h3 class="kt-login__title">Troca de Senha</h3>
              </div>
              <div class="kt-login__form">
                <div class="form-group">
                  <input class="form-control" type="password" placeholder="Nova Senha" #TrocaSenha ngModel name="nova_senha" id="nova_senha" autocomplete="off">
                  <div class="errorLabel kt-padding-0" *ngIf="TrocaSenha.value.length < 6 && TrocaSenha.value != ''">Senhas deve ter, no mínimo, 6 caracteres</div>
                </div>
                <div class="form-group">
                  <input class="form-control" type="password" placeholder="Repita Nova Senha" #TrocaSenhaRepetida ngModel name="repita_senha" id="repita_senha" autocomplete="off">
                  <div class="errorLabel kt-padding-0" *ngIf="TrocaSenha.value != TrocaSenhaRepetida.value && TrocaSenhaRepetida.value != ''">Senhas não conferem, digite as senhas iguais</div>
                </div>
                <div class="kt-login__actions">
                  <button class="btn btn-outline-primary" [disabled]="TrocaSenha.value != TrocaSenhaRepetida.value || TrocaSenha.value == ''" (click)="AlterarSenha(TrocaSenha.value)">Alterar</button>
                  <button class="btn btn-outline-secondary" (click)="CancelarEsqueciMinhaSenha()">Cancelar</button>
                </div>
              </div>
            </div>
            <div class="kt-login__forgot" *ngIf="mostrarResete">
              <div class="kt-section__content">
                <div class="alert alert-primary" role="alert" *ngIf="mostrarMensagemResete">
                  <div class="alert-icon"><i class="flaticon-warning"></i></div>
                  <div class="alert-text">Você receberá um email com um link para redefinir sua senha em alguns minutos.</div>
                </div>
              </div>
              <div class="kt-login__head">
                <h3 class="kt-login__title">Esqueceu sua Senha ?</h3>
                <div class="kt-login__desc">Digite seu e-mail para redefinir sua senha</div>
              </div>
              <div class="kt-login__form">
                <div class="form-group">
                  <input class="form-control" type="text" placeholder="E-Mail" #EsqueciEmail ngModel name="email" id="kt_email" autocomplete="off">
                </div>
                <div class="kt-login__actions">
                  <button class="btn btn-outline-primary" (click)="ReseteSenha(EsqueciEmail.value)">Enviar</button>
                  <button class="btn btn-outline-secondary" (click)="CancelarEsqueciMinhaSenha()">Cancelar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
