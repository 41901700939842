import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutenticacaoGuard } from './common/autenticacao/autenticacao.guard';
import { LoginComponent } from './page/login/login.component';
import { HomeComponent } from './page/home/home.component';
import { ListarClienteComponent } from './page/cliente/listar-cliente.component';
import { GerenciamentoComponent } from './page/gerenciamento/gerenciamento.component';
import { PacienteComponent } from './page/paciente/paciente.component';
import { ProcedimentoComponent } from './page/procedimento/procedimento.component';
import { UsuarioComponent } from './page/usuario/usuario.component';
import { NaoAutorizadoComponent } from './page/error/nao-autorizado/nao-autorizado.component';
import { NotificacaoComponent } from './page/notificacao/notificacao.component';
import { AgendaComponent } from './page/agenda/agenda.component';

const routes: Routes = [
  { path : '', redirectTo:'/Home', pathMatch : 'full'},
  { path : '*', redirectTo:'/login', pathMatch : 'full'},
  { path: 'login', component: LoginComponent },
  { path: 'Home', component: HomeComponent,canActivate:[AutenticacaoGuard] },
  { path: 'Cliente', component: ListarClienteComponent,canActivate:[AutenticacaoGuard] },
  { path: 'Home', component: HomeComponent,canActivate:[AutenticacaoGuard] },
  { path: 'Gerenciamento', component: GerenciamentoComponent,canActivate:[AutenticacaoGuard] },
  { path: 'Paciente', component: PacienteComponent,canActivate:[AutenticacaoGuard] },
  { path: 'Paciente/:id_paciente', component: PacienteComponent,canActivate:[AutenticacaoGuard] },
  { path: 'Reembolso/:id_reembolso', component: ProcedimentoComponent,canActivate:[AutenticacaoGuard] },
  { path: 'Reembolso', component: ProcedimentoComponent,canActivate:[AutenticacaoGuard] },
  { path: 'Usuario', component: UsuarioComponent,canActivate:[AutenticacaoGuard] },
  { path: 'NaoAutorizado', component: NaoAutorizadoComponent,canActivate:[AutenticacaoGuard] },
  { path: 'Notificacao', component: NotificacaoComponent,canActivate:[AutenticacaoGuard] },
  { path: 'Agenda', component: AgendaComponent ,canActivate:[AutenticacaoGuard] },

];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
