import { Injectable } from '@angular/core';
import { GlobalConstants } from '../../common/global-constants';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})

export class AutenticacaoToken {
  readonly minutesToken = GlobalConstants.minutesToken;
  constructor(private router: Router) { }

  Token() {
    const DataHoraAtual = new Date();
    if(localStorage.getItem('userTokenExpires') == null || localStorage.getItem('dataTokenInativo') == null){
      return true;
    }

    const dataTokenExpires = new Date(localStorage.getItem('userTokenExpires')!.toString());
    const dataTokenInativo = new Date(localStorage.getItem('dataTokenInativo')!.toString());

    if (localStorage.getItem('userToken') != null &&
      (DataHoraAtual < dataTokenExpires) &&
      (DataHoraAtual < dataTokenInativo)) {
      //RENOVA O TEMPO DE 15 MIN DENTRO DO TOKEN !
      DataHoraAtual.setMinutes(DataHoraAtual.getMinutes() + this.minutesToken);
      localStorage.setItem('dataTokenInativo', DataHoraAtual.toString());
      return true;
    }
    localStorage.removeItem('userToken');
    return false;
  }
}
