<div class="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
  <div class="kt-subheader  kt-grid__item" id="kt_subheader">
    <div class="kt-container  kt-container--fluid ">
      <div class="kt-subheader__main">
        <h3 class="kt-subheader__title">Cadastro de Cliente</h3>
        <span class="kt-subheader__separator kt-subheader__separator--v"></span>
        <span class="kt-subheader__desc"><small>Cliente >> Cadastro de Cliente</small></span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="kt-subheader   kt-grid__item" id="kt_subheader" [hidden]="!MostrarLista">
        <div class="row col-md-12">
          <div class="col-md-3">
            <div class="kt-subheader__main">
              <div class="kt-subheader__group" id="kt_subheader_search">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 kt-padding-0">
                    <form id="kt_subheader_search_form">
                      <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-0">
                        <input type="search" class="form-control" placeholder="Pesquisar" name="generalSearch" #Pesquisa ngModel (keypress)="p = 1">
                        <span class="kt-input-icon__icon kt-input-icon__icon--right">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path
                                  d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                  fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                <path
                                  d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                  fill="#000000" fill-rule="nonzero"></path>
                              </g>
                            </svg>
                          </span>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group has-feedback" style="float:left">
              <b class="totalRegistro"> Mostrar </b>
              <button type="button" class="btn btn-default dropdown-toggle countPage  " data-toggle="dropdown" aria-expanded="false">
                {{itemsPage}} <span class="caret"></span>
              </button>
              <ul class="dropdown-menu dropdown-menu-right dropCount kt-pointer" role="menu">
                <li>
                  <a (click)="itemsPage = 10">10</a>
                </li>
                <li>
                  <a (click)="itemsPage = 50">50</a>
                </li>
                <li>
                  <a (click)="itemsPage = 100">100</a>
                </li>
                <li>
                  <a (click)="itemsPage = 500">500</a>
                </li>
              </ul>
              <b class="totalRegistro"> Registros</b>
            </div>
          </div>
          <div class="col-md-5">
            <div class="kt-header__topbar" style="float:right">
              <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" (click)="GetListaCliente()">
                <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Carregar os Clientes">
                  <span class="kt-header__topbar-icon"><i class="flaticon-refresh"></i></span>
                </div>
              </div>
              <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" (click)="CarregarTelaCadastro()" *ngIf="idPerfil == '1'">
                <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Adicionar Novos Clientes">
                  <span class="kt-header__topbar-icon"><i class="flaticon-plus"></i></span>
                </div>
              </div>
              <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" (click)="ExportarCliente()" [hidden]="VisualizacaoCelular" *ngIf="idPerfil == '1'">
                <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Download dos Clientes">
                  <span class="kt-header__topbar-icon">
                    <i class="flaticon-download">
                    </i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid" *ngIf="MostrarLista">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body kt-portlet__body--fit">
          <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded" id="kt_apps_user_list_datatable">
            <table class="table table-striped- table-hover table-checkable dataTable no-footer dtr-inline" id="kt_table_1">
              <thead>
                <tr role="row">
                  <th class="sorting">Cliente</th>
                  <th class="sorting" [hidden]="VisualizacaoCelular">Cidade</th>
                  <th class="sorting" [hidden]="VisualizacaoCelular">UF</th>
                  <th class="sorting" [hidden]="VisualizacaoCelular">Telefone Fixo</th>
                  <th class="sorting" [hidden]="VisualizacaoCelular">Cadastro</th>
                  <th class="sorting">Status</th>
                  <th class="sorting">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr role="row" class="odd" *ngFor="let detalhe of ListaCliente | filter : Pesquisa.value | paginate: { itemsPerPage: itemsPage, currentPage: p }">
                  <td class="sorting_1">
                    <span style="width: 190px;">
                      <div class="kt-user-card-v2">
                        <div class="kt-user-card-v2__pic">
                          <img src="{{detalhe.no_logo}}" alt="photo">
                        </div>
                        <div class="kt-user-card-v2__details"> <a class="kt-user-card-v2__name">{{detalhe.no_fantasia}}</a>
                          <span class="kt-user-card-v2__desc"><small>{{detalhe.nu_cnpj.toString().length > 11 ? 'CNPJ' : 'CPF'}} : {{detalhe.nu_cnpj}}</small></span>
                        </div>
                      </div>
                    </span>
                  </td>
                  <td [hidden]="VisualizacaoCelular">{{detalhe.no_cidade}}</td>
                  <td [hidden]="VisualizacaoCelular">{{detalhe.no_uf}}</td>
                  <td [hidden]="VisualizacaoCelular">{{detalhe.no_telefone1}}</td>
                  <td [hidden]="VisualizacaoCelular">{{detalhe.dt_cadastro | date : 'dd/MM/yyyy'}}</td>
                  <td>
                    <span class="kt-font-bold {{detalhe.fg_aprovado == 1 ? 'kt-font-primary' : 'kt-font-danger'}}">{{detalhe.fg_aprovado == 1 ? 'Ativo' : 'Inativo'}}</span>
                  </td>
                  <td> <span class="dropdown kt-pointer">
                      <a class="btn btn-sm btn-clean btn-icon btn-icon-md" data-toggle="dropdown" aria-expanded="true">
                        <i class="la la-ellipsis-h"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" (click)="GetCliente(detalhe.id_cliente)">
                          <i class="la la-edit"></i>
                          {{idPerfil == '1' ? 'Editar Cadastro' : 'Visualizar Cadastro'}}</a>
                      </div>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="kt-datatable__pager kt-datatable--paging-loaded kt-padding-0">
              <div class="kt-datatable__pager-nav">
                <pagination-controls (pageChange)="p = $event" [maxSize]="VisualizacaoCelular ? 4 : 7" previousLabel="<" nextLabel=">"></pagination-controls>
              </div>
              <div class="kt-datatable__pager-info">
                <span class="kt-datatable__pager-detail qt-pagination">Mostrando {{ (ListaCliente | filter: Pesquisa.value)?.length }} de {{ListaCliente.length}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid" *ngIf="!MostrarLista">
      <div class="row">
        <div class="col-lg-12">

          <div class="kt-portlet">
            <form [formGroup]="formCadastroCliente">
              <div class="kt-portlet__body">
                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>Nome / Razão Social:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" [readonly]="idPerfil != '1'" name="no_razaosocial" formControlName="no_razaosocial" class="form-control" placeholder="Digite o Nome ou Razão Social">
                      <span [hidden]="!f.no_razaosocial.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_razaosocial.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>CPF / CNPJ:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <!-- mask="00.000.000/0000-00" -->
                      <input type="text"  [readonly]="idPerfil != '1'" formControlName="nu_cnpj" class="form-control" placeholder="Digite o CPF / CNPJ" (focusout)="GetCNPJ()">
                      <span [hidden]="!f.nu_cnpj.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.nu_cnpj.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>CCM:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text"  [readonly]="idPerfil != '1'" formControlName="nu_ccm" class="form-control" placeholder="Digite o CCM">
                      <span [hidden]="!f.nu_ccm.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.nu_ccm.errors }"></i></span></span>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>Nome Fantasia:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text"  [readonly]="idPerfil != '1'" formControlName="no_fantasia" class="form-control" placeholder="Digite o Nome Fantasia">
                      <span [hidden]="!f.no_fantasia.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_fantasia.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Código:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text"  [readonly]="idPerfil != '1'" formControlName="no_codigo" class="form-control" placeholder="Digite o Código" (focusout)="GetCodigo()">
                      <span [hidden]="!f.no_codigo.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_codigo.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>E-Mail:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text"  [readonly]="idPerfil != '1'" formControlName="no_email" class="form-control" placeholder="Digite o E-Mail">
                      <span [hidden]="!f.no_email.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_email.errors }"></i></span></span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>Endereço:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text"  [readonly]="idPerfil != '1'" formControlName="no_endereco" class="form-control" placeholder="Digite o Endereço">
                      <span [hidden]="!f.no_endereco.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_endereco.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Número:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text"  [readonly]="idPerfil != '1'" formControlName="no_numero" class="form-control" placeholder="Digite o Número">
                      <span [hidden]="!f.no_numero.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_numero.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Complemento:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text"  [readonly]="idPerfil != '1'" formControlName="no_complemento" class="form-control" placeholder="Digite o Complemento">
                      <span [hidden]="!f.no_complemento.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_complemento.errors }"></i></span></span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>CEP:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" [readonly]="idPerfil != '1'" formControlName="no_cep" mask="00000-000" class="form-control" placeholder="Digite o CEP">
                      <span [hidden]="!f.no_cep.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_cep.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Bairro:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text"  [readonly]="idPerfil != '1'" formControlName="no_bairro" class="form-control" placeholder="Digite o Bairro">
                      <span [hidden]="!f.no_bairro.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_bairro.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Cidade:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text"  [readonly]="idPerfil != '1'" formControlName="no_cidade" class="form-control" placeholder="Digite a Cidade">
                      <span [hidden]="!f.no_cidade.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_cidade.errors }"></i></span></span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>UF:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select  [readonly]="idPerfil != '1'" placeholder="SELECIONE" formControlName="no_uf" name="no_uf" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                        <ng-option *ngFor="let opt of ListaUF" [value]="opt.dsuf">
                          <font style="vertical-align: inherit;">{{opt.dsuf}}</font>
                        </ng-option>
                      </ng-select>
                      <span [hidden]="!f.no_uf.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_uf.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Telefone Fixo:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text"  [readonly]="idPerfil != '1'" formControlName="no_telefone1" class="form-control" mask="00 0000.0000" placeholder="Digite o Telefone Principal">
                      <span [hidden]="!f.no_telefone1.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_telefone1.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Telefone Celular:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text"  [readonly]="idPerfil != '1'" formControlName="no_telefone2" class="form-control" mask="00 00000.0000" placeholder="Digite o Telefone 2">
                      <span [hidden]="!f.no_telefone2.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_telefone2.errors }"></i></span></span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>Telefone Opcional:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text"  [readonly]="idPerfil != '1'" formControlName="no_celular" class="form-control" mask="00 00000.0000" placeholder="Digite o Celular">
                      <span [hidden]="!f.no_celular.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_celular.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Status:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select [readonly]="idPerfil != '1'"  placeholder="SELECIONE" formControlName="fg_aprovado" name="fg_aprovado" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                        <ng-option *ngFor="let opt of ListaStatus" [value]="opt.fg_aprovado">
                          <font style="vertical-align: inherit;">{{opt.dsstatus}}</font>
                        </ng-option>
                      </ng-select>
                      <span [hidden]="!f.fg_aprovado.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.fg_aprovado.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4" *ngIf="idPerfil == '1'">
                    <label>Logo do Cliente:</label>
                    <div class="kt-input-icon kt-input-icon--right arquivoAnexo">
                      <label class="kt-avatar__upload custom-dropzone kt-pointer kt-padding-t-10" data-toggle="kt-tooltip" style="width:100%">
                        <div  class="ng-placeholder kt-padding-l-20" style="color: #999;">SELECIONE O ARQUIVO
                          <!-- <span class="kt-input-icon__icon kt-input-icon__icon--right icon" *ngIf="ListaArquivos.length == 0">
                              <span><i class="flaticon-circle color-error"></i>
                              </span>
                            </span> -->
                        </div>
                        <span class="form-text text-muted kt-margin-t-10" *ngIf="ListaArquivos.length > 0">Arquivo carregado com sucesso!</span>
                        <input [readonly]="idPerfil != '1'" type="file" #file placeholder="Choose file" style="display:none;" (change)="uploadDocumento($event, '1')">
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="kt-subheader  kt-grid__item" id="kt_subheader">
              <div class="kt-container  kt-container--fluid kt-padding-l-10">
                <div class="kt-subheader__main">
                  <h4 class="kt-subheader__title">Cadastro de Contatos</h4>
                </div>
              </div>
            </div>
            <form [formGroup]="formCadastroContato">
              <div class="kt-portlet__body separador-borda">
                <div *ngIf="idPerfil == '1'">
                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>Tipo de Contato:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select placeholder="SELECIONE" name="id_tp_contato" formControlName="id_tp_contato" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                        <ng-option *ngFor="let opt of ListaTipoContato" [value]="opt.id_tp_contato">
                          <font style="vertical-align: inherit;">{{opt.no_contato}}</font>
                        </ng-option>
                      </ng-select>
                      <span [hidden]="!c.id_tp_contato.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': c.id_tp_contato.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Função:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select placeholder="SELECIONE" name="id_funcao" formControlName="id_funcao" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                        <ng-option *ngFor="let opt of ListaFuncao" [value]="opt.id_funcao">
                          <font style="vertical-align: inherit;">{{opt.no_funcao}}</font>
                        </ng-option>
                      </ng-select>
                      <span [hidden]="!c.id_funcao.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': c.id_funcao.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>E-Mail:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" class="form-control" formControlName="no_email" placeholder="Digite o E-Mail">
                      <span [hidden]="!c.no_email.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': c.no_email.errors }"></i></span></span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>Nome:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" class="form-control" formControlName="no_contato" placeholder="Digite o Nome">
                      <span [hidden]="!c.no_contato.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': c.no_contato.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Telefone</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" class="form-control" formControlName="nu_telefone" mask="00 00000.0000" placeholder="Digite o Telefone Principal">
                      <span [hidden]="!c.nu_telefone.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': c.nu_telefone.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <label>Envio de E-Mail:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select placeholder="SELECIONE" formControlName="id_envio_email" name="idenvioemail" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                        <ng-option *ngFor="let opt of ListaEnvioEmail" [value]="opt.idenvioemail">
                          <font style="vertical-align: inherit;">{{opt.nmenvioemail}}</font>
                        </ng-option>
                      </ng-select>
                      <span [hidden]="!c.id_envio_email.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': c.id_envio_email.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <button type="reset" class="btn btn-primary alinhamento-botao botaoadicionar" (click)="AdicionarContato()" [disabled]="formCadastroContato.invalid">Adicionar</button>
                  </div>
                </div>
                </div>
                <div class="form-group row kt-margin-b-10" *ngIf="ListaContato.length > 0" [hidden]="VisualizacaoCelular">
                  <div class="col-lg-2">
                    <label><b>Tipo de Contato</b></label>
                  </div>
                  <div class="col-lg-2">
                    <label><b>Função</b></label>
                  </div>
                  <div class="col-lg-2">
                    <label><b>Nome</b></label>
                  </div>
                  <div class="col-lg-3">
                    <label><b>E-Mail / Envio </b></label>
                  </div>
                  <div class="col-lg-2">
                    <label><b>Telefone</b></label>
                  </div>
                  <div class="col-lg-1" *ngIf="idPerfil == '1'">
                    <label><b>Excluir</b></label>
                  </div>
                </div>
                <div class="form-group row kt-margin-b-10" *ngFor="let detalhe of ListaContato; let i = index">
                  <div class="col-lg-2 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                    <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                      <label><b><small>Tipo de Contato</small></b></label>
                    </div>
                    <label>{{detalhe.tp_contato}}</label>
                  </div>
                  <div class="col-lg-2 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                    <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                      <label><b><small>Função</small></b></label>
                    </div>
                    <label>{{detalhe.no_funcao}}</label>
                  </div>
                  <div class="col-lg-2 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                    <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                      <label><b><small>Nome</small></b></label>
                    </div>
                    <label>{{detalhe.no_contato}}</label>
                  </div>
                  <div class="col-lg-3 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                    <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                      <label><b><small>E-Mail / Envio E-Mail</small></b></label>
                    </div>
                    <label>{{detalhe.no_email}} / {{detalhe.envio_email}}</label>
                  </div>
                  <div class="col-lg-2 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                    <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                      <label><b><small>Telefone</small></b></label>
                    </div>
                    <label>{{detalhe.nu_telefone.substring(0,2) + ' ' + detalhe.nu_telefone.substring(2,3) + detalhe.nu_telefone.substring(3,7) + '.' + detalhe.nu_telefone.substring(7,11)}}</label>
                  </div>
                  <div class="col-lg-1" *ngIf="idPerfil == '1'">
                    <button type="button" class="btn btn-outline-danger btn-icon botaomax" (click)="ExcluirContato(i)"><i class="flaticon2-cancel"></i></button>
                  </div>
                </div>
              </div>
            </form>
            <div class="kt-subheader  kt-grid__item" id="kt_subheader">
              <div class="kt-container  kt-container--fluid kt-padding-l-10">
                <div class="kt-subheader__main">
                  <h4 class="kt-subheader__title">Cadastro de Contas Bancarias</h4>
                </div>
              </div>
            </div>
            <form [formGroup]="formCadastroBanco">
              <div class="kt-portlet__body separador-borda">
                <div *ngIf="idPerfil == '1'">
                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>Nome do Banco:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" formControlName="no_banco" class="form-control" placeholder="Digite o Nome do Banco">
                      <span [hidden]="!b.no_banco.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': b.no_banco.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Número do Banco</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" formControlName="nu_banco" class="form-control" placeholder="Digite o Número do Banco">
                      <span [hidden]="!b.nu_banco.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': b.nu_banco.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <label>Agencia</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" formControlName="nu_agencia" class="form-control" placeholder="Digite a Agencia">
                      <span [hidden]="!b.nu_agencia.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': b.nu_agencia.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <label>Conta Corrente:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" formControlName="nu_conta" class="form-control" placeholder="Digite a Conta">
                      <span [hidden]="!b.nu_conta.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': b.nu_conta.errors }"></i></span></span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-2">
                    <label>Tipo PIX:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select placeholder="SELECIONE" formControlName="id_tp_pix" name="id_tp_pix" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                        <ng-option *ngFor="let opt of ListaPix" [value]="opt.id_tp_pix">
                          <font style="vertical-align: inherit;">{{opt.no_pix}}</font>
                        </ng-option>
                      </ng-select>
                      <span [hidden]="!b.id_tp_pix.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': b.id_tp_pix.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <label>Chave PIX</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" class="form-control" formControlName="no_pix" placeholder="Digite a Chave PIX">
                      <span [hidden]="!b.no_pix.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': b.no_pix.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Observação</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" class="form-control" formControlName="no_observacao" placeholder="Digite a Observação">
                      <span [hidden]="!b.no_observacao.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': b.no_observacao.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <button type="reset" class="btn btn-primary alinhamento-botao botaoadicionar" (click)="AdicionarDadosBancario()" [disabled]="formCadastroBanco.invalid">Adicionar</button>
                  </div>
                </div>
                </div>
                <div class="form-group row kt-margin-b-10" *ngIf="ListaDadosBancario.length > 0" [hidden]="VisualizacaoCelular">
                  <div class="col-lg-3">
                    <label><b>Nome do Banco / Nº Banco</b></label>
                  </div>
                  <div class="col-lg-2">
                    <label><b>Agencia / Conta</b></label>
                  </div>
                  <div class="col-lg-3">
                    <label><b>Tipo PIX / Chave Pix</b></label>
                  </div>
                  <div class="col-lg-3">
                    <label><b>Observação</b></label>
                  </div>
                  <div class="col-lg-1" *ngIf="idPerfil == '1'">
                    <label><b>Excluir</b></label>
                  </div>
                </div>
                <div class="form-group row kt-margin-b-10" *ngFor="let detalhe of ListaDadosBancario; let i = index">
                  <div class="col-lg-3 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                    <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                      <label><b><small>Nome do Banco / Nº Banco</small></b></label>
                    </div>
                    <label>{{detalhe.no_banco}}  {{detalhe.nu_banco == '' ? '-' : ' - '}} {{detalhe.nu_banco}}</label>
                  </div>
                  <div class="col-lg-2 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                    <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                      <label><b><small>Agencia</small></b></label>
                    </div>
                    <label>{{detalhe.nu_agencia}} {{detalhe.nu_agencia != '' && detalhe.nu_agencia != null ? '/' : ''}} {{detalhe.no_conta}}</label>
                  </div>
                  <div class="col-lg-3 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                    <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                      <label><b><small>Tipo PIX / Chave Pix</small></b></label>
                    </div>
                    <label>{{detalhe.tp_pix}} {{detalhe.tp_pix != '' && detalhe.tp_pix != null ? '/' : ''}} {{detalhe.no_pix}}</label>
                  </div>
                  <div class="col-lg-3 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                    <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                      <label><b><small>Observação</small></b></label>
                    </div>
                    <label>{{detalhe.no_observacao}}</label>
                  </div>
                  <div class="col-lg-1" *ngIf="idPerfil == '1'">
                    <button type="button" class="btn btn-outline-danger btn-icon botaomax" (click)="ExcluirDadosBancario(i)"><i class="flaticon2-cancel"></i></button>
                  </div>
                </div>
              </div>
            </form>
            <div *ngIf="idPerfil == '1'">
            <div class="kt-subheader  kt-grid__item" id="kt_subheader">
              <div class="kt-container  kt-container--fluid kt-padding-l-10">
                <div class="kt-subheader__main">
                  <h4 class="kt-subheader__title">Condições de Pagamento</h4>
                </div>
              </div>
            </div>
            <form [formGroup]="formCadastroReembolso">
              <div class="kt-portlet__body separador-borda">
                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>Tipo de Reembolso:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select placeholder="SELECIONE" name="id_reembolso" formControlName="id_reembolso" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                        <ng-option *ngFor="let opt of ListaReembolso" [value]="opt.id_reembolso">
                          <font style="vertical-align: inherit;">{{opt.no_reembolso}}</font>
                        </ng-option>
                      </ng-select>
                      <span [hidden]="!r.id_reembolso.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': r.id_reembolso.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Tipo de Processos:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select placeholder="SELECIONE" name="id_processos" formControlName="id_processos" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                        <ng-option *ngFor="let opt of ListaProcessos" [value]="opt.id_processo">
                          <font style="vertical-align: inherit;">{{opt.no_nome}}</font>
                        </ng-option>
                      </ng-select>
                      <span [hidden]="!r.id_processos.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': r.id_processos.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Valor Percentual</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" formControlName="vl_per" class="form-control" placeholder="Digite o Valor Percentual">
                      <span [hidden]="!r.vl_per.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': r.vl_per.errors }"></i></span></span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>Valor Fixo</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input class="form-control" currencyMask formControlName="vl_fix" placeholder="Digite o Valor Fixo" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',',align:'left' }" />
                      <span [hidden]="!r.vl_fix.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': r.vl_fix.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Valor Mínimo </label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input class="form-control" currencyMask formControlName="vl_min" placeholder="Digite o Valor Mínimo" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:'left' }" />
                      <span [hidden]="!r.vl_min.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': r.vl_min.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <button type="reset" class="btn btn-primary alinhamento-botao botaoadicionar" (click)="AdicionarReembolso()" [disabled]="formCadastroReembolso.invalid">Adicionar</button>
                  </div>
                </div>
                <div class="form-group row kt-margin-b-10" *ngIf="ListaReembolsos.length > 0" [hidden]="VisualizacaoCelular">
                  <div class="col-lg-2">
                    <label><b>Tipo de Reembolso</b></label>
                  </div>
                  <div class="col-lg-2">
                    <label><b>Tipo de Processos</b></label>
                  </div>
                  <div class="col-lg-2">
                    <label><b>Valor Percentual</b></label>
                  </div>
                  <div class="col-lg-2">
                    <label><b>Valor Fixo</b></label>
                  </div>
                  <div class="col-lg-2">
                    <label><b>Valor Mínimo</b></label>
                  </div>
                  <div class="col-lg-2">
                    <label><b>Excluir</b></label>
                  </div>
                </div>
                <div class="form-group row kt-margin-b-10" *ngFor="let detalhe of ListaReembolsos; let i = index">
                  <div class="col-lg-2 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                    <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                      <label><b><small>Tipo de Reembolso</small></b></label>
                    </div>
                    <label>{{detalhe.no_reembolso}}</label>
                  </div>
                  <div class="col-lg-2 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                    <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                      <label><b><small>Tipo de Processos</small></b></label>
                    </div>
                    <label>{{detalhe.no_nome}}</label>
                  </div>
                  <div class="col-lg-2 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                    <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                      <label><b><small>Valor Percentual</small></b></label>
                    </div>
                    <label>{{detalhe.vl_per}} %</label>
                  </div>
                  <div class="col-lg-2 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                    <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                      <label><b><small>Valor Fixo</small></b></label>
                    </div>
                    <label>R$ {{detalhe.vl_fix != null ? detalhe.vl_fix.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2  }) : '0,00'}}</label>
                  </div>
                  <div class="col-lg-2 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                    <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                      <label><b><small>Valor Mínimo</small></b></label>
                    </div>
                    <label>R$ {{detalhe.vl_min != null ? detalhe.vl_min.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2  }) : '0,00'}}</label>
                  </div>
                  <div class="col-lg-1">
                    <button type="button" class="btn btn-outline-danger btn-icon botaomax" (click)="ExcluirReembolso(i)"><i class="flaticon2-cancel"></i></button>
                  </div>
                </div>
              </div>
            </form>
            </div>

            <div *ngIf="idPerfil == '1'">
            <div class="kt-subheader  kt-grid__item" id="kt_subheader">
              <div class="kt-container  kt-container--fluid kt-padding-l-10">
                <div class="kt-subheader__main">
                  <h4 class="kt-subheader__title">Cadastro de Faturamento</h4>
                </div>
              </div>
            </div>
            <div class="kt-portlet__body separador-borda" >
              <div class="form-group row">
                <div class="col-lg-4">
                  <label>Faturamento:</label>
                  <div class="kt-input-icon kt-input-icon--right">
                    <ng-select [items]="ListaFaturamento" (change)="ModificarFaturamento($event)" name="no_pagamento" placeholder="Selecione o Faturamento" [multiple]="true" bindLabel="no_pagamento" groupBy="gender" [selectableGroup]="true" [selectableGroupAsModel]="false"
                      [closeOnSelect]="false" bindValue="id_pagamento" [(ngModel)]="Faturamento">
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" name="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.no_pagamento}}
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
            </div>

            <div *ngIf="idPerfil == '1'">
            <div class="kt-subheader  kt-grid__item" id="kt_subheader">
              <div class="kt-container  kt-container--fluid kt-padding-l-10">
                <div class="kt-subheader__main">
                  <h4 class="kt-subheader__title">Cadastro de Notas</h4>
                </div>
              </div>
            </div>
            <div class="kt-portlet__body separador-borda">
              <div class="form-group row">
                <div class="col-lg-4">
                  <label>Notas Aplicáveis:</label>
                  <div class="kt-input-icon kt-input-icon--right">
                    <input type="text" class="form-control" [(ngModel)]="nota" placeholder="Digite a nota">
                  </div>
                </div>
                <div class="col-lg-2">
                  <button type="reset" class="btn btn-primary alinhamento-botao botaoadicionar" (click)="AdicionarNota()" [disabled]="nota == ''">Adicionar</button>
                </div>
              </div>
              <div class="form-group row kt-margin-b-10" *ngIf="ListaNota.length > 0" [hidden]="VisualizacaoCelular">
                <div class="col-lg-2">
                  <label><b>Nota</b></label>
                </div>
              </div>
              <div class="row">
                <div class="form-group kt-margin-b-10 col-lg-4" *ngFor="let detalhe of ListaNota; let i = index">
                  <div class="row">
                    <div class="col-lg-8 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                      <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                        <label><b><small>Nota</small></b></label>
                      </div>
                      <label>{{detalhe.no_nota}}</label>
                    </div>
                    <div class="col-lg-4">
                      <button type="button" class="btn btn-outline-danger btn-icon botaomax" (click)="ExcluirNota(i)"><i class="flaticon2-cancel"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>

            <div class="kt-portlet__foot">
              <div class="kt-form__actions alinhamento-div-botao">
                <button type="reset" class="btn btn-primary alinhamento-botao" [disabled]="formCadastroCliente.invalid" *ngIf="idPerfil == '1'" (click)="CrudCliente()">{{formCadastroCliente.value.id_cliente == '' ? 'Cadastrar' : 'Atualizar'}}</button>
                <button type="reset" class="btn btn-secondary" (click)="MostrarLista = !MostrarLista">Cancelar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
