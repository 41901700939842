import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams, HttpEventType } from '@angular/common/http';
import { GlobalConstants } from '../../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class PacienteService {
  readonly apiURL = GlobalConstants.apiURL;
  readonly headersPost = GlobalConstants.headersPost;
  constructor(private http: HttpClient) { }

  GetListaPaciente(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Paciente/GetListaPaciente',formData)
  }

  GetPaciente(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Paciente/GetPaciente',formData)
  }

  AtualizarPaciente(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Paciente/AtualizarPaciente',formData)
  }

  AdicionarPaciente(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Paciente/AdicionarPaciente',formData)
  }

  GetOperadora(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Paciente/GetOperadora',formData)
  }

  GetListaOperadora(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Paciente/GetListaOperadora',formData)
  }

  CrudOperadora(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Paciente/CrudOperadora',formData)
  }

  GetCliente(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Paciente/GetCliente',formData)
  }

  GetCPF(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Paciente/GetCPF',formData)
  }

  GetCadastroSLA(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Paciente/GetCadastroSLA',formData)
  }

  EnvioContrato(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/EnvioContrato',formData)
  }
  GetFichaCadastral(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Paciente/GetFichaCadastral',formData)
  }
}
