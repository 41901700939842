import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroPagination'
})
export class FiltroPaginationPipe implements PipeTransform {

  transform(items: any[], campo: any[]): any {
    let retorno = items;

    if (Object.keys(campo[0]).length == 0) {
      return items;
    }

    for (var key in campo[0]) {
      if (campo[0].hasOwnProperty(key)) {
        var value = campo[0][key];
        if (value !== '' && value !== null && value !== undefined && value !== 'todos') {
          Filtro(retorno, key, value);
        }
      }
    }
    return retorno;

    function Filtro(array, obj, key) {
      if (items) {
        retorno = retorno.filter((item, index) => {
          if (item[obj] === null) {
            return false;
          }
          const find = item[obj].toString().toLowerCase().indexOf(key.toLowerCase());
          return find === -1 ? false : true;
        });
      }
    }
    return retorno;
  }
}
