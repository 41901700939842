import { Component, OnInit, HostListener } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ClienteService } from '../../service/cliente/cliente.service';
import { SweetAlertService } from '../../service/commom/sweet-alert/sweet-alert.service';
import { ExcelService } from '../../service/commom//excel.service';
declare var Swal: any;
declare function CarregarScript(): any;

@Component({
  selector: 'app-listar-cliente',
  templateUrl: './listar-cliente.component.html',
  styleUrls: ['./listar-cliente.component.css'],
  host: { 'class': 'col' }
})
export class ListarClienteComponent implements OnInit {
  formCadastroCliente: FormGroup;
  formCadastroContato: FormGroup;
  formCadastroBanco: FormGroup;
  formCadastroReembolso: FormGroup;
  formData: FormData;
  MostrarLista: boolean = true;
  VisualizacaoCelular: boolean = false;
  p: number = 1;
  ListaCliente: any = [];
  ListaUF: any = [];
  ListaTipoContato: any = [];
  ListaFuncao: any = [];
  ListaContato: any = [];
  ListaPix: any = [];
  ListaReembolso: any = [];
  ListaDadosBancario: any = [];
  ListaProcessos: any = [];
  ListaReembolsos: any = [];
  Faturamento: any = [];
  ListaFaturamento: any = [];
  ListaStatus: any = [{ fg_aprovado: '1', dsstatus: 'ATIVO' }, { fg_aprovado: '0', dsstatus: 'INATIVO' }];
  ListaEnvioEmail: any = [];
  idPerfil = localStorage.getItem('idPerfil');
  ListaArquivos: File[] = [];
  itemsPage: number = 10;
  CnpjCarregado = '';
  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private clienteService: ClienteService,
    private sweetAlertService: SweetAlertService,
    private excelService: ExcelService
  ) {
    const actualWidth = window.innerWidth;
    if (window.innerWidth < 1024) {
      this.VisualizacaoCelular = true;
    }
  }

  @HostListener('window:resize', [''])
  onResize() {
    this.VisualizacaoCelular = window.innerWidth < 1024 ? true : false;
  }
  ngOnInit(): void {
    setTimeout(() => {
      CarregarScript();
    }, 2000);
    this.GetEnvioEmail();
    this.CarregarUF();
    this.CarregarTipoContato();
    this.CarregarFuncao();
    this.CarregarPix();
    this.CarregarFormBuilder();
    this.GetListaCliente();
    this.Get_TipoReembolso();
    this.Get_Processos();
    this.GetFaturamento();
  }
  GetFaturamento() {
    this.spinner.show();
    this.formData = new FormData();
    this.clienteService.GetTipoPagamento(this.formData).subscribe((data: any) => {
      this.ListaFaturamento = data.retorno;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Faturamentos');
        this.spinner.hide();
      });
  }

  GetEnvioEmail() {
    this.spinner.show();
    this.formData = new FormData();
    this.clienteService.GetEnvioEmail(this.formData).subscribe((data: any) => {
      this.ListaEnvioEmail = data.retorno;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Tipo de Envio de E-Mail');
        this.spinner.hide();
      });
  }

  todosSelecionado: boolean = false;
  ModificarFaturamento(val) {
    if (val.length > 0) {
      for (let index = 0; index < val.length; index++) {
        if (this.todosSelecionado) {
          this.Faturamento = [];

          for (let i = 0; i < val.length; i++) {
            if (val[i].id_pagamento != 11) {
              this.Faturamento.push(val[i].id_pagamento);
            }
          }
          this.todosSelecionado = false;
          return;

        }
        else {
          if (val[index].id_pagamento == 11) {
            this.Faturamento = [11];
            this.todosSelecionado = true;
          }
        }
      }
    }
  }

  GetListaCliente() {
    this.spinner.show();
    this.formData = new FormData();
    this.clienteService.GetListaCliente(this.formData).subscribe((data: any) => {
      this.ListaCliente = data.retorno;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Clientes');
        this.spinner.hide();
      });
  }
  CarregarFormBuilder() {
    this.formCadastroCliente = this.formBuilder.group({
      id_cliente: [''],
      no_logo: [''],
      no_razaosocial: ['', [Validators.required, Validators.maxLength(300)]],
      no_fantasia: ['', [Validators.required,Validators.maxLength(300)]],
      nu_cnpj: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(14), this.isValidCNPJ()]],
      nu_ccm: ['', [Validators.maxLength(20)]],
      no_email: ['', [Validators.maxLength(120), Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      no_codigo: ['', [Validators.maxLength(5)]],
      no_endereco: ['', [Validators.maxLength(600)]],
      no_numero: ['', [Validators.maxLength(5)]],
      no_complemento: ['', [Validators.maxLength(50)]],
      no_cep: ['', [Validators.minLength(8), Validators.maxLength(8)]],
      no_uf: [null, [Validators.minLength(2), Validators.maxLength(2)]],
      no_cidade: ['', [Validators.maxLength(300)]],
      no_bairro: ['', [Validators.maxLength(300)]],
      no_telefone1: ['', [Validators.minLength(10), Validators.maxLength(11)]],
      no_telefone2: ['', [Validators.minLength(11), Validators.maxLength(11)]],
      no_celular: ['', [Validators.minLength(11), Validators.maxLength(11)]],
      fg_aprovado: [null, []],
      dt_cadastro: [null]
    });

    this.formCadastroContato = this.formBuilder.group({
      id_tp_contato: [null, [Validators.required]],
      id_funcao: [null, [Validators.required]],
      no_email: ['', [Validators.required, Validators.maxLength(300)]],
      no_contato: ['', [Validators.required, Validators.maxLength(300)]],
      nu_telefone: ['', [Validators.required, Validators.maxLength(11), Validators.minLength(11)]],
      id_envio_email: [null, [Validators.required]]
    });
    this.formCadastroBanco = this.formBuilder.group({
      no_banco: ['', [Validators.required]],
      nu_banco: [''],
      nu_agencia: ['', [Validators.maxLength(20)]],
      nu_conta: ['', [Validators.maxLength(20)]],
      id_tp_pix: [null],
      no_pix: ['', [Validators.maxLength(50)]],
      no_observacao: ['', [Validators.maxLength(300)]]
    });
    this.formCadastroReembolso = this.formBuilder.group({
      id_reembolso: [null, [Validators.required]],
      id_processos: [null, [Validators.required]],
      vl_per: [null, [Validators.required]],
      vl_fix: [null, []],
      vl_min: [null, []]
    });

  }

  get f() { return this.formCadastroCliente.controls; }
  get c() { return this.formCadastroContato.controls; }
  get b() { return this.formCadastroBanco.controls; }
  get r() { return this.formCadastroReembolso.controls; }

  CarregarTelaCadastro() {
    this.Faturamento = [];
    this.ListaArquivos = [];
    this.ListaContato = [];
    this.ListaDadosBancario = [];
    this.ListaReembolsos = [];
    this.ListaNota = [];
    this.CnpjCarregado = '';
    this.MostrarLista = !this.MostrarLista;
    setTimeout(() => {
      this.CarregarFormBuilder();
    }, 100);

  }
  CarregarUF() {
    this.ListaUF.push({ dsuf: 'RO' })
    this.ListaUF.push({ dsuf: 'AC' })
    this.ListaUF.push({ dsuf: 'AM' })
    this.ListaUF.push({ dsuf: 'RR' })
    this.ListaUF.push({ dsuf: 'PA' })
    this.ListaUF.push({ dsuf: 'AP' })
    this.ListaUF.push({ dsuf: 'TO' })
    this.ListaUF.push({ dsuf: 'MA' })
    this.ListaUF.push({ dsuf: 'PI' })
    this.ListaUF.push({ dsuf: 'CE' })
    this.ListaUF.push({ dsuf: 'RN' })
    this.ListaUF.push({ dsuf: 'PB' })
    this.ListaUF.push({ dsuf: 'PE' })
    this.ListaUF.push({ dsuf: 'AL' })
    this.ListaUF.push({ dsuf: 'SE' })
    this.ListaUF.push({ dsuf: 'BA' })
    this.ListaUF.push({ dsuf: 'MG' })
    this.ListaUF.push({ dsuf: 'ES' })
    this.ListaUF.push({ dsuf: 'RJ' })
    this.ListaUF.push({ dsuf: 'SP' })
    this.ListaUF.push({ dsuf: 'PR' })
    this.ListaUF.push({ dsuf: 'SC' })
    this.ListaUF.push({ dsuf: 'RS' })
    this.ListaUF.push({ dsuf: 'MS' })
    this.ListaUF.push({ dsuf: 'MT' })
    this.ListaUF.push({ dsuf: 'GO' })
    this.ListaUF.push({ dsuf: 'DF' })
  }
  CarregarTipoContato() {
    this.formData = new FormData();
    this.clienteService.Get_Tp_Contato(this.formData).subscribe((data: any) => {
      this.ListaTipoContato = data.retorno;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar Tipo de Contato');
        this.spinner.hide();
      });
  }
  CarregarFuncao() {
    this.formData = new FormData();
    this.clienteService.Get_Funcao(this.formData).subscribe((data: any) => {
      this.ListaFuncao = data.retorno;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar Função');
        this.spinner.hide();
      });
  }
  CarregarPix() {
    this.formData = new FormData();
    this.clienteService.Get_Tp_Pix(this.formData).subscribe((data: any) => {
      this.ListaPix = data.retorno;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar Tipo de Pix');
        this.spinner.hide();
      });
  }
  AdicionarContato() {
    let form = this.formCadastroContato.value;
    let nmfuncao = this.ListaFuncao.filter(a => a.id_funcao === form.id_funcao)[0].no_funcao;
    let tpcontato = this.ListaTipoContato.filter(a => a.id_tp_contato === form.id_tp_contato)[0].no_contato;
    let envioemail = this.ListaEnvioEmail.filter(a => a.idenvioemail === form.id_envio_email)[0].nmenvioemail;

    this.ListaContato.push({
      id_tp_contato: form.id_tp_contato,
      tp_contato: tpcontato,
      id_funcao: form.id_funcao,
      no_funcao: nmfuncao,
      no_contato: form.no_contato,
      no_email: form.no_email,
      nu_telefone: form.nu_telefone,
      id_envio_email: form.id_envio_email,
      envio_email: envioemail,
    });
  }
  ExcluirContato(index) {
    Swal.fire({
      title: 'Deseja Excluir ?',
      text: "Você não será capaz de reverter isso!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ListaContato.splice(index, 1);
      }
    });
  }
  AdicionarDadosBancario() {
    let form = this.formCadastroBanco.value;
    let tppix = form.id_tp_pix != null ? this.ListaPix.filter(a => a.id_tp_pix === form.id_tp_pix)[0].no_pix : '';

    this.ListaDadosBancario.push({
      no_banco: form.no_banco,
      nu_banco: form.nu_banco,
      nu_agencia: form.nu_agencia,
      no_conta: form.nu_conta,
      id_tp_pix: form.id_tp_pix,
      tp_pix: tppix,
      no_pix: form.no_pix,
      no_observacao: form.no_observacao,
    });
  }
  ExcluirDadosBancario(index) {
    Swal.fire({
      title: 'Deseja Excluir ?',
      text: "Você não será capaz de reverter isso!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ListaDadosBancario.splice(index, 1);
      }
    });
  }
  CrudCliente() {
    if (this.formCadastroCliente.value.id_cliente != '') {
      this.AtualizarCliente();
    }
    else {
      this.AdicionarCliente();
    }
  }
  AdicionarCliente() {
    this.spinner.show();
    this.formCadastroCliente.value.fg_aprovado = this.formCadastroCliente.value.fg_aprovado == "1" ? true : false;
    this.formData = new FormData();
    const novaListaFaturamento = this.Faturamento.map(item => ({
      id_pagamento: item,
    }));

    this.formData.append('GetCliente', JSON.stringify(this.formCadastroCliente.value));
    this.formData.append('GetContas', JSON.stringify(this.ListaDadosBancario));
    this.formData.append('GetContato', JSON.stringify(this.ListaContato));
    this.formData.append('GetReembolso', JSON.stringify(this.ListaReembolsos));
    this.formData.append('GetFaturamento', JSON.stringify(novaListaFaturamento));
    this.formData.append('GetNota', JSON.stringify(this.ListaNota));
    this.formData.append('arquivo', this.ListaArquivos[0]);

    this.clienteService.AdicionarCliente(this.formData).subscribe((data: any) => {
      this.sweetAlertService.SweetAlert('ok', 'Cliente cadastrado com sucesso');
      this.CarregarTelaCadastro();
      this.GetListaCliente();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao cadastrar o Cliente');
        this.spinner.hide();
      });
  }
  AtualizarCliente() {
    this.spinner.show();
    this.formCadastroCliente.value.fg_aprovado = this.formCadastroCliente.value.fg_aprovado == "1" ? true : false;
    this.formData = new FormData();
    const novaListaFaturamento = this.Faturamento.map(item => ({
      id_pagamento: item,
    }));

    this.formData.append('GetCliente', JSON.stringify(this.formCadastroCliente.value));
    this.formData.append('GetContas', JSON.stringify(this.ListaDadosBancario));
    this.formData.append('GetContato', JSON.stringify(this.ListaContato));
    this.formData.append('GetReembolso', JSON.stringify(this.ListaReembolsos));
    this.formData.append('GetFaturamento', JSON.stringify(novaListaFaturamento));
    this.formData.append('GetNota', JSON.stringify(this.ListaNota));
    this.formData.append('arquivo', this.ListaArquivos[0]);

    this.clienteService.AtualizarCliente(this.formData).subscribe((data: any) => {
      this.sweetAlertService.SweetAlert('ok', 'Cliente atualizado com sucesso');
      this.CarregarTelaCadastro();
      this.GetListaCliente();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao cadastrar o Cliente');
        this.spinner.hide();
      });
  }
  GetCliente(id_cliente) {
    this.CarregarTelaCadastro();
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_cliente', id_cliente);
    this.clienteService.GetCliente(this.formData).subscribe((data: any) => {
      setTimeout(() => {
        data.listaCliente[0].fg_aprovado = data.listaCliente[0].fg_aprovado == true ? '1' : '0';
        this.formCadastroCliente.setValue(data.listaCliente[0]);
        this.ListaDadosBancario = data.listaContas;
        this.ListaContato = data.listaContatos;
        this.ListaReembolsos = data.listaReembolso;
        this.ListaNota = data.listaNota;
        this.Faturamento = [];
        this.CnpjCarregado = data.listaCliente[0].nu_cnpj;
        for (let index = 0; index < data.listaFaturamento.length; index++) {
          this.Faturamento.push(data.listaFaturamento[index].id_pagamento);
        }
        this.spinner.hide();
      }, 500);

    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar o Cliente');
        this.spinner.hide();
      });
  }
  ExportarCliente(): void {
    const newArray = this.ListaCliente.map(item => ({
      razao_social: item.no_razaosocial,
      nome_fantasia: item.no_fantasia,
      cnpj: item.nu_cnpj,
      ccm: item.nu_ccm,
      email: item.no_email,
      codigo: item.no_codigo,
      endereco: item.no_endereco,
      numero: item.no_numero,
      complemento: item.no_complemento,
      cep: item.no_cep,
      uf: item.no_uf,
      cidade: item.no_cidade,
      bairro: item.no_bairro,
      telefone_1: item.no_telefone1,
      telefone_2: item.no_telefone2,
      celular: item.no_celular,
      status: item.fg_aprovado ? "Ativo" : "Inativo"
    }));

    this.excelService.exportAsExcelFile(newArray, 'Cliente');
  }

  Get_TipoReembolso() {
    this.formData = new FormData();
    this.clienteService.Get_TipoReembolso(this.formData).subscribe((data: any) => {
      this.ListaReembolso = data.retorno;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar Tipo de Reembolso');
        this.spinner.hide();
      });
  }
  Get_Processos() {
    this.formData = new FormData();
    this.clienteService.Get_Processos(this.formData).subscribe((data: any) => {
      this.ListaProcessos = data.retorno;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar Tipo de Processos');
        this.spinner.hide();
      });
  }

  AdicionarReembolso() {
    let form = this.formCadastroReembolso.value;
    let tp_reembolso = this.ListaReembolso.filter(a => a.id_reembolso === form.id_reembolso)[0].no_reembolso;
    let tp_processo = this.ListaProcessos.filter(a => a.id_processo === form.id_processos)[0].no_nome;

    this.ListaReembolsos.push({
      id_cliente_reembolso: '',
      no_reembolso: tp_reembolso,
      no_nome: tp_processo,
      vl_per: form.vl_per,
      vl_fix: form.vl_fix,
      vl_min: form.vl_min,
      id_reembolso: form.id_reembolso,
      id_processos: form.id_processos,
    });
  }
  ExcluirReembolso(index) {
    Swal.fire({
      title: 'Deseja Excluir ?',
      text: "Você não será capaz de reverter isso!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ListaReembolsos.splice(index, 1);
      }
    });
  }

  ListaNota: any = [];
  nota = '';
  AdicionarNota() {
    this.ListaNota.push({ no_nota: this.nota });
    this.nota = '';
  }
  ExcluirNota(index) {
    Swal.fire({
      title: 'Deseja Excluir?',
      text: "Você não será capaz de reverter isso!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ListaNota.splice(index, 1);
      }
    });
  }



  uploadDocumento(fileInput: any, tpArquivo) {
    this.ListaArquivos.push(fileInput.target.files[0]);
  }

  isValidCNPJ() {
    return (control: AbstractControl): Validators => {
      const cnpj = control.value.toString();
      if (cnpj) {
        if (cnpj.length < 14) {
          return this.isValidCpf(cnpj)
        }

        if (!cnpj || cnpj.length != 14
          || cnpj == "00000000000000"
          || cnpj == "11111111111111"
          || cnpj == "22222222222222"
          || cnpj == "33333333333333"
          || cnpj == "44444444444444"
          || cnpj == "55555555555555"
          || cnpj == "66666666666666"
          || cnpj == "77777777777777"
          || cnpj == "88888888888888"
          || cnpj == "99999999999999") {
          return { cnpjNotValid: true };
        }

        var tamanho = cnpj.length - 2
        var numeros = cnpj.substring(0, tamanho)
        var digitos = cnpj.substring(tamanho)
        var soma = 0
        var pos = tamanho - 7
        for (var i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--
          if (pos < 2) pos = 9
        }
        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11

        if (resultado != digitos.charAt(0)) {
          return { cnpjNotValid: true };
        }
        tamanho = tamanho + 1
        numeros = cnpj.substring(0, tamanho)
        soma = 0
        pos = tamanho - 7
        for (var i = tamanho; i >= 1; i--) {
          soma += numeros.charAt(tamanho - i) * pos--
          if (pos < 2) pos = 9
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11

        if (resultado != digitos.charAt(1)) {
          return { cnpjNotValid: true };
        }
      }

      return false;

    };
  }
  isValidCpf(cnpj) {
      const cpf = cnpj;
      if (cpf) {
        let numbers, digits, sum, i, result, equalDigits;
        equalDigits = 1;
        if (cpf.length < 11) {
          return false;
        }

        if (!cpf || cpf.length != 11
          || cpf == "00000000000"
          || cpf == "11111111111"
          || cpf == "22222222222"
          || cpf == "33333333333"
          || cpf == "44444444444"
          || cpf == "55555555555"
          || cpf == "66666666666"
          || cpf == "77777777777"
          || cpf == "88888888888"
          || cpf == "99999999999") {
          return { cnpjNotValid: true };
        }

        for (i = 0; i < cpf.length - 1; i++) {
          if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if (!equalDigits) {
          numbers = cpf.substring(0, 9);
          digits = cpf.substring(9);
          sum = 0;
          for (i = 10; i > 1; i--) {
            sum += numbers.charAt(10 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(0))) {
            return { cpfNotValid: true };
          }
          numbers = cpf.substring(0, 10);
          sum = 0;

          for (i = 11; i > 1; i--) {
            sum += numbers.charAt(11 - i) * i;
          }
          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(1))) {
            return { cpfNotValid: true };
          }
          return false;
        } else {
          return { cpfNotValid: true };
        }
      }
      return false;
  }

  GetCodigo() {
    if (this.formCadastroCliente.value.no_codigo == '') {
      return;
    }
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('no_codigo', this.formCadastroCliente.value.no_codigo);
    this.clienteService.GetCodigo(this.formData).subscribe((data: any) => {
      if (data.retorno != null) {
        this.sweetAlertService.SweetAlert('error', 'Código já cadastrado!');
        this.formCadastroCliente.value.no_codigo = ''

        this.formCadastroCliente.patchValue({
          no_codigo: ''
        });
      }
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao validar o Código');
        this.spinner.hide();
      });
  }
  GetCNPJ() {
    if (this.formCadastroCliente.value.nu_cnpj == '') {
      return;
    }
    if (this.CnpjCarregado == this.formCadastroCliente.value.nu_cnpj) {
      return;
    }
    
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('nu_cnpj', this.formCadastroCliente.value.nu_cnpj);
    this.clienteService.GetCNPJ(this.formData).subscribe((data: any) => {
      if (data.retorno != null) {
        this.sweetAlertService.SweetAlert('error', 'CPF / CNPJ já cadastrado!');
        this.formCadastroCliente.value.nu_cnpj = ''

        this.formCadastroCliente.patchValue({
          nu_cnpj: ''
        });
      }
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao validar o CPF / CNPJ');
        this.spinner.hide();
      });
  }
}
