import { HttpHeaders } from '@angular/common/http';

export class GlobalConstants {
  //public static apiURL: string = "https://apihmg.portallkrepay.com.br/";
  public static apiURL: string = "https://api.portallkrepay.com.br/";
  //public static apiURL: string = "http://localhost:62093/";
  //--configuration=production
  //ng build --output-hashing=all
  public static minutesToken: number = 60;
  public static headersGet = new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "application/json"
  });

  public static headersPostNoAuth = new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "application/json",
    'No-Auth': 'True'
  });

  public static headersPost = new HttpHeaders({
    "Content-Type": "application/x-www-form-urlencoded"
  });

}
//insert lkrepay.tb_status_pagamento values ('CANCELADO COM CRÉDITO', 1)
//sp_get_lista_agendamento
//sp_crud_agendamento
//sp_get_detalhe_agendamento_2

//sp_get_grafico_faturamento_mensal_quantidade
//sp_get_grafico_faturamento_quantidade
//sp_get_gestao_paciente
//sp_get_gestao_paciente_sub
//sp_get_gestao_paciente_conclusao
//tb_agendamento_cancelado --create
//sp_get_gestao_paciente_cancelado
//sp_get_gestao_cliente
