<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#d58f18" type="timer" [fullScreen]="true">
  <p style="color: white"> Aguarde, carregando ... </p>
</ngx-spinner>
<div class="kt-grid kt-grid--hor kt-grid--root" *ngIf="MostrarHeaderFooter">
  <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page" style="backgr">
    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper kt-padding-0"  id="kt_wrapper">
      <app-header></app-header>
      <div class="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor posicao" id="kt_content" style="height:100%">
        <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid kt-padding-0" style="background-image: linear-gradient(#537473, #dae7f4);">
          <div class="row kt-margin-20 tamanhoMin posicao_menu">
            <router-outlet class="col"></router-outlet>
          </div>
        </div>
      </div>
      <!--  <app-footer *ngIf="MostrarHeaderFooter"></app-footer>-->
    </div>
  </div>
</div>

<app-login *ngIf="MostrarHeaderLogin"></app-login>
