import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams, HttpEventType } from '@angular/common/http';
import { GlobalConstants } from '../../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  readonly apiURL = GlobalConstants.apiURL;
  readonly headersPost = GlobalConstants.headersPost;
  constructor(private http: HttpClient) { }

  AutenticacaoUsuario(formData: FormData) {
    var headers = new HttpHeaders({
      'No-Auth': 'True'
    });

    return this.http.post(
      this.apiURL + 'api/Seguranca/Login', formData,
      { headers: headers });
  }

  GetToken(formData: FormData) {
    var headers = new HttpHeaders({
      'No-Auth': 'True'
    });
    return this.http.post(
      this.apiURL + 'api/Seguranca/GetToken', formData,
      { headers: headers });
  }
  AlterarSenha(formData: FormData) {
    var headers = new HttpHeaders({
      'No-Auth': 'True'
    });
    return this.http.post(
      this.apiURL + 'api/Seguranca/AlterarSenha', formData,
      { headers: headers });
  }
  AlterarSenhaLogado(formData: FormData) {
    var headers = new HttpHeaders({
      'No-Auth': 'True'
    });
    return this.http.post(
      this.apiURL + 'api/Seguranca/AlterarSenhaLogado', formData,
      { headers: headers });
  }
  ReseteSenha(formData: FormData) {
    var headers = new HttpHeaders({
      'No-Auth': 'True'
    });
    return this.http.post(
      this.apiURL + 'api/Seguranca/ReseteSenha', formData,
      { headers: headers });
  }

  GetUsuarioLista(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Usuario/GetUsuarioLista',formData)
  }
  GetPerfil(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Usuario/GetPerfil',formData)
  }
  GetUsuarioDetalhes(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Usuario/GetUsuarioDetalhes',formData)
  }
  AdicionarUsuario(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Usuario/AdicionarUsuario',formData)
  }
  GetClienteUsuario(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Usuario/GetClienteUsuario',formData)
  }
}
