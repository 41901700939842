import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams, HttpEventType } from '@angular/common/http';
import { GlobalConstants } from '../../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class AgendamentoService {
  readonly apiURL = GlobalConstants.apiURL;
  readonly headersPost = GlobalConstants.headersPost;
  constructor(private http: HttpClient) { }

  GetAgendamento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/GetAgendamento',formData)
  }
  InsertAgendamento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/InsertAgendamento',formData)
  }
  GetPacienteAgendamento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/GetPacienteAgendamento',formData)
  }
  GetAgendamentoDetalhes(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/GetAgendamentoDetalhes',formData)
  }
  InativarAgendamento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/InativarAgendamento',formData)
  }
  GetInformacaoPaciente(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Paciente/GetPaciente',formData)
  }
  InativarRecorenciaAgendamento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/InativarRecorenciaAgendamento',formData)
  }
}
