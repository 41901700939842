import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SweetAlertService } from '../../service/commom/sweet-alert/sweet-alert.service';
import { ExcelService } from '../../service/commom//excel.service';
import { AgendamentoService } from '../../service/agendamento/agendamento.service';
declare var Swal: any;

declare function CarregarScriptData(): any;

declare var $: any;
declare var FullCalendar: any;
declare var $: any;
declare var moment: any;

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css'],
  host: { 'class': 'col' }
})
export class AgendaComponent implements OnInit {
  MostrarLista: boolean = true;
  VisualizacaoCelular: boolean = false;
  MostrarRecorencia: boolean = false;
  formCadastroAgenda: FormGroup;
  formData: FormData;
  ListaAgendmento: any = [];
  ListaPaciente: any = [];
  ListaTempo: any = [{id:7, descricao:'Semana(s)'},{id:15, descricao:'Quinzena(s)'}, {id:30, descricao:'Mês(s)'}];
  ListaStatus: any = [{id:1, descricao:'ATENDIDO'},{id:2, descricao:'CANCELADA COM COBRANÇA'}, {id:3, descricao:'CANCELADA SEM COBRANÇA'}];
  ListaTipoPagamento: any = [{ id_tipo_pagamento: 1, ds_pagamento: 'NOTA FISCAL' }, { id_tipo_pagamento: 2, ds_pagamento: 'RECIBO' }, { id_tipo_pagamento: 3, ds_pagamento: 'OUTROS' }];

  dt_inicio: any ='';
  dt_fim: any ='';
  bloqueio: boolean = false;
  bloqueioGeral: boolean = false;
  bloqueioBotaoExcluir: boolean = false;
  usoCreditoAgendamento: boolean = false;
  dt_credito: any ='';
  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private sweetAlertService: SweetAlertService,
    private excelService: ExcelService,
    private agendamentoService: AgendamentoService,
    private cdref: ChangeDetectorRef
  ) {
    const actualWidth = window.innerWidth;
    if (window.innerWidth < 1024) {
      this.VisualizacaoCelular = true;
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.GetPacienteAgendamento();
    setTimeout(() => {
      this.GetAgendamento();
      this.CarregarFormBuilder();
    }, 500);
  }
  CarregarTelaCadastro() {
    this.bloqueio = false;
    this.bloqueioGeral = false;
    this.bloqueioBotaoExcluir = false;
    this.usoCreditoAgendamento = false;
    this.MostrarRecorencia = false;
    this.MostrarLista = !this.MostrarLista;
    setTimeout(() => {
      this.CarregarFormBuilder();
      CarregarScriptData();
    }, 300);
  }
  CarregarFormBuilder() {
    $("#kt_datetimepicker_2").val('');
    $("#kt_datetimepicker_3").val('');
    this.formCadastroAgenda = this.formBuilder.group({
      no_titulo: [null],
      no_descricao: [''],
      dt_inicio: [null],
      dt_fim: [null],
      dt_inicio_recorencia: [null],
      id_agendamento: [null],
      no_color: [''],
      qtd: 1,
      segunda:[false],
      terca:[false],
      quarta:[false],
      quinta:[false],
      sexta:[false],
      sabado:[false],
      domingo:[false],
      tempo:null,
      fg_consulta:null,
      id_paciente: null,
      vl_consulta: '',
      id_tipo_pagamento: [null]
    });
  }
  get f() { return this.formCadastroAgenda.controls; }

  ngAfterContentChecked() {
    let id_agendamento = $("#id_agendamento").val()
    if (id_agendamento != '') {
      this.GetAgendamentoDetalhes(id_agendamento);
      $("#id_agendamento").val("");
    }

    var dtInicio = $("#kt_datetimepicker_2").val();
    var dtFim = $("#kt_datetimepicker_3").val();

    var dt_inicio:any = '';
    var dt_fim:any = '';

    if (dtInicio != '' && dtInicio != undefined) {
        dt_inicio = dtInicio.substring(0, 2) + dtInicio.substring(3, 5) + dtInicio.substring(6, 10);
    }

    if(dtFim != '' && dtFim != undefined){
      dt_fim = dtFim.substring(0, 2) + dtFim.substring(3, 5) + dtFim.substring(6, 10);
    }

    if (dt_inicio != '' && dt_fim != '') {
      if (dt_inicio != dt_fim) {
        this.sweetAlertService.SweetAlert('error', 'O início e término da sessão devem ser no mesmo dia !');

        $('#kt_datetimepicker_2').val("");
        $('#kt_datetimepicker_3').val("");

      }
    }


    // $('#kt_datetimepicker_2').val(this.dt_inicio);
    // $('#kt_datetimepicker_3').val(this.dt_fim);

    this.cdref.detectChanges();
 }


  AbrirRecorencia() {
    this.spinner.show();
    setTimeout(() => {
      CarregarScriptData();
      this.MostrarRecorencia = !this.MostrarRecorencia
      this.spinner.hide();
    }, 200);

  }

  GetData_1() {
    console.log($("#kt_datetimepicker_2").val())
  }
  GetData_2() {
    let data = $("#kt_datetimepicker_3").data('getDate');
    if (data != null) {
      var date = new Date(data);
      this.formCadastroAgenda.patchValue({
        dt_fim: data
      });
    }
  }
  GetData_3() {
    let data = $("#kt_datepicker_1_1").datepicker('getDate');
    if (data != null) {
      var date = new Date(data);
      this.formCadastroAgenda.patchValue({
        dt_inicio_recorencia: data
      });
    }
  }

  GetAgendamento() {
    this.ListaAgendmento = [];
    this.formData = new FormData();
    this.agendamentoService.GetAgendamento(this.formData).subscribe((data: any) => {
      for (let index = 0; index < data.retorno.length; index++) {
        this.ListaAgendmento.push({
          id: data.retorno[index].id_agendamento,
          title: data.retorno[index].no_titulo,
          start: data.retorno[index].dt_inicio,
          end: data.retorno[index].dt_fim,
          description: data.retorno[index].no_descricao,
          className: data.retorno[index].no_color
        });
      }
      setTimeout(() => {
        this.CarregarCalendario();
      }, 1000);
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Agendamentos');
        this.spinner.hide();
      });
  }

  GetPacienteAgendamento() {
    this.spinner.show();
    this.formData = new FormData();
    this.agendamentoService.GetPacienteAgendamento(this.formData).subscribe((data: any) => {
      this.ListaPaciente = data.retorno;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Pacientes');
        this.spinner.hide();
      });
  }

  GetAgendamentoDetalhes(id_agendamento) {
    this.usoCreditoAgendamento = false;
    this.bloqueio = false;
    this.CarregarTelaCadastro();
    let elementos: any = document.getElementsByClassName('popover');
    if (elementos.length > 0) {
        elementos[0].className  = "hide";
    }


    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_agendamento', id_agendamento);
    this.agendamentoService.GetAgendamentoDetalhes(this.formData).subscribe((data: any) => {

      setTimeout(() => {
        this.formCadastroAgenda.patchValue({
          id_agendamento: data.retorno[0].id_agendamento,
          no_titulo: data.retorno[0].no_titulo,
          no_descricao: data.retorno[0].no_descricao,
          fg_consulta: data.retorno[0].fg_consulta,
          id_paciente: data.retorno[0].id_paciente,
          id_tipo_pagamento: data.retorno[0].id_tipo_pagamento,
          vl_consulta: data.retorno[0].vl_consulta,
        });

        this.dt_inicio = data.retorno[0].dt_inicio;
        this.dt_fim = data.retorno[0].dt_fim;

        $('#kt_datetimepicker_2').val(this.dt_inicio);
        $('#kt_datetimepicker_3').val(this.dt_fim);

        this.spinner.hide();

        if (data.retorno[0].id_agendamento_pagamento != null ) {
          this.usoCreditoAgendamento = true;
          this.dt_credito = data.retorno[0].dt_agendamento
        }

        if (data.retorno[0].dt_conclusao != null) {
          this.bloqueioGeral = true;

          if(data.retorno[0].no_condicao == 'PRÉ-PAGO' && data.retorno[0].fg_consulta == null){
            if(data.retorno[0].dt_conclusao_lkrepay != null ){
                this.ListaStatus = [{id:1, descricao:'ATENDIDO'},{id:2, descricao:'CANCELADA COM COBRANÇA'},  {id:4, descricao:'CANCELADO COM CRÉDITO'}];
                this.bloqueioBotaoExcluir = true;
            }
            else{
              this.bloqueio = false;
              this.bloqueioBotaoExcluir = false;
              this.ListaStatus = [{id:1, descricao:'ATENDIDO'},{id:2, descricao:'CANCELADA COM COBRANÇA'}, {id:3, descricao:'CANCELADA SEM COBRANÇA'}];
            }
          }
          else{
            this.ListaStatus = [{id:1, descricao:'ATENDIDO'},{id:2, descricao:'CANCELADA COM COBRANÇA'}, {id:3, descricao:'CANCELADA SEM COBRANÇA'}, {id:4, descricao:'CANCELADO COM CRÉDITO'}];
            this.bloqueio = true;
          }
        }

        //this.GetInformacaoPacientePrePago();

      }, 500);


    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Pacientes');
        this.spinner.hide();
      });
  }

  CarregarCalendario() {
    var initPopover = function (el) {
        var skin = el.data('skin') ? 'popover-' + el.data('skin') : '';
        var triggerValue = el.data('trigger') ? el.data('trigger') : 'hover';

        el.popover({
            trigger: triggerValue,
            template: '\
            <div class="popover ' + skin + '" role="tooltip">\
                <div class="arrow"></div>\
                <h3 class="popover-header"></h3>\
                <div class="popover-body"></div>\
            </div>'
        });
    }



    var todayDate = moment().startOf('day');
    var YM = todayDate.format('YYYY-MM');
    var YESTERDAY = todayDate.clone().subtract(1, 'day').format('YYYY-MM-DD');
    var TODAY = todayDate.format('YYYY-MM-DD');
    var TOMORROW = todayDate.clone().add(1, 'day').format('YYYY-MM-DD');

    var calendarEl = document.getElementById('kt_calendar');
    var calendar = new FullCalendar.Calendar(calendarEl, {
      plugins: ['interaction', 'dayGrid', 'timeGrid', 'list'],

      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },

      height: 800,
      contentHeight: 780,
      aspectRatio: 3,

      nowIndicator: true,
      now: TODAY + 'T09:25:00', // just for demo

      views: {
        dayGridMonth: { buttonText: 'Mês' },
        timeGridWeek: { buttonText: 'Semana' },
        timeGridDay: { buttonText: 'Dia' }
      },

      defaultView: 'dayGridMonth',
      defaultDate: TODAY,

      editable: false,
      eventLimit: true, // allow "more" link when too many events
      navLinks: true,
      events: this.ListaAgendmento,
      eventClick: function(info) {
        $('#id_agendamento').val(info.event.id);
      },
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      },
      eventRender: function(info) {
          var element = $(info.el);

          if (info.event.extendedProps && info.event.extendedProps.description) {
              if (element.hasClass('fc-day-grid-event')) {
                  element.data('content', info.event.extendedProps.description);
                  element.data('placement', 'top');
                  initPopover(element);
              } else if (element.hasClass('fc-time-grid-event')) {
                  element.find('.fc-title').append('<div class="fc-description">' + info.event.extendedProps.description + '</div>');
              } else if (element.find('.fc-list-item-title').lenght !== 0) {
                  element.find('.fc-list-item-title').append('<div class="fc-description">' + info.event.extendedProps.description + '</div>');
              }
          }
      }

    });

    calendar.render();
    this.spinner.hide();
  }
  Voltar(){
    this.spinner.show();
    this.MostrarLista = !this.MostrarLista

    setTimeout(() => {
      this.CarregarCalendario();
      this.spinner.hide();
    }, 1000);

  }
  FormatarData(data) {
    return data.substring(3, 5) + '-' + data.substring(0, 2) + '-' + data.substring(6, 16);
  }

  AdicionarEvento() {

    var dtInicio = $("#kt_datetimepicker_2").val();
    var dtFim = $("#kt_datetimepicker_3").val();
    var dtInicioRecorencia = $("#kt_datepicker_1_1").val();


    if (dtInicio == '' || dtFim == '') {
      this.sweetAlertService.SweetAlert('error', 'Os campos de datas são obrigatórios');
      this.spinner.hide();
      return
    }

    var dt_inicio = new Date(this.FormatarData(dtInicio));
    var dt_fim = new Date(this.FormatarData(dtFim));

    if (dt_inicio >= dt_fim ) {
      this.sweetAlertService.SweetAlert('error', 'Data Inicial não pode ser maior que a Data Fim');
      this.spinner.hide();
      return
    }

    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_agendamento', this.formCadastroAgenda.value.id_agendamento);
    this.formData.append('no_descricao', this.formCadastroAgenda.value.no_descricao);
    this.formData.append('no_color', this.formCadastroAgenda.value.no_color);
    this.formData.append('dt_inicio', dtInicio.toString());
    this.formData.append('dt_fim', dtFim.toString());
    this.formData.append('no_titulo', this.formCadastroAgenda.value.no_titulo);
    this.formData.append('qtd', this.formCadastroAgenda.value.qtd);
    this.formData.append('segunda', this.formCadastroAgenda.value.segunda);
    this.formData.append('terca', this.formCadastroAgenda.value.terca);
    this.formData.append('quarta', this.formCadastroAgenda.value.quarta);
    this.formData.append('quinta', this.formCadastroAgenda.value.quinta);
    this.formData.append('sexta', this.formCadastroAgenda.value.sexta);
    this.formData.append('sabado', this.formCadastroAgenda.value.sabado);
    this.formData.append('domingo', this.formCadastroAgenda.value.domingo);
    this.formData.append('tempo', this.formCadastroAgenda.value.tempo == null ? '1' : this.formCadastroAgenda.value.tempo);
    this.formData.append('fg_consulta', this.formCadastroAgenda.value.fg_consulta);
    this.formData.append('id_paciente', this.formCadastroAgenda.value.id_paciente);
    this.formData.append('id_tipo_pagamento', this.formCadastroAgenda.value.id_tipo_pagamento);
    this.formData.append('vl_consulta', this.formCadastroAgenda.value.vl_consulta);
    this.agendamentoService.InsertAgendamento(this.formData).subscribe((data: any) => {
      if (data.retorno == "NOK") {
        this.sweetAlertService.SweetAlert('atencao', 'Agendamento com conflito de datas !');
      }
      else{
        if(this.formCadastroAgenda.value.id_agendamento != null){
          this.sweetAlertService.SweetAlert('ok', 'Sessão atualizada com sucesso!');
        }else{
          this.sweetAlertService.SweetAlert('ok', 'Sessão cadastrada com sucesso!');
        }

    }
    this.MostrarLista = !this.MostrarLista
    this.GetAgendamento();
    this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao cadastrar o Agendamento');
        if(this.formCadastroAgenda.value.id_agendamento != null){
          this.sweetAlertService.SweetAlert('ok', 'Erro na atualização da Sessão');
        }else{
          this.sweetAlertService.SweetAlert('ok', 'Erro no atualização da Sessão');
        }
        this.spinner.hide();
      });
  }
  CancelarEvento(){
    Swal.fire({
      title: 'Deseja Excluir ?',
      text: "Você não será capaz de reverter isso!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ConfirmarCancelarEvento();
      }
    });
  }
  ConfirmarCancelarEvento(){
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_agendamento', this.formCadastroAgenda.value.id_agendamento);
    this.agendamentoService.InativarAgendamento(this.formData).subscribe((data: any) => {

      if (data.retorno[0].qt_agendamento > 0) {
        this.spinner.hide();
        this.CancelarRecorencia(data.retorno[0].id_paciente, data.retorno[0].dt_consulta);
      }
      else{
        this.sweetAlertService.SweetAlert('ok', 'Agendamento Excluido com sucesso');
        this.MostrarLista = !this.MostrarLista
        this.GetAgendamento();
      }
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao cancelar o Agendamento');
        this.spinner.hide();
      });
  }

  CancelarRecorencia(id_paciente, dt_consulta){
    Swal.fire({
      title: 'Deseja Excluir TODAS as Sessões Posteriores?',
      text: "Você não será capaz de reverter isso!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ConfirmarCancelarRecorrencia(id_paciente, dt_consulta);
      }else{
        this.sweetAlertService.SweetAlert('ok', 'Agendamento Excluido com sucesso');
        this.MostrarLista = !this.MostrarLista
        this.GetAgendamento();
      }
    });
  }

  ConfirmarCancelarRecorrencia(id_paciente, dt_consulta){
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_paciente', id_paciente);
    this.formData.append('dt_consulta', dt_consulta);
    this.agendamentoService.InativarRecorenciaAgendamento(this.formData).subscribe((data: any) => {
      this.sweetAlertService.SweetAlert('ok', 'Agendamento Excluido com sucesso');
      this.MostrarLista = !this.MostrarLista
      this.GetAgendamento();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao cancelar o Agendamento');
        this.spinner.hide();
      });
  }
  GetInformacaoPacientePrePago(){
    this.formData = new FormData();
    this.formData.append('id_paciente', this.formCadastroAgenda.value.id_paciente);
    this.agendamentoService.GetInformacaoPaciente(this.formData).subscribe((data: any) => {
      if (data.retorno[0].no_condicao == 'PRÉ-PAGO') {
        this.ListaStatus = [{id:1, descricao:'ATENDIDO'},{id:2, descricao:'CANCELADA COM COBRANÇA'}, {id:3, descricao:'CANCELADA SEM COBRANÇA'}, {id:4, descricao:'CANCELADO COM CRÉDITO'}];
      }

    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar dados do Paciente');
        this.spinner.hide();
      });
  }

  GetInformacaoPaciente(){
    if (this.formCadastroAgenda.value.no_titulo == undefined || this.formCadastroAgenda.value.no_titulo == null) {
      return;
    }
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_paciente', this.formCadastroAgenda.value.no_titulo);
    this.agendamentoService.GetInformacaoPaciente(this.formData).subscribe((data: any) => {
      this.formCadastroAgenda.patchValue({
        id_tipo_pagamento: data.retorno[0].id_tipo_pagamento,
        vl_consulta: data.retorno[0].vl_consulta,
      });
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar dados do Paciente');
        this.spinner.hide();
      });
  }
}
