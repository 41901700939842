import { Component } from '@angular/core';
import { Router,NavigationStart  } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'lkrepay';

  MostrarHeaderFooter: boolean = false;
  MostrarHeaderLogin: boolean = false;

  constructor(private router: Router) {
      router.events.forEach((event) => {
        if (event instanceof NavigationStart) {

          let i = event['url'].indexOf("?");
          let url = event['url'];
          if (i > 0) {
            url = event['url'].substring(0, i);
          }
          if(url != '/expires' && (url == '/login' || localStorage.getItem('userToken') == null)){
            this.MostrarHeaderFooter = false;
            this.MostrarHeaderLogin = true;
          }
          else {
            this.MostrarHeaderFooter = true;
            this.MostrarHeaderLogin = false;
          }
        }
      });

}
}
