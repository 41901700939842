import { HttpInterceptor, HttpRequest,HttpErrorResponse, HttpHandler, HttpUserEvent, HttpEvent } from "@angular/common/http";
import { Observable, Subject } from 'rxjs';
import { Injectable } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { tap } from 'rxjs/operators';
import { AutenticacaoToken } from './autenticacao.token';
import { NgxSpinnerService } from "ngx-spinner";
import { SweetAlertService } from '../../service/commom/sweet-alert/sweet-alert.service';

@Injectable()
export class AutenticacaoInterceptor implements HttpInterceptor {
  href: string = "";
  formData = new FormData();
  constructor(
    private router: Router,
    private autenticacaoToken: AutenticacaoToken,
    private spinner: NgxSpinnerService,
    private sweetAlertService: SweetAlertService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.autenticacaoToken.Token()) {
      const clonedreq = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + localStorage.getItem('userToken'))
      });

    return next.handle(clonedreq).pipe(tap(
        succ => { },
        err => {
          if (err.status === 401){
            this.spinner.hide();
            this.router.navigateByUrl('/login');
            this.sweetAlertService.SweetAlert('error', 'Sua sessão expirou. Faça o login novamente!');
          }
        }
      ));
    }
    else {
      return next.handle(req.clone()).pipe(tap(
        succ => { },
        err => {
          if (err.status === 401){
            this.spinner.hide();
            this.router.navigateByUrl('/login');
            this.sweetAlertService.SweetAlert('error', 'Sua sessão expirou. Faça o login novamente!');
          }
        }
      ));
    }
  }
}
