import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { UsuarioService } from '../../service/usuario/usuario.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SweetAlertService } from '../../service/commom/sweet-alert/sweet-alert.service';
import { GlobalConstants } from '../../common/global-constants';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  formData: FormData;
  token;
  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    private usuarioService: UsuarioService,
    private route: ActivatedRoute,
    private sweetAlertService: SweetAlertService,
  ) { }

  mostrarAutoComplete: boolean = true;
  mostrarLogin: boolean = true;
  mostrarResete: boolean = false;
  mostrarUsuarioInvalido: boolean = false;
  mostrarMensagemResete: boolean = false;
  mostrarTrocaSenha: boolean = false;

  readonly minutesToken = GlobalConstants.minutesToken;

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
        if (params['token'] != undefined) {
          this.token = params['token'];
          this.spinner.show();
          this.formData = new FormData();
          this.formData.append('no_token', params['token']);

          this.usuarioService.GetToken(this.formData).subscribe((data: any) => {
            if (data.erro == 'OK') {
             this.TrocaSenha();
             this.spinner.hide();
            }
            else {
              this.sweetAlertService.SweetAlert('error', 'O Token é inválido ou expirou, favor solicitar um novo resete');
              this.spinner.hide();
            }
          },
            (err: HttpErrorResponse) => {
              this.sweetAlertService.SweetAlert('error', 'O Token é inválido ou expirou, favor solicitar um novo resete');
              this.spinner.hide();
            });
        }
      }
    )

    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
    this.registerForm.disable();
    this.AutoCompleteOff();
    localStorage.removeItem('userToken');
  }
  get f() { return this.registerForm.controls; }

  AutoCompleteOff() {
    setTimeout(() => {
      this.registerForm.enable();
    }, 1000);
  }
  EsqueciMinhaSenha() {
    this.mostrarMensagemResete = false;
    this.mostrarLogin = false;
    this.mostrarTrocaSenha = false;
    this.mostrarResete = true;

  }
  CancelarEsqueciMinhaSenha() {
    this.mostrarAutoComplete = true;
    this.mostrarLogin = true;
    this.mostrarTrocaSenha = false;
    this.mostrarResete = false;
    this.AutoCompleteOff();
  }

  TrocaSenha() {
    this.mostrarLogin = false;
    this.mostrarTrocaSenha = true;
    this.mostrarResete = false;
  }
  AlterarSenha(no_senha){
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('no_token', this.token);
    this.formData.append('no_senha', no_senha);
    this.usuarioService.AlterarSenha(this.formData).subscribe((data: any) => {
      this.spinner.hide();
      this.sweetAlertService.SweetAlert('OK', 'Senha Alterada com Sucesso!');
      this.CancelarEsqueciMinhaSenha();
    },
      (err: HttpErrorResponse) => {
        this.sweetAlertService.SweetAlert('error', 'Erro ao solicitar o Resete de Senha!');
        this.spinner.hide();
      });
  }

  AutenticarUsuario() {
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('no_email', this.registerForm.value.email);
    this.formData.append('nu_senha', this.registerForm.value.password);

    this.usuarioService.AutenticacaoUsuario(this.formData).subscribe((data: any) => {
      if (data.erro == 'Usuário e/ou senha inválidos') {
        this.mostrarUsuarioInvalido = true;
        this.spinner.hide();
      }
      else {
        this.mostrarUsuarioInvalido = false;
        localStorage.setItem('userToken', data.token);
        localStorage.setItem('userName', data.retorno.no_usuario);
        localStorage.setItem('idPerfil', data.retorno.id_perfil);

        const DataHoraAtual = new Date();
        DataHoraAtual.setMinutes(DataHoraAtual.getMinutes() + this.minutesToken);
        const DataExpires= new Date(data.expires);
        localStorage.setItem('userTokenExpires', DataExpires.toString());
        localStorage.setItem('dataTokenInativo', DataHoraAtual.toString());

        setTimeout(() => {
          this.router.navigate(['/Home']);
        }, 1000);
      }
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
      });
  }
  ReseteSenha(email) {
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('no_email', email);
    this.usuarioService.ReseteSenha(this.formData).subscribe((data: any) => {
        this.mostrarMensagemResete = true;
        this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        this.sweetAlertService.SweetAlert('error', 'Erro ao solicitar o Resete de Senha!');
        this.spinner.hide();
      });

  }
}
