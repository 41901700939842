import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TagInputModule } from 'ngx-chips';
import { CurrencyMaskModule } from "ng2-currency-mask";

import { ExcelService } from './service/commom/excel.service';

import { FiltroPaginationPipe } from './pipe/filtro-pagination.pipe';
import { FiltroUniquePipe } from './pipe/filtro-unique.pipe';

import { AutenticacaoGuard } from './common/autenticacao/autenticacao.guard';
import { AutenticacaoInterceptor } from './common/autenticacao/autenticacao.interceptor';
import { LoginComponent } from './page/login/login.component';
import { HomeComponent } from './page/home/home.component';
import { HeaderComponent } from './page/commom/header/header.component';
import { ListarClienteComponent } from './page/cliente/listar-cliente.component';
import { GerenciamentoComponent } from './page/gerenciamento/gerenciamento.component';
import { PacienteComponent } from './page/paciente/paciente.component';
import { ProcedimentoComponent } from './page/procedimento/procedimento.component';
import { UsuarioComponent } from './page/usuario/usuario.component';
import { NaoAutorizadoComponent } from './page/error/nao-autorizado/nao-autorizado.component';
import { NotificacaoComponent } from './page/notificacao/notificacao.component';
import { AgendaComponent } from './page/agenda/agenda.component';
import { SortPipe } from './pipe/sort.pipe';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    HeaderComponent,
    ListarClienteComponent,
    GerenciamentoComponent,
    PacienteComponent,
    ProcedimentoComponent,
    UsuarioComponent,
    NaoAutorizadoComponent,
    NotificacaoComponent,
    FiltroPaginationPipe,
    AgendaComponent,
    SortPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    FormsModule,
    Ng2SearchPipeModule,
    NgSelectModule,
    NgxMaskModule.forRoot(maskConfig),
    HttpClientModule,
    AutocompleteLibModule,
    TagInputModule,
    CurrencyMaskModule
  ],
  providers: [
    ExcelService,
    AutenticacaoGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AutenticacaoInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
