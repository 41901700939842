import { Component, OnInit, HostListener } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ProcedimentoService } from '../../service/procedimento/procedimento.service';
import { SweetAlertService } from '../../service/commom/sweet-alert/sweet-alert.service';
import { ExcelService } from '../../service/commom//excel.service';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
declare function CarregarScriptData(): any;
declare function CarregarScript(): any;
declare var $: any;
declare var Swal: any;

@Component({
  selector: 'app-procedimento',
  templateUrl: './procedimento.component.html',
  styleUrls: ['./procedimento.component.css'],
  host: { 'class': 'col' }
})
export class ProcedimentoComponent implements OnInit {
  MostrarLista: boolean = true;
  MostrarProtocolos: boolean = false;
  MostrarCadastro: boolean = false;
  VisualizacaoCelular: boolean = false;
  VisualizarHistorico: boolean = false;
  ListaProcedimento: any = [];
  ListaCliente: any = [];
  ListaReembolso: any = [];
  ListaProcesso: any = [];
  ListaPaciente: any = [];
  ListaCarteira: any = [];
  ListaStatus: any = [];
  ListaTuss: any = [];
  ListaProtocolos: any = [];
  ListaPagamento: any = [];
  ListaPagamentoAdicionado: any = [];
  ListaDetalhesPlano: any = [];
  ListaHistoricoStatus: any = [];
  ListaDadosPaciente: any = [];
  ListaProcessoFiltro: any = [{ no_sigla: 'CsM' }, { no_sigla: 'ExD' }, { no_sigla: 'PdC' }];

  ListaNota: any = [];
  formData: FormData;
  p: number = 1;
  formCadastroProcedimento: FormGroup;
  formCadastroPagamento: FormGroup;
  formCadastroProtocolo: FormGroup;
  isLoading: boolean = false;
  initialValue: string = ''
  id_procedimento: number = 0;
  id_protocolo: number = 0;
  no_observacao: string = '';
  selectedValue: any = [];
  indexposition: any;
  tagData: any = [];
  no_nota: any = null;
  id_parametro;
  idPerfil = localStorage.getItem('idPerfil');
  idRedirecionamento = localStorage.getItem('RedirecionamentoHome');
  itemsPage: number = 10;
  SearchProcedimento: any = [];
  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private procedimentoService: ProcedimentoService,
    private sweetAlertService: SweetAlertService,
    private excelService: ExcelService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    const actualWidth = window.innerWidth;
    if (window.innerWidth < 1024) {
      this.VisualizacaoCelular = true;
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.GetPacienteProcedimento();
    this.GetTipoPagamento();
    this.CarregarFormBuilder();
    this.GetStatus();

    this.route.paramMap.subscribe(params => {
      this.id_parametro = params.get('id_reembolso');
      if (params.get('id_reembolso') != "" && this.id_parametro != undefined) {
        setTimeout(() => {
          this.GetProcedimento(params.get('id_reembolso'));
          this.GetListaProcedimento();
        }, 1000);
      }
      else {
        this.GetListaProcedimento();
      }
    });
  }

  GetListaProcedimento() {
    this.spinner.show();
    this.formData = new FormData();
    this.procedimentoService.GetListaProcedimento(this.formData).subscribe((data: any) => {
      this.ListaProcedimento = data.retorno;
      if (this.id_parametro == '' || this.id_parametro == undefined) {
        this.spinner.hide();
      }
      this.id_parametro = '';
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.spinner.hide();
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Procedimentos');
      });
  }


  GetProcedimento(id_procedimento) {
    this.initialValue = ' ';
    this.CarregarTelaCadastro();
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_procedimento', id_procedimento.toString());

    this.procedimentoService.GetProcedimento(this.formData).subscribe((data: any) => {
      setTimeout(() => {

        this.formCadastroProcedimento.patchValue(data.retorno[0]);
        const novaListPagamento = data.pagamento.map(item => ({
          id_pagamento: item.id_pagamento,
          vl_pagamento: item.vl_pagamento,
          no_pagamento: item.no_pagamento,
        }));

        if (data.retorno[0].dt_atendimento != null) {
          $('#kt_datepicker_2').datepicker('setDate', new Date(data.retorno[0].dt_atendimento));
        }
        if (data.retorno[0].dt_contato != null) {
          $('#kt_datepicker_1').datepicker('setDate', new Date(data.retorno[0].dt_contato));
        }

        if (data.retorno[0].dt_status != null) {
          $('#kt_datepicker_5').datepicker('setDate', new Date(data.retorno[0].dt_status));
        }

        //this.GetReembolsoProcedimento();
        this.GetPacienteProcedimento();
        this.GetProcessoProcedimento();
        this.GetCarteiraProcedimento();
        this.GetClienteProcedimento();
        this.GetHistoricoStatus(id_procedimento);
        this.ListaPagamentoAdicionado = novaListPagamento;
        this.ListaProtocolos = data.protocolo;

        for (let index = 0; index < data.nota.length; index++) {
          // this.no_nota = this.ListaNota.filter(a => a.id_nota === data.nota[index].id_nota)[0].no_nota;
          this.tagData.push({ no_nota: data.nota[index].no_nota, id_nota: data.nota[index].id_nota });
        }
        this.initialValue = data.retorno[0].no_tuss;
        this.spinner.hide();
        setTimeout(() => {
          CarregarScriptData();
        }, 500);
      }, 3000);
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.spinner.hide();
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Procedimentos');
      });
  }

  GetPacienteProcedimento() {
    this.formData = new FormData();
    this.procedimentoService.GetPacienteProcedimento(this.formData).subscribe((data: any) => {
      this.ListaPaciente = data.retorno;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Pacientes');
      });
  }
  GetClienteProcedimento() {
    this.spinner.show();
    this.GetCarteiraProcedimento();
    this.GetDadosPaciente();
    this.formData = new FormData();
    this.formData.append('id_paciente', this.formCadastroProcedimento.value.id_paciente.toString());
    this.procedimentoService.GetClienteProcedimento(this.formData).subscribe((data: any) => {
      this.ListaCliente = data.retorno;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Clientes');
      });
  }
  GetDadosPaciente() {
    this.spinner.show();
    this.GetCarteiraProcedimento();
    this.formData = new FormData();
    this.formData.append('id_paciente', this.formCadastroProcedimento.value.id_paciente.toString());
    this.procedimentoService.GetDadosPaciente(this.formData).subscribe((data: any) => {
      this.ListaDadosPaciente = data.retorno;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Dados do Paciente');
      });
  }
  GetProcessoProcedimento() {
    this.GetPacienteProcedimento();
    this.GetNota();
    this.formData = new FormData();
    this.formData.append('id_cliente', this.formCadastroProcedimento.value.id_cliente.toString());
    this.procedimentoService.GetProcessoProcedimento(this.formData).subscribe((data: any) => {
      this.ListaProcesso = data.retorno;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Processos');
      });
  }
  GetStatus() {
    this.formData = new FormData();
    this.procedimentoService.GetStatus(this.formData).subscribe((data: any) => {
      this.ListaStatus = data.retorno;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Status');
      });
  }
  GetCarteiraProcedimento() {
    this.formData = new FormData();
    this.formData.append('id_paciente', this.formCadastroProcedimento.value.id_paciente.toString());
    this.procedimentoService.GetCarteiraProcedimento(this.formData).subscribe((data: any) => {
      this.ListaCarteira = data.retorno;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Carteira');
      });
  }

  GetDetalhePlano() {
    let detalhes = this.ListaCarteira.filter(a => a.id_carteira === this.formCadastroProcedimento.value.id_carteira)[0];
    $("#idDetalhePlano").html("<b>Operadora:</b>:" + detalhes.no_operadora + "</br><b>Plano:</b>:" + detalhes.no_plano + "</br><b>Carteira:</b>:" + detalhes.no_carteira);
  }

  Get_Tuss(no_tuss) {
    this.formData = new FormData();
    this.formData.append('no_tuss', no_tuss);
    this.procedimentoService.Get_Tuss(this.formData).subscribe((data: any) => {
      this.ListaTuss = data.retorno;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Tuss');
      });
  }
  GetTipoPagamento() {
    this.formData = new FormData();
    this.procedimentoService.GetTipoPagamento(this.formData).subscribe((data: any) => {
      this.ListaPagamento = data.retorno;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Tipo Pagamento');
      });
  }
  CarregarTelaCadastro() {
    this.tagData = []
    this.ListaDadosPaciente = [];
    this.ListaPagamentoAdicionado = [];
    this.ListaProtocolos = [];
    this.initialValue = ""
    this.MostrarLista = !this.MostrarLista;
    this.VisualizarHistorico = false;
    this.MostrarCadastro = !this.MostrarCadastro;
    setTimeout(() => {
      this.CarregarFormBuilder();
      CarregarScriptData();
      CarregarScript();
    }, 500);
  }
  CancelarCadastro() {
    this.MostrarLista = !this.MostrarLista;
    this.MostrarCadastro = !this.MostrarCadastro;
  }
  CarregarFormBuilder() {
    this.formCadastroProcedimento = this.formBuilder.group({
      id_procedimento: [''],
      no_tuss: [''],
      id_cliente: [null, [Validators.required]],
      id_status: [null, [Validators.required]],
      id_processo: [null, [Validators.required]],
      id_paciente: [null, [Validators.required]],
      id_carteira: [null, [Validators.required]],
      dt_contato: ['', [Validators.required]],
      dt_atendimento: ['', [Validators.required]],
      nu_tuss: [null, [Validators.required, Validators.maxLength(100)]],
      no_observacao: [null, [Validators.maxLength(1000)]],
      dt_envio : [null],
      dt_status: [null]
    });
    this.formCadastroPagamento = this.formBuilder.group({
      id_pagamento: [null, [Validators.required]],
      vl_pagamento: [null, [Validators.required]],
      no_pagamento: [null]
    });
    this.formCadastroProtocolo = this.formBuilder.group({
      id_protocolo: [null],
      no_protocolo: ['', [Validators.required]],
      nu_protocolo: [''],
      dt_cadastro: [''],
    });
  }
  get f() { return this.formCadastroProcedimento.controls; }
  get g() { return this.formCadastroPagamento.controls; }
  get h() { return this.formCadastroProtocolo.controls; }

  GetData() {
    let data = $("#kt_datepicker_1").datepicker('getDate');
    if (data != null) {
      var date = new Date(data);
      this.formCadastroProcedimento.patchValue({
        dt_contato: data
      });
    }
  }
  GetDataStatus() {
    let data = $("#kt_datepicker_5").datepicker('getDate');
    if (data != null) {
      var date = new Date(data);
      this.formCadastroProcedimento.patchValue({
        dt_status: data
      });
    }
  }
  GetDataAtendimento() {
    let data = $("#kt_datepicker_2").datepicker('getDate');
    if (data != null) {
      var date = new Date(data);
      this.formCadastroProcedimento.patchValue({
        dt_atendimento: data
      });
    }
  }
  GetDataProtocolo() {
    let data = $("#kt_datepicker_3").datepicker('getDate');
    if (data != null) {
      var date = new Date(data);
      this.formCadastroProtocolo.patchValue({
        dt_cadastro: data
      });
    }
  }

  AutoComplete_SelectedItem(item) {
    if (this.initialValue == "") {
      this.formCadastroProcedimento.patchValue({
        nu_tuss: item.nu_tuss
      });
    }
  }
  AutoComplete_Search(val: string) {
    this.initialValue = "";
    if (val.length >= 3) {
      this.Get_Tuss(val);
    }
    else {
      this.initialValue = "";
      this.ListaTuss = [];
    }
  }
  AdicionarPagamento() {
    let form = this.formCadastroPagamento.value;
    let no_pagamento = form.id_pagamento != null ? this.ListaPagamento.filter(a => a.id_pagamento === form.id_pagamento)[0].no_pagamento : '';
    this.ListaPagamentoAdicionado.push({
      id_pagamento: form.id_pagamento,
      vl_pagamento: form.vl_pagamento,
      no_pagamento: no_pagamento
    });
  }
  ExcluirPagamento(index) {
    Swal.fire({
      title: 'Deseja Excluir ?',
      text: "Você não será capaz de reverter isso!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ListaPagamentoAdicionado.splice(index, 1);
      }
    });
  }

  CrudProcedimento() {
    if (this.formCadastroProcedimento.value.id_procedimento != '') {
      this.AtualizarProcedimento();
    }
    else {
      this.AdicionarProcedimento();
    }
  }

  AdicionarProcedimento() {
    this.spinner.show();

    this.formData = new FormData();
    this.formData.append('GetProcedimento', JSON.stringify(this.formCadastroProcedimento.value));
    this.formData.append('GetPagamento', JSON.stringify(this.ListaPagamentoAdicionado));
    this.formData.append('GetNota', JSON.stringify(this.tagData));
    this.formData.append('GetProtocolo', JSON.stringify(this.ListaProtocolos));
    this.procedimentoService.AdicionarProcedimento(this.formData).subscribe((data: any) => {
      this.sweetAlertService.SweetAlert('ok', 'Procedimento adicionado com sucesso');
      if (this.idRedirecionamento != null) {
        this.Voltar();
        this.router.navigate(['/Home']);
      }
      this.CarregarTelaCadastro();
      this.GetListaProcedimento();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao cadastrar o Procedimento');
        this.spinner.hide();
      });
  }
  AtualizarProcedimento() {
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('GetProcedimento', JSON.stringify(this.formCadastroProcedimento.value));
    this.formData.append('GetPagamento', JSON.stringify(this.ListaPagamentoAdicionado));
    this.formData.append('GetNota', JSON.stringify(this.tagData));
    this.formData.append('GetProtocolo', JSON.stringify(this.ListaProtocolos));

    this.procedimentoService.AdicionarProcedimento(this.formData).subscribe((data: any) => {
      this.sweetAlertService.SweetAlert('ok', 'Procedimento atualizado com sucesso');
      if (this.idRedirecionamento != null) {
        this.Voltar();
        this.router.navigate(['/Home']);
      }
      this.CarregarTelaCadastro();
      this.GetListaProcedimento();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao atualizar o Procedimento');
        this.spinner.hide();
      });
  }

  GetMostrarProtocolo(id_procedimento) {
    this.MostrarProtocolos = true;
    this.MostrarCadastro = false;
    this.MostrarLista = false;
    this.GetProtocolos(id_procedimento);
  }
  CancelarProtocolo() {
    this.MostrarProtocolos = false;
    this.MostrarCadastro = false;
    this.MostrarLista = true;
  }
  GetProtocolos(id_procedimento) {
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_procedimento', id_procedimento.toString());
    this.procedimentoService.GetProtocolos(this.formData).subscribe((data: any) => {
      this.ListaProtocolos = data.retorno;
      this.id_procedimento = id_procedimento;
      this.id_protocolo = 0;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Protocolos');
        this.spinner.hide();
      });
  }
  GetProtocoloDetalhes(id_protocolo) {
    this.no_observacao = this.ListaProtocolos.filter(a => a.id_protocolo === id_protocolo)[0].no_protocolo;
    this.id_protocolo = id_protocolo;
  }
  AdicionarProtocolo(no_protocolo) {
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_procedimento', this.id_procedimento.toString());
    this.formData.append('id_protocolo', this.id_protocolo.toString());
    this.formData.append('no_protocolo', no_protocolo);

    this.procedimentoService.AdicionarProtocolo(this.formData).subscribe((data: any) => {
      this.sweetAlertService.SweetAlert('ok', 'Protocolo cadastrado com sucesso');
      this.GetProtocolos(this.id_procedimento);
      this.no_observacao = '';
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao cadastrar o protocolo!');
        this.spinner.hide();
      });
  }

  GetHistoricoStatus(id_procedimento) {
    this.formData = new FormData();
    this.formData.append('id_procedimento', id_procedimento.toString());
    this.procedimentoService.GetHistoricoStatus(this.formData).subscribe((data: any) => {
      this.ListaHistoricoStatus = data.retorno;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Historicos de Status');
        this.spinner.hide();
      });
  }

  GetNota() {
    this.formData = new FormData();
    this.formData.append('id_cliente', this.formCadastroProcedimento.value.id_cliente);
    this.procedimentoService.GetNota(this.formData).subscribe((data: any) => {
      this.ListaNota = data.retorno;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Historicos de Status');
        this.spinner.hide();
      });
  }



  selectedTag(index) {
    this.indexposition = index
  }

  addData() {
    let indexposition = 0;

    if (this.tagData.filter(a => a === this.no_nota).length > 0) {
      return;
    }
    else {
      let no_nota;
      no_nota = this.ListaNota.filter(a => a.id_nota === this.no_nota)[0].no_nota;
      this.tagData.push({ no_nota: no_nota, id_nota: this.no_nota });
      this.no_nota = null;
    }
  }

  removeTag(index) {
    this.tagData.splice(index, 1);
  }


  AdicionarProtocolo_2() {
    let form = this.formCadastroProtocolo.value;
    this.ListaProtocolos.push({
      dt_cadastro: form.dt_cadastro,
      nu_protocolo: form.nu_protocolo,
      no_protocolo: form.no_protocolo,
      no_usuario: localStorage.getItem('userName')
    });
  }
  ExcluirProtocolo_2(index) {
    Swal.fire({
      title: 'Deseja Excluir ?',
      text: "Você não será capaz de reverter isso!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ListaProtocolos.splice(index, 1);
      }
    });
  }

  Voltar() {
    localStorage.setItem('RedirecionamentoVoltar', '1');
  }
}
