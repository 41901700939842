import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { PacienteService } from '../../service/paciente/paciente.service';
import { SweetAlertService } from '../../service/commom/sweet-alert/sweet-alert.service';
import { ExcelService } from '../../service/commom//excel.service';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
declare function CarregarScript(): any;
declare function CarregarScriptData(): any;
declare var Swal: any;
declare var $: any;
@Component({
  selector: 'app-paciente',
  templateUrl: './paciente.component.html',
  styleUrls: ['./paciente.component.css'],
  host: { 'class': 'col' }
})
export class PacienteComponent implements OnInit {
  @ViewChild('content', { 'static': true }) content: ElementRef;

  MostrarLista: boolean = true;
  VisualizacaoCelular: boolean = false;
  formCadastroPaciente: FormGroup;
  formCadastroPlano: FormGroup;
  formCadastroCliente: FormGroup;
  formData: FormData;
  p: number = 1;
  ListaUF: any = [];
  ListaStatus: any = [{ fg_aprovado: '1', dsstatus: 'ATIVO' }, { fg_aprovado: '0', dsstatus: 'INATIVO' }];
  ListaTipoPagamento: any = [{ id_tipo_pagamento: 1, ds_pagamento: 'NOTA FISCAL' }, { id_tipo_pagamento: 2, ds_pagamento: 'RECIBO' }, { id_tipo_pagamento: 3, ds_pagamento: 'OUTROS' }];
  ListaTipoPaciente: any = [{ id_tipo_paciente: 2, no_tipo_paciente: 'TERAPIA' }, { id_tipo_paciente: 1, no_tipo_paciente: 'REEMBOLSO' }, { id_tipo_paciente: 3, no_tipo_paciente: 'TODOS' }];
  ListaReembolso: any = [{ fg_reembolso: '1', dsstatus: 'SIM' }, { fg_reembolso: '0', dsstatus: 'NÃO' }];
  ListaCondicao: any = [{ no_condicao: 'PÓS-PAGO'}, {no_condicao: 'PRÉ-PAGO'}];

  ListaPaciente: any = [];
  ListaOperadora: any = [];
  ListaCarteira: any = [];
  ListaCliente: any = [];
  ListaClientes: any = [];
  isLoading: boolean = false;
  idPerfil = localStorage.getItem('idPerfil');
  itemsPage: number = 10;
  dt_cadastro = '';
  CpfCarregado = '';
  ListaArquivos: File[] = [];
  id_parametro;
  idRedirecionamento = localStorage.getItem('RedirecionamentoHome');

  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private pacienteService: PacienteService,
    private sweetAlertService: SweetAlertService,
    private excelService: ExcelService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    const actualWidth = window.innerWidth;
    if (window.innerWidth < 1024) {
      this.VisualizacaoCelular = true;
    }
  }
  @HostListener('window:resize', [''])
  onResize() {
    this.VisualizacaoCelular = window.innerWidth < 1024 ? true : false;
  }
  ngOnInit(): void {
    this.CarregarUF();

    this.GetCliente();
    this.CarregarFormBuilder();
    this.ListaOperadora = [];

    this.route.paramMap.subscribe(params => {
      this.id_parametro = params.get('id_paciente');
      if (params.get('id_paciente') != "" && this.id_parametro != undefined) {
        setTimeout(() => {
          this.GetPaciente(params.get('id_paciente'));
          this.CarregarListaPaciente();
        }, 1000);
      }
      else {
        this.CarregarListaPaciente();
      }
    });

  }
  AutoComplete_SelectedItem(item) {

    this.formCadastroPlano.patchValue({
      id_operadora: item.id_operadora,
      no_operadora: item.no_operadora,
    });

    this.formData = new FormData();
    this.formData.append('id_operadora', item.id_operadora.toString());

    this.pacienteService.GetCadastroSLA(this.formData).subscribe((data: any) => {
      if (data.retorno.length > 0) {
        if (data.retorno[0].qt < 5) {
          this.sweetAlertService.SweetAlert('error', 'Pendentes o cadastro do SLA do plano selecionado');
        }
      }
      else {
        this.sweetAlertService.SweetAlert('error', 'Pendentes o cadastro do SLA do plano selecionado');
      }
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao validar SLA do Plano');
      });
  }
  AutoComplete_Search(val: string) {
    if (val.length >= 3) {
      this.GetOperadora(val);
    }
    else {
      this.ListaOperadora = [];
    }
  }

  GetOperadora(no_operadora) {
    this.isLoading = true;
    this.formData = new FormData();
    this.formData.append('no_operadora', no_operadora);

    this.pacienteService.GetOperadora(this.formData).subscribe((data: any) => {
      this.ListaOperadora = data.retorno;
      this.isLoading = false;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar as Operadora');
        this.isLoading = false;
      });
  }
  CarregarListaPaciente() {
    this.spinner.show();
    this.formData = new FormData();
    this.pacienteService.GetListaPaciente(this.formData).subscribe((data: any) => {
      this.ListaPaciente = data.retorno;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Pacientes');
        this.spinner.hide();
      });
  }
  GetPaciente(id_paciente) {
    this.CarregarTelaCadastro();
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_paciente', id_paciente);
    this.pacienteService.GetPaciente(this.formData).subscribe((data: any) => {
      setTimeout(() => {
        data.retorno[0].fg_aprovado = data.retorno[0].fg_aprovado == true ? '1' : '0';
        data.retorno[0].fg_reembolso = data.retorno[0].fg_reembolso == true ? '1' : '0';
        this.formCadastroPaciente.setValue(data.retorno[0]);
        this.ListaCarteira = data.carteira;
        this.ListaClientes = data.cliente;
        this.dt_cadastro = data.retorno[0].dt_cadastro;
        this.CpfCarregado = data.retorno[0].nu_cpf;

        if (data.retorno[0].dt_nascimento != null) {
          $('#kt_datepicker_1').datepicker('setDate', new Date(data.retorno[0].dt_nascimento));
        }

        this.spinner.hide();
      }, 500);
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Pacientes');
        this.spinner.hide();
      });
  }
  CarregarTelaCadastro() {
    this.ListaCarteira = [];
    this.ListaClientes = [];
    this.dt_cadastro = '';
    this.CpfCarregado = '';
    this.MostrarLista = !this.MostrarLista;
    setTimeout(() => {
      this.ListaCarteira = [];
      this.CarregarFormBuilder();
      CarregarScriptData();
      CarregarScript();
    }, 100);
  }
  CarregarFormBuilder() {
    this.formCadastroPaciente = this.formBuilder.group({
      id_paciente: [''],
      no_paciente: ['', [Validators.required, Validators.maxLength(300)]],
      //
      nu_cpf: ['', [Validators.minLength(11), Validators.maxLength(11), this.isValidCpf()]],
      no_email: ['', [Validators.maxLength(120), Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      no_endereco: ['', [Validators.maxLength(300)]],
      no_numero: ['', [Validators.maxLength(10)]],
      no_complemento: ['', [Validators.maxLength(50)]],
      no_cep: ['', [Validators.minLength(8), Validators.maxLength(8)]],
      no_uf: [null, [Validators.minLength(2), Validators.maxLength(2)]],
      no_cidade: ['', [Validators.maxLength(300)]],
      no_bairro: ['', [Validators.maxLength(300)]],
      no_telefone: ['', [Validators.minLength(10), Validators.maxLength(12)]],
      no_celular: ['', [Validators.minLength(11), Validators.maxLength(11)]],
      no_celular_2: ['', [Validators.minLength(11), Validators.maxLength(11)]],
      fg_aprovado: [null, [Validators.required]],
      dt_cadastro: [null],
      dt_nascimento: [null],
      id_tipo_paciente: [null],
      vl_consulta: '',
      id_tipo_pagamento: [null],
      fg_reembolso: [null],
      dt_envio_contrato: [null],
      no_nota: null,
      no_condicao: null
    });
    this.formCadastroPlano = this.formBuilder.group({
      id_carteira: [null],
      id_operadora: [null, [Validators.required]],
      no_operadora: [null],
      id_plano: [null],
      no_carteira: ['', [Validators.required, Validators.maxLength(50)]],
      no_plano: ['', [Validators.required, Validators.maxLength(100)]]
    });
    this.formCadastroCliente = this.formBuilder.group({
      id_cliente: [null, [Validators.required]],
      no_fantasia: [null]
    });
  }
  get f() { return this.formCadastroPaciente.controls; }
  get c() { return this.formCadastroPlano.controls; }
  get d() { return this.formCadastroCliente.controls; }
  CarregarUF() {
    this.ListaUF.push({ dsuf: 'RO' })
    this.ListaUF.push({ dsuf: 'AC' })
    this.ListaUF.push({ dsuf: 'AM' })
    this.ListaUF.push({ dsuf: 'RR' })
    this.ListaUF.push({ dsuf: 'PA' })
    this.ListaUF.push({ dsuf: 'AP' })
    this.ListaUF.push({ dsuf: 'TO' })
    this.ListaUF.push({ dsuf: 'MA' })
    this.ListaUF.push({ dsuf: 'PI' })
    this.ListaUF.push({ dsuf: 'CE' })
    this.ListaUF.push({ dsuf: 'RN' })
    this.ListaUF.push({ dsuf: 'PB' })
    this.ListaUF.push({ dsuf: 'PE' })
    this.ListaUF.push({ dsuf: 'AL' })
    this.ListaUF.push({ dsuf: 'SE' })
    this.ListaUF.push({ dsuf: 'BA' })
    this.ListaUF.push({ dsuf: 'MG' })
    this.ListaUF.push({ dsuf: 'ES' })
    this.ListaUF.push({ dsuf: 'RJ' })
    this.ListaUF.push({ dsuf: 'SP' })
    this.ListaUF.push({ dsuf: 'PR' })
    this.ListaUF.push({ dsuf: 'SC' })
    this.ListaUF.push({ dsuf: 'RS' })
    this.ListaUF.push({ dsuf: 'MS' })
    this.ListaUF.push({ dsuf: 'MT' })
    this.ListaUF.push({ dsuf: 'GO' })
    this.ListaUF.push({ dsuf: 'DF' })
  }
  GetDataNascimento() {
    let data = $("#kt_datepicker_1").datepicker('getDate');
    if (data != null) {
      var date = new Date(data);
      this.formCadastroPaciente.patchValue({
        dt_nascimento: data
      });
    }
  }
  CrudPaciente() {
    if (this.idPerfil == '4') {
      this.ListaClientes = [];
      this.formCadastroPaciente.value.id_tipo_paciente = 2;
      this.ListaClientes.push({
        id_cliente: this.ListaCliente[0].id_cliente,
        no_fantasia: this.ListaCliente[0].no_fantasia
      });
    }

    if (this.formCadastroPaciente.value.id_paciente != '') {
      this.AtualizarPaciente();
    }
    else {
      this.AdicionarPaciente();
    }
  }
  AtualizarPaciente() {
    this.spinner.show();
    this.formCadastroPaciente.value.fg_aprovado = this.formCadastroPaciente.value.fg_aprovado == "1" ? true : false;
    this.formCadastroPaciente.value.fg_reembolso = this.formCadastroPaciente.value.fg_reembolso == "1" ? true : false;
    this.formData = new FormData();
    this.formData.append('GetPaciente', JSON.stringify(this.formCadastroPaciente.value));
    this.formData.append('GetCarteira', JSON.stringify(this.ListaCarteira));
    this.formData.append('GetCliente', JSON.stringify(this.ListaClientes));
    this.pacienteService.AtualizarPaciente(this.formData).subscribe((data: any) => {

      this.sweetAlertService.SweetAlert('ok', 'Paciente atualizado com sucesso');
      if (this.idRedirecionamento != null) {
        this.Voltar();
        this.router.navigate(['/Home']);
      }

      this.CarregarTelaCadastro();
      this.CarregarListaPaciente();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao atualizar o Paciente');
        this.spinner.hide();
      });
  }
  AdicionarPaciente() {
    this.spinner.show();
    this.formCadastroPaciente.value.fg_aprovado = this.formCadastroPaciente.value.fg_aprovado == "1" ? true : false;
    this.formCadastroPaciente.value.fg_reembolso = this.formCadastroPaciente.value.fg_reembolso == "1" ? true : false;
    this.formData = new FormData();
    this.formData.append('GetPaciente', JSON.stringify(this.formCadastroPaciente.value));
    this.formData.append('GetCarteira', JSON.stringify(this.ListaCarteira));
    this.formData.append('GetCliente', JSON.stringify(this.ListaClientes));
    this.pacienteService.AdicionarPaciente(this.formData).subscribe((data: any) => {
      this.sweetAlertService.SweetAlert('ok', 'Paciente adicionado com sucesso');
      this.spinner.hide();
      this.CarregarTelaCadastro();
      this.CarregarListaPaciente();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao cadastrar o Paciente');
        this.spinner.hide();
      });
  }
  AdicionarPlano() {
    let form = this.formCadastroPlano.value;
    this.ListaCarteira.push({
      id_operadora: form.id_operadora,
      no_operadora: form.no_operadora,
      no_carteira: form.no_carteira,
      no_plano: form.no_plano
    });
    this.ListaOperadora = [];
  }
  ExcluirPlano(index) {
    Swal.fire({
      title: 'Deseja Excluir ?',
      text: "Você não será capaz de reverter isso!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ListaCarteira.splice(index, 1);
      }
    });
  }
  Exportar(): void {
    const newArray = this.ListaPaciente.map(item => ({
      paciente: item.no_paciente,
      cpf: item.nu_cpf,
      email: item.no_email,
      endereco: item.no_endereco,
      numero: item.no_numero,
      complemento: item.no_complemento,
      cep: item.no_cep,
      uf: item.no_uf,
      cidade: item.no_cidade,
      bairro: item.no_bairro,
      telefone: item.no_telefone,
      celular: item.no_celular,
      dt_cadastro: item.dt_cadastro,
      status: item.fg_aprovado ? "Ativo" : "Inativo"
    }));
    this.excelService.exportAsExcelFile(newArray, 'Cliente');
  }
  AdicionarCliente() {
    let form = this.formCadastroCliente.value;
    let no_fantasia = form.id_cliente != null ? this.ListaCliente.filter(a => a.id_cliente === form.id_cliente)[0].no_fantasia : '';
    this.ListaClientes.push({
      id_cliente: form.id_cliente,
      no_fantasia: no_fantasia
    });
  }
  GetCliente() {
    this.spinner.show();
    this.formData = new FormData();
    this.pacienteService.GetCliente(this.formData).subscribe((data: any) => {
      this.ListaCliente = data.retorno;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Clientes');
        this.spinner.hide();
      });
  }
  ExcluirCliente(index) {
    Swal.fire({
      title: 'Deseja Excluir ?',
      text: "Você não será capaz de reverter isso!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ListaClientes.splice(index, 1);
      }
    });
  }
  isValidCpf() {
    return (control: AbstractControl): Validators => {
      const cpf = control.value.toString();
      if (cpf) {
        let numbers, digits, sum, i, result, equalDigits;
        equalDigits = 1;
        if (cpf.length < 11) {
          return false;
        }

        if (!cpf || cpf.length != 11
          || cpf == "00000000000"
          || cpf == "11111111111"
          || cpf == "22222222222"
          || cpf == "33333333333"
          || cpf == "44444444444"
          || cpf == "55555555555"
          || cpf == "66666666666"
          || cpf == "77777777777"
          || cpf == "88888888888"
          || cpf == "99999999999") {
          return { cnpjNotValid: true };
        }

        for (i = 0; i < cpf.length - 1; i++) {
          if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if (!equalDigits) {
          numbers = cpf.substring(0, 9);
          digits = cpf.substring(9);
          sum = 0;
          for (i = 10; i > 1; i--) {
            sum += numbers.charAt(10 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(0))) {
            return { cpfNotValid: true };
          }
          numbers = cpf.substring(0, 10);
          sum = 0;

          for (i = 11; i > 1; i--) {
            sum += numbers.charAt(11 - i) * i;
          }
          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(1))) {
            return { cpfNotValid: true };
          }
          return false;
        } else {
          return { cpfNotValid: true };
        }
      }
      return false;
    };
  }
  GetCPF() {
    if (this.formCadastroPaciente.value.nu_cpf == '') {
      return;
    }
    if (this.CpfCarregado == this.formCadastroPaciente.value.nu_cpf) {
      return;
    }
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('nu_cpf', this.formCadastroPaciente.value.nu_cpf);
    this.pacienteService.GetCPF(this.formData).subscribe((data: any) => {
      if (data.retorno.length > 0) {
        this.sweetAlertService.SweetAlert('error', 'CPF já cadastrado!');
        this.formCadastroPaciente.value.nu_cpf = ''

        this.formCadastroPaciente.patchValue({
          nu_cpf: ''
        });
      }
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao validar o CPF');
        this.spinner.hide();
      });
  }

  ExportarPaciente(id_paciente) {
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_paciente', id_paciente);
    this.pacienteService.GetFichaCadastral(this.formData).subscribe((data: any) => {
      var doc = new jsPDF();


      doc.text("Ficha Cadastral", 80, 10);
      doc.setFont("normal")
      doc.setFontSize(10);
      doc.text("Nome Completo: " + data.retorno[0].no_paciente, 20, 20);
      doc.text("CPF: " + data.retorno[0].nu_cpf, 20, 25);
      doc.text("E-Mail: " + data.retorno[0].no_email, 20, 30);
      doc.text("Data Nascimento: " + data.retorno[0].dt_nascimento, 20, 35);
      doc.text("Telefone Fixo: " + data.retorno[0].no_telefone, 20, 40);
      doc.text("Telefone Celular 1: " + data.retorno[0].no_celular, 20, 45);
      doc.text("Telefone Celular 2: " + data.retorno[0].no_celular_2, 20, 50);
      doc.text("Data de Cadastro: " + data.retorno[0].dt_cadastro, 20, 55);

      doc.addPage("a4", "l");

      doc.save('FichaPaciente - ' + data.retorno[0].no_paciente + '.pdf');
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao criar o PDF');
        this.spinner.hide();
      });
  }

  uploadDocumento(fileInput: any, tpArquivo) {
    this.ListaArquivos.push(fileInput.target.files[0]);
  }
  EnviarProcedimento(){
    this.formData = new FormData();
    this.formData.append('id_paciente', this.formCadastroPaciente.value.id_paciente);
    this.formData.append('arquivo', this.ListaArquivos[0]);

    this.pacienteService.EnvioContrato(this.formData).subscribe((data: any) => {
      this.sweetAlertService.SweetAlert('ok', 'Contrato enviado com Sucesso!');
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao enviar o contrato');
        this.spinner.hide();
      });
  }

  Voltar() {
    localStorage.setItem('RedirecionamentoVoltar', '3');
  }
}
