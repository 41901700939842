<div class="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
  <div class="kt-subheader  kt-grid__item" id="kt_subheader">
    <div class="kt-container  kt-container--fluid ">
      <div class="kt-subheader__main">
        <h3 class="kt-subheader__title">Cadastro de Paciente</h3>
        <span class="kt-subheader__separator kt-subheader__separator--v"></span>
        <span class="kt-subheader__desc"><small>Paciente >> Cadastro de Paciente</small></span>
      </div>
      <button type="reset" class="btn btn-secondary kt-margin-t-10" (click)="Voltar()" [routerLink]="'/Home'" *ngIf="idRedirecionamento != null">Voltar</button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="kt-subheader   kt-grid__item" id="kt_subheader" [hidden]="!MostrarLista">
        <div class="row col-md-12">
          <div class="col-md-3">
            <div class="kt-subheader__main">
              <div class="kt-subheader__group" id="kt_subheader_search">
                <div class="row">
                  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 kt-padding-0">
                    <form id="kt_subheader_search_form">
                      <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-0">
                        <input type="search" class="form-control" placeholder="Pesquisar" name="generalSearch" #Pesquisa ngModel (keypress)="p = 1">
                        <span class="kt-input-icon__icon kt-input-icon__icon--right">
                          <span>
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path
                                  d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                  fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                <path
                                  d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                  fill="#000000" fill-rule="nonzero"></path>
                              </g>
                            </svg>
                          </span>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group has-feedback" style="float:left">
              <b class="totalRegistro"> Mostrar </b>
              <button type="button" class="btn btn-default dropdown-toggle countPage  " data-toggle="dropdown" aria-expanded="false">
                {{itemsPage}} <span class="caret"></span>
              </button>
              <ul class="dropdown-menu dropdown-menu-right dropCount kt-pointer" role="menu">
                <li>
                  <a (click)="itemsPage = 10">10</a>
                </li>
                <li>
                  <a (click)="itemsPage = 50">50</a>
                </li>
                <li>
                  <a (click)="itemsPage = 100">100</a>
                </li>
                <li>
                  <a (click)="itemsPage = 500">500</a>
                </li>
              </ul>
              <b class="totalRegistro"> Registros</b>
            </div>
          </div>
          <div class="col-md-5">
            <div class="kt-header__topbar" style="float:right">
              <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" (click)="CarregarListaPaciente()">
                <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Carregar os Pacientes">
                  <span class="kt-header__topbar-icon"><i class="flaticon-refresh"></i></span>
                </div>
              </div>
              <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" (click)="CarregarTelaCadastro()">
                <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Adicionar Novos Pacientes">
                  <span class="kt-header__topbar-icon"><i class="flaticon-plus"></i></span>
                </div>
              </div>
              <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" (click)="Exportar()" [hidden]="VisualizacaoCelular" *ngIf="idPerfil == '3'">
                <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Exportar Dados dos Pacientes">
                  <span class="kt-header__topbar-icon">
                    <i class="flaticon-download">
                    </i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid" *ngIf="MostrarLista">
      <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body kt-portlet__body--fit">
          <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded" id="kt_apps_user_list_datatable">
            <table class="table table-striped- table-hover table-checkable dataTable no-footer dtr-inline" id="kt_table_1">
              <thead>
                <tr role="row">
                  <th class="sorting">Paciente</th>
                  <th class="sorting" [hidden]="VisualizacaoCelular">CPF</th>
                  <th class="sorting" [hidden]="VisualizacaoCelular">Cidade</th>
                  <th class="sorting" [hidden]="VisualizacaoCelular">UF</th>
                  <th class="sorting" [hidden]="VisualizacaoCelular">Celular</th>
                  <th class="sorting" [hidden]="VisualizacaoCelular">Cadastro</th>
                  <th class="sorting">Status</th>
                  <th class="sorting">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr role="row" class="odd" *ngFor="let detalhe of ListaPaciente | filter : Pesquisa.value | paginate: { itemsPerPage: itemsPage, currentPage: p }">
                  <td>{{detalhe.no_paciente}}</td>
                  <td [hidden]="VisualizacaoCelular">{{detalhe.nu_cpf}}</td>
                  <td [hidden]="VisualizacaoCelular">{{detalhe.no_cidade}}</td>
                  <td [hidden]="VisualizacaoCelular">{{detalhe.no_uf}}</td>
                  <td [hidden]="VisualizacaoCelular">{{detalhe.no_celular}}</td>
                  <td [hidden]="VisualizacaoCelular">{{detalhe.dt_cadastro | date : 'dd/MM/yyyy'}}</td>
                  <td>
                    <span class="kt-font-bold {{detalhe.fg_aprovado == 1 ? 'kt-font-primary' : 'kt-font-danger'}}">{{detalhe.fg_aprovado == 1 ? 'Ativo' : 'Inativo'}}</span>
                  </td>
                  <td> <span class="dropdown">
                      <a class="btn btn-sm btn-clean btn-icon btn-icon-md" data-toggle="dropdown" aria-expanded="true">
                        <i class="la la-ellipsis-h"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right kt-pointer">
                        <a class="dropdown-item" (click)="GetPaciente(detalhe.id_paciente)">
                          <i class="la la-edit"></i>
                          Editar Cadastro</a>
                        <a class="dropdown-item" (click)="ExportarPaciente(detalhe.id_paciente)">
                            <i class="la la-print"></i>
                          Ficha do Paciente</a>
                      </div>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="kt-datatable__pager kt-datatable--paging-loaded kt-padding-0">
              <div class="kt-datatable__pager-nav">
                <pagination-controls (pageChange)="p = $event" [maxSize]="VisualizacaoCelular ? 4 : 7" previousLabel="<" nextLabel=">"></pagination-controls>
              </div>
              <div class="kt-datatable__pager-info">
                <span class="kt-datatable__pager-detail qt-pagination">Mostrando {{ (ListaPaciente | filter: Pesquisa.value)?.length }} de {{ListaPaciente.length}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid" *ngIf="!MostrarLista">
      <div class="row">
        <div class="col-lg-12">
          <div class="kt-portlet">
            <form [formGroup]="formCadastroPaciente">
              <div class="kt-portlet__body kt-padding-10">
                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>Nome Completo:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" name="no_paciente" formControlName="no_paciente" class="form-control" placeholder="Digite o Nome do Paciente">
                      <span [hidden]="!f.no_paciente.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_paciente.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>CPF:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" formControlName="nu_cpf" class="form-control" mask="000.000.000-00" placeholder="Digite o CPF" (focusout)="GetCPF()">
                      <span [hidden]="!f.nu_cpf.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.nu_cpf.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>E-Mail:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" formControlName="no_email" class="form-control" placeholder="Digite o E-Mail">
                      <span [hidden]="!f.no_email.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_email.errors }"></i></span></span>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>Data Nascimento:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" class="form-control" id="kt_datepicker_1" readonly="" placeholder="Selecione Data Nascimento" (focusout)="GetDataNascimento()">
                      <span [hidden]="!f.dt_nascimento.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.dt_nascimento.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Telefone Fixo:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" formControlName="no_telefone" class="form-control" mask="00 0000.0000" placeholder="Digite o Telefone">
                      <span [hidden]="!f.no_telefone.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_telefone.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Telefone Celular 1:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" formControlName="no_celular" class="form-control" mask="00 0.0000.0000" placeholder="Digite o Celular 1">
                      <span [hidden]="!f.no_celular.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_celular.errors }"></i></span></span>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>Telefone Celular 2:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" formControlName="no_celular_2" class="form-control" mask="00 0.0000.0000" placeholder="Digite o Celular 2">
                      <span [hidden]="!f.no_celular_2.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_celular_2.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Status:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select placeholder="SELECIONE" formControlName="fg_aprovado" name="fg_aprovado" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                        <ng-option *ngFor="let opt of ListaStatus" [value]="opt.fg_aprovado">
                          <font style="vertical-align: inherit;">{{opt.dsstatus}}</font>
                        </ng-option>
                      </ng-select>
                      <span [hidden]="!f.fg_aprovado.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.fg_aprovado.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4" *ngIf="dt_cadastro != ''">
                    <label>Data de Cadastro:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input class="form-control" placeholder="{{dt_cadastro | date : 'dd/MM/yyyy'}}" disabled="true" />
                    </div>
                  </div>
                </div>
                <div class="kt-subheader  kt-grid__item" id="kt_subheader">
                  <div class="kt-container  kt-container--fluid kt-padding-l-10">
                    <div class="kt-subheader__main">
                      <h4 class="kt-subheader__title">Informações Complementares</h4>
                    </div>
                  </div>
                </div>
                <div class="form-group row separador-borda">
                  <div class="col-lg-4">
                    <label>Endereço:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" formControlName="no_endereco" class="form-control" placeholder="Digite o Endereço">
                      <span [hidden]="!f.no_endereco.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_endereco.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Número:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" formControlName="no_numero" class="form-control" placeholder="Digite o Número">
                      <span [hidden]="!f.no_numero.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_numero.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Complemento:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" formControlName="no_complemento" class="form-control" placeholder="Digite o Complemento">
                      <span [hidden]="!f.no_complemento.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_complemento.errors }"></i></span></span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>CEP:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" formControlName="no_cep" mask="00000-000" class="form-control" placeholder="Digite o CEP">
                      <span [hidden]="!f.no_cep.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_cep.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Bairro:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" formControlName="no_bairro" class="form-control" placeholder="Digite o Bairro">
                      <span [hidden]="!f.no_bairro.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_bairro.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label>Cidade:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <input type="text" formControlName="no_cidade" class="form-control" placeholder="Digite a Cidade">
                      <span [hidden]="!f.no_cidade.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_cidade.errors }"></i></span></span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-lg-4">
                    <label>UF:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select placeholder="SELECIONE" formControlName="no_uf" name="no_uf" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                        <ng-option *ngFor="let opt of ListaUF" [value]="opt.dsuf">
                          <font style="vertical-align: inherit;">{{opt.dsuf}}</font>
                        </ng-option>
                      </ng-select>
                      <span [hidden]="!f.no_uf.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_uf.errors }"></i></span></span>
                    </div>
                  </div>
                  <div class="col-lg-4" [hidden]="true">
                    <label>Tipo de Cadastro:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select placeholder="SELECIONE" formControlName="id_tipo_paciente" name="id_tipo_paciente" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                        <ng-option *ngFor="let opt of ListaTipoPaciente" [value]="opt.id_tipo_paciente">
                          <font style="vertical-align: inherit;">{{opt.no_tipo_paciente}}</font>
                        </ng-option>
                      </ng-select>
                      <span [hidden]="!f.id_tipo_paciente.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.id_tipo_paciente.errors }"></i></span></span>
                    </div>
                  </div>

                </div>

                <div class="kt-subheader  kt-grid__item" id="kt_subheader" [hidden]="idPerfil == '4'">
                  <div class="kt-container  kt-container--fluid kt-padding-l-10">
                    <div class="kt-subheader__main">
                      <h4 class="kt-subheader__title">Informações para Terapias</h4>
                    </div>
                  </div>
                </div>
                <div class="form-group row" style="border-left: 3px solid #ec876b;
                border-bottom: 3px solid #ec876b;
                border-top: 3px solid #ec876b;
                border-right: 3px solid #ec876b;
                border-radius: 10px 10px 10px 10px;
                padding-bottom: 10px;
                padding-top: 10px;">


                <div class="col-lg-4">
                  <label>Valor da Sessão:</label>
                  <div class="kt-input-icon kt-input-icon--right">
                    <input class="form-control" currencyMask formControlName="vl_consulta" placeholder="Digite o Valor Sessão" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',',align:'left' }" />
                    <span [hidden]="!f.vl_consulta.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.vl_consulta.errors }"></i></span></span>
                  </div>
                </div>

                <div class="col-lg-4">
                  <label>Tipo de Pagamento:</label>
                  <div class="kt-input-icon kt-input-icon--right">
                    <ng-select placeholder="SELECIONE" formControlName="id_tipo_pagamento" name="id_tipo_pagamento" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                      <ng-option *ngFor="let opt of ListaTipoPagamento" [value]="opt.id_tipo_pagamento">
                        <font style="vertical-align: inherit;">{{opt.ds_pagamento}}</font>
                      </ng-option>
                    </ng-select>
                    <span [hidden]="!f.id_tipo_pagamento.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.id_tipo_pagamento.errors }"></i></span></span>
                  </div>
                </div>
                <div class="col-lg-4">
                  <label>Condição de Pagamento:</label>
                  <div class="kt-input-icon kt-input-icon--right">
                    <ng-select placeholder="SELECIONE" formControlName="no_condicao" name="no_condicao" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                      <ng-option *ngFor="let opt of ListaCondicao" [value]="opt.no_condicao">
                        <font style="vertical-align: inherit;">{{opt.no_condicao}}</font>
                      </ng-option>
                    </ng-select>
                    <span [hidden]="!f.no_condicao.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_condicao.errors }"></i></span></span>
                  </div>
                </div>
                  <div class="col-lg-4">
                    <label>Reembolso:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select placeholder="SELECIONE" formControlName="fg_reembolso" name="fg_reembolso" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                        <ng-option *ngFor="let opt of ListaReembolso" [value]="opt.fg_reembolso">
                          <font style="vertical-align: inherit;">{{opt.dsstatus}}</font>
                        </ng-option>
                      </ng-select>
                      <span [hidden]="!f.fg_reembolso.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.fg_reembolso.errors }"></i></span></span>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <label>Anexar Contrato:</label>
                    <div class="kt-input-icon kt-input-icon--right arquivoAnexo">
                      <label class="kt-avatar__upload custom-dropzone kt-pointer kt-padding-t-10" data-toggle="kt-tooltip" style="width:100%">
                        <div class="ng-placeholder kt-padding-l-20" style="color: #999;">SELECIONE O ARQUIVO
                        </div>
                        <span class="form-text text-muted kt-margin-t-10" *ngIf="ListaArquivos.length > 0">Arquivo carregado com sucesso!</span>
                        <input type="file" #file placeholder="Choose file" accept=".xlsx, .xls .doc, .pdf" style="display:none;" (change)="uploadDocumento($event, '1')">
                      </label>
                    </div>
                  </div>
                  <div class="col-md-1">
                    <div class="kt-form__actions alinhamento-div-botao">
                      <button type="reset" class="btn btn-primary alinhamento-botao" style="margin-top: 25px;background-color: #5867dd !important;border-color: #5867dd !important;color: #ffffff; !important" (click)="EnviarProcedimento()">Enviar</button>
                    </div>
                  </div>
                  <div class="col-lg-3" *ngIf="formCadastroPaciente.value.dt_envio_contrato != null" style="padding-top: 8px;
    font-weight: 500;
    background: #ffc318;
    height: 35px;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 25px;">
                    <label>Contrato Enviado: {{formCadastroPaciente.value.dt_envio_contrato | date : 'dd/MM/yyyy'}}</label>
                  </div>
                  <div class="col-lg-12">
                    <label>Notas:</label>
                    <div class="kt-input-icon kt-input-icon--right">
                      <textarea type="text" style="height: 135px;" name="no_nota" formControlName="no_nota" class="form-control" placeholder="Digite a Notas"></textarea>
                      <span [hidden]="!f.no_nota.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_nota.errors }"></i></span></span>
                    </div>
                  </div>
                </div>

              </div>
            </form>
            <div *ngIf="idPerfil == '1' || idPerfil == '2'">
              <div class="kt-subheader  kt-grid__item" id="kt_subheader">
                <div class="kt-container  kt-container--fluid kt-padding-l-10">
                  <div class="kt-subheader__main">
                    <h4 class="kt-subheader__title">Cadastro do Plano de Saúde</h4>
                  </div>
                </div>
              </div>
              <form [formGroup]="formCadastroPlano">
                <div class="kt-portlet__body separador-borda">
                  <div class="form-group row">
                    <div class="col-lg-4">
                      <label>Nome da Operadora:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <div class="kt-input-icon kt-input-icon--left">
                          <ng-autocomplete class="form-control" [data]="ListaOperadora" searchKeyword="no_operadora" (selected)='AutoComplete_SelectedItem($event)' (inputChanged)='AutoComplete_Search($event)' [itemTemplate]="itemTemplate"
                            [notFoundTemplate]="notFoundTemplate" [isLoading]='isLoading' placeholder="Digite a Operadora">
                          </ng-autocomplete>
                          <ng-template #itemTemplate let-item>
                            <a [innerHTML]="item.no_operadora"></a>
                          </ng-template>
                          <ng-template #notFoundTemplate let-notFound>
                            <div>Nenhum registro encontrado!</div>
                          </ng-template>
                          <span [hidden]="!c.id_operadora.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': c.id_operadora.errors }"></i></span></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <label>Plano:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <input type="text" class="form-control" formControlName="no_plano" placeholder="Digite o Nome do Plano">
                        <span [hidden]="!c.no_plano.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': c.no_plano.errors }"></i></span></span>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <label>Numero da Carteira:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <input type="text" class="form-control" formControlName="no_carteira" placeholder="Digite o Número da Carteira">
                        <span [hidden]="!c.no_carteira.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': c.no_carteira.errors }"></i></span></span>
                      </div>
                    </div>
                    <div class="col-lg-2">
                      <button type="reset" class="btn btn-primary alinhamento-botao botaoadicionar" (click)="AdicionarPlano()" [disabled]="formCadastroPlano.invalid">Adicionar</button>
                    </div>
                  </div>
                  <div class="form-group row kt-margin-b-10" *ngIf="ListaCarteira.length > 0" [hidden]="VisualizacaoCelular">
                    <div class="col-lg-4">
                      <label><b>Nome da Operadora</b></label>
                    </div>
                    <div class="col-lg-3">
                      <label><b>Nome do Plano</b></label>
                    </div>
                    <div class="col-lg-3">
                      <label><b>Número da Carteira</b></label>
                    </div>
                    <div class="col-lg-1">
                      <label><b>Excluir</b></label>
                    </div>
                  </div>
                  <div class="form-group row kt-margin-b-10" *ngFor="let detalhe of ListaCarteira; let i = index">
                    <div class="col-lg-4 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                      <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                        <label><b><small>Nome da Operadora</small></b></label>
                      </div>
                      <label>{{detalhe.no_operadora}}</label>
                    </div>
                    <div class="col-lg-3 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                      <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                        <label><b><small>Nome do Plano</small></b></label>
                      </div>
                      <label>{{detalhe.no_plano}}</label>
                    </div>
                    <div class="col-lg-3 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                      <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                        <label><b><small>Número da Carteira</small></b></label>
                      </div>
                      <label>{{detalhe.no_carteira}}</label>
                    </div>
                    <div class="col-lg-2">
                      <button type="button" class="btn btn-outline-danger btn-icon botaomax" (click)="ExcluirPlano(i)"><i class="flaticon2-cancel"></i></button>
                    </div>
                  </div>
                </div>
              </form>
              <div class="kt-subheader  kt-grid__item" id="kt_subheader">
                <div class="kt-container  kt-container--fluid kt-padding-l-10">
                  <div class="kt-subheader__main">
                    <h4 class="kt-subheader__title">Vinculo com Cliente</h4>
                  </div>
                </div>
              </div>
              <form [formGroup]="formCadastroCliente">
                <div class="kt-portlet__body separador-borda">
                  <div class="form-group row">
                    <div class="col-lg-4">
                      <label>Nome do Cliente:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <ng-select placeholder="SELECIONE" formControlName="id_cliente" name="id_cliente" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                          <ng-option *ngFor="let opt of ListaCliente" [value]="opt.id_cliente">
                            <font style="vertical-align: inherit;">{{opt.no_fantasia}}</font>
                          </ng-option>
                        </ng-select>
                        <span [hidden]="!d.id_cliente.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': d.id_cliente.errors }"></i></span></span>
                      </div>
                    </div>
                    <div class="col-lg-2">
                      <button type="reset" class="btn btn-primary alinhamento-botao botaoadicionar" (click)="AdicionarCliente()" [disabled]="formCadastroCliente.invalid">Adicionar</button>
                    </div>
                  </div>
                  <div class="form-group row kt-margin-b-10" *ngIf="ListaClientes.length > 0" [hidden]="VisualizacaoCelular">
                    <div class="col-lg-4">
                      <label><b>Nome do Cliente</b></label>
                    </div>
                    <div class="col-lg-1">
                      <label><b>Excluir</b></label>
                    </div>
                  </div>
                  <div class="form-group row kt-margin-b-10" *ngFor="let detalhe of ListaClientes; let i = index">
                    <div class="col-lg-4 {{VisualizacaoCelular ? 'kt-margin-t-0' : 'kt-margin-t-10'}}">
                      <div class="kt-input-icon kt-input-icon--right" [hidden]="!VisualizacaoCelular">
                        <label><b><small>Nome do Cliente</small></b></label>
                      </div>
                      <label>{{detalhe.no_fantasia}}</label>
                    </div>
                    <div class="col-lg-2">
                      <button type="button" class="btn btn-outline-danger btn-icon botaomax" (click)="ExcluirCliente(i)"><i class="flaticon2-cancel"></i></button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="kt-portlet__foot">
              <div class="kt-form__actions alinhamento-div-botao">
                <button type="reset" class="btn btn-primary alinhamento-botao" [disabled]="formCadastroPaciente.invalid" (click)="CrudPaciente()">{{formCadastroPaciente.value.id_paciente == '' ? 'Cadastrar' : 'Atualizar'}}</button>
                <button type="reset" class="btn btn-secondary" (click)="MostrarLista = !MostrarLista">Cancelar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
