<div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid" *ngIf=" idFuncionalidade == '0'">
  <div class="row">
    <div class="col-lg-3">
      <div class="kt-portlet kt-iconbox kt-margin-t-10" style="{{Visualizacao == 1 ? 'background: rgb(222 240 243 / 47%);' : ''}}">
        <div class="kt-portlet__body" style="height: 60px;">
          <!-- height: 120px; -->
          <div class="kt-iconbox__body">
            <div class="kt-iconbox__icon">
              <i class="kt-menu__link-icon fa fa-dollar-sign" style="font-size: 35px;color: #ffb822;padding-left: 10px;"></i>
            </div>
            <div class="kt-iconbox__desc" (click)="CarregarTipoVisualizacao(1)">
              <h3 class="kt-iconbox__title">
                <a class="kt-link">REEMBOLSOS</a>
              </h3>
              <div class="kt-iconbox__content">
                Departamento de Reembolsos
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="kt-portlet kt-iconbox kt-margin-t-10" style="{{Visualizacao == 2 ? 'background: rgb(222 240 243 / 47%);' : ''}}">
        <div class="kt-portlet__body" style="height: 60px;">
          <!-- height: 120px; -->
          <div class="kt-iconbox__body">
            <div class="kt-iconbox__icon">
              <i class="kt-menu__link-icon fa fa-clinic-medical" style="font-size: 35px;color: #ffb822;"></i>
            </div>
            <div class="kt-iconbox__desc" (click)="CarregarTipoVisualizacao(2)">
              <h3 class="kt-iconbox__title">
                <a class="kt-link">TERAPIA</a>
              </h3>
              <div class="kt-iconbox__content">
                Departamento de Terapia
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid" *ngIf=" idFuncionalidade != '0'">
  <div class="row" *ngIf=" idFuncionalidade == '1'">
    <div class="col-lg-3" *ngIf="idPerfil != '2'">
      <div class="kt-portlet kt-iconbox kt-margin-t-10" style="{{Visualizacao == 1 ? 'background: rgb(222 240 243 / 47%);' : ''}}">
        <div class="kt-portlet__body" style="height: 60px;">
          <!-- height: 120px; -->
          <div class="kt-iconbox__body">
            <div class="kt-iconbox__icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <path
                    d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z"
                    fill="#000000" opacity="0.3"></path>
                  <path
                    d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z"
                    fill="#000000"></path>
                </g>
              </svg>
            </div>
            <div class="kt-iconbox__desc" (click)="CarregarVisualizacao_1()">
              <h3 class="kt-iconbox__title">
                <a class="kt-link">Relatório Gerencial</a>
              </h3>
              <div class="kt-iconbox__content">
                Gráficos de Atendimento
              </div>
              <!-- <div class="kt-iconbox__content kt-padding-t-10 posicaoTexto">
                <a class="btn btn-label-success btn-bold btn-sm" style="float: right;">+ Informações</a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3" *ngIf="idPerfil != '3'">
      <div class="kt-portlet kt-iconbox kt-margin-t-10" style="{{Visualizacao == 2 ? 'background: rgb(222 240 243 / 47%);' : ''}}">
        <div class="kt-portlet__body" style="height: 60px;">
          <!-- height: 120px; -->
          <div class="kt-iconbox__body">
            <div class="kt-iconbox__icon">
              <i class="la la-undo-alt" style="font-size: 30px;padding-top: 10px;color: #f90;"></i>
            </div>
            <div class="kt-iconbox__desc" (click)="CarregarVisualizacao_2()">
              <h3 class="kt-iconbox__title">
                <a class="kt-link">Reembolsos</a>
              </h3>
              <div class="kt-iconbox__content">
                Informações dos Reembolsos
              </div>
              <!-- <div class="kt-iconbox__content kt-padding-t-10 posicaoTexto">
                <a class="btn btn-label-success btn-bold btn-sm" style="float: right;">+ Informações</a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3" *ngIf="idPerfil != '3'">
      <div class="kt-portlet kt-iconbox kt-margin-t-10" style="{{Visualizacao == 3 ? 'background: rgb(222 240 243 / 47%);' : ''}}">
        <div class="kt-portlet__body" style="height: 60px;">
          <!-- height: 120px; -->
          <div class="kt-iconbox__body">
            <div class="kt-iconbox__icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <path
                    d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
                    fill="#000000" opacity="0.3"></path>
                  <path
                    d="M10.875,15.75 C10.6354167,15.75 10.3958333,15.6541667 10.2041667,15.4625 L8.2875,13.5458333 C7.90416667,13.1625 7.90416667,12.5875 8.2875,12.2041667 C8.67083333,11.8208333 9.29375,11.8208333 9.62916667,12.2041667 L10.875,13.45 L14.0375,10.2875 C14.4208333,9.90416667 14.9958333,9.90416667 15.3791667,10.2875 C15.7625,10.6708333 15.7625,11.2458333 15.3791667,11.6291667 L11.5458333,15.4625 C11.3541667,15.6541667 11.1145833,15.75 10.875,15.75 Z"
                    fill="#000000"></path>
                  <path
                    d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
                    fill="#000000"></path>
                </g>
              </svg>
            </div>
            <div class="kt-iconbox__desc" (click)="CarregarVisualizacao_3()">
              <h3 class="kt-iconbox__title">
                <a class="kt-link">Prévias</a>
              </h3>
              <div class="kt-iconbox__content ">
                Informações das Prévias
              </div>
              <!-- <div class="kt-iconbox__content kt-padding-t-10 posicaoTexto">
                <a class="btn btn-label-success btn-bold btn-sm" style="float: right;">+ Informações</a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3" *ngIf="idPerfil != '2'">
      <div class="kt-portlet kt-iconbox kt-margin-t-10" style="{{Visualizacao == 4 ? 'background: rgb(222 240 243 / 47%);' : ''}}">
        <div class="kt-portlet__body" style="height: 60px;">
          <!-- height: 120px; -->
          <div class="kt-iconbox__body">
            <div class="kt-iconbox__icon">
              <i class="la la-file-invoice-dollar" style="font-size: 30px;padding-top: 10px;color: #f90;"></i>
            </div>
            <div class="kt-iconbox__desc" (click)="CarregarVisualizacao_4()">
              <h3 class="kt-iconbox__title">
                <a class="kt-link">Boletim de Medição</a>
              </h3>
              <div class="kt-iconbox__content">
                Gráfico Financeiro
              </div>
              <!-- <div class="kt-iconbox__content kt-padding-t-10 posicaoTexto">
                <a class="btn btn-label-success btn-bold btn-sm" style="float: right;">+ Informações</a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="idFuncionalidade == '2'">
    <div class="col-lg-3">
      <div class="kt-portlet kt-iconbox kt-margin-t-10" style="{{Visualizacao == 5 ? 'background: rgb(222 240 243 / 47%);' : ''}}">
        <div class="kt-portlet__body" style="height: 60px;">
          <!-- height: 120px; -->
          <div class="kt-iconbox__body">
            <div class="kt-iconbox__icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"></rect>
                  <path
                    d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z"
                    fill="#000000" opacity="0.3"></path>
                  <path
                    d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z"
                    fill="#000000"></path>
                </g>
              </svg>
            </div>
            <div class="kt-iconbox__desc" (click)="CarregarVisualizacao_5()">
              <h3 class="kt-iconbox__title">
                <a class="kt-link">Relatório Gerencial</a>
              </h3>
              <div class="kt-iconbox__content">
                Gráficos de Atendimento
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3" *ngIf="idPerfil == '1' || idPerfil == '2' ">
      <div class="kt-portlet kt-iconbox kt-margin-t-10" style="{{Visualizacao == 6 ? 'background: rgb(222 240 243 / 47%);' : ''}}">
        <div class="kt-portlet__body" style="height: 60px;">
          <!-- height: 120px; -->
          <div class="kt-iconbox__body">
            <div class="kt-iconbox__icon">
              <i class="la la-headset" style="font-size: 30px;padding-top: 10px;color: #f90;"></i>
            </div>
            <div class="kt-iconbox__desc" (click)="CarregarVisualizacao_6()">
              <h3 class="kt-iconbox__title">
                <a class="kt-link">Novos Pacientes</a>
              </h3>
              <div class="kt-iconbox__content ">
                Primeiro Contato (PCP)
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3" *ngIf="idPerfil == '1' || idPerfil == '2' ">
      <div class="kt-portlet kt-iconbox kt-margin-t-10" style="{{Visualizacao == 7 ? 'background: rgb(222 240 243 / 47%);' : ''}}">
        <div class="kt-portlet__body" style="height: 60px;">
          <!-- height: 120px; -->
          <div class="kt-iconbox__body">
            <div class="kt-iconbox__icon">
              <i class="la la-handshake-o" style="font-size: 30px;padding-top: 10px;color: #f90;"></i>
            </div>
            <div class="kt-iconbox__desc" (click)="CarregarVisualizacao_7()">
              <h3 class="kt-iconbox__title">
                <a class="kt-link">Gestão de Clientes</a>
              </h3>
              <div class="kt-iconbox__content ">
                Status da Cobrança
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3" *ngIf="idPerfil == '1' || idPerfil == '2' ">
      <div class="kt-portlet kt-iconbox kt-margin-t-10" style="{{Visualizacao == 8 ? 'background: rgb(222 240 243 / 47%);' : ''}}">
        <div class="kt-portlet__body" style="height: 60px;">
          <!-- height: 120px; -->
          <div class="kt-iconbox__body">
            <div class="kt-iconbox__icon">
              <i class="la la-file-invoice-dollar" style="font-size: 30px;padding-top: 10px;color: #f90;"></i>
            </div>
            <div class="kt-iconbox__desc" (click)="CarregarVisualizacao_8()">
              <h3 class="kt-iconbox__title">
                <a class="kt-link">Gestão de Pacientes</a>
              </h3>
              <div class="kt-iconbox__content ">
                Status de Pagamento
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf=" idFuncionalidade != '0'">
  <div class="row separador-borda" *ngIf="Visualizacao == 1">
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid kt-padding-t-10" *ngIf="idPerfil != '3'">
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group row">
            <div class="col-lg-3">
              <div class="kt-input-icon kt-input-icon--right">
                <ng-select placeholder="Selecione o Cliente" name="id_status" [(ngModel)]="id_cliente" (change)="RecarregarGraficos()" notFoundText="Nenhum item encontrado" class="form-control ng-select kt-padding-0">
                  <ng-option *ngFor="let opt of ListaCliente" [value]="opt.id_cliente">
                    <font style="vertical-align: inherit;">{{opt.no_fantasia}}</font>
                  </ng-option>
                </ng-select>
              </div>
            </div>
            <!-- <div class="col-lg-3>
            <div class="kt-input-icon kt-input-icon--right">
              <input type="text" class="form-control" id="kt_datepicker_1" readonly="" (focusout)="GetDataInicio()" placeholder="Selecione Inicio">
            </div>
          </div>
          <div class="col-lg-3">
            <div class="kt-input-icon kt-input-icon--right">
              <input type="text" class="form-control" id="kt_datepicker_2" readonly="" (focusout)="GetDataFim()" placeholder="Selecione Fim">
            </div>
          </div>
          <div class="col-lg-1">
            <div class="kt-input-icon kt-input-icon--right">
              <button type="reset" class="btn btn-primary alinhamento-botao botaoadicionar">Pesquisar</button>
            </div>
          </div>-->
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 kt-padding-t-10" style="border-right: {{VisualizacaoCelular ? '0px' : '1px'}} solid;">
      <div class="row">
        <div class="col-md-12">
          <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div class="kt-portlet kt-portlet--mobile">
              <div class="kt-subheader  kt-grid__item" id="kt_subheader">
                <div class="kt-container  kt-container--fluid ">
                  <div class="kt-subheader__main">
                    <h3 class="kt-subheader__title">Relatório Mensal {{anoMes}}</h3>
                  </div>
                  <div class="col-lg-5 kt-padding-t-10">
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select placeholder="Selecione o Período" name="periodo" [(ngModel)]="periodo" (change)="RecarregarDia()" notFoundText="Nenhum item encontrado" class="form-control ng-select kt-padding-0">
                        <ng-option *ngFor="let opt of ListaPeriodo" [value]="opt.periodo">
                          <font style="vertical-align: inherit;">{{opt.periodo}}</font>
                        </ng-option>
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="kt-portlet__body kt-portlet__body--fit">
                <p style="font-weight: 600;padding-left: 30px;">
                  Total Faturado por Reembolso: R${{total_dia_aberto.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2  })}} <br />
                  Total de Pacientes Atendidos por Reembolso: {{total_dia_reembolso}} <br />
                  <!-- Total de Faturamento em Aberto: R$ {{total_dia_aberto.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2  })}} -->
                </p>
                <div id="grafico-atendimento-mes" style="width:100%; height:300px;"></div>
                <p style="font-weight: 600;padding-left: 30px;margin-top: -30px;text-align: center;">
                  ( Quantidades de Pacientes x Periodos )
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 kt-padding-t-10">
      <div class="row">
        <div class="col-md-12">
          <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div class="kt-portlet kt-portlet--mobile">
              <div class="kt-subheader  kt-grid__item" id="kt_subheader">
                <div class="kt-container  kt-container--fluid ">
                  <div class="kt-subheader__main">
                    <h3 class="kt-subheader__title">Relatório Anual - {{ano}}</h3>
                  </div>
                  <div class="col-lg-5 kt-padding-t-10">
                    <div class="kt-input-icon kt-input-icon--right">
                      <ng-select placeholder="Selecione o Ano" name="ano" [(ngModel)]="ano" (change)="RecarregarMes()" notFoundText="Nenhum item encontrado" class="form-control ng-select kt-padding-0">
                        <ng-option *ngFor="let opt of ListaAno" [value]="opt.periodo">
                          <font style="vertical-align: inherit;">{{opt.periodo}}</font>
                        </ng-option>
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="kt-portlet__body kt-portlet__body--fit">
                <p style="font-weight: 600;padding-left: 30px;">
                  Total Faturado por Reembolso: R${{vl_total_mensal.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2  })}} <br />
                  Total de Pacientes Atendidos por Reembolso: {{total_mensal_reembolso}} <br />
                  Total de Faturamento Previsto: R$ {{total_mensal_aberto.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2  })}}
                </p>
                <div id="grafico-atendimento-mes_geral" style="width:100%; height:300px;"></div>
                <p style="font-weight: 600;padding-left: 30px;margin-top: -30px;text-align: center;">
                  ( Quantidades de Pacientes x Periodos )
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 kt-padding-t-10" style="border-right: {{VisualizacaoCelular ? '0px' : '1px'}} solid;">
      <div class="row">
        <div class="col-md-12">
          <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div class="kt-portlet kt-portlet--mobile">
              <div class="kt-subheader  kt-grid__item" id="kt_subheader">
                <div class="kt-container  kt-container--fluid ">
                  <div class="kt-subheader__main">
                    <h3 class="kt-subheader__title">Ranking de Convênios - {{anoMes}}</h3>
                  </div>
                </div>
              </div>
              <div class="kt-portlet__body kt-portlet__body--fit">
                <div id="grafico-atendimento-acumulado" style="width:100%;height:300px;"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 kt-padding-t-10">
      <div class="row">
        <div class="col-md-12">
          <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div class="kt-portlet kt-portlet--mobile">
              <div class="kt-subheader  kt-grid__item" id="kt_subheader">
                <div class="kt-container  kt-container--fluid ">
                  <div class="kt-subheader__main">
                    <h3 class="kt-subheader__title">Ranking de Convênios - {{anoTexto}}</h3>
                  </div>
                </div>
              </div>
              <div class="kt-portlet__body kt-portlet__body--fit">
                <div id="grafico-atendimento-acumulado_geral" style="width:100%;height:300px;"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row separador-borda" *ngIf="Visualizacao == 2">
    <div class="col-md-12 kt-padding-t-10">
      <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
          <div class="kt-subheader  kt-grid__item" id="kt_subheader">
            <div class="kt-container  kt-container--fluid ">
              <div class="kt-subheader__main">
                <h3 class="kt-subheader__title">Informações de Reembolsos</h3>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div class="kt-portlet kt-portlet--mobile">

                  <div class="kt-subheader__main">
                    <div class="kt-subheader__group" id="kt_subheader_search">
                      <div class="row kt-padding-l-10" style="margin-bottom: 20px;">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 kt-padding-0">
                          <form id="kt_subheader_search_form">
                            <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-0">
                              <input type="search" class="form-control" placeholder="Pesquisar" name="generalSearch" #PesquisaReembolso ngModel (keypress)="p = 1">
                              <span class="kt-input-icon__icon kt-input-icon__icon--right">
                                <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                      <rect x="0" y="0" width="24" height="24"></rect>
                                      <path
                                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                        fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                      <path
                                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                        fill="#000000" fill-rule="nonzero"></path>
                                    </g>
                                  </svg>
                                </span>
                              </span>
                            </div>
                          </form>
                        </div>
                        <div class="col-lg-3">
                          <div class="kt-input-icon kt-input-icon--right">
                            <ng-select placeholder="Filtro de Cores" name="id_cor" (change)="FiltroCoresReembolso($event)" notFoundText="Nenhum item encontrado" class="form-control ng-select kt-padding-0">
                              <ng-option *ngFor="let opt of ListaCor" [value]="opt.id" style="background: red !important;">
                                <font style="vertical-align: inherit;" title="{{opt.cor}}">{{opt.cor}}</font>
                              </ng-option>
                            </ng-select>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-group row">
                            <label class="col-4 col-form-label">Concluídos</label>
                            <div class="col-6">
                              <span class="kt-switch kt-switch--sm kt-switch--icon">
                                <label>
                                  <input type="checkbox" name="concluido" (click)="GetConcluidoReembolso()">
                                  <span></span>
                                </label>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group has-feedback" style="float:left">
                            <b class="totalRegistro"> Mostrar </b>
                            <button type="button" class="btn btn-default dropdown-toggle countPage  " data-toggle="dropdown" aria-expanded="false">
                              {{itemsPageR}} <span class="caret"></span>
                            </button>
                            <ul class="dropdown-menu dropdown-menu-right dropCount kt-pointer" role="menu">
                              <li>
                                <a (click)="itemsPageR = 10">10</a>
                              </li>
                              <li>
                                <a (click)="itemsPageR = 50">50</a>
                              </li>
                              <li>
                                <a (click)="itemsPageR = 100">100</a>
                              </li>
                              <li>
                                <a (click)="itemsPageR = 500">500</a>
                              </li>
                            </ul>
                            <b class="totalRegistro"> Registros</b>
                          </div>
                        </div>
                        <div class="kt-header__topbar" style="right: 15px;position: absolute;">
                          <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle">
                            <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Adicionar Novo Procedimento" (click)="CarregarReembolso()">
                              <span class="kt-header__topbar-icon"><i class="flaticon-refresh"></i></span>
                            </div>
                          </div>
                          <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" [hidden]="VisualizacaoCelular" *ngIf="idPerfil == '3'">
                            <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" (click)="ExportarReembolso()" data-content="Exportar Dados dos Reembolsos">
                              <span class="kt-header__topbar-icon">
                                <i class="flaticon-download">
                                </i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="kt-portlet__body kt-portlet__body--fit">
                    <div class="tab-pane active kt-margin-t-10 kt-margin-b-10" id="kt_widget31_tab1_content" [hidden]="VisualizacaoCelular">
                      <div class="tab-pane active" id="kt_widget4_tab1_content">
                        <div class="kt-widget4">
                          <div class="kt-widget4__item">
                            <div class="row col kt-margin-0">
                              <div class="col-md-4">
                                <div class="kt-widget4__info">
                                  <a class="kt-widget4__username">
                                    Paciente / Cliente
                                  </a>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="kt-widget4__info">
                                  <a class="kt-widget4__username">
                                    Status / SLA
                                  </a>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="kt-widget4__info">
                                  <a class="kt-widget4__username">
                                    Data Status / Data Cadastro
                                  </a>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="kt-widget4__info">
                                  <a class="kt-widget4__username">
                                    Operadora
                                  </a>
                                </div>
                              </div>
                              <div class="col-md-1">
                                <div class="kt-widget4__info">
                                  <a class="kt-widget4__username">
                                    Ação
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="kt-portlet__body" *ngFor="let detalhe of ListaReembolso | filter : PesquisaReembolso.value | paginate: { itemsPerPage: itemsPageR, currentPage: p }">
                      <div class="tab-content" style="background-color: {{detalhe.background}};border-radius:5px">
                        <div class="tab-pane active kt-margin-t-10 kt-margin-b-10" id="kt_widget31_tab1_content">
                          <div class="tab-pane active" id="kt_widget4_tab1_content">
                            <div class="kt-widget4">
                              <div class="kt-widget4__item">
                                <div class="row col kt-margin-0">
                                  <div class="col-md-4">
                                    <div class="kt-widget4__info">
                                      <a class="kt-widget4__username">
                                        {{detalhe.no_paciente}}
                                      </a>
                                      <p class="kt-widget4__text">
                                        {{detalhe.no_cliente}}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="col-md-2">
                                    <div class="kt-widget4__info">
                                      <a class="kt-widget4__username">
                                        {{detalhe.no_status}}
                                      </a>
                                      <p class="kt-widget4__text">
                                        {{detalhe.aging}} {{detalhe.aging == null ? '' : 'dia'}}{{detalhe.aging > 1 ? 's' : ''}}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="col-md-2">
                                    <div class="kt-widget4__info">
                                      <a class="kt-widget4__username">
                                        {{detalhe.dt_ultima_atualizacao | date : 'dd/MM/yyyy'}}
                                      </a>
                                      <p class="kt-widget4__text">
                                        {{detalhe.dt_registro | date : 'dd/MM/yyyy'}}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="kt-widget4__info">
                                      <p class="kt-widget4__text">
                                        {{detalhe.no_plano}}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="col-md-1 kt-pointer">
                                    <a (click)="Redirect('Reembolso', '2')" [routerLink]="['/Reembolso', detalhe.id_procedimento]" class="btn {{detalhe.css_registro}} kt-padding-r-10 kt-padding-l-10">Detalhes</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="kt-portlet__body kt-portlet__body--fit">
                      <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded" id="kt_apps_user_list_datatable">
                        <div class="kt-datatable__pager kt-datatable--paging-loaded kt-padding-0">
                          <div class="kt-datatable__pager-nav">
                            <pagination-controls (pageChange)="p = $event" [maxSize]="VisualizacaoCelular ? 4 : 7" previousLabel="<" nextLabel=">"></pagination-controls>
                          </div>
                          <div class="kt-datatable__pager-info">
                            <span class="kt-datatable__pager-detail">Mostrando {{ (ListaReembolso | filter: PesquisaReembolso.value)?.length }} de {{ListaReembolsoCompleta.length}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row separador-borda" *ngIf="Visualizacao == 3">
    <div class="col-md-12 kt-padding-t-10">
      <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
          <div class="kt-subheader  kt-grid__item" id="kt_subheader">
            <div class="kt-container  kt-container--fluid ">
              <div class="kt-subheader__main">
                <h3 class="kt-subheader__title">Informações de Prévia</h3>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div class="kt-portlet kt-portlet--mobile">

                  <div class="kt-subheader__main">
                    <div class="kt-subheader__group" id="kt_subheader_search">
                      <div class="row kt-padding-l-10" style="margin-bottom: 20px;">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 kt-padding-0">
                          <form id="kt_subheader_search_form">
                            <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-0">
                              <input type="search" class="form-control" placeholder="Pesquisar" name="generalSearch" #PesquisaPrevia ngModel (keypress)="p = 1">
                              <span class="kt-input-icon__icon kt-input-icon__icon--right">
                                <span>
                                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                      <rect x="0" y="0" width="24" height="24"></rect>
                                      <path
                                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                        fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                      <path
                                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                        fill="#000000" fill-rule="nonzero"></path>
                                    </g>
                                  </svg>
                                </span>
                              </span>
                            </div>
                          </form>
                        </div>
                        <div class="col-lg-3">
                          <div class="kt-input-icon kt-input-icon--right">
                            <ng-select placeholder="Filtro de Cores" name="id_cor" (change)="FiltroCoresPrevia($event)" notFoundText="Nenhum item encontrado" class="form-control ng-select kt-padding-0">
                              <ng-option *ngFor="let opt of ListaCorPrevia" [value]="opt.id" style="background: red !important;">
                                <font style="vertical-align: inherit;" title="{{opt.cor}}">{{opt.cor}}</font>
                              </ng-option>
                            </ng-select>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group has-feedback" style="float:left">
                            <b class="totalRegistro"> Mostrar </b>
                            <button type="button" class="btn btn-default dropdown-toggle countPage  " data-toggle="dropdown" aria-expanded="false">
                              {{itemsPageP}} <span class="caret"></span>
                            </button>
                            <ul class="dropdown-menu dropdown-menu-right dropCount kt-pointer" role="menu">
                              <li>
                                <a (click)="itemsPageP = 10">10</a>
                              </li>
                              <li>
                                <a (click)="itemsPageP = 50">50</a>
                              </li>
                              <li>
                                <a (click)="itemsPageP = 100">100</a>
                              </li>
                              <li>
                                <a (click)="itemsPageP = 500">500</a>
                              </li>
                            </ul>
                            <b class="totalRegistro"> Registros</b>
                          </div>
                        </div>
                        <div class="kt-header__topbar" style="right: 15px;position: absolute;">
                          <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle">
                            <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Adicionar Novo Procedimento" (click)="CarregarReembolso()">
                              <span class="kt-header__topbar-icon"><i class="flaticon-refresh"></i></span>
                            </div>
                          </div>
                          <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" [hidden]="VisualizacaoCelular" *ngIf="idPerfil == '3'">
                            <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" (click)="ExportarPrevia()" data-content="Exportar Dados das Previas">
                              <span class="kt-header__topbar-icon">
                                <i class="flaticon-download">
                                </i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="kt-portlet__body kt-portlet__body--fit">
                    <div class="tab-pane active kt-margin-t-10 kt-margin-b-10" id="kt_widget31_tab1_content" [hidden]="VisualizacaoCelular">
                      <div class="tab-pane active" id="kt_widget4_tab1_content">
                        <div class="kt-widget4">
                          <div class="kt-widget4__item">
                            <div class="row col kt-margin-0">
                              <div class="col-md-4">
                                <div class="kt-widget4__info">
                                  <a class="kt-widget4__username">
                                    Paciente / Cliente
                                  </a>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="kt-widget4__info">
                                  <a class="kt-widget4__username">
                                    Status / SLA
                                  </a>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="kt-widget4__info">
                                  <a class="kt-widget4__username">
                                    Data Status / Data Cadastro
                                  </a>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="kt-widget4__info">
                                  <a class="kt-widget4__username">
                                    Operadora
                                  </a>
                                </div>
                              </div>
                              <div class="col-md-1">
                                <div class="kt-widget4__info">
                                  <a class="kt-widget4__username">
                                    Ação
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="kt-portlet__body" *ngFor="let detalhe of ListaPrevia | filter : PesquisaPrevia.value | paginate: { itemsPerPage: itemsPageP, currentPage: p }">
                      <div class="tab-content" style="background-color: {{detalhe.background}};border-radius:5px">
                        <div class="tab-pane active kt-margin-t-10 kt-margin-b-10" id="kt_widget31_tab1_content">
                          <div class="tab-pane active" id="kt_widget4_tab1_content">
                            <div class="kt-widget4">
                              <div class="kt-widget4__item">
                                <div class="row col kt-margin-0">
                                  <div class="col-md-4">
                                    <div class="kt-widget4__info">
                                      <a class="kt-widget4__username">
                                        {{detalhe.no_paciente}}
                                      </a>
                                      <p class="kt-widget4__text">
                                        {{detalhe.no_cliente}}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="col-md-2">
                                    <div class="kt-widget4__info">
                                      <a class="kt-widget4__username">
                                        {{detalhe.no_status}}
                                      </a>
                                      <p class="kt-widget4__text">
                                        {{detalhe.aging}} {{detalhe.aging == null ? '' : 'dia'}}{{detalhe.aging > 1 ? 's' : ''}}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="col-md-2">
                                    <div class="kt-widget4__info">
                                      <a class="kt-widget4__username">
                                        {{detalhe.dt_ultima_atualizacao | date : 'dd/MM/yyyy'}}
                                      </a>
                                      <p class="kt-widget4__text">
                                        {{detalhe.dt_registro | date : 'dd/MM/yyyy'}}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="col-md-3">
                                    <div class="kt-widget4__info">
                                      <p class="kt-widget4__text">
                                        {{detalhe.no_plano}}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="col-md-1 kt-pointer">
                                    <a (click)="Redirect('Reembolso', '3')" [routerLink]="['/Reembolso', detalhe.id_procedimento]" class="btn {{detalhe.css_registro}} kt-padding-r-10 kt-padding-l-10">Detalhes</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="kt-portlet__body kt-portlet__body--fit">
                      <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded" id="kt_apps_user_list_datatable">
                        <div class="kt-datatable__pager kt-datatable--paging-loaded kt-padding-0">
                          <div class="kt-datatable__pager-nav">
                            <pagination-controls (pageChange)="p = $event" [maxSize]="VisualizacaoCelular ? 4 : 7" previousLabel="<" nextLabel=">"></pagination-controls>
                          </div>
                          <div class="kt-datatable__pager-info">
                            <span class="kt-datatable__pager-detail">Mostrando {{ (ListaPrevia | filter: PesquisaPrevia.value)?.length }} de {{ListaPrevia.length}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row separador-borda" *ngIf="Visualizacao == 4">
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid kt-padding-t-10" *ngIf="idPerfil != '3'">
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group row">
            <div class="col-lg-3">
              <div class="kt-input-icon kt-input-icon--right">
                <ng-select placeholder="Selecione o Cliente" name="id_status" [(ngModel)]="id_cliente" (change)="RecarregarVisualizacao_4()" notFoundText="Nenhum item encontrado" class="form-control ng-select kt-padding-0">
                  <ng-option *ngFor="let opt of ListaCliente" [value]="opt.id_cliente">
                    <font style="vertical-align: inherit;">{{opt.no_fantasia}}</font>
                  </ng-option>
                </ng-select>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="kt-input-icon kt-input-icon--right">
                <ng-select placeholder="Selecione o Ano" name="ano" [(ngModel)]="ano" (change)="RecarregarVisualizacao_4()" notFoundText="Nenhum item encontrado" class="form-control ng-select kt-padding-0">
                  <ng-option *ngFor="let opt of ListaAno" [value]="opt.periodo">
                    <font style="vertical-align: inherit;">{{opt.periodo}}</font>
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 kt-padding-t-10">
      <div class="row">
        <div class="col-md-12">
          <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div class="kt-portlet kt-portlet--mobile">
              <div class="kt-subheader  kt-grid__item" id="kt_subheader">
                <div class="kt-container  kt-container--fluid ">
                  <div class="kt-subheader__main">
                    <h3 class="kt-subheader__title">Boletim de de Medição de Serviço</h3>
                  </div>
                </div>
              </div>
              <div class="kt-portlet__body kt-portlet__body--fit">
                <div id="grafico-bms" style="width:100%; height:300px;"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row col" *ngIf="idPerfil == '1' || idPerfil == '3'">

      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <form id="kt_subheader_search_form">
          <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-0">
            <input type="search" class="form-control" placeholder="Pesquisar" name="generalSearch" #PesquisaBMS ngModel (keypress)="p = 1">
            <span class="kt-input-icon__icon kt-input-icon__icon--right">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"></rect>
                    <path
                      d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                      fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                    <path
                      d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                      fill="#000000" fill-rule="nonzero"></path>
                  </g>
                </svg>
              </span>
            </span>
          </div>
        </form>
      </div>
      <div class="col-md-3">
        <div class="form-group has-feedback" style="float:left">
          <b class="totalRegistro"> Mostrar </b>
          <button type="button" class="btn btn-default dropdown-toggle countPage  " data-toggle="dropdown" aria-expanded="false">
            {{itemsPageB}} <span class="caret"></span>
          </button>
          <ul class="dropdown-menu dropdown-menu-right dropCount kt-pointer" role="menu">
            <li>
              <a (click)="itemsPageB = 10">10</a>
            </li>
            <li>
              <a (click)="itemsPageB = 50">50</a>
            </li>
            <li>
              <a (click)="itemsPageB = 100">100</a>
            </li>
            <li>
              <a (click)="itemsPageB = 500">500</a>
            </li>
          </ul>
          <b class="totalRegistro"> Registros</b>
        </div>
      </div>
      <div class="col-md-12 kt-padding-t-10">
        <div class="kt-portlet__body kt-portlet__body--fit">
          <div class="tab-pane active kt-margin-t-10 kt-margin-b-10" id="kt_widget31_tab1_content" [hidden]="VisualizacaoCelular">
            <div class="tab-pane active" id="kt_widget4_tab1_content">
              <div class="kt-widget4">
                <div class="kt-widget4__item">
                  <div class="row col">
                    <div class="col-md-4">
                      <div class="kt-widget4__info">
                        <a class="kt-widget4__username" style="font-weight: 600;">
                          Paciente / Cliente
                        </a>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="kt-widget4__info">
                        <a class="kt-widget4__username" style="font-weight: 600;">
                          Operadora / Valor BMS
                        </a>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="kt-widget4__info">
                        <a class="kt-widget4__username" style="font-weight: 600;">
                          Tipo Processo / Tuss
                        </a>
                      </div>
                    </div>
                    <div class="col-md-1 kt-pointer">
                      <div class="kt-widget4__info">
                        <a class="kt-widget4__username" style="font-weight: 600;">
                          Pagamento Efetuado
                        </a>
                      </div>
                    </div>
                    <div class="col-md-1 kt-pointer">
                      <div class="kt-widget4__info">
                        <a class="kt-widget4__username" style="font-weight: 600;">
                          Ação
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="kt-portlet__body" *ngFor="let detalhe of ListaDetalheGraficoBMS | filter : PesquisaBMS.value | paginate: { itemsPerPage: itemsPageB, currentPage: p }; let i = index">
            <div class="tab-content">
              <div class="tab-pane active kt-margin-t-10 kt-margin-b-10" id="kt_widget31_tab1_content">
                <div class="tab-pane active" id="kt_widget4_tab1_content">
                  <div class="kt-widget4">
                    <div class="kt-widget4__item">
                      <div class="row col">
                        <div class="col-md-4">
                          <div class="kt-widget4__info">
                            <a class="kt-widget4__username">
                              {{detalhe.no_paciente}}
                            </a>
                            <p class="kt-widget4__text">
                              {{detalhe.no_razaosocial}}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="kt-widget4__info">
                            <a class="kt-widget4__username">
                              {{detalhe.no_operadora}}
                            </a>
                            <p class="kt-widget4__text">
                              R${{detalhe.vl_pagamento.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2  })}}
                            </p>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="kt-widget4__info">
                            <p class="kt-widget4__text">
                              {{detalhe.no_processo}}
                            </p>
                          </div>
                          <p class="kt-widget4__text">
                            {{detalhe.no_tuss}}
                          </p>
                        </div>
                        <div class="col-md-1 kt-pointer">
                          {{detalhe.dt_registro | date : 'dd/MM/yyyy'}}
                        </div>
                        <div class="col-md-1 kt-pointer">
                          <span class="dropdown">
                            <a class="btn btn-sm btn-clean btn-icon btn-icon-md" (click)="SubDetalheBMS(i, detalhe.id_procedimento)">
                              <i class="la la-plus"></i>
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="kt-widget4__item">
                      <div class="row col">
                        <div class="col-md-12" *ngIf="detalhe.expand" style="background: #ededed;border-radius: 5px;padding: 10px;">
                          <div class="row col">
                            <div class="col-md-3">
                              <div class="kt-widget4__info">
                                <a class="kt-widget4__username">
                                  Tipo Pagamento
                                </a>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="kt-widget4__info">
                                <a class="kt-widget4__username">
                                  Valor Faturado
                                </a>
                              </div>
                            </div>
                            <div class="col-md-3 kt-padding-0">
                              <div class="kt-widget4__info">
                                <a class="kt-widget4__username">
                                  Valor BMS
                                </a>
                              </div>
                            </div>
                            <div class="col-md-12" *ngFor="let subdetalhe of ListaSubDetalheGraficoBMS">
                              <div class="row col">
                                <div class="col-md-3">
                                  {{subdetalhe.no_pagamento}}
                                </div>
                                <div class="col-md-3">
                                  R$ {{subdetalhe.vl_pagamento.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2  })}}
                                </div>
                                <div class="col-md-3">
                                  R$ {{subdetalhe.vl_bms.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2  })}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="kt-portlet__body kt-portlet__body--fit">
            <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded" id="kt_apps_user_list_datatable">
              <div class="kt-datatable__pager kt-datatable--paging-loaded kt-padding-0">
                <div class="kt-datatable__pager-nav">
                  <pagination-controls (pageChange)="p = $event" [maxSize]="VisualizacaoCelular ? 4 : 7" previousLabel="<" nextLabel=">"></pagination-controls>
                </div>
                <div class="kt-datatable__pager-info">
                  <span class="kt-datatable__pager-detail">Mostrando {{ (ListaDetalheGraficoBMS | filter: PesquisaBMS.value)?.length }} de {{ListaDetalheGraficoBMS.length}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row separador-borda" *ngIf="Visualizacao == 5">
    <div class="alert alert-warning fade show" role="alert" *ngIf="qtAlerta > 0" style="width: 100%;margin: 10px;font-weight: 500;">
      <div class="alert-icon"><i class="flaticon-warning"></i></div>
      <div class="alert-text">{{qtAlerta}} {{qtAlerta > 1 ? 'Sessões Pendentes' : 'Sessão Pendente'}} de conclusão</div>
    </div>
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid kt-padding-t-10" *ngIf="idPerfil != '3'">
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group row">
            <div class="col-lg-3" *ngIf="idPerfil != '1' && idPerfil != '2'">
              <div class="kt-input-icon kt-input-icon--right">
                <ng-select placeholder="Selecione o Paciente" name="id_status" [(ngModel)]="id_paciente" (change)="RecarregarGraficosAgenda()" notFoundText="Nenhum item encontrado" class="form-control ng-select kt-padding-0">
                  <ng-option *ngFor="let opt of ListaPacienteAgendamento" [value]="opt.id_paciente">
                    <font style="vertical-align: inherit;">{{opt.no_paciente}}</font>
                  </ng-option>
                </ng-select>
              </div>
            </div>
            <div class="col-lg-3" *ngIf="idPerfil == '1' || idPerfil == '2'">
              <div class="kt-input-icon kt-input-icon--right">
                <ng-select placeholder="Selecione o Cliente" name="id_cliente" [(ngModel)]="id_cliente" (change)="RecarregarGraficosAgenda()" notFoundText="Nenhum item encontrado" class="form-control ng-select kt-padding-0">
                  <ng-option *ngFor="let opt of ListaClienteAgendamento" [value]="opt.id_cliente">
                    <font style="vertical-align: inherit;" title="{{opt.no_fantasia}}">{{opt.no_fantasia}}</font>
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 kt-padding-t-10">
      <div class="row">
        <div class="col-md-12">
          <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
            <div class="kt-portlet kt-portlet--mobile">
              <div class="row col">
                <div class="col-md-6" style="border-right: 1px solid;margin-bottom: 10px;padding: 0px">
                  <div class="row col kt-padding-0" style="padding-top: 10px !important;">
                    <div class="col-md-7">
                      <div class="kt-container  kt-container--fluid" style="padding-left: 10px;padding-right: 10px;padding-bottom: 10px;">
                        <div class="kt-subheader__main kt-padding-t-10">
                          <h3 class="kt-subheader__title" style="font-size:1.2rem">Relatório Mensal {{anoMes}}</h3>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="kt-input-icon kt-input-icon--right">
                        <ng-select placeholder="Selecione o Período" name="periodo" [(ngModel)]="periodo" (change)="CarregarGraficoFaturamento()" notFoundText="Nenhum item encontrado" class="form-control ng-select kt-padding-0">
                          <ng-option *ngFor="let opt of ListaPeriodoAgendamento" [value]="opt.periodo">
                            <font style="vertical-align: inherit;">{{opt.periodo}}</font>
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="kt-portlet__body kt-portlet__body--fit">
                    <p style="font-weight: 600;padding-left: 30px;">
                      Pacientes Atendidos: {{qt_paciente_mensal}} <br />
                      Sessões Atendidas: {{qt_agendamento_mensal}} <br />
                      Total Faturado: R$ {{vl_mensal.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2  })}} <br />
                      Total Recebido: R$ {{vl_faturamento.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2  })}}
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row col">
                    <div class="col-md-7">
                      <div class="kt-container  kt-container--fluid " style="padding-left: 10px;padding-right: 10px;padding-bottom: 10px;">
                        <div class="kt-subheader__main kt-padding-t-10">
                          <h3 class="kt-subheader__title" style="font-size:1.2rem">Relatório Anual {{ano}}</h3>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="kt-input-icon kt-input-icon--right">
                        <ng-select placeholder="Selecione o Ano" name="ano" [(ngModel)]="ano" (change)="CarregarGraficoFaturamento()" notFoundText="Nenhum item encontrado" class="form-control ng-select kt-padding-0">
                          <ng-option *ngFor="let opt of ListaAnoAgendamento" [value]="opt.ano">
                            <font style="vertical-align: inherit;">{{opt.ano}}</font>
                          </ng-option>
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="kt-portlet__body kt-portlet__body--fit">
                    <p style="font-weight: 600;padding-left: 30px;">
                      Pacientes Atendidos: {{qt_paciente_anual}} <br />
                      Sessões Atendidas: {{qt_agendamento_anual}} <br />
                      Total Recebido (Nota Fiscal): R$ {{vl_nota_anual.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2  })}} <br />
                      Total Recebido (Recibo): R$ {{vl_recibo_anual.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2  })}} <br />
                      Total Recebido (Outros): R$ {{vl_outros_anual.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2  })}} <br />
                      Previsão de Faturamento: R$ {{vl_faturamento_previsto_anual.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2  })}}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-12" style="border-top: 1px solid;padding-top: 10px;">
                <div id="grafico-faturamento" style="width:100%; height:300px;"></div>
                <p style="font-weight: 600;padding-left: 30px;margin-top: -30px;text-align: center;">
                  ( Recebido Anual por Tipo de Pagamento  )
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row col">
            <div class="col-xl-6 col-lg-9 col-md-6 col-sm-6">
              <form id="kt_vheader_search_form">
                <div class="row col">
                  <div class="col-md-4" [hidden]="idPerfil == '4'">
                    <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-0">
                      <input type="search" class="form-control" placeholder="Pesquisar Paciente" name="generalSearch" [(ngModel)]="SearchAgendamento.no_paciente" (keypress)="p = 1">
                      <span class="kt-input-icon__icon kt-input-icon__icon--right">
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <path
                                d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                              <path
                                d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                fill="#000000" fill-rule="nonzero"></path>
                            </g>
                          </svg>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-0">
                      <input type="search" class="form-control" placeholder="Pesquisar Sessão" name="generalSearch" [(ngModel)]="SearchAgendamento.dt_inicio" (keypress)="p = 1">
                      <span class="kt-input-icon__icon kt-input-icon__icon--right">
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <path
                                d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                              <path
                                d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                fill="#000000" fill-rule="nonzero"></path>
                            </g>
                          </svg>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-0" style="display:none">
                      <input type="search" class="form-control" placeholder="Pesquisar Cliente" name="generalSearch" [(ngModel)]="SearchAgendamento.no_fantasia" (keypress)="p = 1">
                      <span class="kt-input-icon__icon kt-input-icon__icon--right">
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <rect x="0" y="0" width="24" height="24"></rect>
                              <path
                                d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                              <path
                                d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                fill="#000000" fill-rule="nonzero"></path>
                            </g>
                          </svg>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-3">
              <div class="form-group has-feedback" style="float:left">
                <b class="totalRegistro"> Mostrar </b>
                <button type="button" class="btn btn-default dropdown-toggle countPage  " data-toggle="dropdown" aria-expanded="false">
                  {{itemsPageB}} <span class="caret"></span>
                </button>
                <ul class="dropdown-menu dropdown-menu-right dropCount kt-pointer" role="menu">
                  <li>
                    <a (click)="itemsPageB = 10">10</a>
                  </li>
                  <li>
                    <a (click)="itemsPageB = 50">50</a>
                  </li>
                  <li>
                    <a (click)="itemsPageB = 100">100</a>
                  </li>
                  <li>
                    <a (click)="itemsPageB = 500">500</a>
                  </li>
                </ul>
                <b class="totalRegistro"> Registros</b>
              </div>
            </div>
            <div class="col-md-12 kt-padding-t-10">
              <div class="kt-portlet__body kt-portlet__body--fit">
                <div class="tab-pane active kt-margin-t-10 kt-margin-b-10" id="kt_widget31_tab1_content" [hidden]="VisualizacaoCelular">
                  <div class="tab-pane active" id="kt_widget4_tab1_content">
                    <div class="kt-widget4">
                      <div class="kt-widget4__item">
                        <div class="row col">
                          <div class="col-md-3">
                            <div class="kt-widget4__info">
                              <a class="kt-widget4__username" style="font-weight: 600;">
                                Paciente / Cliente
                              </a>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <div class="kt-widget4__info">
                              <a class="kt-widget4__username" style="font-weight: 600;">
                                Sessão
                              </a>
                            </div>
                          </div>
                          <div class="col-md-1">
                            <div class="kt-widget4__info">
                              <a class="kt-widget4__username" style="font-weight: 600;">
                                Pré-Pago
                              </a>
                            </div>
                          </div>
                          <div class="col-md-1">
                            <div class="kt-widget4__info">
                              <a class="kt-widget4__username" style="font-weight: 600;">
                                Valor
                              </a>
                            </div>
                          </div>
                          <div class="col-md-2 kt-pointer">
                            <div class="kt-widget4__info">
                              <a class="kt-widget4__username" style="font-weight: 600;">
                                Tipo Pagamento
                              </a>
                            </div>
                          </div>
                          <div class="col-md-2 kt-pointer">
                            <div class="kt-widget4__info">
                              <a class="kt-widget4__username" style="font-weight: 600;">
                                Status
                              </a>
                            </div>
                          </div>
                          <div class="col-md-1 kt-pointer">
                            <div class="kt-widget4__info">
                              <a class="kt-widget4__username" style="font-weight: 600;color: red;">
                                Cobrança
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="kt-portlet__body" *ngFor="let detalhe of ListaDetalheAgendamento |
                filtroPagination: [{no_paciente: SearchAgendamento.no_paciente, dt_sessao: SearchAgendamento.dt_inicio, no_fantasia: SearchAgendamento.no_fantasia}] |
                paginate: { itemsPerPage: itemsPageB, currentPage: p }; let i = index">
                  <div class="tab-content">
                    <div class="tab-pane active kt-margin-t-10 kt-margin-b-10" id="kt_widget31_tab1_content">
                      <div class="tab-pane active" id="kt_widget4_tab1_content">
                        <div class="kt-widget4">
                          <div class="kt-widget4__item">
                            <div class="row col">
                              <div class="col-md-3">
                                <div class="kt-widget4__info">
                                  <a class="kt-widget4__username">
                                    {{detalhe.no_paciente}}
                                  </a>
                                </div>
                                <div class="kt-widget4__info">
                                  <a class="kt-widget4__username" style="font-weight: 100;font-size: 12px;">
                                    {{detalhe.no_fantasia}}
                                  </a>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="kt-widget4__info">
                                  <a class="kt-widget4__username">
                                    {{detalhe.dt_inicio | date : 'dd/MM/yyyy HH:mm'}} a {{detalhe.dt_fim | date : 'HH:mm'}}
                                  </a>
                                </div>
                              </div>
                              <div class="col-md-1">
                                <div class="kt-widget4__info">
                                  <p class="kt-widget4__text">
                                    {{detalhe.no_condicao}}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-1">
                                <div class="kt-widget4__info">
                                  <p class="kt-widget4__text">
                                    R${{detalhe.vl_consulta.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2  })}}
                                  </p>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="kt-widget4__info">
                                  <a class="kt-widget4__username">
                                    {{detalhe.ds_tipo_pagamento}}
                                  </a>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="kt-widget4__info">
                                  <a class="kt-widget4__username">
                                    {{detalhe.ds_consulta}}
                                  </a>
                                </div>
                              </div>
                              <div class="col-md-1">
                                <div class="kt-widget4__info">
                                  <div class="kt-form__actions alinhamento-div-botao" *ngIf="idPerfil != '1' && idPerfil != '2'">
                                    <button type="reset" class="btn btn-primary alinhamento-botao" style="background-color: #5867dd !important;border-color: #5867dd !important;color: #ffffff !important;     width: 80px;"
                                      (click)="SalvarOKProcedimento(detalhe.id_agendamento)" *ngIf="detalhe.dt_conclusao == null">Enviar</button>
                                    <button type="reset" class="btn btn-primary alinhamento-botao" style="background-color: #bbbbbb4d !important;border-color: #bbbbbb4d !important;color: #000000 !important;"
                                      *ngIf="detalhe.dt_conclusao != null">Enviado</button>
                                  </div>
                                  <div class="kt-form__actions alinhamento-div-botao" *ngIf="idPerfil == '1' || idPerfil == '2'">
                                    <button type="reset" class="btn btn-primary alinhamento-botao" [disabled]="detalhe.dt_conclusao == null" style="background-color: #5867dd !important;border-color: #5867dd !important;color: #ffffff !important"
                                      (click)="EditarAgendamento(i, detalhe.id_agendamento)">Editar</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="kt-widget4__item" *ngIf="detalhe.expand">
                            <div class="row col" style="background: #dedddd;padding: 20px;">
                              <div class="col-lg-3">
                                <label>Atendimento:</label>
                                <div class="kt-input-icon kt-input-icon--right">
                                  <ng-select placeholder="SELECIONE" [(ngModel)]="atendimento" name="fg_consulta" notFoundText="Nenhum item encontrado" class="form-control ng-select" style="padding-right: 0;">
                                    <ng-option *ngFor="let opt of ListaStatusAgendamento" [value]="opt.id">
                                      <font style="vertical-align: inherit;">{{opt.descricao}}</font>
                                    </ng-option>
                                  </ng-select>
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <label>Tipo de Pagamento:</label>
                                <div class="kt-input-icon kt-input-icon--right">
                                  <ng-select placeholder="SELECIONE" [(ngModel)]="tipoPagamento" name="id_tipo_pagamento" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                                    <ng-option *ngFor="let opt of ListaTipoPagamento" [value]="opt.id_tipo_pagamento">
                                      <font style="vertical-align: inherit;">{{opt.ds_pagamento}}</font>
                                    </ng-option>
                                  </ng-select>
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <label>Valor da Consulta:</label>
                                <div class="kt-input-icon kt-input-icon--right">
                                  <input class="form-control" currencyMask [(ngModel)]="valorConsulta" placeholder="Digite o Valor Consulta" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',',align:'left' }" />
                                </div>
                              </div>
                              <div class="col-lg-3">
                                <button type="reset" class="btn btn-primary alinhamento-botao" style="background-color: #ffffff !important;border-color: #ffffff !important;color: #000 !important;margin-top: 25px;margin-right: 10px;"
                                  (click)="CancelarAgendamento()">Cancelar</button>
                                <button type="reset" class="btn btn-primary alinhamento-botao" style="background-color: #5867dd !important;border-color: #5867dd !important;color: #ffffff !important;margin-top: 25px;"
                                  (click)="SalvarAgendamento(detalhe.id_agendamento)">Salvar</button>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="kt-portlet__body kt-portlet__body--fit">
                  <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded" id="kt_apps_user_list_datatable">
                    <div class="kt-datatable__pager kt-datatable--paging-loaded kt-padding-0">
                      <div class="kt-datatable__pager-nav">
                        <pagination-controls (pageChange)="p = $event" [maxSize]="VisualizacaoCelular ? 4 : 7" previousLabel="<" nextLabel=">"></pagination-controls>
                      </div>
                      <div class="kt-datatable__pager-info">
                        <span class="kt-datatable__pager-detail">Mostrando {{ListaDetalheAgendamento?.length}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row separador-borda" *ngIf="Visualizacao == 6">
    <div class="col-md-12 kt-padding-t-10">
      <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
          <div class="kt-subheader  kt-grid__item" id="kt_subheader">
            <div class="kt-container  kt-container--fluid ">
              <div class="kt-subheader__main">
                <h3 class="kt-subheader__title">Novos Pacientes</h3>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div class="kt-subheader__main">
                  <div class="kt-subheader__group" id="kt_subheader_search">
                    <div class="row kt-padding-l-10" style="margin-bottom: 20px;">
                      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 kt-padding-0">
                        <form id="kt_subheader_search_form">
                          <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-0">
                            <input type="search" class="form-control" placeholder="Pesquisar" name="generalSearch" #PesquisaPendente ngModel (keypress)="p = 1">
                            <span class="kt-input-icon__icon kt-input-icon__icon--right">
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"></rect>
                                    <path
                                      d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                      fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                    <path
                                      d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                      fill="#000000" fill-rule="nonzero"></path>
                                  </g>
                                </svg>
                              </span>
                            </span>
                          </div>
                        </form>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group has-feedback" style="float:left">
                          <b class="totalRegistro"> Mostrar </b>
                          <button type="button" class="btn btn-default dropdown-toggle countPage  " data-toggle="dropdown" aria-expanded="false">
                            {{itemsPageR}} <span class="caret"></span>
                          </button>
                          <ul class="dropdown-menu dropdown-menu-right dropCount kt-pointer" role="menu">
                            <li>
                              <a (click)="itemsPageR = 10">10</a>
                            </li>
                            <li>
                              <a (click)="itemsPageR = 50">50</a>
                            </li>
                            <li>
                              <a (click)="itemsPageR = 100">100</a>
                            </li>
                            <li>
                              <a (click)="itemsPageR = 500">500</a>
                            </li>
                          </ul>
                          <b class="totalRegistro"> Registros</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="kt-portlet__body kt-portlet__body--fit">
                  <div class="tab-pane active kt-margin-t-10 kt-margin-b-10" id="kt_widget31_tab1_content" [hidden]="VisualizacaoCelular">
                    <div class="tab-pane active" id="kt_widget4_tab1_content">
                      <div class="kt-widget4">
                        <div class="kt-widget4__item">
                          <div class="row col kt-margin-0">
                            <div class="col-md-2">
                              <div class="kt-widget4__info">
                                <a class="kt-widget4__username">
                                  Cliente
                                </a>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="kt-widget4__info">
                                <a class="kt-widget4__username">
                                  Paciente
                                </a>
                              </div>
                            </div>
                            <div class="col-md-2">
                              <div class="kt-widget4__info">
                                <a class="kt-widget4__username">
                                  Valor
                                </a>
                              </div>
                            </div>
                            <div class="col-md-2">
                              <div class="kt-widget4__info">
                                <a class="kt-widget4__username">
                                  Celular
                                </a>
                              </div>
                            </div>
                            <div class="col-md-2">
                              <div class="kt-widget4__info">
                                <a class="kt-widget4__username">
                                  Data Cadastro
                                </a>
                              </div>
                            </div>
                            <div class="col-md-1">
                              <div class="kt-widget4__info">
                                <a class="kt-widget4__username">
                                  Ação
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="kt-portlet__body" *ngFor="let detalhe of ListaPendenteTipoPagamento | filter : PesquisaPendente.value | paginate: { itemsPerPage: itemsPageR, currentPage: p }; let i = index">
                    <div class="tab-content" style="background-color: {{detalhe.background}};border-radius:5px">
                      <div class="tab-pane active kt-margin-t-10 kt-margin-b-10" id="kt_widget31_tab1_content">
                        <div class="tab-pane active" id="kt_widget4_tab1_content">
                          <div class="kt-widget4">
                            <div class="kt-widget4__item">
                              <div class="row col kt-margin-0">
                                <div class="col-md-2">
                                  <div class="kt-widget4__info">
                                    <a class="kt-widget4__text">
                                      {{detalhe.no_fantasia}}
                                    </a>
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <div class="kt-widget4__info">
                                    <a class="kt-widget4__text">
                                      {{detalhe.no_paciente}}
                                    </a>
                                  </div>
                                </div>
                                <div class="col-md-2">
                                  <div class="kt-widget4__info">
                                    <a class="kt-widget4__text">
                                      R$ {{detalhe.vl_consulta.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2  })}}
                                    </a>
                                  </div>
                                </div>
                                <div class="col-md-2">
                                  <div class="kt-widget4__info">
                                    <a class="kt-widget4__text">
                                      {{detalhe.no_celular}}
                                    </a>
                                  </div>
                                </div>
                                <div class="col-md-2">
                                  <div class="kt-widget4__info">
                                    <p class="kt-widget4__text">
                                      {{detalhe.dt_cadastro | date : 'dd/MM/yyyy'}}
                                    </p>
                                  </div>
                                </div>
                                <div class="col-md-1 kt-pointer">
                                  <span class="dropdown">
                                    <a class="btn btn-sm btn-clean btn-icon btn-icon-md" data-toggle="dropdown" aria-expanded="true">
                                      <i class="la la-ellipsis-h"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right kt-pointer">
                                      <a class="dropdown-item" (click)="Redirect('Paciente', '6')" [routerLink]="['/Paciente', detalhe.id_paciente]">
                                        <i class="la la-edit"></i>
                                        Editar Cadastro</a>
                                      <a class="dropdown-item" (click)="SalvarProcedimento(detalhe.id_paciente)">
                                        <i class="la la-check"></i>
                                        Concluído</a>
                                    </div>
                                  </span>

                                </div>
                              </div>
                            </div>
                            <!-- <form [formGroup]="formCadastroPendentePagamento">
                            <div class="kt-widget4__item" *ngIf="detalhe.expand">
                              <div class="row col kt-margin-0">
                                <div class="col-md-4">
                                  <label>Tipo de Pagamento:</label>
                                  <div class="kt-input-icon kt-input-icon--right">
                                    <ng-select placeholder="SELECIONE" name="id_tipo_pagamento" formControlName="id_tipo_pagamento" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                                      <ng-option *ngFor="let opt of ListaTipoPagamento" [value]="opt.id_tipo_pagamento">
                                        <font style="vertical-align: inherit;">{{opt.ds_pagamento}}</font>
                                      </ng-option>
                                    </ng-select>
                                  </div>
                                </div>
                                <div class="col-lg-4">
                                  <label>Arquivo Anexo:</label>
                                  <div class="kt-input-icon kt-input-icon--right arquivoAnexo">
                                    <label class="kt-avatar__upload custom-dropzone kt-pointer kt-padding-t-10" data-toggle="kt-tooltip" style="width:100%">
                                      <div class="ng-placeholder kt-padding-l-20" style="color: #999;">SELECIONE O ARQUIVO
                                      </div>
                                      <span class="form-text text-muted kt-margin-t-10" *ngIf="ListaArquivos.length > 0">Arquivo carregado com sucesso!</span>
                                      <input type="file" #file placeholder="Choose file" accept=".xlsx, .xls .doc, .pdf" style="display:none;" (change)="uploadDocumento($event, '1')">
                                    </label>
                                  </div>
                                </div>
                                <div class="col-md-1">
                                  <div class="kt-form__actions alinhamento-div-botao">
                                    <button type="reset" class="btn btn-primary alinhamento-botao" style="margin-top: 25px;background-color: #5867dd !important;border-color: #5867dd !important;color: #ffffff; !important" (click)="SalvarProcedimento()">Salvar</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </form> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="kt-portlet__body kt-portlet__body--fit">
                    <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded" id="kt_apps_user_list_datatable">
                      <div class="kt-datatable__pager kt-datatable--paging-loaded kt-padding-0">
                        <div class="kt-datatable__pager-nav">
                          <pagination-controls (pageChange)="p = $event" [maxSize]="VisualizacaoCelular ? 4 : 7" previousLabel="<" nextLabel=">"></pagination-controls>
                        </div>
                        <div class="kt-datatable__pager-info">
                          <span class="kt-datatable__pager-detail">Mostrando {{ (ListaPendenteTipoPagamento | filter: PesquisaPendente.value)?.length }} de {{ListaPendenteTipoPagamento?.length}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row separador-borda" *ngIf="Visualizacao == 7">
    <div class="col-md-12 kt-padding-t-10">
      <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
          <div class="kt-subheader  kt-grid__item" id="kt_subheader">
            <div class="kt-container  kt-container--fluid ">
              <div class="kt-subheader__main">
                <h3 class="kt-subheader__title">Gestão de Clientes</h3>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div class="kt-subheader__main">
                  <div class="kt-subheader__group" id="kt_subheader_search">
                    <div class="row kt-padding-l-10" style="margin-bottom: 20px;">
                      <div class="col-md-3 kt-padding-0">
                        <form id="kt_subheader_search_form">
                          <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-0">
                            <input type="search" class="form-control" placeholder="Pesquisar" name="generalSearch" #PesquisaGestao ngModel (keypress)="p = 1">
                            <span class="kt-input-icon__icon kt-input-icon__icon--right">
                              <span>
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"></rect>
                                    <path
                                      d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                      fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                    <path
                                      d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                      fill="#000000" fill-rule="nonzero"></path>
                                  </g>
                                </svg>
                              </span>
                            </span>
                          </div>
                        </form>
                      </div>
                      <div class="col-md-3 kt-padding-0">
                        <form id="kt_subheader_search_form">
                          <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-l-5">
                            <ng-select placeholder="Selecione o Ano" name="ano" [(ngModel)]="ano" (change)="GetGestaoCliente()" notFoundText="Nenhum item encontrado" class="form-control ng-select kt-padding-0">
                              <ng-option *ngFor="let opt of ListaAnoAgendamento" [value]="opt.ano">
                                <font style="vertical-align: inherit;">{{opt.ano}}</font>
                              </ng-option>
                            </ng-select>
                          </div>
                        </form>
                      </div>
                      <div class="col-md-3">
                      </div>
                      <div class="col-md-3">
                        <div class="form-group has-feedback" style="float:left">
                          <b class="totalRegistro"> Mostrar </b>
                          <button type="button" class="btn btn-default dropdown-toggle countPage  " data-toggle="dropdown" aria-expanded="false">
                            {{itemsPageR}} <span class="caret"></span>
                          </button>
                          <ul class="dropdown-menu dropdown-menu-right dropCount kt-pointer" role="menu">
                            <li>
                              <a (click)="itemsPageR = 10">10</a>
                            </li>
                            <li>
                              <a (click)="itemsPageR = 50">50</a>
                            </li>
                            <li>
                              <a (click)="itemsPageR = 100">100</a>
                            </li>
                            <li>
                              <a (click)="itemsPageR = 500">500</a>
                            </li>
                          </ul>
                          <b class="totalRegistro"> Registros</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="kt-subheader__main">
                  <div class="kt-subheader__group" id="kt_subheader_search">
                    <div class="row kt-padding-l-10" style="margin-bottom: 20px;">
                      <div class="col-md-3">
                        <div class="col row">
                          <div class="col-md-1" style="background: rgba(255, 255, 0, 0.32);height: 20px;">
                          </div>
                          <div class="col-md-10">
                            <p class="kt-margin-0">
                              Aguardando Liberação
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="col row">
                          <div class="col-md-1" style="background: #369b36;height: 20px;">
                          </div>
                          <div class="col-md-10">
                            <p class="kt-margin-0">
                              Liberado para Cobrança (Pós-Pago)
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="col row">
                          <div class="col-md-1" style="background: #1546f79e ;height: 20px;">
                          </div>
                          <div class="col-md-10">
                            <p class="kt-margin-0">
                              Liberado para Cobrança (Pré-Pago)
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="col row">
                          <div class="col-md-1" style="background: #6b696469;height: 20px;">
                          </div>
                          <div class="col-md-10">
                            <p class="kt-margin-0">
                              Pagamento Efetuado
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="kt-portlet__body kt-portlet__body--fit">
                  <div class="tab-pane active kt-margin-t-10 kt-margin-b-10" id="kt_widget31_tab1_content" [hidden]="VisualizacaoCelular">
                    <div class="tab-pane active" id="kt_widget4_tab1_content">
                      <div class="kt-widget4">
                        <div class="kt-widget4__item">
                          <div class="row col kt-margin-0">
                            <div class="col-md-12">
                              <div class="kt-widget4__info kt-padding-r-0">
                                <table style="width:100%">
                                  <tr>
                                    <th style="width: 300px;">CLIENTE</th>
                                    <th style="width: 50px;text-align: center;">JAN</th>
                                    <th style="width: 50px;text-align: center;">FEV</th>
                                    <th style="width: 50px;text-align: center;">MAR</th>
                                    <th style="width: 50px;text-align: center;">ABR</th>
                                    <th style="width: 50px;text-align: center;">MAI</th>
                                    <th style="width: 50px;text-align: center;">JUN</th>
                                    <th style="width: 50px;text-align: center;">JUL</th>
                                    <th style="width: 50px;text-align: center;">AGO</th>
                                    <th style="width: 50px;text-align: center;">SET</th>
                                    <th style="width: 50px;text-align: center;">OUT</th>
                                    <th style="width: 50px;text-align: center;">NOV</th>
                                    <th style="width: 50px;text-align: center;">DEZ</th>
                                  </tr>
                                </table>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="kt-portlet__body" *ngFor="let detalhe of ListaGestaoCliente | filter : PesquisaGestao.value | paginate: { itemsPerPage: itemsPageR, currentPage: p }; let i = index">
                    <div class="tab-content" style="background-color: {{detalhe.background}};border-radius:5px;height: 25px;">
                      <div class="tab-pane active kt-margin-t-10 kt-margin-b-10" id="kt_widget31_tab1_content">
                        <div class="tab-pane active" id="kt_widget4_tab1_content">
                          <div class="kt-widget4">
                            <div class="kt-widget4__item">
                              <div class="row col kt-margin-0">
                                <div class="col-md-12">
                                  <table style="width:100%">
                                    <tr>
                                      <td style="width: 300px;">{{detalhe.no_fantasia}}</td>
                                      <td style="width: 50px;background: {{detalhe.dt_01}}; border: 1px solid #fff;"></td>
                                      <td style="width: 50px;background: {{detalhe.dt_02}}; border: 1px solid #fff;"></td>
                                      <td style="width: 50px;background: {{detalhe.dt_03}}; border: 1px solid #fff;"></td>
                                      <td style="width: 50px;background: {{detalhe.dt_04}}; border: 1px solid #fff;"></td>
                                      <td style="width: 50px;background: {{detalhe.dt_05}}; border: 1px solid #fff;"></td>
                                      <td style="width: 50px;background: {{detalhe.dt_06}}; border: 1px solid #fff;"></td>
                                      <td style="width: 50px;background: {{detalhe.dt_07}}; border: 1px solid #fff;"></td>
                                      <td style="width: 50px;background: {{detalhe.dt_08}}; border: 1px solid #fff;"></td>
                                      <td style="width: 50px;background: {{detalhe.dt_09}}; border: 1px solid #fff;"></td>
                                      <td style="width: 50px;background: {{detalhe.dt_10}}; border: 1px solid #fff;"></td>
                                      <td style="width: 50px;background: {{detalhe.dt_11}}; border: 1px solid #fff;"></td>
                                      <td style="width: 50px;background: {{detalhe.dt_12}}; border: 1px solid #fff;"></td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="kt-portlet__body kt-portlet__body--fit kt-padding-t-30">
                    <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded" id="kt_apps_user_list_datatable">
                      <div class="kt-datatable__pager kt-datatable--paging-loaded kt-padding-0">
                        <div class="kt-datatable__pager-nav">
                          <pagination-controls (pageChange)="p = $event" [maxSize]="VisualizacaoCelular ? 4 : 7" previousLabel="<" nextLabel=">"></pagination-controls>
                        </div>
                        <div class="kt-datatable__pager-info">
                          <span class="kt-datatable__pager-detail">Mostrando {{ (ListaGestaoCliente | filter: PesquisaGestao.value)?.length }} de {{ListaGestaoCliente?.length}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row separador-borda" *ngIf="Visualizacao == 8">
    <div class="col-md-12 kt-padding-t-10">
      <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet kt-portlet--mobile">
          <div class="kt-subheader  kt-grid__item" id="kt_subheader">
            <div class="kt-container  kt-container--fluid ">
              <div class="kt-subheader__main">
                <h3 class="kt-subheader__title">Gestão de Pacientes</h3>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div class="kt-subheader__main">
                  <div class="kt-subheader__group" id="kt_subheader_search">
                    <div class="row kt-padding-l-10" style="margin-bottom: 20px;">
                      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 kt-padding-0">
                        <form id="kt_subheader_search_form">
                          <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-5">
                            <ng-select placeholder="Selecione o Cliente" name="id_status" [(ngModel)]="SearchGestaoPaciente.id_cliente" (change)="GetGestaoPaciente()" notFoundText="Nenhum item encontrado" class="form-control ng-select kt-padding-0">
                              <ng-option *ngFor="let opt of ListaClienteAgendamento" [value]="opt.id_cliente">
                                <font style="vertical-align: inherit;">{{opt.no_fantasia}}</font>
                              </ng-option>
                            </ng-select>
                          </div>
                        </form>
                      </div>

                      <div class="col-md-2 kt-padding-0">
                        <form id="kt_subheader_search_form">
                          <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-5">
                            <ng-select placeholder="Selecione o Período" name="periodo" [(ngModel)]="SearchGestaoPaciente.mes" (change)="GetGestaoPaciente()" notFoundText="Nenhum item encontrado" class="form-control ng-select kt-padding-0">
                              <ng-option *ngFor="let opt of ListaPeriodoAgendamento" [value]="opt.periodo">
                                <font style="vertical-align: inherit;">{{opt.periodo}}</font>
                              </ng-option>
                            </ng-select>
                          </div>
                        </form>
                      </div>
                      <div class="col-md-2 kt-padding-0">
                        <form id="kt_subheader_search_form">
                          <div class="kt-input-icon kt-input-icon--right kt-subheader__search kt-padding-5">
                            <ng-select placeholder="Selecione o Ano" name="ano" [(ngModel)]="SearchGestaoPaciente.ano" (change)="GetGestaoPaciente()" notFoundText="Nenhum item encontrado" class="form-control ng-select kt-padding-0">
                              <ng-option *ngFor="let opt of ListaAnoAgendamento" [value]="opt.ano">
                                <font style="vertical-align: inherit;">{{opt.ano}}</font>
                              </ng-option>
                            </ng-select>
                          </div>
                        </form>
                      </div>
                      <div class="col-lg-2">
                        <div class="form-group row">
                          <label class="col-6 col-form-label">Concluídos</label>
                          <div class="col-6">
                            <span class="kt-switch kt-switch--sm kt-switch--icon">
                              <label>
                                <input type="checkbox" name="concluido" (click)="GetGestaoPacienteConcluido()">
                                <span></span>
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group has-feedback" style="float:left">
                          <b class="totalRegistro"> Mostrar </b>
                          <button type="button" class="btn btn-default dropdown-toggle countPage  " data-toggle="dropdown" aria-expanded="false">
                            {{itemsPageR}} <span class="caret"></span>
                          </button>
                          <ul class="dropdown-menu dropdown-menu-right dropCount kt-pointer" role="menu">
                            <li>
                              <a (click)="itemsPageR = 10">10</a>
                            </li>
                            <li>
                              <a (click)="itemsPageR = 50">50</a>
                            </li>
                            <li>
                              <a (click)="itemsPageR = 100">100</a>
                            </li>
                            <li>
                              <a (click)="itemsPageR = 500">500</a>
                            </li>
                          </ul>
                          <b class="totalRegistro"> Registros</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="kt-portlet__body kt-portlet__body--fit">
                  <div class="tab-pane active kt-margin-t-10 kt-margin-b-10" id="kt_widget31_tab1_content" [hidden]="VisualizacaoCelular">
                    <div class="tab-pane active" id="kt_widget4_tab1_content">
                      <div class="kt-widget4">
                        <div class="kt-widget4__item">
                          <div class="row col">
                            <div class="col-md-1 kt-pointer">
                              <div class="kt-widget4__info">
                                <a class="kt-widget4__username" style="font-weight: 600;">

                                </a>
                              </div>
                            </div>
                            <div class="col-md-2">
                              <div class="kt-widget4__info">
                                <a class="kt-widget4__username" style="font-weight: 600;">
                                  Cliente
                                </a>
                              </div>
                            </div>
                            <div class="col-md-2">
                              <div class="kt-widget4__info">
                                <a class="kt-widget4__username" style="font-weight: 600;">
                                  Paciente
                                </a>
                              </div>
                            </div>
                            <div class="col-md-1">
                              <div class="kt-widget4__info">
                                <a class="kt-widget4__username" style="font-weight: 600;">
                                  Valor Total
                                </a>
                              </div>
                            </div>
                            <div class="col-md-2 kt-pointer">
                              <div class="kt-widget4__info">
                                <a class="kt-widget4__username" style="font-weight: 600;">
                                  Tipo Pagamento
                                </a>
                              </div>
                            </div>
                            <div class="col-md-1 kt-pointer">
                              <div class="kt-widget4__info">
                                <a class="kt-widget4__username" style="font-weight: 600;">
                                  Reembolso
                                </a>
                              </div>
                            </div>
                            <div class="col-md-2 kt-pointer">
                              <div class="kt-widget4__info">
                                <a class="kt-widget4__username" style="font-weight: 600;">
                                  Celular
                                </a>
                              </div>
                            </div>
                            <div class="col-md-1 kt-pointer">
                              <div class="kt-widget4__info">
                                <a class="kt-widget4__username" style="font-weight: 600;">
                                  Ação
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <!-- <div class="kt-portlet__body" *ngFor="let detalhe of ListaGestaoPaciente | filtroPagination: [{ no_fantasia:SearchGestaoPaciente.no_fantasia }] | paginate: { itemsPerPage: itemsPageB, currentPage: p }; let i = index"> -->
                  <div class="kt-portlet__body" *ngFor="let detalhe of ListaGestaoPaciente | paginate: { itemsPerPage: itemsPageR, currentPage: p }; let i = index">
                    <div class="tab-content">
                      <div class="tab-pane active kt-margin-t-10 kt-margin-b-10" id="kt_widget31_tab1_content">
                        <div class="tab-pane active" id="kt_widget4_tab1_content">
                          <div class="kt-widget4">
                            <div class="kt-widget4__item">
                              <div class="row col">
                                <div class="col-md-1">
                                  <div class="kt-widget4__info">
                                    <span class="dropdown">
                                      <a class="btn btn-sm btn-clean btn-icon btn-icon-md" (click)="GetGestaoPacienteSub(i, detalhe.id_cliente, detalhe.dt_periodo, detalhe.id_paciente, detalhe.ds_tipo_pagamento, detalhe.id_agendamento)">
                                        <i class="la {{detalhe.expand ? 'la-minus' : 'la-plus'}}"></i>
                                      </a>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-2">
                                  <div class="kt-widget4__info">
                                    <a class="kt-widget4__text">
                                      {{detalhe.no_fantasia}}
                                    </a>
                                  </div>
                                </div>
                                <div class="col-md-2">
                                  <div class="kt-widget4__info">
                                    <a class="kt-widget4__text">
                                      {{detalhe.no_paciente}}
                                    </a>
                                  </div>
                                </div>
                                <div class="col-md-1">
                                  <div class="kt-widget4__info">
                                    <p class="kt-widget4__text">
                                      R${{detalhe.vl_consulta.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2  })}}
                                    </p>
                                  </div>
                                </div>
                                <div class="col-md-2">
                                  <div class="kt-widget4__info">
                                    <label class="checkbox checkbox-outline checkbox-success">
                                      <input type="checkbox" [(ngModel)]="detalhe.flow_pagamento" name="Checkboxes14" (change)="GestaoPacienteCheck(detalhe,1)">
                                      <span></span> <a class="kt-widget4__text">
                                        {{detalhe.ds_tipo_pagamento}}
                                      </a></label>

                                  </div>
                                </div>
                                <div class="col-md-1">
                                  <div class="kt-widget4__info">
                                    <label class="checkbox checkbox-outline checkbox-success">
                                      <input type="checkbox" [(ngModel)]="detalhe.flow_reembolso" name="Checkboxes15" (change)="GestaoPacienteCheck(detalhe,2)">
                                      <span></span> <a class="kt-widget4__text">
                                        {{detalhe.fg_reembolso ? 'SIM' : 'NÃO'}}
                                        <!-- {{detalhe.fg_reembolso}} -->
                                      </a></label>
                                  </div>
                                </div>
                                <div class="col-md-2">
                                  <div class="kt-widget4__info">
                                    <a class="kt-widget4__text">
                                      {{detalhe.no_celular}}
                                    </a>
                                  </div>
                                </div>
                                <div class="col-md-1 kt-pointer">
                                  <span class="dropdown">
                                    <a class="btn btn-sm btn-clean btn-icon btn-icon-md" data-toggle="dropdown" aria-expanded="true">
                                      <i class="la la-ellipsis-h"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right kt-pointer">
                                      <a class="dropdown-item" (click)="ExportarGestaoPaciente(detalhe)">
                                        <i class="la la-edit"></i>
                                        Imprimir</a>
                                      <a class="dropdown-item" *ngIf="detalhe.dt_conclusao_lkrepay == null" (click)="GetGestaoPacienteConclusaoAlerta(i, detalhe.id_cliente, detalhe.dt_periodo, detalhe.id_paciente, detalhe.ds_tipo_pagamento, detalhe.id_agendamento)">
                                        <i class="la la-check"></i>
                                        Concluído</a>
                                    </div>
                                  </span>

                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="kt-widget4__item">
                            <div class="row col">
                              <div class="col-md-12" *ngIf="detalhe.expand" style="background: #ededed;border-radius: 5px;padding: 10px;">
                                <div class="row col">
                                  <div class="col-md-3">
                                    <div class="kt-widget4__info">
                                      <a class="kt-widget4__username" style="font-weight: 600;">
                                        Sessão
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col-md-2">
                                    <div class="kt-widget4__info">
                                      <a class="kt-widget4__username" style="font-weight: 600;">
                                        Valor
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col-md-3 kt-padding-0">
                                    <div class="kt-widget4__info">
                                      <a class="kt-widget4__username" style="font-weight: 600;">
                                        Status
                                      </a>
                                    </div>
                                  </div>
                                  <div class="col-md-12" *ngFor="let subdetalhe of ListaGestaoPacienteSub; let x = index">
                                    <div class="row col">
                                      <div class="col-md-3">
                                        {{subdetalhe.dt_sessao}}
                                      </div>
                                      <div class="col-md-2">
                                        R$ {{subdetalhe.vl_consulta.toLocaleString("pt-BR", {minimumFractionDigits: 2, maximumFractionDigits: 2  })}}
                                      </div>
                                      <div class="col-md-3">
                                        {{subdetalhe.ds_status_pagamento}}
                                      </div>
                                      <div class="col-md-3" *ngIf="qtCancelado > 0">
                                        <label class="checkbox checkbox-outline checkbox-success">
                                          <input type="checkbox" [(ngModel)]="checkCancelado" name="Checkboxes15" (change)="GestaoPacienteCancelado(detalhe.id_cliente)">
                                          <span></span> <a class="kt-widget4__text">
                                            {{qtCancelado}} - Crédito disponível
                                          </a></label>
                                      </div>
                                      <div class="col-md-4 kt-padding-10" style="background: #ffb822;margin-top: -10px;font-weight: 600;" *ngIf="subdetalhe.dt_conclusao_lkrepay != null && x == 0">
                                        <div >
                                          Pagamento Concluído em {{subdetalhe.dt_conclusao_lkrepay | date : 'dd/MM/yyyy'}}
                                        </div>
                                        <div style="font-size: 12px;font-weight: 400;" *ngIf="subdetalhe.id_agendamento_pagamento != null">
                                          Crédito utilizado para pagamento ({{subdetalhe.dt_agendamento}})
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="kt-portlet__body kt-portlet__body--fit">
                    <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded" id="kt_apps_user_list_datatable">
                      <div class="kt-datatable__pager kt-datatable--paging-loaded kt-padding-0">
                        <div class="kt-datatable__pager-nav">
                          <pagination-controls (pageChange)="p = $event" [maxSize]="VisualizacaoCelular ? 4 : 7" previousLabel="<" nextLabel=">"></pagination-controls>
                        </div>
                        <div class="kt-datatable__pager-info">
                          <span class="kt-datatable__pager-detail">Mostrando {{ (ListaGestaoPaciente | filtroPagination: [{ no_fantasia:SearchGestaoPaciente.ID_SOLICITACAO }])?.length }} de {{ListaGestaoPaciente?.length}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
