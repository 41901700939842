import { Component, OnInit, HostListener } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ClienteService } from '../../service/cliente/cliente.service';
import { SweetAlertService } from '../../service/commom/sweet-alert/sweet-alert.service';
import { ExcelService } from '../../service/commom//excel.service';
import { PacienteService } from '../../service/paciente/paciente.service';
import { ProcedimentoService } from '../../service/procedimento/procedimento.service';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

@Component({
  selector: 'app-gerenciamento',
  templateUrl: './gerenciamento.component.html',
  styleUrls: ['./gerenciamento.component.css'],
  host: { 'class': 'col' }
})
export class GerenciamentoComponent implements OnInit {
  tipovisualizacao: number = 0;
  ListaStatus: any = [{ idstatus: '1', nmstatus: 'ATIVO' }, { idstatus: '0', nmstatus: 'INATIVO' }];
  formData: FormData;
  ListaFuncao: any = [];
  ListaTipoContato: any = [];
  ListaOperadora: any = [];
  ListaPagamento: any = [];
  ListaNota: any = [];
  ListaSLA: any = [];
  ListaOperadoraSLA: any = [];
  ListaStatusSLA: any = [];
  ListaDiasUteis: any = [{ fg_diautil: '1', nm_dia: 'SIM' }, { fg_diautil: '0', nm_dia: 'NÃO' }];

  VisualizacaoCelular: boolean = false;
  MostrarLista: boolean = true;
  formCadastroFuncao: FormGroup;
  formCadastroTipoContato: FormGroup;
  formCadastroOperadora: FormGroup;
  formCadastroPagamento: FormGroup;
  formCadastroNota: FormGroup;
  formCadastroSLA: FormGroup;
  p: number = 1;
  mx: number = 3;
  isLoading: boolean = false;
  initialValue: string = ''
  idPerfil = localStorage.getItem('idPerfil');
  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private clienteService: ClienteService,
    private pacienteService: PacienteService,
    private procedimentoService: ProcedimentoService,
    private sweetAlertService: SweetAlertService,
    private excelService: ExcelService
  ) {
    const actualWidth = window.innerWidth;
    if (window.innerWidth < 1024) {
      this.VisualizacaoCelular = true;
    }
  }
  @HostListener('window:resize', [''])
  onResize() {
    this.VisualizacaoCelular = window.innerWidth < 1024 ? true : false;
  }
  ngOnInit(): void {

  }
  CarregarFormBuilder() {
    this.formCadastroFuncao = this.formBuilder.group({
      id_funcao: ['', []],
      no_funcao: ['', [Validators.required, Validators.maxLength(200)]],
      fg_aprovado: [null, [Validators.required]]
    });
    this.formCadastroTipoContato = this.formBuilder.group({
      id_tp_contato: ['', []],
      no_contato: ['', [Validators.required, Validators.maxLength(200)]],
      fg_aprovado: [null, [Validators.required]]
    });
    this.formCadastroTipoContato = this.formBuilder.group({
      id_tp_contato: ['', []],
      no_contato: ['', [Validators.required, Validators.maxLength(200)]],
      fg_aprovado: [null, [Validators.required]]
    });
    this.formCadastroOperadora = this.formBuilder.group({
      id_operadora: ['', []],
      nu_ans: ['', [Validators.maxLength(25)]],
      nu_cnpj: ['', [Validators.minLength(14), Validators.maxLength(14)]],
      no_operadora: ['', [Validators.required,Validators.maxLength(100)]],
      no_email: ['', [Validators.maxLength(120)]],
      no_telefone: ['', [Validators.minLength(10), Validators.maxLength(11)]],
      fg_aprovado: [null, [Validators.required]]
    });
    this.formCadastroPagamento = this.formBuilder.group({
      id_pagamento: ['', []],
      no_pagamento: ['', [Validators.required, Validators.maxLength(200)]],
      fg_aprovado: [null, [Validators.required]]
    });
    this.formCadastroNota = this.formBuilder.group({
      id_nota: ['', []],
      no_nota: ['', [Validators.required, Validators.maxLength(200)]],
      fg_aprovado: [null, [Validators.required]]
    });

    this.formCadastroSLA = this.formBuilder.group({
      id_sla: ['', []],
      id_operadora: ['', [Validators.required]],
      no_operadora: ['', []],
      id_status: [null, [Validators.required]],
      no_status: ['', []],
      fg_diautil: [null, [Validators.required]],
      nu_dia: [null, [Validators.required]]
    });
  }
  get f() { return this.formCadastroFuncao.controls; }
  get c() { return this.formCadastroTipoContato.controls; }
  get o() { return this.formCadastroOperadora.controls; }
  get a() { return this.formCadastroPagamento.controls; }
  get b() { return this.formCadastroNota.controls; }
  get s() { return this.formCadastroSLA.controls; }

  GetListaFuncao() {
    this.CarregarFormBuilder();
    this.tipovisualizacao = 1;
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_funcao', '');
    this.clienteService.GetListaFuncao(this.formData).subscribe((data: any) => {
      this.ListaFuncao = data.retorno;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar as Funções');
        this.spinner.hide();
      });
  }
  CrudFuncao() {
    this.initialValue == "";
    if (this.formCadastroFuncao.value.id_funcao != '') {
      this.AtualizarFuncao();
    }
    else {
      this.AdicionarFuncao();
    }
  }
  CadastroFuncao() {
    this.CarregarFormBuilder();
    this.MostrarLista = !this.MostrarLista
  }
  GetFuncao(id_funcao) {
    this.formData = new FormData();
    this.formData.append('id_funcao', id_funcao);
    this.clienteService.GetListaFuncao(this.formData).subscribe((data: any) => {
      this.formCadastroFuncao.patchValue({
        id_funcao: data.retorno[0].id_funcao,
        no_funcao: data.retorno[0].no_funcao,
        fg_aprovado: data.retorno[0].fg_aprovado == true ? '1' : '0'
      });
      this.MostrarLista = !this.MostrarLista
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.MostrarLista = !this.MostrarLista
        this.GetListaFuncao();
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar a Função');
        this.spinner.hide();
      });
  }
  AdicionarFuncao() {
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('no_funcao', this.formCadastroFuncao.value.no_funcao);
    this.formData.append('fg_aprovado', this.formCadastroFuncao.value.fg_aprovado);
    this.clienteService.AdicionarFuncao(this.formData).subscribe((data: any) => {
      this.sweetAlertService.SweetAlert('ok', 'Função cadastrada com sucesso');
      this.spinner.hide();
      this.MostrarLista = !this.MostrarLista
      this.GetListaFuncao();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao cadastrar o Função');
        this.spinner.hide();
      });
  }
  AtualizarFuncao() {
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_funcao', this.formCadastroFuncao.value.id_funcao);
    this.formData.append('no_funcao', this.formCadastroFuncao.value.no_funcao);
    this.formData.append('fg_aprovado', this.formCadastroFuncao.value.fg_aprovado);
    this.clienteService.AtualizarFuncao(this.formData).subscribe((data: any) => {
      this.sweetAlertService.SweetAlert('ok', 'Função atualizado com sucesso');
      this.spinner.hide();
      this.MostrarLista = !this.MostrarLista
      this.GetListaFuncao();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao atualizar a Função');
        this.spinner.hide();
      });
  }
  ExportarFuncao(): void {
    const lista = this.ListaFuncao.map(item => ({
      funcao: item.no_funcao,
      status: item.fg_aprovado ? "Ativo" : "Inativo",
      dt_modificacao: item.dt_modificacao
    }));
    this.excelService.exportAsExcelFile(lista, 'Funcao');
  }
  GetListaTpContato() {
    this.CarregarFormBuilder();
    this.tipovisualizacao = 2;
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_tp_contato', '');
    this.clienteService.GetListaTpContato(this.formData).subscribe((data: any) => {
      this.ListaTipoContato = data.retorno;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Tipos de Contatos');
        this.spinner.hide();
      });
  }
  GetTipoContato(id_tp_contato) {
    this.formData = new FormData();
    this.formData.append('id_tp_contato', id_tp_contato);
    this.clienteService.GetListaTpContato(this.formData).subscribe((data: any) => {
      this.formCadastroTipoContato.patchValue({
        id_tp_contato: data.retorno[0].id_tp_contato,
        no_contato: data.retorno[0].no_contato,
        fg_aprovado: data.retorno[0].fg_aprovado == true ? '1' : '0'
      });
      this.MostrarLista = !this.MostrarLista
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.MostrarLista = !this.MostrarLista
        this.GetListaTpContato();
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Tipos de Contatos');
        this.spinner.hide();
      });
  }
  CrudTpContato() {
    if (this.formCadastroTipoContato.value.id_tp_contato != '') {
      this.AtualizarTpContato();
    }
    else {
      this.AdicionarTpContato();
    }
  }
  AdicionarTpContato() {
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('no_contato', this.formCadastroTipoContato.value.no_contato);
    this.formData.append('fg_aprovado', this.formCadastroTipoContato.value.fg_aprovado);
    this.clienteService.AdicionarTpContato(this.formData).subscribe((data: any) => {
      this.sweetAlertService.SweetAlert('ok', 'Tipo de Contato cadastrada com sucesso');
      this.spinner.hide();
      this.MostrarLista = !this.MostrarLista
      this.GetListaTpContato();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao cadastrar o Tipo de Contato');
        this.spinner.hide();
      });
  }
  AtualizarTpContato() {
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_tp_contato', this.formCadastroTipoContato.value.id_tp_contato);
    this.formData.append('no_contato', this.formCadastroTipoContato.value.no_contato);
    this.formData.append('fg_aprovado', this.formCadastroTipoContato.value.fg_aprovado);
    this.clienteService.AtualizarTpContato(this.formData).subscribe((data: any) => {
      this.sweetAlertService.SweetAlert('ok', 'Tipo de Contato atualizado com sucesso');
      this.spinner.hide();
      this.MostrarLista = !this.MostrarLista
      this.GetListaTpContato();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao atualizar o Tipo de Contato');
        this.spinner.hide();
      });
  }
  ExportarTipoContato(): void {
    const lista = this.ListaTipoContato.map(item => ({
      tipo_contato: item.no_contato,
      status: item.fg_aprovado ? "Ativo" : "Inativo",
      dt_modificacao: item.dt_modificacao
    }));
    this.excelService.exportAsExcelFile(lista, 'TipoContato');
  }
  GetListaOperadora() {
    this.CarregarFormBuilder();
    this.tipovisualizacao = 3;
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_operadora', '0');
    this.pacienteService.GetListaOperadora(this.formData).subscribe((data: any) => {
      this.ListaOperadora = data.retorno;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Operadoras');
        this.spinner.hide();
      });
  }
  CrudOperadora() {
    this.spinner.show();
    this.formData = new FormData();
    this.formCadastroOperadora.value.fg_aprovado = this.formCadastroOperadora.value.fg_aprovado == "1" ? true : false;
    this.formData.append('GetOperadora', JSON.stringify(this.formCadastroOperadora.value));
    this.pacienteService.CrudOperadora(this.formData).subscribe((data: any) => {
      this.sweetAlertService.SweetAlert('ok', 'Operadora cadastrada com sucesso');
      this.spinner.hide();
      this.MostrarLista = !this.MostrarLista
      this.GetListaOperadora();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao cadastrar a Operadora');
        this.spinner.hide();
      });
  }
  ExportarOperadora() {
    const lista = this.ListaOperadora.map(item => ({
      operadora: item.no_operadora,
      ans: item.nu_ans,
      cnpj: item.nu_cnpj,
      email: item.no_email,
      telefone: item.no_telefone,
      status: item.fg_aprovado ? "Ativo" : "Inativo"
    }));

    this.excelService.exportAsExcelFile(lista, 'Operadora');
  }
  GetOperadora(id_operadora) {
    this.CarregarFormBuilder();
    this.tipovisualizacao = 3;
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_operadora', id_operadora);
    this.pacienteService.GetListaOperadora(this.formData).subscribe((data: any) => {
      this.formCadastroOperadora.patchValue({
        id_operadora: data.retorno[0].id_operadora,
        nu_ans: data.retorno[0].nu_ans,
        nu_cnpj: data.retorno[0].nu_cnpj,
        no_operadora: data.retorno[0].no_operadora,
        no_email: data.retorno[0].no_email,
        no_telefone: data.retorno[0].no_telefone,
        fg_aprovado: data.retorno[0].fg_aprovado == true ? '1' : '0'
      });
      this.MostrarLista = !this.MostrarLista
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Operadoras');
        this.spinner.hide();
      });
  }

  GetListaPagamento() {
    this.CarregarFormBuilder();
    this.tipovisualizacao = 4;
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_pagamento', '0');
    this.procedimentoService.GetListaPagamento(this.formData).subscribe((data: any) => {
      this.ListaPagamento = data.retorno;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Pagamentos');
        this.spinner.hide();
      });
  }
  GetPagamento(id_pagamento) {
    this.formData = new FormData();
    this.formData.append('id_pagamento', id_pagamento);
    this.procedimentoService.GetListaPagamento(this.formData).subscribe((data: any) => {
      this.formCadastroPagamento.patchValue({
        id_pagamento: data.retorno[0].id_pagamento,
        no_pagamento: data.retorno[0].no_pagamento,
        fg_aprovado: data.retorno[0].fg_aprovado == true ? '1' : '0'
      });
      this.MostrarLista = !this.MostrarLista
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        this.MostrarLista = !this.MostrarLista
        this.GetListaPagamento();
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar o Pagamento');
        this.spinner.hide();
      });
  }
  CrudPagamento() {
    this.spinner.show();
    this.formData = new FormData();

    this.formCadastroPagamento.value.fg_aprovado = this.formCadastroPagamento.value.fg_aprovado == "1" ? true : false;

    if (this.formCadastroPagamento.value.id_pagamento != '') {
      this.formData.append('id_pagamento', this.formCadastroPagamento.value.id_pagamento);
    }
    else {
      this.formData.append('id_pagamento', '0');
    }

    this.formData.append('no_pagamento', this.formCadastroPagamento.value.no_pagamento);
    this.formData.append('fg_aprovado', this.formCadastroPagamento.value.fg_aprovado);

    this.procedimentoService.AdicionarPagamento(this.formData).subscribe((data: any) => {
      this.sweetAlertService.SweetAlert('ok', 'Pagamento cadastrada com sucesso');
      this.MostrarLista = !this.MostrarLista
      this.GetListaPagamento();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao cadastrar o Pagamento');
        this.spinner.hide();
      });
  }

  GetListaNota() {
    this.CarregarFormBuilder();
    this.tipovisualizacao = 5;
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_nota', '0');
    this.procedimentoService.GetListaNota(this.formData).subscribe((data: any) => {
      this.ListaNota = data.retorno;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar as Notas');
        this.spinner.hide();
      });
  }
  GetNota(id_nota) {
    this.formData = new FormData();
    this.formData.append('id_nota', id_nota);
    this.procedimentoService.GetListaNota(this.formData).subscribe((data: any) => {
      this.formCadastroNota.patchValue({
        id_nota: data.retorno[0].id_nota,
        no_nota: data.retorno[0].no_nota,
        fg_aprovado: data.retorno[0].fg_aprovado == true ? '1' : '0'
      });
      this.MostrarLista = !this.MostrarLista
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.MostrarLista = !this.MostrarLista
        this.GetListaNota();
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar a Nota');
        this.spinner.hide();
      });
  }
  CrudNota() {
    this.spinner.show();
    this.formData = new FormData();
    this.formCadastroNota.value.fg_aprovado = this.formCadastroNota.value.fg_aprovado == "1" ? true : false;

    if (this.formCadastroNota.value.id_nota != null) {
      this.formData.append('id_nota', this.formCadastroNota.value.id_nota);
    }
    else {
      this.formData.append('id_nota', '0');
    }
    this.formData.append('no_nota', this.formCadastroNota.value.no_nota);
    this.formData.append('fg_aprovado', this.formCadastroNota.value.fg_aprovado);

    this.procedimentoService.AdicionarNota(this.formData).subscribe((data: any) => {
      this.sweetAlertService.SweetAlert('ok', 'Nota cadastrada com sucesso');
      this.MostrarLista = !this.MostrarLista
      this.GetListaNota();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao cadastrar a Nota');
        this.spinner.hide();
      });
  }

  GetListaSLA() {
    this.GetStatusSla();
    this.CarregarFormBuilder();
    this.tipovisualizacao = 6;
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_sla', '0');
    this.procedimentoService.GetListaSLA(this.formData).subscribe((data: any) => {
      this.ListaSLA = data.retorno;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os SLAs');
        this.spinner.hide();
      });
  }
  GetOperadoraSLA(no_operadora) {
    this.isLoading = true;
    this.formData = new FormData();
    this.formData.append('no_operadora', no_operadora);
    this.pacienteService.GetOperadora(this.formData).subscribe((data: any) => {
      this.ListaOperadoraSLA = data.retorno;
      this.isLoading = false;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar as Operadora');
        this.isLoading = false;
      });
  }
  AutoComplete_SelectedItem(item) {
    if (this.initialValue == "") {
      this.formCadastroSLA.patchValue({
        id_operadora: item.id_operadora,
        no_operadora: item.no_operadora,
      });
    }
  }
  AutoComplete_Search(val: string) {
    this.initialValue = "";
    if (val.length >= 3) {
      this.GetOperadoraSLA(val);
    }
    else {
      this.initialValue = "";
      this.ListaOperadoraSLA = [];
    }
  }
  GetStatusSla() {
    function FilterStatus(status) {
        return [2,3,6,7,10].filter(a => a === status.id_status).length == 1;
    }
    this.formData = new FormData();
    this.procedimentoService.GetStatus(this.formData).subscribe((data: any) => {
      this.ListaStatusSLA = data.retorno.filter(FilterStatus);
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Status');
      });
  }

  GetDetalheSLA(id_sla) {
    this.initialValue = ' ';
    this.spinner.show();
    this.GetStatusSla();
    this.CarregarFormBuilder();
    this.tipovisualizacao = 6;
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_sla', id_sla);
    this.procedimentoService.GetListaSLA(this.formData).subscribe((data: any) => {

      setTimeout(() => {
              this.formCadastroSLA.patchValue({
                id_sla: data.retorno[0].id_sla,
                id_operadora: data.retorno[0].id_operadora,
                id_status: data.retorno[0].id_status,
                no_operadora: data.retorno[0].no_operadora,
                no_status: data.retorno[0].no_status,
                nu_dia: data.retorno[0].nu_dia,
                fg_diautil: data.retorno[0].fg_diautil == true ? '1' : '0'
              });
              this.initialValue = data.retorno[0].no_operadora;
              this.MostrarLista = !this.MostrarLista
              this.spinner.hide();
      }, 500);
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os SLAs');
        this.spinner.hide();
      });
  }

  CrudSLA() {
    this.spinner.show();
    this.formData = new FormData();
    this.formCadastroSLA.value.fg_diautil = this.formCadastroSLA.value.fg_diautil == "1" ? true : false;

    if (this.formCadastroSLA.value.id_sla != '') {
      this.formData.append('id_sla', this.formCadastroSLA.value.id_sla);
    }
    else {
      this.formData.append('id_sla', '0');
    }

    this.formData.append('id_operadora', this.formCadastroSLA.value.id_operadora);
    this.formData.append('id_status', this.formCadastroSLA.value.id_status);
    this.formData.append('fg_diautil', this.formCadastroSLA.value.fg_diautil);
    this.formData.append('nu_dia', this.formCadastroSLA.value.nu_dia);

    this.procedimentoService.AdicionarSLA(this.formData).subscribe((data: any) => {
      if (this.formCadastroSLA.value.id_sla != null) {
        this.sweetAlertService.SweetAlert('ok', 'SLA atualizado com sucesso');
      }
      else {
        this.sweetAlertService.SweetAlert('ok', 'SLA cadastrada com sucesso');
      }

      this.MostrarLista = !this.MostrarLista
      this.GetListaSLA();
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao cadastrar o SLA');
        this.spinner.hide();
      });
  }
}
