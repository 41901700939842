import { Component, OnInit, HostListener } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { UsuarioService } from '../../service/usuario/usuario.service';
import { SweetAlertService } from '../../service/commom/sweet-alert/sweet-alert.service';
import { ExcelService } from '../../service/commom//excel.service';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css'],
  host: { 'class': 'col' }
})
export class UsuarioComponent implements OnInit {
  MostrarLista: boolean = true;
  VisualizacaoCelular: boolean = false;
  formData: FormData;
  p: number = 1;
  ListaUsuario: any = [];
  ListaPerfil: any = [];
  ListaCliente: any = [];
  ListaStatus: any = [{ fg_aprovado: '1', dsstatus: 'ATIVO' }, { fg_aprovado: '0', dsstatus: 'INATIVO' }];
  formCadastroUsuario: FormGroup;
  idPerfil = localStorage.getItem('idPerfil');
  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private sweetAlertService: SweetAlertService,
    private excelService: ExcelService
  ) {
    const actualWidth = window.innerWidth;
    if (window.innerWidth < 1024) {
      this.VisualizacaoCelular = true;
    }
  }

  ngOnInit(): void {
    this.GetUsuarioLista();
    this.GetPerfil();
    this.GetClienteUsuario();
    this.CarregarFormBuilder();
  }
  CarregarTelaCadastro() {
    this.MostrarLista = !this.MostrarLista;
    setTimeout(() => {
      this.CarregarFormBuilder();
      setTimeout(() => {
        this.formCadastroUsuario.enable();
      }, 1000);
    }, 100);
  }
  CarregarFormBuilder() {
    this.formCadastroUsuario = this.formBuilder.group({
      id_usuario: [''],
      no_usuario: ['', [Validators.required, Validators.maxLength(300)]],
      no_email: ['', [Validators.required, Validators.maxLength(120), Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      no_perfil: [''],
      id_perfil: [null, [Validators.required]],
      id_cliente: [null],
      fg_aprovado: [null, [Validators.required]],
      dt_cadastro: [null],
      nu_senha: [null],
      nu_senha_repita: [null],
    });
    this.formCadastroUsuario.disable();
  }
  get f() { return this.formCadastroUsuario.controls; }
  GetUsuarioLista() {
    this.spinner.show();
    this.formData = new FormData();
    this.usuarioService.GetUsuarioLista(this.formData).subscribe((data: any) => {
      this.ListaUsuario = data.retorno;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Usuários');
      });
  }
  GetUsuarioDetalhes(id_usuario) {
    this.CarregarTelaCadastro();
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('id_usuario', id_usuario);
    this.usuarioService.GetUsuarioDetalhes(this.formData).subscribe((data: any) => {
      setTimeout(() => {
        data.retorno[0].fg_aprovado = data.retorno[0].fg_aprovado == true ? '1' : '0';

        const detalheUsuario = data.retorno.map(item => ({
          id_usuario: item.id_usuario,
          no_usuario: item.no_usuario,
          no_email: item.no_email,
          no_perfil: item.no_perfil,
          id_perfil: item.id_perfil,
          id_cliente: item.id_cliente,
          fg_aprovado: item.fg_aprovado,
          dt_cadastro: item.dt_cadastro,
          nu_senha: item.nu_senha,
          nu_senha_repita: ''
        }));

        this.formCadastroUsuario.setValue(detalheUsuario[0]);
        this.spinner.hide();
      }, 500);
    },
      (err: HttpErrorResponse) => {
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar o Usuário');
        this.spinner.hide();
      });
  }
  GetPerfil() {
    this.spinner.show();
    this.formData = new FormData();
    this.usuarioService.GetPerfil(this.formData).subscribe((data: any) => {
      this.ListaPerfil = data.retorno;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Perfil');
        this.spinner.hide();
      });
  }
  GetClienteUsuario() {
    this.spinner.show();
    this.formData = new FormData();
    this.usuarioService.GetClienteUsuario(this.formData).subscribe((data: any) => {
      this.ListaCliente = data.retorno;
      this.spinner.hide();
    },
      (err: HttpErrorResponse) => {
        this.sweetAlertService.SweetAlert('error', 'Erro ao carregar os Clientes');
        this.spinner.hide();
      });
  }
  CrudUsuario() {
    if (this.formCadastroUsuario.value.id_usuario != '') {
      this.AtualizarUsuario();
    }
    else {
      this.AdicionarUsuario();
    }
  }
  AdicionarUsuario() {
    this.spinner.show();
    this.formCadastroUsuario.value.fg_aprovado = this.formCadastroUsuario.value.fg_aprovado == "1" ? true : false;
    this.formCadastroUsuario.value.id_cliente = this.formCadastroUsuario.value.id_cliente == null ? 0 : this.formCadastroUsuario.value.id_cliente;
    this.formData = new FormData();
    this.formData.append('id_usuario', '0');
    this.formData.append('id_perfil', this.formCadastroUsuario.value.id_perfil);
    this.formData.append('no_usuario', this.formCadastroUsuario.value.no_usuario);
    this.formData.append('fg_aprovado', this.formCadastroUsuario.value.fg_aprovado);
    this.formData.append('nu_senha', this.formCadastroUsuario.value.nu_senha);
    this.formData.append('no_email', this.formCadastroUsuario.value.no_email);
    this.formData.append('id_cliente', this.formCadastroUsuario.value.id_cliente);
    this.usuarioService.AdicionarUsuario(this.formData).subscribe((data: any) => {
      this.sweetAlertService.SweetAlert('ok', 'Usuario adicionado com sucesso');
      this.CarregarTelaCadastro();
      this.GetUsuarioLista();
    },
      (err: HttpErrorResponse) => {
        this.sweetAlertService.SweetAlert('error', 'Erro ao cadastrar o Usuário');
        this.spinner.hide();
      });
  }

  AtualizarUsuario() {
    this.spinner.show();
    this.formCadastroUsuario.value.fg_aprovado = this.formCadastroUsuario.value.fg_aprovado == "1" ? true : false;
    this.formCadastroUsuario.value.id_cliente = this.formCadastroUsuario.value.id_cliente == null ? 0 : this.formCadastroUsuario.value.id_cliente;

    this.formData = new FormData();
    this.formData.append('id_usuario', this.formCadastroUsuario.value.id_usuario);
    this.formData.append('id_perfil', this.formCadastroUsuario.value.id_perfil);
    this.formData.append('no_usuario', this.formCadastroUsuario.value.no_usuario);
    this.formData.append('fg_aprovado', this.formCadastroUsuario.value.fg_aprovado);
    this.formData.append('nu_senha', this.formCadastroUsuario.value.nu_senha);
    this.formData.append('no_email', this.formCadastroUsuario.value.no_email);
    this.formData.append('id_cliente', this.formCadastroUsuario.value.id_cliente);

    this.usuarioService.AdicionarUsuario(this.formData).subscribe((data: any) => {
      this.sweetAlertService.SweetAlert('ok', 'Usuario atualizado com sucesso');
      this.CarregarTelaCadastro();
      this.GetUsuarioLista();
    },
      (err: HttpErrorResponse) => {
        this.sweetAlertService.SweetAlert('error', 'Erro ao atualizar o Usuário');
        this.spinner.hide();
      });
  }

  Exportar() {
    const detalheUsuario = this.ListaUsuario.map(item => ({
      no_usuario: item.no_usuario,
      no_email: item.no_email,
      no_perfil: item.no_perfil,
      status: item.fg_aprovado ? "Ativo" : "Inativo",
      dt_cadastro: item.dt_cadastro,
    }));
   this.excelService.exportAsExcelFile(detalheUsuario, 'Usuario');
  }
}
