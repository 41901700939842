import { ViewChild, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { UsuarioService } from '../../../service/usuario/usuario.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SweetAlertService } from '../../../service/commom/sweet-alert/sweet-alert.service';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  url: string = '';
  nome;
  mostrarGerenciamento: boolean = false;
  mostrarSenha: boolean = false;
  formData: FormData;
  idPerfil = localStorage.getItem('idPerfil');
  VisualizacaoCelular: boolean = false;
  inicio: string = "0";
  constructor(
    private router: Router,
    private usuarioService: UsuarioService,
    private sweetAlertService: SweetAlertService,
    private spinner: NgxSpinnerService,
  ) {
    const actualWidth = window.innerWidth;
    if (window.innerWidth < 1024) {
      this.VisualizacaoCelular = true;
    }
  }

  ngOnInit(): void {
    this.GetUrl();
    let nome = localStorage.getItem('userName');
    this.nome = nome;
  }

  GetUrl() {
    $('#id_menu_Home').removeClass('kt-menu__item--active').addClass('kt-menu__item--submenu');
    $('#id_menu_Cliente').removeClass('kt-menu__item--active').addClass('kt-menu__item--submenu');
    $('#id_menu_Paciente').removeClass('kt-menu__item--active').addClass('kt-menu__item--submenu');
    $('#id_menu_Reembolso').removeClass('kt-menu__item--active').addClass('kt-menu__item--submenu');
    $('#id_menu_Usuario').removeClass('kt-menu__item--active').addClass('kt-menu__item--submenu');
    $('#id_menu_Gerenciamento').removeClass('kt-menu__item--active').addClass('kt-menu__item--submenu');
    $('#id_menu_Notificacao').removeClass('kt-menu__item--active').addClass('kt-menu__item--submenu');
    $('#id_menu_Agenda').removeClass('kt-menu__item--active').addClass('kt-menu__item--submenu');

    var currentLocation = window.location;
    if (currentLocation.pathname == "/Home" && this.inicio == "1") {
      setTimeout(() => {
        if (window.location.pathname == "/Home") {
                window.location.reload();
        }

      }, 200);
    }


    setTimeout(() => {
      if (this.idPerfil == '3' && (currentLocation.pathname != '/Home')) {
        this.spinner.hide();
        this.router.navigate(['/NaoAutorizado']);
      }
      $('#id_menu_' + this.router.url.replace(/\/|_/g, '')).removeClass('kt-menu__item--submenu').addClass('kt-menu__item--active');
    }, 200);
    this.inicio  = "1";
  }

  AbrirGerenciamento() {
    this.mostrarGerenciamento = true;
    this.mostrarSenha = false;
  }

  AbrirSenha() {
    this.mostrarSenha = !this.mostrarSenha;
  }

  AlterarSenha(no_senha) {
    this.spinner.show();
    this.formData = new FormData();
    this.formData.append('no_senha', no_senha);
    this.usuarioService.AlterarSenhaLogado(this.formData).subscribe((data: any) => {
      this.spinner.hide();
      this.sweetAlertService.SweetAlert('OK', 'Senha Alterada com Sucesso!');
      this.mostrarGerenciamento = false;
    },
      (err: HttpErrorResponse) => {
        if (err.status === 401) {
          return;
        }
        this.sweetAlertService.SweetAlert('error', 'Erro ao solicitar o Resete de Senha!');
        this.spinner.hide();
      });
  }

  Sair(){
    this.router.navigate(['/login']);
  }

}
