import { Injectable } from '@angular/core';
//import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var Swal: any;

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  constructor() { }

  SweetAlert(TP_ALERTA, MSG_ALERTA) {
    switch (TP_ALERTA) {
      case 'error':
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: MSG_ALERTA == null ? 'Erro ao carregar os registros!' : MSG_ALERTA,
          timer: 5000,
          showConfirmButton: true,
          width: 600
        });
        break;
      case 'atencao':
        return Swal.fire({
          icon: 'warning',
          title: 'Atenção',
          text: MSG_ALERTA,
          width: 600,
          showConfirmButton: true,
        });
        break;
        case 'time':
          return Swal.fire({
            position: 'center',
            icon: 'success',
            title: MSG_ALERTA,
            showConfirmButton: false,
            timer: 1500,
          });
          break;
      default:
        return Swal.fire({
          position: 'center',
          icon: 'success',
          title: MSG_ALERTA,
          // showConfirmButton: false,
          //timer: 5000,
          showConfirmButton: true,
          width: 600
        });
        break;
    }
  }

}
