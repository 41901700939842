<div class="kt-grid kt-grid--ver kt-grid--root" style="height: 100%;">
			<div class="kt-grid__item kt-grid__item--fluid kt-grid  kt-error-v5" style="background: #fff;">
				<div class="kt-error_container">
					<span class="kt-error_title">
						<h1 style="font-weight: 700;font-size: 100px;">Oops!</h1>
					</span>
					<p class="kt-error_subtitle" style="font-size: 30px;font-weight: 500;color: #ec876b;"> Acesso não Autorizado !!!</p>
				</div>
			</div>
		</div>
