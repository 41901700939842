<div class="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" id="kt_content">
  <div class="kt-subheader  kt-grid__item" id="kt_subheader">
    <div class="kt-container  kt-container--fluid ">
      <div class="kt-subheader__main">
        <h3 class="kt-subheader__title">Gerenciamento de Informações</h3>
        <span class="kt-subheader__separator kt-subheader__separator--v"></span>
        <span class="kt-subheader__desc"><small>Gerenciamento >> Gerenciamento de Informações</small></span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
      <div class="kt-portlet">
        <div class="kt-portlet__body kt-portlet__body--fit">
          <div class="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2" data-ktwizard-state="step-first">
            <div class="kt-grid__item kt-wizard-v2__aside">
              <div class="kt-wizard-v2__nav">
                <div class="kt-wizard-v2__nav-items kt-wizard-v2__nav-items--clickable">
                  <div class="kt-wizard-v2__nav-item {{tipovisualizacao == 1 ? 'colorselect' : ''}}" (click)="GetListaFuncao()">
                    <div class="kt-wizard-v2__nav-body">
                      <div class="kt-wizard-v2__nav-icon">
                        <i class="flaticon-responsive"></i>
                      </div>
                      <div class="kt-wizard-v2__nav-label">
                        <div class="kt-wizard-v2__nav-label-title">
                          Função
                        </div>
                        <div class="kt-wizard-v2__nav-label-desc">
                          Cadastro de Função do Contato do Cliente
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="kt-wizard-v2__nav-item {{tipovisualizacao == 2 ? 'colorselect' : ''}}" (click)="GetListaTpContato()">
                    <div class="kt-wizard-v2__nav-body">
                      <div class="kt-wizard-v2__nav-icon">
                        <i class="flaticon-responsive"></i>
                      </div>
                      <div class="kt-wizard-v2__nav-label">
                        <div class="kt-wizard-v2__nav-label-title">
                          Tipo de Contato
                        </div>
                        <div class="kt-wizard-v2__nav-label-desc">
                          Cadastro do Tipo de Contato do Cliente
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="kt-wizard-v2__nav-item {{tipovisualizacao == 3 ? 'colorselect' : ''}}" (click)="GetListaOperadora()">
                    <div class="kt-wizard-v2__nav-body">
                      <div class="kt-wizard-v2__nav-icon">
                        <i class="flaticon-responsive"></i>
                      </div>
                      <div class="kt-wizard-v2__nav-label">
                        <div class="kt-wizard-v2__nav-label-title">
                          Operadora
                        </div>
                        <div class="kt-wizard-v2__nav-label-desc">
                          Cadastro da Operadora de Saúde
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="kt-wizard-v2__nav-item {{tipovisualizacao == 4 ? 'colorselect' : ''}}" (click)="GetListaPagamento()">
                    <div class="kt-wizard-v2__nav-body">
                      <div class="kt-wizard-v2__nav-icon">
                        <i class="flaticon-responsive"></i>
                      </div>
                      <div class="kt-wizard-v2__nav-label">
                        <div class="kt-wizard-v2__nav-label-title">
                          Pagamento
                        </div>
                        <div class="kt-wizard-v2__nav-label-desc">
                          Cadastro de Tipo de Pagamento
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="kt-wizard-v2__nav-item {{tipovisualizacao == 5 ? 'colorselect' : ''}}" (click)="GetListaNota()">
                    <div class="kt-wizard-v2__nav-body">
                      <div class="kt-wizard-v2__nav-icon">
                        <i class="flaticon-responsive"></i>
                      </div>
                      <div class="kt-wizard-v2__nav-label">
                        <div class="kt-wizard-v2__nav-label-title">
                          Notas
                        </div>
                        <div class="kt-wizard-v2__nav-label-desc">
                          Cadastro de Notas
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <div class="kt-wizard-v2__nav-item {{tipovisualizacao == 6 ? 'colorselect' : ''}}" (click)="GetListaSLA()">
                    <div class="kt-wizard-v2__nav-body">
                      <div class="kt-wizard-v2__nav-icon">
                        <i class="flaticon-responsive"></i>
                      </div>
                      <div class="kt-wizard-v2__nav-label">
                        <div class="kt-wizard-v2__nav-label-title">
                          SLA
                        </div>
                        <div class="kt-wizard-v2__nav-label-desc">
                          Cadastro dos SLAs
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
              <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid" *ngIf="tipovisualizacao == 1">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="kt-header__topbar" style="float: right;">
                      <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" (click)="GetListaFuncao()">
                        <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Carregar as Funções">
                          <span class="kt-header__topbar-icon"><i class="flaticon-refresh"></i></span>
                        </div>
                      </div>
                      <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" (click)="CadastroFuncao()">
                        <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Adicionar Novas Funções">
                          <span class="kt-header__topbar-icon"><i class="flaticon-plus"></i></span>
                        </div>
                      </div>
                      <!-- <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" [hidden]="VisualizacaoCelular" *ngIf="idPerfil == '3'">
                        <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" (click)="ExportarFuncao()" data-content="Download dos Funções">
                          <span class="kt-header__topbar-icon">
                            <i class="flaticon-download">
                            </i>
                          </span>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="kt-portlet kt-portlet--mobile" [hidden]="!MostrarLista">
                  <div class="kt-input-icon kt-input-icon--right kt-subheader__search col-lg-6 col-md-6 col-sm-12">
                    <input type="search" class="form-control" placeholder="Pesquisar" name="generalSearch" #PesquisaFuncao ngModel (keypress)="p = 1">
                    <span class="kt-input-icon__icon kt-input-icon__icon--right">
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <path
                              d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                            <path
                              d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="#000000" fill-rule="nonzero"></path>
                          </g>
                        </svg>
                      </span>
                    </span>
                  </div>
                  <div class="kt-portlet__body kt-portlet__body--fit">
                    <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded" id="kt_apps_user_list_datatable">
                      <table class="table table-striped- table-hover table-checkable dataTable no-footer dtr-inline" id="kt_table_1">
                        <thead>
                          <tr role="row">
                            <th class="sorting">Função</th>
                            <th class="sorting">Data</th>
                            <th class="sorting">Status</th>
                            <th class="sorting">Ação</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr role="row" class="odd" *ngFor="let detalhe of ListaFuncao | filter : PesquisaFuncao.value | paginate: { itemsPerPage: 5, currentPage: p }">
                            <td>{{detalhe.no_funcao}}</td>
                            <td>{{detalhe.dt_modificacao | date : 'dd/MM/yyyy'}}</td>
                            <td>
                              <span class="kt-font-bold {{detalhe.fg_aprovado == 1 ? 'kt-font-primary' : 'kt-font-danger'}}">{{detalhe.fg_aprovado == 1 ? 'Ativo' : 'Inativo'}}</span>
                            </td>
                            <td> <span class="dropdown">
                                <a class="btn btn-sm btn-clean btn-icon btn-icon-md" data-toggle="dropdown" aria-expanded="true">
                                  <i class="la la-ellipsis-h"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right kt-pointer" (click)="GetFuncao(detalhe.id_funcao)">
                                  <a class="dropdown-item">
                                    <i class="la la-edit"></i>
                                    Editar Cadastro</a>
                                </div>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div class="kt-datatable__pager kt-datatable--paging-loaded kt-padding-0">
                        <div class="kt-datatable__pager-nav">
                          <pagination-controls (pageChange)="p = $event" [maxSize]="VisualizacaoCelular ? 4 : 7" previousLabel="<" nextLabel=">"></pagination-controls>
                        </div>
                      <!--   <div class="kt-datatable__pager-info">
                          <span class="kt-datatable__pager-detail qt-pagination">Mostrando {{ListaOperadora.length}}</span>
                        </div>-->
                      </div>
                    </div>
                  </div>
                </div>
                <form class="kt-form kt-padding-0" [formGroup]="formCadastroFuncao" [hidden]="MostrarLista">
                  <div class="kt-subheader  kt-grid__item" id="kt_subheader">
                    <div class="kt-container  kt-container--fluid kt-padding-0">
                      <div class="kt-subheader__main">
                        <h3 class="kt-subheader__title">{{formCadastroFuncao.value.id_tp_contato == '' ? 'Cadastrar' : 'Atualizar'}} Função</h3>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-8">
                      <label>Nome da Função:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <input type="text" formControlName="no_funcao" class="form-control" placeholder="Digite a Função">
                        <span [hidden]="!f.no_funcao.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.no_funcao.errors }"></i></span></span>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <label>Status:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <ng-select placeholder="SELECIONE" formControlName="fg_aprovado" name="idstatus" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                          <ng-option *ngFor="let opt of ListaStatus" [value]="opt.idstatus">
                            <font style="vertical-align: inherit;">{{opt.nmstatus}}</font>
                          </ng-option>
                        </ng-select>
                        <span [hidden]="!f.fg_aprovado.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': f.fg_aprovado.errors }"></i></span></span>
                      </div>
                    </div>
                    <div class="kt-portlet__foot">
                      <div class="kt-form__actions alinhamento-div-botao">
                        <button type="reset" class="btn btn-primary alinhamento-botao" (click)="CrudFuncao()" [disabled]="formCadastroFuncao.invalid">{{formCadastroFuncao.value.id_funcao == '' ? 'Cadastrar' : 'Atualizar'}}</button>
                        <button type="reset" class="btn btn-secondary" (click)="MostrarLista = !MostrarLista">Cancelar</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid" *ngIf="tipovisualizacao == 2">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="kt-header__topbar" style="float: right;">
                      <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" (click)="GetListaTpContato()">
                        <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Carregar Tipo de Contatos">
                          <span class="kt-header__topbar-icon"><i class="flaticon-refresh"></i></span>
                        </div>
                      </div>
                      <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" (click)="CadastroFuncao()">
                        <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Adicionar Novas Funções">
                          <span class="kt-header__topbar-icon"><i class="flaticon-plus"></i></span>
                        </div>
                      </div>
                      <!-- <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" [hidden]="VisualizacaoCelular" *ngIf="idPerfil == '3'">
                        <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" (click)="ExportarTipoContato()" data-content="Download dos Funções">
                          <span class="kt-header__topbar-icon">
                            <i class="flaticon-download">
                            </i>
                          </span>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="kt-portlet kt-portlet--mobile" [hidden]="!MostrarLista">
                  <div class="kt-input-icon kt-input-icon--right kt-subheader__search col-lg-6 col-md-6 col-sm-12">
                    <input type="search" class="form-control" placeholder="Pesquisar" name="generalSearch" #PesquisaTipoContato ngModel (keypress)="p = 1">
                    <span class="kt-input-icon__icon kt-input-icon__icon--right">
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <path
                              d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                            <path
                              d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="#000000" fill-rule="nonzero"></path>
                          </g>
                        </svg>
                      </span>
                    </span>
                  </div>
                  <div class="kt-portlet__body kt-portlet__body--fit">
                    <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded" id="kt_apps_user_list_datatable">
                      <table class="table table-striped- table-hover table-checkable dataTable no-footer dtr-inline" id="kt_table_1">
                        <thead>
                          <tr role="row">
                            <th class="sorting">Tipo de Contato</th>
                            <th class="sorting">Data</th>
                            <th class="sorting">Status</th>
                            <th class="sorting">Ação</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr role="row" class="odd" *ngFor="let detalhe of ListaTipoContato  | filter : PesquisaTipoContato.value | paginate: { itemsPerPage: 5, currentPage: p }">
                            <td>{{detalhe.no_contato}}</td>
                            <td>{{detalhe.dt_modificacao | date : 'dd/MM/yyyy'}}</td>
                            <td>
                              <span class="kt-font-bold {{detalhe.fg_aprovado == 1 ? 'kt-font-primary' : 'kt-font-danger'}}">{{detalhe.fg_aprovado == 1 ? 'Ativo' : 'Inativo'}}</span>
                            </td>
                            <td> <span class="dropdown">
                                <a class="btn btn-sm btn-clean btn-icon btn-icon-md" data-toggle="dropdown" aria-expanded="true">
                                  <i class="la la-ellipsis-h"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right kt-pointer" (click)="GetTipoContato(detalhe.id_tp_contato)">
                                  <a class="dropdown-item">
                                    <i class="la la-edit"></i>
                                    Editar Cadastro</a>
                                </div>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                    <div class="kt-datatable__pager kt-datatable--paging-loaded kt-padding-0">
                        <div class="kt-datatable__pager-nav">
                          <pagination-controls (pageChange)="p = $event" [maxSize]="VisualizacaoCelular ? 4 : 7" previousLabel="<" nextLabel=">"></pagination-controls>
                        </div>  <!--
                        <div class="kt-datatable__pager-info">
                          <span class="kt-datatable__pager-detail qt-pagination">Mostrando {{ListaOperadora.length}}</span>
                        </div>-->
                      </div>
                    </div>
                  </div>
                </div>
                <form class="kt-form kt-padding-0" [formGroup]="formCadastroTipoContato" [hidden]="MostrarLista">
                  <div class="kt-subheader  kt-grid__item" id="kt_subheader">
                    <div class="kt-container  kt-container--fluid kt-padding-0">
                      <div class="kt-subheader__main">
                        <h3 class="kt-subheader__title">{{formCadastroTipoContato.value.id_tp_contato == '' ? 'Cadastrar' : 'Atualizar'}} Tipo de Contato</h3>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-8">
                      <label>Tipo de Contato:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <input type="text" formControlName="no_contato" class="form-control" placeholder="Digite o Tipo de Contato">
                        <span [hidden]="!c.no_contato.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': c.no_contato.errors }"></i></span></span>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <label>Status:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <ng-select placeholder="SELECIONE" formControlName="fg_aprovado" name="idstatus" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                          <ng-option *ngFor="let opt of ListaStatus" [value]="opt.idstatus">
                            <font style="vertical-align: inherit;">{{opt.nmstatus}}</font>
                          </ng-option>
                        </ng-select>
                        <span [hidden]="!c.fg_aprovado.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': c.fg_aprovado.errors }"></i></span></span>
                      </div>
                    </div>
                    <div class="kt-portlet__foot">
                      <div class="kt-form__actions alinhamento-div-botao">
                        <button type="reset" class="btn btn-primary alinhamento-botao" (click)="CrudTpContato()" [disabled]="formCadastroTipoContato.invalid">{{formCadastroTipoContato.value.id_tp_contato == '' ? 'Cadastrar' : 'Atualizar'}}</button>
                        <button type="reset" class="btn btn-secondary" (click)="MostrarLista = !MostrarLista">Cancelar</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid" *ngIf="tipovisualizacao == 3">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="kt-header__topbar" style="float: right;">
                      <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" (click)="GetListaOperadora()">
                        <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Carregar Tipo de Contatos">
                          <span class="kt-header__topbar-icon"><i class="flaticon-refresh"></i></span>
                        </div>
                      </div>
                      <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" (click)="CadastroFuncao()">
                        <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Adicionar Novas Funções">
                          <span class="kt-header__topbar-icon"><i class="flaticon-plus"></i></span>
                        </div>
                      </div>
                      <!-- <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" [hidden]="VisualizacaoCelular" *ngIf="idPerfil == '3'">
                        <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" (click)="ExportarOperadora()" data-content="Download dos Funções">
                          <span class="kt-header__topbar-icon">
                            <i class="flaticon-download">
                            </i>
                          </span>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="kt-portlet kt-portlet--mobile" [hidden]="!MostrarLista">
                  <div class="kt-input-icon kt-input-icon--right kt-subheader__search col-lg-6 col-md-6 col-sm-12">
                    <input type="search" class="form-control" placeholder="Pesquisar" name="generalSearch" #PesquisaOperadora ngModel (keypress)="p = 1">
                    <span class="kt-input-icon__icon kt-input-icon__icon--right">
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <path
                              d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                            <path
                              d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="#000000" fill-rule="nonzero"></path>
                          </g>
                        </svg>
                      </span>
                    </span>
                  </div>
                  <div class="kt-portlet__body kt-portlet__body--fit">
                    <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded" id="kt_apps_user_list_datatable">
                      <table class="table table-striped- table-hover table-checkable dataTable no-footer dtr-inline" id="kt_table_1">
                        <thead>
                          <tr role="row">
                            <th class="sorting">Operadora</th>
                            <th class="sorting" [hidden]="VisualizacaoCelular">CNPJ</th>
                            <th class="sorting" [hidden]="VisualizacaoCelular">ANS</th>
                            <th class="sorting">Status</th>
                            <th class="sorting">Ação</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr role="row" class="odd" *ngFor="let detalhe of ListaOperadora  | filter : PesquisaOperadora.value | paginate: { itemsPerPage: 5, currentPage: p }">
                            <td>{{detalhe.no_operadora}}</td>
                            <td [hidden]="VisualizacaoCelular">{{detalhe.nu_cnpj}}</td>
                            <td [hidden]="VisualizacaoCelular">{{detalhe.nu_ans}}</td>
                            <td>
                              <span class="kt-font-bold {{detalhe.fg_aprovado == 1 ? 'kt-font-primary' : 'kt-font-danger'}}">{{detalhe.fg_aprovado == 1 ? 'Ativo' : 'Inativo'}}</span>
                            </td>
                            <td> <span class="dropdown">
                                <a class="btn btn-sm btn-clean btn-icon btn-icon-md" data-toggle="dropdown" aria-expanded="true">
                                  <i class="la la-ellipsis-h"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right kt-pointer" (click)="GetOperadora(detalhe.id_operadora)">
                                  <a class="dropdown-item">
                                    <i class="la la-edit"></i>
                                    Editar Cadastro</a>
                                </div>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div class="kt-datatable__pager kt-datatable--paging-loaded kt-padding-0">
                        <div class="kt-datatable__pager-nav">
                          <pagination-controls (pageChange)="p = $event" [maxSize]="VisualizacaoCelular ? 4 : 7" previousLabel="<" nextLabel=">"></pagination-controls>
                        </div><!--
                        <div class="kt-datatable__pager-info">
                          <span class="kt-datatable__pager-detail qt-pagination">Mostrando {{ListaOperadora.length}}</span>
                        </div>-->
                      </div>
                    </div>
                  </div>
                </div>
                <form class="kt-form kt-padding-0" [formGroup]="formCadastroOperadora" [hidden]="MostrarLista">
                  <div class="kt-subheader  kt-grid__item" id="kt_subheader">
                    <div class="kt-container  kt-container--fluid kt-padding-0">
                      <div class="kt-subheader__main">
                        <h3 class="kt-subheader__title">{{formCadastroOperadora.value.id_operadora == '' ? 'Cadastrar' : 'Atualizar'}} Operadora de Saúde</h3>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-8">
                      <label>Nome Operadora:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <input type="text" formControlName="no_operadora" class="form-control" placeholder="Digite o Tipo de Contato">
                        <span [hidden]="!o.no_operadora.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': o.no_operadora.errors }"></i></span></span>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <label>CNPJ:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <input type="text" formControlName="nu_cnpj" class="form-control" placeholder="Digite o CNPJ" mask="00.000.000/0000-00">
                        <span [hidden]="!o.nu_cnpj.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': o.nu_cnpj.errors }"></i></span></span>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <label>ANS:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <input type="text" formControlName="nu_ans" class="form-control" placeholder="Digite o ANS">
                        <span [hidden]="!o.nu_ans.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': o.nu_ans.errors }"></i></span></span>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <label>E-Mail:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <input type="text" formControlName="no_email" class="form-control" placeholder="Digite o E-Mail">
                        <span [hidden]="!o.no_email.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': o.no_email.errors }"></i></span></span>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <label>Telefone:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <input type="text" formControlName="no_telefone" class="form-control" mask="00 0000.0000" placeholder="Digite o Telefone">
                        <span [hidden]="!o.no_telefone.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': o.no_telefone.errors }"></i></span></span>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <label>Status:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <ng-select placeholder="SELECIONE" formControlName="fg_aprovado" name="idstatus" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                          <ng-option *ngFor="let opt of ListaStatus" [value]="opt.idstatus">
                            <font style="vertical-align: inherit;">{{opt.nmstatus}}</font>
                          </ng-option>
                        </ng-select>
                        <span [hidden]="!o.fg_aprovado.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': o.fg_aprovado.errors }"></i></span></span>
                      </div>
                    </div>
                    <div class="kt-portlet__foot">
                      <div class="kt-form__actions alinhamento-div-botao">
                        <button type="reset" class="btn btn-primary alinhamento-botao" (click)="CrudOperadora()" [disabled]="formCadastroOperadora.invalid">{{formCadastroOperadora.value.id_operadora == '' ? 'Cadastrar' : 'Atualizar'}}</button>
                        <button type="reset" class="btn btn-secondary" (click)="MostrarLista = !MostrarLista">Cancelar</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid" *ngIf="tipovisualizacao == 4">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="kt-header__topbar" style="float: right;">
                      <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" (click)="GetListaPagamento()">
                        <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Carregar os Pagamento">
                          <span class="kt-header__topbar-icon"><i class="flaticon-refresh"></i></span>
                        </div>
                      </div>
                      <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" (click)="CadastroFuncao()">
                        <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Adicionar Novos Pagamentos">
                          <span class="kt-header__topbar-icon"><i class="flaticon-plus"></i></span>
                        </div>
                      </div>
                      <!-- <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" [hidden]="VisualizacaoCelular" *ngIf="idPerfil == '3'">
                        <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" (click)="ExportarFuncao()" data-content="Download dos Pagamento">
                          <span class="kt-header__topbar-icon">
                            <i class="flaticon-download">
                            </i>
                          </span>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="kt-portlet kt-portlet--mobile" [hidden]="!MostrarLista">
                  <div class="kt-input-icon kt-input-icon--right kt-subheader__search col-lg-6 col-md-6 col-sm-12">
                    <input type="search" class="form-control" placeholder="Pesquisar" name="generalSearch" #PesquisaPagamento ngModel (keypress)="p = 1">
                    <span class="kt-input-icon__icon kt-input-icon__icon--right">
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <path
                              d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                            <path
                              d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="#000000" fill-rule="nonzero"></path>
                          </g>
                        </svg>
                      </span>
                    </span>
                  </div>
                  <div class="kt-portlet__body kt-portlet__body--fit">
                    <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded" id="kt_apps_user_list_datatable">
                      <table class="table table-striped- table-hover table-checkable dataTable no-footer dtr-inline" id="kt_table_1">
                        <thead>
                          <tr role="row">
                            <th class="sorting">Pagamento</th>
                            <th class="sorting">Status</th>
                            <th class="sorting">Ação</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr role="row" class="odd" *ngFor="let detalhe of ListaPagamento | filter : PesquisaPagamento.value | paginate: { itemsPerPage: 5, currentPage: p }">
                            <td>{{detalhe.no_pagamento}}</td>
                            <td>
                              <span class="kt-font-bold {{detalhe.fg_aprovado == 1 ? 'kt-font-primary' : 'kt-font-danger'}}">{{detalhe.fg_aprovado == 1 ? 'Ativo' : 'Inativo'}}</span>
                            </td>
                            <td> <span class="dropdown">
                                <a class="btn btn-sm btn-clean btn-icon btn-icon-md" data-toggle="dropdown" aria-expanded="true">
                                  <i class="la la-ellipsis-h"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right kt-pointer" (click)="GetPagamento(detalhe.id_pagamento)">
                                  <a class="dropdown-item">
                                    <i class="la la-edit"></i>
                                    Editar Cadastro</a>
                                </div>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div class="kt-datatable__pager kt-datatable--paging-loaded kt-padding-0">
                        <div class="kt-datatable__pager-nav">
                          <pagination-controls (pageChange)="p = $event" [maxSize]="VisualizacaoCelular ? 4 : 7" previousLabel="<" nextLabel=">"></pagination-controls>
                        </div><!--
                        <div class="kt-datatable__pager-info">
                          <span class="kt-datatable__pager-detail qt-pagination">Mostrando {{ListaOperadora.length}}</span>
                        </div>-->
                      </div>
                    </div>
                  </div>
                </div>
                <form class="kt-form kt-padding-0" [formGroup]="formCadastroPagamento" [hidden]="MostrarLista">
                  <div class="kt-subheader  kt-grid__item" id="kt_subheader">
                    <div class="kt-container  kt-container--fluid kt-padding-0">
                      <div class="kt-subheader__main">
                        <h3 class="kt-subheader__title">{{formCadastroPagamento.value.id_pagamento == '' ? 'Cadastrar' : 'Atualizar'}} Pagamento</h3>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-8">
                      <label>Nome do Pagamento:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <input type="text" formControlName="no_pagamento" class="form-control" placeholder="Digite o Pagamento">
                        <span [hidden]="!a.no_pagamento.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': a.no_pagamento.errors }"></i></span></span>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <label>Status:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <ng-select placeholder="SELECIONE" formControlName="fg_aprovado" name="idstatus" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                          <ng-option *ngFor="let opt of ListaStatus" [value]="opt.idstatus">
                            <font style="vertical-align: inherit;">{{opt.nmstatus}}</font>
                          </ng-option>
                        </ng-select>
                        <span [hidden]="!a.fg_aprovado.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': a.fg_aprovado.errors }"></i></span></span>
                      </div>
                    </div>
                    <div class="kt-portlet__foot">
                      <div class="kt-form__actions alinhamento-div-botao">
                        <button type="reset" class="btn btn-primary alinhamento-botao" (click)="CrudPagamento()" [disabled]="formCadastroPagamento.invalid">{{formCadastroPagamento.value.id_pagamento == '' ? 'Cadastrar' : 'Atualizar'}}</button>
                        <button type="reset" class="btn btn-secondary" (click)="MostrarLista = !MostrarLista">Cancelar</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid" *ngIf="tipovisualizacao == 5">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="kt-header__topbar" style="float: right;">
                      <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" (click)="GetListaNota()">
                        <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Carregar os nota">
                          <span class="kt-header__topbar-icon"><i class="flaticon-refresh"></i></span>
                        </div>
                      </div>
                      <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" (click)="CadastroFuncao()">
                        <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Adicionar Novos notas">
                          <span class="kt-header__topbar-icon"><i class="flaticon-plus"></i></span>
                        </div>
                      </div>
                      <!-- <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" [hidden]="VisualizacaoCelular" *ngIf="idPerfil == '3'">
                        <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" (click)="ExportarFuncao()" data-content="Download dos nota">
                          <span class="kt-header__topbar-icon">
                            <i class="flaticon-download">
                            </i>
                          </span>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="kt-portlet kt-portlet--mobile" [hidden]="!MostrarLista">
                  <div class="kt-input-icon kt-input-icon--right kt-subheader__search col-lg-6 col-md-6 col-sm-12">
                    <input type="search" class="form-control" placeholder="Pesquisar" name="generalSearch" #PesquisaNota ngModel (keypress)="p = 1">
                    <span class="kt-input-icon__icon kt-input-icon__icon--right">
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <path
                              d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                            <path
                              d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="#000000" fill-rule="nonzero"></path>
                          </g>
                        </svg>
                      </span>
                    </span>
                  </div>
                  <div class="kt-portlet__body kt-portlet__body--fit">
                    <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded" id="kt_apps_user_list_datatable">
                      <table class="table table-striped- table-hover table-checkable dataTable no-footer dtr-inline" id="kt_table_1">
                        <thead>
                          <tr role="row">
                            <th class="sorting">Nota</th>
                            <th class="sorting">Status</th>
                            <th class="sorting">Ação</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr role="row" class="odd" *ngFor="let detalhe of ListaNota | filter : PesquisaNota.value | paginate: { itemsPerPage: 5, currentPage: p }">
                            <td>{{detalhe.no_nota}}</td>
                            <td>
                              <span class="kt-font-bold {{detalhe.fg_aprovado == 1 ? 'kt-font-primary' : 'kt-font-danger'}}">{{detalhe.fg_aprovado == 1 ? 'Ativo' : 'Inativo'}}</span>
                            </td>
                            <td> <span class="dropdown">
                                <a class="btn btn-sm btn-clean btn-icon btn-icon-md" data-toggle="dropdown" aria-expanded="true">
                                  <i class="la la-ellipsis-h"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right kt-pointer" (click)="GetNota(detalhe.id_nota)">
                                  <a class="dropdown-item">
                                    <i class="la la-edit"></i>
                                    Editar Cadastro</a>
                                </div>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div class="kt-datatable__pager kt-datatable--paging-loaded kt-padding-0">
                        <div class="kt-datatable__pager-nav">
                          <pagination-controls (pageChange)="p = $event" [maxSize]="VisualizacaoCelular ? 4 : 7" previousLabel="<" nextLabel=">"></pagination-controls>
                        </div><!--
                        <div class="kt-datatable__pager-info">
                          <span class="kt-datatable__pager-detail qt-pagination">Mostrando {{ListaOperadora.length}}</span>
                        </div>-->
                      </div>
                    </div>
                  </div>
                </div>
                <form class="kt-form kt-padding-0" [formGroup]="formCadastroNota" [hidden]="MostrarLista">
                  <div class="kt-subheader  kt-grid__item" id="kt_subheader">
                    <div class="kt-container  kt-container--fluid kt-padding-0">
                      <div class="kt-subheader__main">
                        <h3 class="kt-subheader__title">{{formCadastroNota.value.id_nota == '' ? 'Cadastrar' : 'Atualizar'}} nota</h3>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-8">
                      <label>Nome do nota:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <input type="text" formControlName="no_nota" class="form-control" placeholder="Digite o nota">
                        <span [hidden]="!b.no_nota.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': b.no_nota.errors }"></i></span></span>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <label>Status:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <ng-select placeholder="SELECIONE" formControlName="fg_aprovado" name="idstatus" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                          <ng-option *ngFor="let opt of ListaStatus" [value]="opt.idstatus">
                            <font style="vertical-align: inherit;">{{opt.nmstatus}}</font>
                          </ng-option>
                        </ng-select>
                        <span [hidden]="!b.fg_aprovado.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': b.fg_aprovado.errors }"></i></span></span>
                      </div>
                    </div>
                    <div class="kt-portlet__foot">
                      <div class="kt-form__actions alinhamento-div-botao">
                        <button type="reset" class="btn btn-primary alinhamento-botao" (click)="CrudNota()" [disabled]="formCadastroNota.invalid">{{formCadastroNota.value.id_nota == '' ? 'Cadastrar' : 'Atualizar'}}</button>
                        <button type="reset" class="btn btn-secondary" (click)="MostrarLista = !MostrarLista">Cancelar</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid" *ngIf="tipovisualizacao == 6">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="kt-header__topbar" style="float: right;">
                      <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" (click)="GetListaSLA()">
                        <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Carregar os nota">
                          <span class="kt-header__topbar-icon"><i class="flaticon-refresh"></i></span>
                        </div>
                      </div>
                      <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" (click)="CadastroFuncao()">
                        <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" data-content="Adicionar Novos SLA">
                          <span class="kt-header__topbar-icon"><i class="flaticon-plus"></i></span>
                        </div>
                      </div>
                      <!-- <div class="kt-header__topbar-item kt-header__topbar-item--search dropdown" id="kt_quick_search_toggle" [hidden]="VisualizacaoCelular" *ngIf="idPerfil == '3'">
                        <div class="kt-header__topbar-wrapper" data-toggle="kt-popover" data-placement="top" (click)="ExportarFuncao()" data-content="Download dos SLA">
                          <span class="kt-header__topbar-icon">
                            <i class="flaticon-download">
                            </i>
                          </span>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="kt-portlet kt-portlet--mobile" [hidden]="!MostrarLista">
                  <div class="kt-input-icon kt-input-icon--right kt-subheader__search col-lg-6 col-md-6 col-sm-12">
                    <input type="search" class="form-control" placeholder="Pesquisar" name="generalSearch" #PesquisaSLA ngModel (keypress)="p = 1">
                    <span class="kt-input-icon__icon kt-input-icon__icon--right">
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <path
                              d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                              fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                            <path
                              d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                              fill="#000000" fill-rule="nonzero"></path>
                          </g>
                        </svg>
                      </span>
                    </span>
                  </div>
                  <div class="kt-portlet__body kt-portlet__body--fit">
                    <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded" id="kt_apps_user_list_datatable">
                      <table class="table table-striped- table-hover table-checkable dataTable no-footer dtr-inline" id="kt_table_1">
                        <thead>
                          <tr role="row">
                            <th class="sorting">Operadora</th>
                            <th class="sorting">Status</th>
                            <th class="sorting">Dias Util</th>
                            <th class="sorting">Dias</th>
                            <th class="sorting">Ação</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr role="row" class="odd" *ngFor="let detalhe of ListaSLA | filter : PesquisaSLA.value | paginate: { itemsPerPage: 5, currentPage: p }">
                            <td>{{detalhe.no_operadora}}</td>
                            <td>{{detalhe.no_status}}</td>
                            <td>{{detalhe.fg_diautil == true ? 'Sim' : 'Não'}}</td>
                            <td>{{detalhe.nu_dia}}</td>
                            <td> <span class="dropdown">
                                <a class="btn btn-sm btn-clean btn-icon btn-icon-md" data-toggle="dropdown" aria-expanded="true">
                                  <i class="la la-ellipsis-h"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right kt-pointer" (click)="GetDetalheSLA(detalhe.id_sla)">
                                  <a class="dropdown-item">
                                    <i class="la la-edit"></i>
                                    Editar Cadastro</a>
                                </div>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div class="kt-datatable__pager kt-datatable--paging-loaded kt-padding-0">
                        <div class="kt-datatable__pager-nav">
                          <pagination-controls (pageChange)="p = $event" [maxSize]="VisualizacaoCelular ? 4 : 7" previousLabel="<" nextLabel=">"></pagination-controls>
                        </div>
                        <!-- <div class="kt-datatable__pager-info">
                          <span class="kt-datatable__pager-detail qt-pagination">Mostrando {{ListaOperadora.length}}</span>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
                <form class="kt-form kt-padding-0" [formGroup]="formCadastroSLA" [hidden]="MostrarLista">
                  <div class="kt-subheader  kt-grid__item" id="kt_subheader">
                    <div class="kt-container  kt-container--fluid kt-padding-0">
                      <div class="kt-subheader__main">
                        <h3 class="kt-subheader__title">{{formCadastroSLA.value.id_sla == '' ? 'Cadastrar' : 'Atualizar'}} SLAs</h3>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-8">
                      <label>Nome da Operadora:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <div class="kt-input-icon kt-input-icon--left">
                          <ng-autocomplete class="form-control" [data]="ListaOperadoraSLA" [initialValue]="initialValue" searchKeyword="no_operadora" (selected)='AutoComplete_SelectedItem($event)' (inputChanged)='AutoComplete_Search($event)' [itemTemplate]="itemTemplate"
                            [notFoundTemplate]="notFoundTemplate" [isLoading]='isLoading' placeholder="Digite a Operadora">
                          </ng-autocomplete>
                          <ng-template #itemTemplate let-item>
                            <a [innerHTML]="item.no_operadora"></a>
                          </ng-template>
                          <ng-template #notFoundTemplate let-notFound>
                            <div>Nenhum registro encontrado!</div>
                          </ng-template>
                          <span [hidden]="!s.id_operadora.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': s.id_operadora.errors }"></i></span></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <label>Status:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <ng-select placeholder="SELECIONE" formControlName="id_status" name="id_status" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                          <ng-option *ngFor="let opt of ListaStatusSLA" [value]="opt.id_status">
                            <font style="vertical-align: inherit;">{{opt.no_nome}}</font>
                          </ng-option>
                        </ng-select>
                        <span [hidden]="!s.id_status.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': s.id_status.errors }"></i></span></span>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <label>Dias Uteis:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <ng-select placeholder="SELECIONE" formControlName="fg_diautil" name="fg_diautil" notFoundText="Nenhum item encontrado" class="form-control ng-select">
                          <ng-option *ngFor="let opt of ListaDiasUteis" [value]="opt.fg_diautil">
                            <font style="vertical-align: inherit;">{{opt.nm_dia}}</font>
                          </ng-option>
                        </ng-select>
                        <span [hidden]="!s.fg_diautil.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': s.fg_diautil.errors }"></i></span></span>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <label>Qtd de Dias:</label>
                      <div class="kt-input-icon kt-input-icon--right">
                        <input type="text" formControlName="nu_dia" class="form-control" placeholder="Digite Qtd de Dias">
                        <span [hidden]="!s.nu_dia.errors" class="kt-input-icon__icon kt-input-icon__icon--right"><span><i class="flaticon-circle" [ngClass]="{ 'color-error': s.nu_dia.errors }"></i></span></span>
                      </div>
                    </div>
                    <div class="kt-portlet__foot">
                      <div class="kt-form__actions alinhamento-div-botao">
                        <button type="reset" class="btn btn-primary alinhamento-botao" (click)="CrudSLA()" [disabled]="formCadastroSLA.invalid">{{formCadastroSLA.value.id_sla == '' ? 'Cadastrar' : 'Atualizar'}}</button>
                        <button type="reset" class="btn btn-secondary" (click)="MostrarLista = !MostrarLista">Cancelar</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
