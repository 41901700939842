import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams, HttpEventType } from '@angular/common/http';
import { GlobalConstants } from '../../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  readonly apiURL = GlobalConstants.apiURL;
  readonly headersPost = GlobalConstants.headersPost;
  constructor(private http: HttpClient) { }

  GetGraficoOperadora(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Home/GetGraficoOperadora',formData)
  }

  GetGraficoOperadoraTotal(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Home/GetGraficoOperadoraTotal',formData)
  }
  GetStatus(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/GetStatus',formData)
  }
  GetGraficoMes(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Home/GetGraficoMes',formData)
  }
  GetGraficoDia(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Home/GetGraficoDia',formData)
  }
  GetCliente(formData: FormData) {
    return this.http.post(this.apiURL + 'api/Usuario/GetClienteUsuario',formData)
  }
  GetHomeReembolso(formData: FormData) {
    return this.http.post(this.apiURL + 'api/Home/GetHomeReembolso',formData)
  }
  GetHomePrevia(formData: FormData) {
    return this.http.post(this.apiURL + 'api/Home/GetHomePrevia',formData)
  }
  GetGraficoBMS(formData: FormData) {
    return this.http.post(this.apiURL + 'api/Home/GetGraficoBMS',formData)
  }
  GetNotificacao(formData: FormData) {
    return this.http.post(this.apiURL + 'api/Home/GetNotificacao',formData)
  }
  GetBMS(formData: FormData) {
    return this.http.post(this.apiURL + 'api/Home/GetBMS',formData)
  }
  EnvioBMS(formData: FormData) {
    return this.http.post(this.apiURL + 'api/Home/EnvioBMS',formData)
  }
  EnvioPrevia(formData: FormData) {
    return this.http.post(this.apiURL + 'api/Home/EnvioPrevia',formData)
  }
  ListaPaciente(formData: FormData) {
    return this.http.post(this.apiURL + 'api/Procedimento/GetPacienteClienteProcedimento',formData)
  }
  GetDetalhesGraficoBMS(formData: FormData) {
    return this.http.post(this.apiURL + 'api/Home/GetDetalhesGraficoBMS',formData)
  }
  GetFiltroData(formData: FormData) {
    return this.http.post(this.apiURL + 'api/Home/GetFiltroData',formData)
  }
  GetSubDetalhesGraficoBMS(formData: FormData) {
    return this.http.post(this.apiURL + 'api/Home/GetSubDetalhesGraficoBMS',formData)
  }

  GetPacienteAgendamento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/GetPacienteAgendamento',formData)
  }
  GetGraficoFaturamento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/GetGraficoFaturamento',formData)
  }
  GetAnoDisponivel(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/GetAnoDisponivel',formData)
  }
  GetPendenteTipoPagamento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/GetPendenteTipoPagamento',formData)
  }
  AtualizarPagamentoPaciente(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/AtualizarPagamentoPaciente',formData)
  }
  ConclusaoAgendamento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/ConclusaoAgendamento',formData)
  }
  GetInformacaoFaturamentoMes(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/GetInformacaoFaturamentoMes',formData)
  }
  GetInformacaoFaturamentoAnual(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/GetInformacaoFaturamentoAnual',formData)
  }
  ConclusaoPCP(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/ConclusaoPCP',formData)
  }
  GetGestaoCliente(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/GetGestaoCliente',formData)
  }
  GetGestaoPaciente(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/GetGestaoPaciente',formData)
  }
  GetGestaoPacienteSub(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/GetGestaoPacienteSub',formData)
  }
  GetGestaoPacienteConclusao(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/GetGestaoPacienteConclusao',formData)
  }
  GestaoPacienteCheck(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/GestaoPacienteCheck',formData)
  }
  GetAgendamentoDetalhes(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/GetAgendamentoDetalhes',formData)
  }
  SalvarAgendamento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Agendamento/SalvarAgendamento',formData)
  }
  GetClienteAgendamento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Usuario/GetClienteAgendamento',formData)
  }
}
