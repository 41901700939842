import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams, HttpEventType } from '@angular/common/http';
import { GlobalConstants } from '../../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  readonly apiURL = GlobalConstants.apiURL;
  readonly headersPost = GlobalConstants.headersPost;
  constructor(private http: HttpClient) { }

  Get_Funcao(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Cliente/Get_Funcao',formData)
  }
  Get_Tp_Pix(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Cliente/Get_Tp_Pix',formData)
  }
  Get_Tp_Contato(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Cliente/Get_Tp_Contato',formData)
  }
  GetListaCliente(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Cliente/GetListaCliente',formData)
  }
  AdicionarCliente(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Cliente/AdicionarCliente',formData)
  }
  AtualizarCliente(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Cliente/AtualizarCliente',formData)
  }
  GetCliente(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Cliente/GetCliente',formData)
  }

  GetListaFuncao(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Cliente/GetListaFuncao',formData)
  }
  AdicionarFuncao(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Cliente/AdicionarFuncao',formData)
  }
  AtualizarFuncao(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Cliente/AtualizarFuncao',formData)
  }

  GetListaTpContato(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Cliente/GetListaTpContato',formData)
  }
  AdicionarTpContato(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Cliente/AdicionarTpContato',formData)
  }
  AtualizarTpContato(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Cliente/AtualizarTpContato',formData)
  }
  Get_TipoReembolso(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Cliente/Get_TipoReembolso',formData)
  }
  Get_Processos(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Cliente/Get_Processos',formData)
  }
  GetTipoPagamento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/GetTipoPagamento',formData)
  }
  GetCodigo(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Cliente/GetCodigo',formData)
  }
  GetEnvioEmail(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Cliente/GetEnvioEmail',formData)
  }
  GetCNPJ(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Cliente/GetCNPJ',formData)
  }
}
