import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams, HttpEventType } from '@angular/common/http';
import { GlobalConstants } from '../../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class ProcedimentoService {
  readonly apiURL = GlobalConstants.apiURL;
  readonly headersPost = GlobalConstants.headersPost;
  constructor(private http: HttpClient) { }

  GetListaProcedimento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/GetListaProcedimento',formData)
  }
  GetClienteProcedimento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/GetClienteProcedimento',formData)
  }
  GetReembolsoProcedimento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/GetReembolsoProcedimento',formData)
  }
  GetProcessoProcedimento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/GetProcessoProcedimento',formData)
  }
  GetPacienteProcedimento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/GetPacienteProcedimento',formData)
  }
  GetCarteiraProcedimento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/GetCarteiraProcedimento',formData)
  }
  Get_Tuss(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/Get_Tuss',formData)
  }
  GetTipoPagamento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/GetTipoPagamento',formData)
  }
  AdicionarProcedimento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/AdicionarProcedimento',formData)
  }
  GetProcedimento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/GetProcedimento',formData)
  }
  GetStatus(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/GetStatus',formData)
  }
  GetProtocolos(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/GetProtocolos',formData)
  }

  AdicionarProtocolo(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/AdicionarProtocolo',formData)
  }

  GetHistoricoStatus(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/GetHistoricoStatus',formData)
  }

  GetListaPagamento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/GetListaPagamento',formData)
  }
  AdicionarPagamento(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/AdicionarPagamento',formData)
  }
  GetListaNota(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/GetListaNota',formData)
  }
  AdicionarNota(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/AdicionarNota',formData)
  }

  GetNota(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/GetNota',formData)
  }

  GetListaSLA(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/GetListaSLA',formData)
  }
  AdicionarSLA(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/AdicionarSLA',formData)
  }
  GetDadosPaciente(formData: FormData) {
  return this.http.post(this.apiURL + 'api/Procedimento/GetDadosPaciente',formData)
  }

}
